import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import ErrorIcon from '@mui/icons-material/Error';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import { CircularProgress, Grid, SxProps, Theme, Typography } from '@mui/material';

const fullSizePreview: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
};

const pdfIcon: SxProps<Theme> = {
  width: '50%',
  height: '50%',
};

const placeholderContainer: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  height: '100%',
};

const imagePlaceholderText: SxProps<Theme> = (theme: Theme) => ({
  alignSelf: 'center',
  fontWeight: 'bold',
  marginTop: 2,
  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
  },
});

const PdfViewer = ({ file }: { file: any }) => {
  const [numPages, setNumPages] = useState<Number | null>(null);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  return (
    <>
      <Grid sx={fullSizePreview}>
        {!file.cachedUrl && !file.isMalware && (
          <Grid sx={placeholderContainer}>
            <PictureAsPdf sx={pdfIcon} data-testid="pdf-placeholder-image" />{' '}
            <Typography sx={imagePlaceholderText}>
              File preview image not found. If the problem persists, please contact support.
            </Typography>
          </Grid>
        )}

        {file.isMalware && (
          <Grid sx={placeholderContainer}>
            <ErrorIcon sx={pdfIcon} data-testid="malware-icon-pdf-view" />{' '}
            <Typography sx={imagePlaceholderText}>Malware detected. This file is unavailable.</Typography>
          </Grid>
        )}

        {file.cachedUrl && !file.isMalware && (
          <Grid data-testid="pdf-viewer">
            <Document
              file={{ url: file.cachedUrl }}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={(error: any) => {
                console.log('PDF ERROR', error);
              }}
              loading={<CircularProgress color="primary" />}
            >
              {Array.from(new Array(numPages), (_, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default PdfViewer;
