import PropTypes from 'prop-types';
import { FC } from 'react';
import { formatDate } from 'utils/general';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import EqualIcon from '@mui/icons-material/DragHandle';
import { Grid, Hidden, SxProps, Theme, Tooltip, Typography } from '@mui/material';

const certifiedIcon: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.success.main,
});

const classificationData: SxProps<Theme> = {
  fontSize: '0.75rem',
};

const diverseBusinessTotalStyles: SxProps<Theme> = {
  display: 'none',
};

export enum ClassificationCertificationStatusChangeOption {
  NoChange = 'No Change',
  Certified = 'Certified',
  NotCertified = 'Not Certified',
}

type ClassificationCertificationStatusProps = {
  changeOption: ClassificationCertificationStatusChangeOption;
  classificationDisplayName?: string;
  startIsoDate?: string;
  endIsoDate?: string | null;
};

const getDateRangeString = (startIsoDate?: string, endIsoDate?: string | null) => {
  if (startIsoDate && endIsoDate) {
    return ` from ${formatDate(startIsoDate)} until ${formatDate(endIsoDate)}`;
  } else if (startIsoDate) {
    return ` as of ${formatDate(startIsoDate)}`;
  } else if (endIsoDate) {
    return ` until ${formatDate(endIsoDate)}`;
  } else {
    return '';
  }
};

const ClassificationCertificationStatusChangeDisplayOption: FC<ClassificationCertificationStatusProps> = ({
  changeOption,
  classificationDisplayName,
  startIsoDate,
  endIsoDate,
}) => {
  const classificationDisplayNamePart = classificationDisplayName ? ` ${classificationDisplayName}` : '';
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      spacing={1}
      sx={classificationDisplayName === 'DBT' ? diverseBusinessTotalStyles : null}
    >
      <Grid item>
        <Tooltip
          arrow
          title={changeOption + classificationDisplayNamePart + getDateRangeString(startIsoDate, endIsoDate)}
        >
          <Grid container direction="row" alignItems="center" spacing={1}>
            <Grid item>
              {changeOption === ClassificationCertificationStatusChangeOption.Certified ? (
                <CheckCircleIcon sx={certifiedIcon} />
              ) : changeOption === ClassificationCertificationStatusChangeOption.NotCertified ? (
                <CloseIcon />
              ) : (
                <EqualIcon />
              )}
            </Grid>
            <Hidden only={'xs'}>
              <Grid item>
                <Typography sx={classificationData}>{changeOption}</Typography>
              </Grid>
            </Hidden>
          </Grid>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

ClassificationCertificationStatusChangeDisplayOption.propTypes = {
  changeOption: PropTypes.oneOf(Object.values(ClassificationCertificationStatusChangeOption)).isRequired,
  classificationDisplayName: PropTypes.string,
  startIsoDate: PropTypes.string,
  endIsoDate: PropTypes.string,
};

export default ClassificationCertificationStatusChangeDisplayOption;
