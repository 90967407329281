import { SmallBusinessRequirementOption } from 'constants/smallBusinessRequirementOptions';
import { ControllerRenderProps } from 'react-hook-form';

import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';

const radioGroup: SxProps<Theme> = (theme: Theme) => ({
  marginLeft: theme.spacing(3),
});

const radioLabel: SxProps<Theme> = (theme: Theme) => ({
  marginTop: theme.spacing(0.5),
});

const SmallBusinessRequirementField = (field: ControllerRenderProps) => {
  return (
    <RadioGroup {...field} sx={radioGroup}>
      <Grid container direction="row" spacing={2}>
        <Grid item>
          <FormControlLabel
            value={SmallBusinessRequirementOption.HP_SELF_REPORTING}
            control={<Radio size="small" color="primary" />}
            label={<Typography sx={radioLabel}>{SmallBusinessRequirementOption.HP_SELF_REPORTING}</Typography>}
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            value={SmallBusinessRequirementOption.FEDERAL}
            control={<Radio size="small" color="primary" />}
            label={<Typography sx={radioLabel}>{SmallBusinessRequirementOption.FEDERAL}</Typography>}
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            value={SmallBusinessRequirementOption.NON_FEDERAL}
            control={<Radio size="small" color="primary" />}
            label={<Typography sx={radioLabel}>{SmallBusinessRequirementOption.NON_FEDERAL}</Typography>}
          />
        </Grid>
      </Grid>
    </RadioGroup>
  );
};

export default SmallBusinessRequirementField;
