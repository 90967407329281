import StyledButtonPrimary from 'components/shared/ButtonPrimary/ButtonPrimary';
import { getApolloClient } from 'config/apolloConfig';
import useToast from 'hooks/useToast';
import { FC } from 'react';

import { ApolloProvider } from '@apollo/client';
import { PublicClientApplication } from '@azure/msal-browser';

const Reloader = () => {
  return (
    <StyledButtonPrimary
      label="Reload Page"
      onClick={() => {
        localStorage.clear();
        window.location.reload();
      }}
    />
  );
};

export type MercuryApolloProviderProps = {
  /** MSAL instance needed to acquire Azure AD access token for sending to the API */
  msalInstance: PublicClientApplication;
  /** standard children prop: accepts any valid React Node */
  children: React.ReactNode;
};

const MercuryApolloProvider: FC<MercuryApolloProviderProps> = ({ msalInstance, children }) => {
  const { displayToast } = useToast();
  const onWsError = (error: any) => {
    console.error('Websocket error:');
    console.error(error);
    displayToast(
      'Please try reloading the page. If the problem persists, please contact support.',
      'warning',
      Reloader,
      false,
    );
  };
  const apolloClient = getApolloClient(msalInstance, onWsError);
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default MercuryApolloProvider;
