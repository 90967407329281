import { DateTime } from 'luxon';
import { FC } from 'react';

import { CalendarPickerView } from '@mui/lab/CalendarPicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

type StyledDatePickerProps = {
  label?: string;
  value: DateTime | undefined | null;
  handleDateChange: (x: DateTime | null) => void;
  openTo?: CalendarPickerView;
  helperText?: string;
  meta?: any;
  disableFuture?: boolean;
  disablePast?: boolean;
  disabled?: boolean;
  shouldDisplayAsError?: boolean;
  minDate?: any;
  isRequired?: boolean;
};

const StyledDatePicker: FC<StyledDatePickerProps> = ({
  label,
  value,
  handleDateChange,
  openTo = 'day',
  meta,
  disableFuture = false,
  disablePast = false,
  disabled,
  shouldDisplayAsError = false,
  minDate,
  isRequired = false,
  helperText,
  ...rest
}) => {
  return (
    <DatePicker
      label={label}
      value={value}
      onChange={(date: DateTime | null) => handleDateChange(date)}
      openTo={openTo}
      format="D"
      minDate={minDate}
      slotProps={{
        actionBar: {
          actions: ['clear', 'cancel'],
        },
        textField: {
          required: isRequired,
          error: isRequired,
          helperText: !value && helperText,
        },
      }}
      {...rest}
    />
  );
};

export default StyledDatePicker;
