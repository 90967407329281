import { Grid, Paper, SxProps, Theme } from '@mui/material';

const container: SxProps<Theme> = (theme: Theme) => ({
  height: 'calc(100vh - 176px)',
  [theme.breakpoints.down('md')]: {
    height: 'calc(100vh - 152px)',
  },
  [theme.breakpoints.down('sm')]: {
    height: 'calc(100vh - 104px)',
  },
});

const paper: SxProps<Theme> = (theme: Theme) => ({
  borderTop: '12px solid' + theme.palette.primary.main,
});

const contentStyles: SxProps<Theme> = (theme: Theme) => ({
  padding: theme.spacing(3),
  borderRadius: 4,
});

type StyledUserNoticeTemplateProps = {
  image: React.ReactNode;
  content: React.ReactNode;
};

export const StyledUserNoticeTemplate: React.FC<StyledUserNoticeTemplateProps> = ({ image, content }) => {
  return (
    <Grid container justifyContent="center" alignItems="center" alignContent="center" sx={container}>
      <Grid item>
        <Paper sx={paper}>
          <Grid container direction="column" justifyContent="center" alignItems="center" sx={contentStyles}>
            <Grid item xs={12}>
              {image}
            </Grid>
            <Grid item xs={12}>
              {content}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default StyledUserNoticeTemplate;
