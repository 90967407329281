import { gql } from '@apollo/client';

export const GET_SMALL_BUSINESS_REPORT = gql`
  query projectSmallBusinessReport(
    $smallBusinessReportSettingsId: ID!
    $startDate: String
    $endDate: String
    $form: SmallBusinessFederalReportFormOption
  ) {
    projectSmallBusinessReport(
      smallBusinessReportSettingsId: $smallBusinessReportSettingsId
      startDate: $startDate
      endDate: $endDate
      form: $form
    ) {
      checksum
      currentContractValue
      currentSubContractorContractValue
      classificationSummaries {
        abbreviation
        actualPercentTotal
        actualPercentOriginal
        contractIds
        lowerTierParticipationIds
        actualTotal
        lowerTierParticipationTotal
        lowerTierParticipationPercentOriginal
        lowerTierPercentTotal
        firstTierTotal
        firstTierPercentOriginal
        firstTierPercentTotal
        sortOrder
        smallBusinessReportClassificationId
        smallBusinessClassificationId
        goalPercent
        goalTotal
        name
        isFederal
      }
      contractSummaries {
        companyId
        companyName
        coverages {
          id
          isFederal
          smallBusinessClassification {
            id
            name
            abbreviation
            sortOrder
            isFederal
            created
            lastModified
          }
          startDate
          endDate
          smallBusinessAgency {
            id
            name
            abbreviation
          }
        }
        smallBusinessClassifications {
          id
          name
          abbreviation
          isFederal
        }
        contractId
        phaseCode
        effectiveDate
        changeOrderSummaries {
          date
          value
        }
        valueTotal
        isSelfWork
        participationPercentage
        lowerTierParticipationClassificationSummaries {
          lowerTierParticipationId
          contractId
          value
          lowerTierCompanyName
          smallBusinessClassifications {
            id
            name
            abbreviation
          }
          effectiveDate
          lowerTierParticipationFileSummary {
            id
            file {
              id
              contentType
              created
              isMalware
              isScanned
              lastModified
              name
              sizeInBytes
              url
              cachedUrl @client
            }
            uploadedBy {
              id
              name
            }
            smallBusinessAgency {
              id
              name
              abbreviation
            }
            lowerTierParticipationClassifications {
              id
              smallBusinessClassification {
                id
                name
                abbreviation
              }
            }
            participationPercentage
          }
        }
      }
    }
  }
`;

export const SAVE_SMALL_BUSINESS_REPORT = gql`
  mutation saveProjectSmallBusinessReport(
    $comments: String
    $smallBusinessReportSettingsId: ID!
    $startDate: String
    $endDate: String
    $transactionKey: String!
    $form: SmallBusinessFederalReportFormOption
    $type: SmallBusinessSavedReportTypeOption!
  ) {
    saveProjectSmallBusinessReport(
      comments: $comments
      smallBusinessReportSettingsId: $smallBusinessReportSettingsId
      startDate: $startDate
      endDate: $endDate
      transactionKey: $transactionKey
      form: $form
      type: $type
    ) {
      checksum
      currentContractValue
      currentSubContractorContractValue
    }
  }
`;
