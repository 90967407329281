import { Fragment, useState } from 'react';
import Select, { GroupBase, StylesConfig } from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager';

import { FormHelperText, SxProps, Theme, Typography, useTheme } from '@mui/material';

const errorFormHelperText: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.error.main,
});

const labelText: SxProps<Theme> = {
  fontFamily: ['Helvetica LT std', 'Helvetica', 'Arial'].join(','),
  fontWeight: 'bold',
  lineHeight: 1.5,
  fontSize: '0.75rem',
};

export interface StyledAutoCompleteOptions<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> extends StateManagerProps<Option, IsMulti, Group> {
  selectedValue?: Option;
  isRequired?: boolean;
  label?: string;
  customError?: string;
}

export function StyledAutoComplete<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  isDisabled = false,
  selectedValue,
  isRequired = false,
  label,
  customError,
  autoFocus = false,
  isMulti,
  isClearable = true,
  ...rest
}: StyledAutoCompleteOptions<Option, IsMulti, Group>): JSX.Element {
  const theme = useTheme();
  const [selectHasBeenFocused, handleFocusSelectChange] = useState(false);

  const selectIsInvalid = (() => {
    return selectHasBeenFocused && !selectedValue;
  })();

  const menuPortalStyles: StylesConfig<Option, IsMulti, Group> = {
    menuPortal: (styles) => ({ ...styles, zIndex: 9999 }),
    menu: (base) => ({
      ...base,
      fontFamily: ['Helvetica LT std', 'Helvetica', 'Arial'].join(','),
      backgroundColor: theme.palette.background.default,
    }),
    control: (base) => ({
      ...base,
      boxShadow: 'none',
      backgroundColor: theme.palette.background.default,
      borderColor: selectIsInvalid ? theme.palette.error.main : theme.palette.secondary.main,
      '&:hover': {
        borderColor: selectIsInvalid ? theme.palette.error.main : theme.palette.background.default,
      },
      '&:focused': {
        boxShadow: 'none',
      },
    }),
    option: (provided) => ({
      ...provided,
      backgroundColor: theme.palette.background.default,
      color: theme.palette.secondary.contrastText,
    }),
    input: (provided) => ({
      ...provided,
      color: theme.palette.secondary.contrastText,
    }),
    placeholder: (provided) => ({
      ...provided,
      fontFamily: ['Helvetica LT std', 'Helvetica', 'Arial'].join(','),
      color: 'inherit',
      fontSize: '0.875rem',
    }),
    loadingIndicator: (provided) => ({
      ...provided,
      color: theme.palette.secondary.contrastText,
    }),
    singleValue: (provided) => ({
      ...provided,
      fontFamily: ['Helvetica LT std', 'Helvetica', 'Arial'].join(','),
      color: theme.palette.secondary.contrastText,
    }),
    multiValue: (provided) => ({
      ...provided,
      fontFamily: ['Helvetica LT std', 'Helvetica', 'Arial'].join(','),
      color: theme.palette.secondary.contrastText,
    }),
  };

  return (
    <Fragment>
      {label && (
        <Fragment>
          {!(selectHasBeenFocused && customError) && (
            <Typography color="textSecondary" sx={labelText}>
              {isRequired ? `${label} *` : label}
            </Typography>
          )}
          {selectHasBeenFocused && customError && (
            <Typography color="error" sx={labelText}>
              {isRequired ? `${label} *` : label}
            </Typography>
          )}
        </Fragment>
      )}
      <Select
        aria-label={isMulti ? 'multiselect-input' : 'select-input'}
        filterOption={() => true}
        menuPortalTarget={document.body}
        isMulti={isMulti}
        styles={menuPortalStyles}
        isDisabled={isDisabled}
        isClearable={isClearable}
        backspaceRemovesValue
        value={selectedValue ? selectedValue : isMulti ? [] : null}
        onFocus={() => handleFocusSelectChange(true)}
        autoFocus={autoFocus}
        {...rest}
      />
      {!isDisabled && customError && <FormHelperText sx={errorFormHelperText}>{customError}</FormHelperText>}
    </Fragment>
  );
}
