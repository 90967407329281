import { HP_LIGHT_THEME } from 'constants/themes';
import { FC } from 'react';

import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';

export type MercuryThemeProviderProps = {
  /** standard children prop: accepts any valid React Node */
  children: React.ReactNode;
};

const MercuryThemeProvider: FC<MercuryThemeProviderProps> = ({ children }) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={HP_LIGHT_THEME}>
        <CssBaseline />
        <div>{children}</div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default MercuryThemeProvider;
