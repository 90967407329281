import ErrorNotice from 'components/shared/ErrorNotice';
import useToast from 'hooks/useToast';
import { FC } from 'react';
import { useGetProjectsContractedAfterQuery } from 'types/generated/graphql';

import { ApolloClient } from '@apollo/client';
import { Grid, List, ListItem, Typography } from '@mui/material';

type RelatedContractsSummaryProps = {
  apolloClient: ApolloClient<object>;
  companyId: string;
  afterIsoDate: string;
};

const RelatedContractsSummary: FC<RelatedContractsSummaryProps> = ({
  apolloClient,
  companyId,
  afterIsoDate,
}: RelatedContractsSummaryProps) => {
  const {
    loading: contractedProjectsLoading,
    error: contractedProjectsError,
    data: contractedProjectsData,
  } = useGetProjectsContractedAfterQuery({
    client: apolloClient,
    variables: {
      companyId,
      date: afterIsoDate,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const { displayToast } = useToast();
  if (contractedProjectsError) {
    console.error(contractedProjectsError);
    displayToast(
      'Error: Something went wrong while fetching contracted projects. If the problem persists after reloading the page, please contact support.',
      'error',
    );
  }
  if (contractedProjectsError) {
    return <ErrorNotice />;
  }
  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography>This change may reflect on contracted projects.</Typography>
      </Grid>
      {contractedProjectsLoading && (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography>Loading...</Typography>
        </Grid>
      )}
      {!contractedProjectsLoading && !!contractedProjectsData?.getProjectsContractedAfter?.length && (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <List>
            {contractedProjectsData?.getProjectsContractedAfter?.map((project) => (
              <ListItem key={project.number}>{project.number + ' - ' + project.name}</ListItem>
            ))}
          </List>
        </Grid>
      )}
      {!contractedProjectsLoading && !contractedProjectsData?.getProjectsContractedAfter?.length && (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography>No contracted projects found.</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default RelatedContractsSummary;
