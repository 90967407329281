import StyledButtonPrimary from 'components/shared/ButtonPrimary';
import StyledButtonSecondary from 'components/shared/ButtonSecondary';
import { GET_SINGLE_PROJECT, UPDATE_PROJECT } from 'graphql/projects';
import { GET_SMALL_BUSINESS_REPORT } from 'graphql/smallBusinessReport';
import useToast from 'hooks/useToast';
import { FC, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import {
  SmallBusinessFederalReportFormOptionSelection,
} from 'recoil/smallBusinessFederalReportFormOption/atom';
import {
  GetSingleProjectQueryResult,
  ProjectSmallBusinessReportQueryResult,
  SmallBusinessClassification,
  SmallBusinessClient,
} from 'types/generated/graphql';

import { useMutation } from '@apollo/client';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EditIcon from '@mui/icons-material/Edit';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import {
  Box,
  Card,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  SxProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material';

const sectionStyles: SxProps<Theme> = (theme: Theme) => ({
  marginBottom: theme.spacing(2),
});

const cardStyles: SxProps<Theme> = (theme: Theme) => ({
  padding: theme.spacing(2, 2, 2.5, 2),
});

const cardColumnContainer: SxProps<Theme> = (theme: Theme) => ({
  [theme.breakpoints.down('sm')]: { marginBottom: theme.spacing(2) },
});

const cardColumnTitle: SxProps<Theme> = {
  textTransform: 'uppercase',
  fontWeight: 'bold',
};

const cardColumnTitleActive: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.primary.main,
});

const cardColumnIconContainer: SxProps<Theme> = (theme: Theme) => ({
  marginRight: theme.spacing(0.5),
});

const cardColumnContainerActive: SxProps<Theme> = (theme: Theme) => ({
  border: '1px solid' + theme.palette.primary.main,
  paddingRight: 4,
});

const cardColumnIcon: SxProps<Theme> = {
  color: '#636363',
  fontSize: '3rem',
};

const editButtonContainer: SxProps<Theme> = (theme: Theme) => ({
  marginBottom: -8,
  [theme.breakpoints.down('md')]: { marginBottom: 0 },
});

const labelEditing: SxProps<Theme> = {
  fontWeight: 'bold',
};

const radioGroup: SxProps<Theme> = (theme: Theme) => ({
  marginLeft: theme.spacing(3),
});

const radioLabel: SxProps<Theme> = (theme: Theme) => ({
  marginTop: theme.spacing(0.5),
});

const editingModeIndicator: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.primary.main,
  margin: theme.spacing(4, 4, 0, 0),
});

const editingContainer: SxProps<Theme> = (theme: Theme) => ({
  padding: theme.spacing(4),
});

const notesCard: SxProps<Theme> = (theme: Theme) => ({
  backgroundColor: '#f2f2f2',
  boxShadow: 'none',
  padding: theme.spacing(2),
});

const notesSection: SxProps<Theme> = {
  whiteSpace: 'pre-wrap',
};

const notesLabel: SxProps<Theme> = (theme: Theme) => ({
  fontWeight: 'bold',
  marginTop: theme.spacing(2),
});

const projectField: SxProps<Theme> = {
  paddingTop: 2,
};

type CardColumnProps = {
  icon?: React.ReactNode;
  title: string;
  amount?: number;
  contractNumber?: string;
  isActive?: Boolean;
};

const CardColumn: FC<CardColumnProps> = ({ icon, title, amount, contractNumber, isActive = false }) => {
  return (
    <Grid container direction="row" alignItems="center" sx={cardColumnContainer}>
      <Box component="span" sx={isActive ? cardColumnContainerActive : undefined}>
        <Grid container direction="row">
          <Grid item sx={cardColumnIconContainer}>
            {icon}
          </Grid>
          <Grid item>
            <Typography sx={isActive ? cardColumnTitleActive : cardColumnTitle} color="textSecondary">
              {title}
            </Typography>
            {!!amount && (
              <NumberFormat
                value={amount}
                displayType={'text'}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale={true}
                allowLeadingZeros={false}
                isNumericString={true}
                thousandSeparator={true}
                margin="dense"
                variant="outlined"
                color="primary"
              />
            )}
            {!!contractNumber && <Typography>{contractNumber}</Typography>}
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

const TotalsDisplay: FC<{ title: string; total: number }> = ({ title, total }) => {
  return (
    <Grid>
      <Typography display="inline">{title}: </Typography>
      <NumberFormat
        value={total}
        displayType={'text'}
        prefix={'$'}
        decimalScale={2}
        fixedDecimalScale={true}
        allowLeadingZeros={false}
        isNumericString={true}
        thousandSeparator={true}
        margin="dense"
        variant="outlined"
        color="primary"
      />
    </Grid>
  );
};

type PickedClients = Pick<SmallBusinessClient, 'id' | 'name' | 'abbreviation'>;
type PickedSmallBusinessClassification = Pick<
  SmallBusinessClassification,
  'id' | 'abbreviation' | 'name' | 'sortOrder'
>;

type UpdateFormInput = {
  isActualCalculationsBasedOnTotalContractValue?: string;
  originalContractAmount: string;
  originalSubcontractAmount: string;
  primeContractNumber?: string;
  smallBusinessRequirement?: string;
  notes?: string;
  client?: PickedClients | null;
};

type SmallBusinessContractAmountsCardProps = {
  project?: Exclude<GetSingleProjectQueryResult['data'], undefined>['project'];
  report?: Exclude<ProjectSmallBusinessReportQueryResult['data'], undefined>['projectSmallBusinessReport'];
  editable?: boolean;
  largeBusinessClassification?: SmallBusinessClassification | PickedSmallBusinessClassification | undefined;
  selectedReportOption?: SmallBusinessFederalReportFormOptionSelection;
};

const SmallBusinessContractAmountsCard: FC<SmallBusinessContractAmountsCardProps> = ({
  project,
  report,
  editable,
  largeBusinessClassification,
  selectedReportOption,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const { displayToast } = useToast();

  const [updateProject, { loading: isLoading }] = useMutation(UPDATE_PROJECT, {
    refetchQueries: [
      {
        query: GET_SINGLE_PROJECT,
        variables: { id: project?.id },
      },
      {
        query: GET_SMALL_BUSINESS_REPORT,
        variables: {
          smallBusinessReportSettingsId: project?.smallBusinessReportSettings?.id,
          form: selectedReportOption === '' ? undefined : selectedReportOption,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const defaultValues = {
    isActualCalculationsBasedOnTotalContractValue:
      project?.smallBusinessReportSettings?.isActualCalculationsBasedOnTotalContractValue?.toString() ?? '',
    originalContractAmount: project?.originalContractValue?.toString() ?? '',
    originalSubcontractAmount: project?.originalSubcontractValue?.toString() ?? '',
    primeContractNumber: project?.primeContractNumber ?? '',
    notes: project?.smallBusinessReportSettings?.notes ?? '',
  };

  const { handleSubmit, control, watch, formState, reset } = useForm<UpdateFormInput>({ defaultValues });

  const { isDirty, isValid } = formState;

  const originalContractAmount = watch('originalContractAmount');
  const originalSubcontractAmount = watch('originalSubcontractAmount');
  const isActualCalculationsBasedOnTotalContractValue = watch('isActualCalculationsBasedOnTotalContractValue');
  const actualCalculationIsBasedOnTotalContractValue = isActualCalculationsBasedOnTotalContractValue === 'true';
  const noOriginalContractAmount = originalContractAmount === '0';
  const noOriginalSubcontractAmount = originalSubcontractAmount === '0';

  const handleCancel = () => {
    setIsEditing(false);
    reset(defaultValues);
  };

  const handleEditButtonClick = () => {
    setIsEditing(true);
    reset(defaultValues); // Re-initialize based on latest project info
  };

  let sbTotalForTotalCurrentContract = 0;
  let lbTotalForTotalCurrentContract = 0;
  let lbTotalForTotalCurrentContractWithNoCompany = 0;
  let sbTotalForSubcontractedTotalContract = 0;
  let lbTotalForSubcontractedTotalContract = 0;

  report?.contractSummaries.forEach((summary) => {
    const summaryHasLargeBusinessClassification = summary.smallBusinessClassifications.some(
      (classification) => classification.id === largeBusinessClassification?.id,
    );
    if (summary.companyId) {
      if (!summary.isSelfWork) {
        if (summaryHasLargeBusinessClassification) {
          lbTotalForSubcontractedTotalContract += summary.valueTotal;
          lbTotalForTotalCurrentContract += summary.valueTotal;
        } else {
          sbTotalForSubcontractedTotalContract += summary.valueTotal;
          sbTotalForTotalCurrentContract += summary.valueTotal;
        }
      } else {
        if (summaryHasLargeBusinessClassification) {
          lbTotalForTotalCurrentContract += summary.valueTotal;
        } else {
          sbTotalForTotalCurrentContract += summary.valueTotal;
        }
      }
    } else {
      lbTotalForTotalCurrentContractWithNoCompany += summary.valueTotal;
    }
  });

  const onSubmit: SubmitHandler<UpdateFormInput> = (data) => {
    return updateProject({
      variables: {
        input: {
          id: project?.id,
          primeContractNumber: data.primeContractNumber ?? undefined,
          originalContractValue:
            data.originalContractAmount && actualCalculationIsBasedOnTotalContractValue
              ? parseFloat(data.originalContractAmount.replaceAll(',', ''))
              : 0,
          originalSubcontractValue:
            data.originalSubcontractAmount && !actualCalculationIsBasedOnTotalContractValue
              ? parseFloat(data.originalSubcontractAmount.replaceAll(',', ''))
              : 0,
          smallBusinessReportSettings: {
            id: project?.smallBusinessReportSettings?.id,
            isActualCalculationsBasedOnTotalContractValue: data.isActualCalculationsBasedOnTotalContractValue
              ? JSON.parse(data.isActualCalculationsBasedOnTotalContractValue)
              : undefined,
            notes: data.notes ?? undefined,
          },
        },
      },
    })
      .then(() => {
        displayToast('The small business report settings were updated successfully', 'success');
        setIsEditing(false);
      })
      .catch((error) => {
        console.error('Create smallBusReportSettings failed: ', error);
        displayToast(
          'Error: Something went wrong while trying submit your changes. Please try again. If the problem persists, please contact support.',
          'error',
        );
      });
  };

  return (
    <Card sx={cardStyles}>
      <Grid container direction="row" justifyContent="center">
        {editable && (
          <Grid item xs={12} sx={editButtonContainer}>
            <Grid container justifyContent="flex-end" sx={editButtonContainer}>
              {!isEditing && (
                <IconButton size="small" onClick={handleEditButtonClick}>
                  <EditIcon />
                </IconButton>
              )}
              {isEditing && <Typography sx={editingModeIndicator}>Editing...</Typography>}
            </Grid>
          </Grid>
        )}
        {!isEditing && (
          <>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <CardColumn
                icon={<AttachMoneyIcon sx={cardColumnIcon} />}
                title="Original Contract"
                amount={project?.originalContractValue ?? undefined}
                isActive={project?.smallBusinessReportSettings?.isActualCalculationsBasedOnTotalContractValue === true}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <CardColumn
                icon={<AttachMoneyIcon sx={cardColumnIcon} />}
                title="Original Subcontract"
                amount={project?.originalSubcontractValue ?? undefined}
                isActive={project?.smallBusinessReportSettings?.isActualCalculationsBasedOnTotalContractValue === false}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <CardColumn
                icon={<MonetizationOnIcon sx={cardColumnIcon} />}
                title="Total Current Contract"
                amount={report?.currentContractValue}
                isActive={project?.smallBusinessReportSettings?.isActualCalculationsBasedOnTotalContractValue === true}
              />
              <TotalsDisplay
                title="LB Total"
                total={
                  lbTotalForTotalCurrentContract
                    ? lbTotalForTotalCurrentContract
                    : lbTotalForTotalCurrentContractWithNoCompany
                }
              />
              <TotalsDisplay title="SB Total" total={sbTotalForTotalCurrentContract} />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <CardColumn
                icon={<MonetizationOnIcon sx={cardColumnIcon} />}
                title="Subcontracted Total"
                amount={report?.currentSubContractorContractValue}
                isActive={project?.smallBusinessReportSettings?.isActualCalculationsBasedOnTotalContractValue === false}
              />
              <TotalsDisplay title="LB Total" total={lbTotalForSubcontractedTotalContract} />
              <TotalsDisplay title="SB Total" total={sbTotalForSubcontractedTotalContract} />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={12} sx={projectField}>
              <CardColumn title="Prime Contract Number" contractNumber={project?.primeContractNumber ?? undefined} />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={12} sx={projectField}>
              <CardColumn title="Client" contractNumber={project?.smallBusinessClient?.name ?? undefined} />
            </Grid>
            <Grid container direction="row">
              <Grid item xs={12}>
                <Typography sx={notesLabel}>Notes</Typography>
                <Card sx={notesCard}>
                  <Typography sx={notesSection}>
                    {project?.smallBusinessReportSettings?.notes
                      ? project?.smallBusinessReportSettings?.notes
                      : 'There are no notes.'}
                  </Typography>
                </Card>
              </Grid>
            </Grid>
          </>
        )}
        {isEditing && (
          <Grid item xs={12} sx={editingContainer}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container>
                <Grid item xs={12} sx={sectionStyles}>
                  <Typography color="textSecondary" sx={labelEditing}>
                    Contract Reporting Requirement
                  </Typography>
                  <section>
                    <Controller
                      render={({ field }) => (
                        <>
                          <RadioGroup
                            aria-label="isActualCalculationsBasedOnTotalContractValue"
                            {...field}
                            sx={radioGroup}
                          >
                            <Grid container direction="row" spacing={2}>
                              <Grid item>
                                <FormControlLabel
                                  value="true"
                                  control={<Radio size="small" color="primary" />}
                                  label={<Typography sx={radioLabel}>Total Contract Amount</Typography>}
                                />
                              </Grid>
                              <Grid item>
                                <FormControlLabel
                                  value="false"
                                  control={<Radio size="small" color="primary" />}
                                  label={<Typography sx={radioLabel}>Subcontracted Dollars Only</Typography>}
                                />
                              </Grid>
                            </Grid>
                          </RadioGroup>
                        </>
                      )}
                      name="isActualCalculationsBasedOnTotalContractValue"
                      control={control}
                      rules={{ required: true }}
                    />
                  </section>
                </Grid>
                {actualCalculationIsBasedOnTotalContractValue && (
                  <Grid item xs={12} sx={sectionStyles}>
                    <Typography color="textSecondary" sx={labelEditing}>
                      {`Original Contract ${actualCalculationIsBasedOnTotalContractValue ? '*' : ''}`}
                    </Typography>
                    <section>
                      <Controller
                        render={({ field }) => (
                          <NumberFormat
                            {...field}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            allowLeadingZeros={false}
                            isNumericString={true}
                            thousandSeparator={true}
                            customInput={TextField}
                            margin="dense"
                            variant="outlined"
                            fullWidth={true}
                            color="primary"
                            error={actualCalculationIsBasedOnTotalContractValue && noOriginalContractAmount}
                            required={actualCalculationIsBasedOnTotalContractValue && noOriginalContractAmount}
                            helperText={
                              actualCalculationIsBasedOnTotalContractValue && noOriginalContractAmount ? 'Required' : ''
                            }
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                          />
                        )}
                        name="originalContractAmount"
                        control={control}
                        rules={{
                          required: actualCalculationIsBasedOnTotalContractValue && noOriginalContractAmount,
                          min: 0,
                        }}
                      />
                    </section>
                  </Grid>
                )}
                {!actualCalculationIsBasedOnTotalContractValue && (
                  <Grid item xs={12} sx={sectionStyles}>
                    <Typography color="textSecondary" sx={labelEditing}>
                      {`Original Subcontract ${!actualCalculationIsBasedOnTotalContractValue ? '*' : ''}`}
                    </Typography>
                    <section>
                      <Controller
                        render={({ field }) => (
                          <NumberFormat
                            {...field}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            allowLeadingZeros={false}
                            isNumericString={true}
                            thousandSeparator={true}
                            customInput={TextField}
                            margin="dense"
                            variant="outlined"
                            fullWidth={true}
                            color="primary"
                            error={!actualCalculationIsBasedOnTotalContractValue && noOriginalSubcontractAmount}
                            required={!actualCalculationIsBasedOnTotalContractValue && noOriginalSubcontractAmount}
                            helperText={
                              !actualCalculationIsBasedOnTotalContractValue && noOriginalSubcontractAmount
                                ? 'Required'
                                : ''
                            }
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                          />
                        )}
                        name="originalSubcontractAmount"
                        control={control}
                        rules={{
                          required: !actualCalculationIsBasedOnTotalContractValue && noOriginalSubcontractAmount,
                          min: 0,
                        }}
                      />
                    </section>
                  </Grid>
                )}
                <Grid item xs={12} sx={sectionStyles}>
                  <Typography color="textSecondary" sx={labelEditing}>
                    Prime Contract Number
                  </Typography>
                  <section>
                    <Controller
                      render={({ field }) => <TextField {...field} fullWidth variant="outlined" margin="dense" />}
                      name="primeContractNumber"
                      control={control}
                    />
                  </section>
                </Grid>
                <Grid item xs={12} sx={sectionStyles}>
                  <Typography color="textSecondary" sx={labelEditing}>
                    Notes
                  </Typography>
                  <section>
                    <Controller
                      render={({ field }) => <TextField {...field} variant="outlined" fullWidth multiline />}
                      name="notes"
                      control={control}
                    />
                  </section>
                </Grid>
                <Grid item xs={12} sx={sectionStyles}>
                  <Grid container alignItems="center" direction="row" spacing={1} justifyContent="flex-end">
                    <Grid item>
                      <StyledButtonSecondary disabled={isLoading} label="Cancel" onClick={handleCancel} />
                    </Grid>
                    <Grid item>
                      <StyledButtonPrimary disabled={!isDirty || !isValid || isLoading} type="submit" label="Submit" />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default SmallBusinessContractAmountsCard;
