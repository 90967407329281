/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  Upload: any;
};

export type AdContact = {
  __typename?: 'AdContact';
  accountEnabled?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  isPayrollActive?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  microsoftAdId: Scalars['String'];
  name: Scalars['String'];
  officeLocation?: Maybe<Scalars['String']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  upn: Scalars['String'];
};

export type AdContactFilter = {
  accountEnabled?: Maybe<Scalars['Boolean']>;
  isPayrollActive?: Maybe<Scalars['Boolean']>;
};

export type AdUser = {
  __typename?: 'AdUser';
  contactInformation: ContactInformation;
  created: Scalars['DateTime'];
  dob?: Maybe<Scalars['Date']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  hpEmployeeNumber?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  jobTitle?: Maybe<Scalars['String']>;
  lastModified: Scalars['DateTime'];
  lastName: Scalars['String'];
  microsoftAdId: Scalars['String'];
  name: Scalars['String'];
  projects: Array<Project>;
  upn: Scalars['String'];
};

export enum BooleanOperator {
  And = 'AND',
  Or = 'OR'
}

export type ChangeOrder = {
  __typename?: 'ChangeOrder';
  contract: Contract;
  created: Scalars['DateTime'];
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  lastModified: Scalars['DateTime'];
  lastSink: Scalars['DateTime'];
  type: Scalars['String'];
  value: Scalars['Float'];
};

export type Company = {
  __typename?: 'Company';
  contracts: Array<Contract>;
  created: Scalars['DateTime'];
  federalSmallBusinessClassifications?: Maybe<Array<SmallBusinessClassification>>;
  files: Array<CompanyFile>;
  id: Scalars['ID'];
  lastModified: Scalars['DateTime'];
  lastSink: Scalars['DateTime'];
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  smallBusinessClassifications?: Maybe<Array<SmallBusinessClassification>>;
};

export type CompanyFile = {
  __typename?: 'CompanyFile';
  company: Company;
  created: Scalars['DateTime'];
  file: UploadedFile;
  id: Scalars['ID'];
  lastModified: Scalars['DateTime'];
  notes?: Maybe<Scalars['String']>;
  smallBusinessClassificationCoverages: Array<SmallBusinessClassificationCoverage>;
  title: Scalars['String'];
  uploaded: Scalars['DateTime'];
  uploadedBy: AdUser;
};

export type CompanyFileCreateInput = {
  companyId: Scalars['ID'];
  file: Scalars['Upload'];
  notes?: Maybe<Scalars['String']>;
  smallBusinessClassificationCoverages: Array<NestedSmallBusinessClassificationCoverageUpsertInput>;
  title: Scalars['String'];
  transactionKey: Scalars['String'];
};

export type CompanyFileUpdateInput = {
  id: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type CompanyListFilterInput = {
  federalSmallBusinessClassificationList?: Maybe<FilterStringList>;
  smallBusinessClassificationList?: Maybe<FilterStringList>;
};

export type CompanyListOrderInput = {
  direction: SortOrderOption;
  field: Scalars['String'];
};

export type CompanyListResult = {
  __typename?: 'CompanyListResult';
  count: Scalars['Int'];
  items: Array<Company>;
};

export type CompanyUpdateInput = {
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
};

export type ContactInformation = {
  __typename?: 'ContactInformation';
  emailAddress: EmailAddress;
  faxPhone: PhoneNumber;
  phoneNumbers: Array<PhoneNumber>;
  primaryPhone: PhoneNumber;
  secondaryPhone: PhoneNumber;
  website?: Maybe<Scalars['String']>;
};

export type Contract = {
  __typename?: 'Contract';
  changeOrders: Array<ChangeOrder>;
  company?: Maybe<Company>;
  created: Scalars['DateTime'];
  effectiveDate?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  isSelfWork?: Maybe<Scalars['Boolean']>;
  lastModified: Scalars['DateTime'];
  lowerTierParticipation?: Maybe<Array<LowerTierParticipation>>;
  participationPercentage?: Maybe<Scalars['Float']>;
  phaseCode: Scalars['String'];
  project: Project;
  smallBusinessClassifications: Array<SmallBusinessClassification>;
};

export type ContractUpsertInput = {
  companyId?: Maybe<Scalars['String']>;
  effectiveDate?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  isSelfWork?: Maybe<Scalars['Boolean']>;
  participationPercentage?: Maybe<Scalars['Float']>;
};



export type EmailAddress = {
  __typename?: 'EmailAddress';
  email?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type EntityIdentifier = {
  __typename?: 'EntityIdentifier';
  id: Scalars['ID'];
};

export type EntityIdentifierInput = {
  id: Scalars['ID'];
};

export type FilterStringList = {
  booleanOperator: BooleanOperator;
  items: Array<Scalars['String']>;
};

export type LowerTierParticipation = {
  __typename?: 'LowerTierParticipation';
  contract: Contract;
  created: Scalars['DateTime'];
  effectiveDate?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  lastModified: Scalars['DateTime'];
  lowerTierCompanyName?: Maybe<Scalars['String']>;
  lowerTierParticipationFileSummary?: Maybe<Array<LowerTierParticipationFileSummary>>;
  smallBusinessClassifications: Array<SmallBusinessClassification>;
  value: Scalars['Float'];
};

export type LowerTierParticipationClassification = {
  __typename?: 'LowerTierParticipationClassification';
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  lastModified: Scalars['DateTime'];
  lowerTierParticipationFileSummary: LowerTierParticipationFileSummary;
  smallBusinessClassification: SmallBusinessClassification;
};

export type LowerTierParticipationClassificationSummary = {
  __typename?: 'LowerTierParticipationClassificationSummary';
  contractId: Scalars['ID'];
  effectiveDate?: Maybe<Scalars['Date']>;
  lowerTierCompanyName: Scalars['String'];
  lowerTierParticipationFileSummary?: Maybe<Array<LowerTierParticipationFileSummary>>;
  lowerTierParticipationId: Scalars['ID'];
  smallBusinessClassifications: Array<SmallBusinessClassification>;
  value: Scalars['Float'];
};

export type LowerTierParticipationFileSummary = {
  __typename?: 'LowerTierParticipationFileSummary';
  created: Scalars['DateTime'];
  file?: Maybe<UploadedFile>;
  id: Scalars['ID'];
  lastModified: Scalars['DateTime'];
  lowerTierParticipation: LowerTierParticipation;
  lowerTierParticipationClassifications: Array<LowerTierParticipationClassification>;
  participationPercentage?: Maybe<Scalars['Float']>;
  smallBusinessAgency?: Maybe<SmallBusinessAgency>;
  smallBusinessAgencyId?: Maybe<Scalars['String']>;
  uploaded: Scalars['DateTime'];
  uploadedBy: AdUser;
};

export type LowerTierParticipationUpsertInput = {
  contractId: Scalars['ID'];
  effectiveDate?: Maybe<Scalars['Date']>;
  fileSummaryToDelete?: Maybe<Array<EntityIdentifierInput>>;
  filesToAdd?: Maybe<Array<NestedLowerTierParticipationFileSummary>>;
  id?: Maybe<Scalars['String']>;
  lowerTierCompanyName: Scalars['String'];
  transactionKey?: Maybe<Scalars['String']>;
  value: Scalars['Float'];
};

export type Mutation = {
  __typename?: 'Mutation';
  deleteCompany: EntityIdentifier;
  deleteCompanyFile: EntityIdentifier;
  deleteLowerTierParticipation: EntityIdentifier;
  deleteSmallBusinessAgency: EntityIdentifier;
  deleteSmallBusinessClassification: EntityIdentifier;
  deleteSmallBusinessClient: EntityIdentifier;
  saveProjectSmallBusinessReport: ProjectSmallBusinessReport;
  setAdminStatusOnProject: Project;
  updateCompany: Company;
  updateCompanyFile: CompanyFile;
  updateProject: Project;
  uploadCompanyFile: CompanyFile;
  upsertContract: Contract;
  upsertLowerTierParticipation: LowerTierParticipation;
  upsertSmallBusinessAgency: SmallBusinessAgency;
  upsertSmallBusinessClassification: SmallBusinessClassification;
  upsertSmallBusinessClient: SmallBusinessClient;
};


export type MutationDeleteCompanyArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCompanyFileArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteLowerTierParticipationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSmallBusinessAgencyArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSmallBusinessClassificationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSmallBusinessClientArgs = {
  id: Scalars['ID'];
};


export type MutationSaveProjectSmallBusinessReportArgs = {
  comments?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  form?: Maybe<SmallBusinessFederalReportFormOption>;
  smallBusinessReportSettingsId: Scalars['ID'];
  startDate?: Maybe<Scalars['String']>;
  transactionKey: Scalars['String'];
  type: SmallBusinessSavedReportTypeOption;
};


export type MutationSetAdminStatusOnProjectArgs = {
  isAdmin: Scalars['Boolean'];
  microsoftAdId: Scalars['String'];
  projectId: Scalars['ID'];
};


export type MutationUpdateCompanyArgs = {
  input: CompanyUpdateInput;
};


export type MutationUpdateCompanyFileArgs = {
  input: CompanyFileUpdateInput;
};


export type MutationUpdateProjectArgs = {
  input: ProjectUpdateInput;
};


export type MutationUploadCompanyFileArgs = {
  input: CompanyFileCreateInput;
};


export type MutationUpsertContractArgs = {
  input: ContractUpsertInput;
};


export type MutationUpsertLowerTierParticipationArgs = {
  input: LowerTierParticipationUpsertInput;
};


export type MutationUpsertSmallBusinessAgencyArgs = {
  input: SmallBusinessAgencyUpsertInput;
};


export type MutationUpsertSmallBusinessClassificationArgs = {
  input: SmallBusinessClassificationUpsertInput;
};


export type MutationUpsertSmallBusinessClientArgs = {
  input: SmallBusinessClientUpsertInput;
};

export type NestedLowerTierParticipationClassification = {
  smallBusinessClassificationId?: Maybe<Scalars['String']>;
};

export type NestedLowerTierParticipationFileSummary = {
  file?: Maybe<Scalars['Upload']>;
  id?: Maybe<Scalars['String']>;
  lowerTierParticipationClassifications: Array<NestedLowerTierParticipationClassification>;
  participationPercentage?: Maybe<Scalars['Float']>;
  smallBusinessAgencyId?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
};

export type NestedSmallBusinessClassificationCoverageUpsertInput = {
  endDate: Scalars['Date'];
  isFederal?: Maybe<Scalars['Boolean']>;
  smallBusinessAgencyId?: Maybe<Scalars['String']>;
  smallBusinessClassificationId: Scalars['String'];
  startDate: Scalars['Date'];
};

export type NestedSmallBusinessClientAgency = {
  smallBusinessAgencyId?: Maybe<Scalars['String']>;
  smallBusinessClientClassifications: Array<NestedSmallBusinessClientClassification>;
  smallBusinessClientId?: Maybe<Scalars['String']>;
};

export type NestedSmallBusinessClientClassification = {
  smallBusinessClassificationId?: Maybe<Scalars['String']>;
};

export type NestedSmallBusinessReportClassificationUpsertInput = {
  goalPercent?: Maybe<Scalars['Float']>;
  smallBusinessClassificationId: Scalars['ID'];
};

export type NestedSmallBusinessReportSettingsUpdateInput = {
  classifications?: Maybe<Array<NestedSmallBusinessReportClassificationUpsertInput>>;
  id?: Maybe<Scalars['String']>;
  isActualCalculationsBasedOnTotalContractValue?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
};

export type PhoneNumber = {
  __typename?: 'PhoneNumber';
  display?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type Project = {
  __typename?: 'Project';
  SmallBusinessReportSettings?: Maybe<SmallBusinessReportSettings>;
  address?: Maybe<Scalars['String']>;
  admins: Array<AdUser>;
  city?: Maybe<Scalars['String']>;
  contracts: Array<Contract>;
  created: Scalars['DateTime'];
  district?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  lastModified: Scalars['DateTime'];
  lastSink: Scalars['DateTime'];
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  originalContractValue?: Maybe<Scalars['Float']>;
  originalSubcontractValue?: Maybe<Scalars['Float']>;
  primeContractNumber?: Maybe<Scalars['String']>;
  smallBusinessClassifications: Array<SmallBusinessClassification>;
  smallBusinessClient?: Maybe<SmallBusinessClient>;
  smallBusinessClientId?: Maybe<Scalars['String']>;
  smallBusinessReportSettings?: Maybe<SmallBusinessReportSettings>;
  smallBusinessReportSettingsId?: Maybe<Scalars['String']>;
  smallBusinessRequirement?: Maybe<Scalars['String']>;
  smallBusinessSavedReports: Array<SmallBusinessSavedReport>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type ProjectListFilterInput = {
  districtList?: Maybe<FilterStringList>;
  isActive?: Maybe<Scalars['Boolean']>;
  smallBusinessClientId?: Maybe<Scalars['String']>;
  smallBusinessRequirement?: Maybe<Scalars['String']>;
};

export type ProjectListOrderInput = {
  direction: SortOrderOption;
  field: Scalars['String'];
};

export type ProjectListResult = {
  __typename?: 'ProjectListResult';
  count: Scalars['Int'];
  items: Array<Project>;
};

export type ProjectSmallBusinessReport = {
  __typename?: 'ProjectSmallBusinessReport';
  checksum: Scalars['String'];
  classificationSummaries: Array<SmallBusinessReportClassificationSummary>;
  contractSummaries: Array<SmallBusinessReportContractSummary>;
  currentContractValue: Scalars['Float'];
  currentSubContractorContractValue: Scalars['Float'];
};

export type ProjectUpdateInput = {
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  originalContractValue?: Maybe<Scalars['Float']>;
  originalSubcontractValue?: Maybe<Scalars['Float']>;
  primeContractNumber?: Maybe<Scalars['String']>;
  smallBusinessClientId?: Maybe<Scalars['String']>;
  smallBusinessReportSettings?: Maybe<NestedSmallBusinessReportSettingsUpdateInput>;
  smallBusinessRequirement?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  adContact: AdContact;
  adContacts: Array<AdContact>;
  authenticationCheck: Scalars['String'];
  company: Company;
  companyListResult: CompanyListResult;
  currentAdUser: AdUser;
  getCompaniesInUse: Array<Company>;
  getLowerTierParticipationsInUse: Array<LowerTierParticipation>;
  getProjectsContractedAfter: Array<Project>;
  getProjectsInUse: Array<Project>;
  getSmallBusinessAgenciesInUse: Array<SmallBusinessAgency>;
  getSmallBusinessClientsInUse: Array<SmallBusinessClient>;
  lowerTierParticipation: LowerTierParticipation;
  project: Project;
  projectListResult: ProjectListResult;
  projectSmallBusinessReport: ProjectSmallBusinessReport;
  projectsAsAdmin: Array<Project>;
  smallBusinessAgency: SmallBusinessAgency;
  smallBusinessAgencyList: Array<SmallBusinessAgency>;
  smallBusinessClassification: SmallBusinessClassification;
  smallBusinessClassificationList: Array<SmallBusinessClassification>;
  smallBusinessClient: SmallBusinessClient;
  smallBusinessClientList: Array<SmallBusinessClient>;
};


export type QueryAdContactArgs = {
  microsoftAdId: Scalars['String'];
};


export type QueryAdContactsArgs = {
  filter?: Maybe<AdContactFilter>;
  first?: Maybe<Scalars['Int']>;
  search: Scalars['String'];
};


export type QueryCompanyArgs = {
  id: Scalars['ID'];
};


export type QueryCompanyListResultArgs = {
  filter?: Maybe<CompanyListFilterInput>;
  first: Scalars['Int'];
  order?: Maybe<CompanyListOrderInput>;
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetCompaniesInUseArgs = {
  smallBusinessAgencyId?: Maybe<Scalars['String']>;
  smallBusinessClassificationIds?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetLowerTierParticipationsInUseArgs = {
  smallBusinessAgencyId?: Maybe<Scalars['String']>;
  smallBusinessClassificationIds?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetProjectsContractedAfterArgs = {
  companyId: Scalars['String'];
  date: Scalars['String'];
};


export type QueryGetProjectsInUseArgs = {
  smallBusinessClassificationId?: Maybe<Scalars['String']>;
  smallBusinessClientId?: Maybe<Scalars['String']>;
};


export type QueryGetSmallBusinessAgenciesInUseArgs = {
  smallBusinessClassificationId: Scalars['String'];
};


export type QueryGetSmallBusinessClientsInUseArgs = {
  smallBusinessAgencyId: Scalars['String'];
};


export type QueryLowerTierParticipationArgs = {
  id: Scalars['ID'];
};


export type QueryProjectArgs = {
  id?: Maybe<Scalars['ID']>;
  number?: Maybe<Scalars['String']>;
};


export type QueryProjectListResultArgs = {
  filter?: Maybe<ProjectListFilterInput>;
  first: Scalars['Int'];
  order?: Maybe<ProjectListOrderInput>;
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryProjectSmallBusinessReportArgs = {
  endDate?: Maybe<Scalars['String']>;
  form?: Maybe<SmallBusinessFederalReportFormOption>;
  smallBusinessReportSettingsId: Scalars['ID'];
  startDate?: Maybe<Scalars['String']>;
};


export type QuerySmallBusinessAgencyArgs = {
  id: Scalars['ID'];
};


export type QuerySmallBusinessAgencyListArgs = {
  federal?: Maybe<Scalars['Boolean']>;
  isActive: Array<Scalars['Boolean']>;
};


export type QuerySmallBusinessClassificationArgs = {
  id: Scalars['ID'];
};


export type QuerySmallBusinessClassificationListArgs = {
  federal?: Maybe<Scalars['Boolean']>;
};


export type QuerySmallBusinessClientArgs = {
  id: Scalars['ID'];
};


export type QuerySmallBusinessClientListArgs = {
  federal?: Maybe<Scalars['Boolean']>;
  isActive: Array<Scalars['Boolean']>;
};

export type SmallBusinessAgency = {
  __typename?: 'SmallBusinessAgency';
  abbreviation: Scalars['String'];
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  isFederal?: Maybe<Scalars['Boolean']>;
  lastModified: Scalars['DateTime'];
  name: Scalars['String'];
  participationPercentage?: Maybe<Scalars['Float']>;
  smallBusinessAgencyClassifications: Array<SmallBusinessAgencyClassification>;
  specialNotice?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type SmallBusinessAgencyClassification = {
  __typename?: 'SmallBusinessAgencyClassification';
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  lastModified: Scalars['DateTime'];
  smallBusinessAgency: SmallBusinessAgency;
  smallBusinessAgencyId: Scalars['String'];
  smallBusinessClassification: SmallBusinessClassification;
  smallBusinessClassificationId: Scalars['String'];
};

export type SmallBusinessAgencyUpsertInput = {
  abbreviation: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isFederal?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  participationPercentage?: Maybe<Scalars['Float']>;
  smallBusinessClassificationIds: Array<Scalars['ID']>;
  specialNotice?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type SmallBusinessClassification = {
  __typename?: 'SmallBusinessClassification';
  abbreviation: Scalars['String'];
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  isFederal?: Maybe<Scalars['Boolean']>;
  lastModified: Scalars['DateTime'];
  name: Scalars['String'];
  sortOrder: Scalars['Float'];
};

export type SmallBusinessClassificationCoverage = {
  __typename?: 'SmallBusinessClassificationCoverage';
  created: Scalars['DateTime'];
  endDate: Scalars['Date'];
  id: Scalars['ID'];
  isFederal?: Maybe<Scalars['Boolean']>;
  lastModified: Scalars['DateTime'];
  smallBusinessAgency?: Maybe<SmallBusinessAgency>;
  smallBusinessClassification: SmallBusinessClassification;
  startDate: Scalars['Date'];
  supportingDocument: CompanyFile;
};

export type SmallBusinessClassificationUpsertInput = {
  abbreviation: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isFederal?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  sortOrder: Scalars['Float'];
  transactionKey?: Maybe<Scalars['String']>;
};

export type SmallBusinessClient = {
  __typename?: 'SmallBusinessClient';
  abbreviation?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  isFederal?: Maybe<Scalars['Boolean']>;
  lastModified: Scalars['DateTime'];
  name: Scalars['String'];
  projects?: Maybe<Array<Project>>;
  smallBusinessClientAgencies: Array<SmallBusinessClientAgency>;
};

export type SmallBusinessClientAgency = {
  __typename?: 'SmallBusinessClientAgency';
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  lastModified: Scalars['DateTime'];
  smallBusinessAgency: SmallBusinessAgency;
  smallBusinessAgencyId: Scalars['String'];
  smallBusinessClient: SmallBusinessClient;
  smallBusinessClientClassifications: Array<SmallBusinessClientClassification>;
  smallBusinessClientId: Scalars['String'];
};

export type SmallBusinessClientClassification = {
  __typename?: 'SmallBusinessClientClassification';
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  lastModified: Scalars['DateTime'];
  smallBusinessClassification: SmallBusinessClassification;
  smallBusinessClassificationId: Scalars['String'];
  smallBusinessClientAgency: SmallBusinessClientAgency;
};

export type SmallBusinessClientUpsertInput = {
  abbreviation: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isFederal?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  smallBusinessClientAgencies?: Maybe<Array<NestedSmallBusinessClientAgency>>;
  transactionKey?: Maybe<Scalars['String']>;
};

export enum SmallBusinessFederalReportFormOption {
  SmallBusiness294March = 'SmallBusiness294March',
  SmallBusiness294September = 'SmallBusiness294September',
  SmallBusiness295September = 'SmallBusiness295September'
}

export type SmallBusinessReportChangeOrderSummary = {
  __typename?: 'SmallBusinessReportChangeOrderSummary';
  changeOrderId: Scalars['String'];
  date: Scalars['DateTime'];
  value: Scalars['Float'];
};

export type SmallBusinessReportClassification = {
  __typename?: 'SmallBusinessReportClassification';
  created: Scalars['DateTime'];
  goalPercent?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  lastModified: Scalars['DateTime'];
  smallBusinessClassification: SmallBusinessClassification;
  smallBusinessReportSettings: SmallBusinessReportSettings;
};

export type SmallBusinessReportClassificationSummary = {
  __typename?: 'SmallBusinessReportClassificationSummary';
  abbreviation: Scalars['String'];
  actualPercentOriginal: Scalars['Float'];
  actualPercentTotal: Scalars['Float'];
  actualTotal: Scalars['Float'];
  contractIds?: Maybe<Array<Scalars['String']>>;
  firstTierPercentOriginal?: Maybe<Scalars['Float']>;
  firstTierPercentTotal?: Maybe<Scalars['Float']>;
  firstTierTotal?: Maybe<Scalars['Float']>;
  goalPercent?: Maybe<Scalars['Float']>;
  goalTotal?: Maybe<Scalars['Float']>;
  isFederal?: Maybe<Scalars['Boolean']>;
  lowerTierParticipationIds?: Maybe<Array<Scalars['String']>>;
  lowerTierParticipationPercentOriginal?: Maybe<Scalars['Float']>;
  lowerTierParticipationTotal?: Maybe<Scalars['Float']>;
  lowerTierPercentTotal?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  smallBusinessClassificationId: Scalars['ID'];
  smallBusinessReportClassificationId: Scalars['ID'];
  sortOrder: Scalars['Float'];
};

export type SmallBusinessReportContractSummary = {
  __typename?: 'SmallBusinessReportContractSummary';
  changeOrderSummaries: Array<SmallBusinessReportChangeOrderSummary>;
  companyId: Scalars['ID'];
  companyName: Scalars['String'];
  contractId: Scalars['ID'];
  coverages: Array<SmallBusinessClassificationCoverage>;
  effectiveDate?: Maybe<Scalars['Date']>;
  isSelfWork?: Maybe<Scalars['Boolean']>;
  lowerTierParticipationClassificationSummaries: Array<LowerTierParticipationClassificationSummary>;
  participationPercentage?: Maybe<Scalars['Float']>;
  phaseCode: Scalars['String'];
  smallBusinessClassifications: Array<SmallBusinessClassification>;
  valueTotal: Scalars['Float'];
};

export type SmallBusinessReportSettings = {
  __typename?: 'SmallBusinessReportSettings';
  classifications: Array<SmallBusinessReportClassification>;
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  isActualCalculationsBasedOnTotalContractValue: Scalars['Boolean'];
  lastModified: Scalars['DateTime'];
  notes?: Maybe<Scalars['String']>;
  project: Project;
};

export type SmallBusinessSavedReport = {
  __typename?: 'SmallBusinessSavedReport';
  comments?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  createdBy: AdUser;
  endDate?: Maybe<Scalars['String']>;
  form: SmallBusinessFederalReportFormOption;
  id: Scalars['ID'];
  lastModified: Scalars['DateTime'];
  project: Project;
  reportJson: Scalars['String'];
  startDate?: Maybe<Scalars['String']>;
  type: SmallBusinessSavedReportTypeOption;
};

export enum SmallBusinessSavedReportTypeOption {
  Final = 'Final',
  Regular = 'Regular',
  Revised = 'Revised'
}

export enum SortOrderOption {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Subscription = {
  __typename?: 'Subscription';
  adUser: AdUser;
  company: Company;
  deletedCompany: EntityIdentifier;
  deletedLowerTierParticipation: EntityIdentifier;
  deletedSmallBusinessAgency: EntityIdentifier;
  deletedSmallBusinessClassification: EntityIdentifier;
  deletedSmallBusinessClient: EntityIdentifier;
  lowerTierParticipation: LowerTierParticipation;
  smallBusinessAgency: SmallBusinessAgency;
  smallBusinessClassification: SmallBusinessClassification;
  smallBusinessClient: SmallBusinessClient;
};


export type SubscriptionAdUserArgs = {
  microsoftAdId: Scalars['String'];
};


export type SubscriptionCompanyArgs = {
  id: Scalars['ID'];
};


export type SubscriptionDeletedCompanyArgs = {
  id: Scalars['ID'];
};


export type SubscriptionDeletedLowerTierParticipationArgs = {
  id: Scalars['ID'];
};


export type SubscriptionDeletedSmallBusinessAgencyArgs = {
  id: Scalars['ID'];
};


export type SubscriptionDeletedSmallBusinessClassificationArgs = {
  id: Scalars['ID'];
};


export type SubscriptionDeletedSmallBusinessClientArgs = {
  id: Scalars['ID'];
};


export type SubscriptionLowerTierParticipationArgs = {
  id: Scalars['ID'];
};


export type SubscriptionSmallBusinessAgencyArgs = {
  id: Scalars['ID'];
};


export type SubscriptionSmallBusinessClassificationArgs = {
  id: Scalars['ID'];
};


export type SubscriptionSmallBusinessClientArgs = {
  id: Scalars['ID'];
};


export type UploadedFile = {
  __typename?: 'UploadedFile';
  cachedUrl?: Maybe<Scalars['String']>;
  contentType: Scalars['String'];
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  isMalware?: Maybe<Scalars['Boolean']>;
  isScanned?: Maybe<Scalars['Boolean']>;
  lastModified: Scalars['DateTime'];
  name: Scalars['String'];
  sizeInBytes?: Maybe<Scalars['Float']>;
  url?: Maybe<Scalars['String']>;
};

export type GetAdContactsQueryVariables = Exact<{
  search: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  filter?: Maybe<AdContactFilter>;
}>;


export type GetAdContactsQuery = (
  { __typename?: 'Query' }
  & { adContacts: Array<(
    { __typename?: 'AdContact' }
    & Pick<AdContact, 'microsoftAdId' | 'upn' | 'name' | 'firstName' | 'lastName' | 'email' | 'jobTitle' | 'officeLocation' | 'thumbnailUrl'>
  )> }
);

export type GetAdContactQueryVariables = Exact<{
  microsoftAdId: Scalars['String'];
}>;


export type GetAdContactQuery = (
  { __typename?: 'Query' }
  & { adContact: (
    { __typename?: 'AdContact' }
    & Pick<AdContact, 'microsoftAdId' | 'upn' | 'name' | 'firstName' | 'lastName' | 'email' | 'jobTitle' | 'officeLocation' | 'thumbnailUrl'>
  ) }
);

export type CurrentAdUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentAdUserQuery = (
  { __typename?: 'Query' }
  & { currentAdUser: (
    { __typename?: 'AdUser' }
    & { projects: Array<(
      { __typename?: 'Project' }
      & Pick<Project, 'id'>
    )> }
  ) }
);

export type GetPaginatedCompaniesQueryVariables = Exact<{
  skip: Scalars['Int'];
  first: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
  filter?: Maybe<CompanyListFilterInput>;
  order?: Maybe<CompanyListOrderInput>;
}>;


export type GetPaginatedCompaniesQuery = (
  { __typename?: 'Query' }
  & { companyListResult: (
    { __typename?: 'CompanyListResult' }
    & Pick<CompanyListResult, 'count'>
    & { items: Array<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name' | 'location'>
      & { federalSmallBusinessClassifications?: Maybe<Array<(
        { __typename?: 'SmallBusinessClassification' }
        & Pick<SmallBusinessClassification, 'id' | 'name' | 'abbreviation' | 'sortOrder'>
      )>>, smallBusinessClassifications?: Maybe<Array<(
        { __typename?: 'SmallBusinessClassification' }
        & Pick<SmallBusinessClassification, 'id' | 'name' | 'abbreviation' | 'sortOrder'>
      )>> }
    )> }
  ) }
);

export type GetSingleCompanyQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetSingleCompanyQuery = (
  { __typename?: 'Query' }
  & { company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'location' | 'notes'>
    & { federalSmallBusinessClassifications?: Maybe<Array<(
      { __typename?: 'SmallBusinessClassification' }
      & Pick<SmallBusinessClassification, 'id' | 'name' | 'abbreviation' | 'sortOrder'>
    )>>, smallBusinessClassifications?: Maybe<Array<(
      { __typename?: 'SmallBusinessClassification' }
      & Pick<SmallBusinessClassification, 'id' | 'name' | 'abbreviation' | 'sortOrder'>
    )>>, files: Array<(
      { __typename?: 'CompanyFile' }
      & Pick<CompanyFile, 'id' | 'title' | 'notes' | 'uploaded'>
      & { uploadedBy: (
        { __typename?: 'AdUser' }
        & Pick<AdUser, 'id' | 'name'>
      ), file: (
        { __typename?: 'UploadedFile' }
        & Pick<UploadedFile, 'id' | 'contentType' | 'created' | 'isMalware' | 'isScanned' | 'lastModified' | 'name' | 'sizeInBytes' | 'url' | 'cachedUrl'>
      ), smallBusinessClassificationCoverages: Array<(
        { __typename?: 'SmallBusinessClassificationCoverage' }
        & Pick<SmallBusinessClassificationCoverage, 'id' | 'isFederal' | 'startDate' | 'endDate'>
        & { smallBusinessClassification: (
          { __typename?: 'SmallBusinessClassification' }
          & Pick<SmallBusinessClassification, 'id' | 'name' | 'abbreviation' | 'sortOrder' | 'isFederal'>
        ), smallBusinessAgency?: Maybe<(
          { __typename?: 'SmallBusinessAgency' }
          & Pick<SmallBusinessAgency, 'id' | 'name' | 'abbreviation' | 'url' | 'participationPercentage' | 'specialNotice'>
        )> }
      )> }
    )> }
  ) }
);

export type GetCompaniesInUseQueryVariables = Exact<{
  smallBusinessAgencyId?: Maybe<Scalars['String']>;
  smallBusinessClassificationIds?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetCompaniesInUseQuery = (
  { __typename?: 'Query' }
  & { getCompaniesInUse: Array<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
    & { files: Array<(
      { __typename?: 'CompanyFile' }
      & Pick<CompanyFile, 'id'>
      & { smallBusinessClassificationCoverages: Array<(
        { __typename?: 'SmallBusinessClassificationCoverage' }
        & Pick<SmallBusinessClassificationCoverage, 'id'>
        & { smallBusinessClassification: (
          { __typename?: 'SmallBusinessClassification' }
          & Pick<SmallBusinessClassification, 'id' | 'name' | 'abbreviation'>
        ) }
      )> }
    )> }
  )> }
);

export type UpdateCompanyMutationVariables = Exact<{
  input: CompanyUpdateInput;
}>;


export type UpdateCompanyMutation = (
  { __typename?: 'Mutation' }
  & { updateCompany: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'notes' | 'location'>
  ) }
);

export type UploadCompanyFileMutationVariables = Exact<{
  input: CompanyFileCreateInput;
}>;


export type UploadCompanyFileMutation = (
  { __typename?: 'Mutation' }
  & { uploadCompanyFile: (
    { __typename?: 'CompanyFile' }
    & Pick<CompanyFile, 'id' | 'title' | 'notes' | 'uploaded'>
    & { uploadedBy: (
      { __typename?: 'AdUser' }
      & Pick<AdUser, 'id' | 'name'>
    ), file: (
      { __typename?: 'UploadedFile' }
      & Pick<UploadedFile, 'id' | 'contentType' | 'created' | 'isMalware' | 'isScanned' | 'lastModified' | 'name' | 'sizeInBytes' | 'url' | 'cachedUrl'>
    ) }
  ) }
);

export type UpdateCompanyFileMutationVariables = Exact<{
  input: CompanyFileUpdateInput;
}>;


export type UpdateCompanyFileMutation = (
  { __typename?: 'Mutation' }
  & { updateCompanyFile: (
    { __typename?: 'CompanyFile' }
    & Pick<CompanyFile, 'id' | 'title' | 'notes' | 'uploaded'>
    & { uploadedBy: (
      { __typename?: 'AdUser' }
      & Pick<AdUser, 'id' | 'name'>
    ), file: (
      { __typename?: 'UploadedFile' }
      & Pick<UploadedFile, 'id' | 'contentType' | 'created' | 'isMalware' | 'isScanned' | 'lastModified' | 'name' | 'sizeInBytes' | 'url' | 'cachedUrl'>
    ) }
  ) }
);

export type DeleteCompanyFileMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCompanyFileMutation = (
  { __typename?: 'Mutation' }
  & { deleteCompanyFile: (
    { __typename?: 'EntityIdentifier' }
    & Pick<EntityIdentifier, 'id'>
  ) }
);

export type UpsertContractMutationVariables = Exact<{
  input: ContractUpsertInput;
}>;


export type UpsertContractMutation = (
  { __typename?: 'Mutation' }
  & { upsertContract: (
    { __typename?: 'Contract' }
    & Pick<Contract, 'id' | 'effectiveDate' | 'isSelfWork' | 'participationPercentage'>
    & { company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
      & { smallBusinessClassifications?: Maybe<Array<(
        { __typename?: 'SmallBusinessClassification' }
        & Pick<SmallBusinessClassification, 'id' | 'name' | 'abbreviation' | 'isFederal'>
      )>> }
    )> }
  ) }
);

export type GetLowerTierParticipationsInUseQueryVariables = Exact<{
  smallBusinessAgencyId?: Maybe<Scalars['String']>;
  smallBusinessClassificationIds?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetLowerTierParticipationsInUseQuery = (
  { __typename?: 'Query' }
  & { getLowerTierParticipationsInUse: Array<(
    { __typename?: 'LowerTierParticipation' }
    & Pick<LowerTierParticipation, 'id' | 'lowerTierCompanyName'>
    & { contract: (
      { __typename?: 'Contract' }
      & Pick<Contract, 'id'>
      & { project: (
        { __typename?: 'Project' }
        & Pick<Project, 'id' | 'name' | 'number'>
      ) }
    ), lowerTierParticipationFileSummary?: Maybe<Array<(
      { __typename?: 'LowerTierParticipationFileSummary' }
      & Pick<LowerTierParticipationFileSummary, 'id' | 'smallBusinessAgencyId'>
      & { lowerTierParticipationClassifications: Array<(
        { __typename?: 'LowerTierParticipationClassification' }
        & Pick<LowerTierParticipationClassification, 'id'>
        & { smallBusinessClassification: (
          { __typename?: 'SmallBusinessClassification' }
          & Pick<SmallBusinessClassification, 'id' | 'name' | 'abbreviation'>
        ) }
      )> }
    )>> }
  )> }
);

export type UpsertLowerTierParticipationMutationVariables = Exact<{
  input: LowerTierParticipationUpsertInput;
}>;


export type UpsertLowerTierParticipationMutation = (
  { __typename?: 'Mutation' }
  & { upsertLowerTierParticipation: (
    { __typename?: 'LowerTierParticipation' }
    & Pick<LowerTierParticipation, 'id' | 'value' | 'lowerTierCompanyName' | 'effectiveDate'>
    & { contract: (
      { __typename?: 'Contract' }
      & Pick<Contract, 'id'>
    ), lowerTierParticipationFileSummary?: Maybe<Array<(
      { __typename?: 'LowerTierParticipationFileSummary' }
      & Pick<LowerTierParticipationFileSummary, 'id' | 'uploaded' | 'smallBusinessAgencyId' | 'participationPercentage'>
      & { uploadedBy: (
        { __typename?: 'AdUser' }
        & Pick<AdUser, 'id' | 'name'>
      ), file?: Maybe<(
        { __typename?: 'UploadedFile' }
        & Pick<UploadedFile, 'id' | 'contentType' | 'created' | 'isMalware' | 'isScanned' | 'lastModified' | 'name' | 'sizeInBytes' | 'url' | 'cachedUrl'>
      )>, lowerTierParticipationClassifications: Array<(
        { __typename?: 'LowerTierParticipationClassification' }
        & Pick<LowerTierParticipationClassification, 'id'>
        & { smallBusinessClassification: (
          { __typename?: 'SmallBusinessClassification' }
          & Pick<SmallBusinessClassification, 'id'>
        ) }
      )> }
    )>> }
  ) }
);

export type DeleteLowerTierParticipationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteLowerTierParticipationMutation = (
  { __typename?: 'Mutation' }
  & { deleteLowerTierParticipation: (
    { __typename?: 'EntityIdentifier' }
    & Pick<EntityIdentifier, 'id'>
  ) }
);

export type GetPaginatedProjectsQueryVariables = Exact<{
  skip: Scalars['Int'];
  first: Scalars['Int'];
  order?: Maybe<ProjectListOrderInput>;
  filter?: Maybe<ProjectListFilterInput>;
  search?: Maybe<Scalars['String']>;
}>;


export type GetPaginatedProjectsQuery = (
  { __typename?: 'Query' }
  & { projectListResult: (
    { __typename?: 'ProjectListResult' }
    & Pick<ProjectListResult, 'count'>
    & { items: Array<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'number' | 'name' | 'district' | 'city' | 'state' | 'smallBusinessRequirement' | 'isActive' | 'smallBusinessClientId'>
      & { smallBusinessClient?: Maybe<(
        { __typename?: 'SmallBusinessClient' }
        & Pick<SmallBusinessClient, 'id' | 'name' | 'abbreviation'>
      )> }
    )> }
  ) }
);

export type GetSingleProjectQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetSingleProjectQuery = (
  { __typename?: 'Query' }
  & { project: (
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'number' | 'name' | 'notes' | 'address' | 'district' | 'city' | 'state' | 'zip' | 'isActive' | 'originalContractValue' | 'originalSubcontractValue' | 'smallBusinessRequirement' | 'primeContractNumber'>
    & { smallBusinessClient?: Maybe<(
      { __typename?: 'SmallBusinessClient' }
      & Pick<SmallBusinessClient, 'id' | 'name' | 'abbreviation' | 'isFederal'>
      & { smallBusinessClientAgencies: Array<(
        { __typename?: 'SmallBusinessClientAgency' }
        & Pick<SmallBusinessClientAgency, 'id'>
        & { smallBusinessAgency: (
          { __typename?: 'SmallBusinessAgency' }
          & Pick<SmallBusinessAgency, 'id' | 'name' | 'abbreviation' | 'isActive' | 'isFederal' | 'url' | 'specialNotice' | 'participationPercentage'>
          & { smallBusinessAgencyClassifications: Array<(
            { __typename?: 'SmallBusinessAgencyClassification' }
            & Pick<SmallBusinessAgencyClassification, 'id'>
            & { smallBusinessClassification: (
              { __typename?: 'SmallBusinessClassification' }
              & Pick<SmallBusinessClassification, 'id' | 'name' | 'abbreviation' | 'isFederal'>
            ) }
          )> }
        ), smallBusinessClientClassifications: Array<(
          { __typename?: 'SmallBusinessClientClassification' }
          & Pick<SmallBusinessClientClassification, 'id'>
          & { smallBusinessClassification: (
            { __typename?: 'SmallBusinessClassification' }
            & Pick<SmallBusinessClassification, 'id' | 'name' | 'abbreviation' | 'isFederal'>
          ) }
        )> }
      )> }
    )>, smallBusinessReportSettings?: Maybe<(
      { __typename?: 'SmallBusinessReportSettings' }
      & Pick<SmallBusinessReportSettings, 'id' | 'isActualCalculationsBasedOnTotalContractValue' | 'notes'>
    )>, admins: Array<(
      { __typename?: 'AdUser' }
      & Pick<AdUser, 'id' | 'microsoftAdId' | 'upn' | 'name' | 'firstName' | 'lastName' | 'email' | 'jobTitle'>
    )> }
  ) }
);

export type GetProjectsContractedAfterQueryVariables = Exact<{
  companyId: Scalars['String'];
  date: Scalars['String'];
}>;


export type GetProjectsContractedAfterQuery = (
  { __typename?: 'Query' }
  & { getProjectsContractedAfter: Array<(
    { __typename?: 'Project' }
    & Pick<Project, 'number' | 'name'>
  )> }
);

export type GetProjectsInUseQueryVariables = Exact<{
  smallBusinessClientId?: Maybe<Scalars['String']>;
  smallBusinessClassificationId?: Maybe<Scalars['String']>;
}>;


export type GetProjectsInUseQuery = (
  { __typename?: 'Query' }
  & { getProjectsInUse: Array<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'number' | 'name'>
    & { smallBusinessReportSettings?: Maybe<(
      { __typename?: 'SmallBusinessReportSettings' }
      & Pick<SmallBusinessReportSettings, 'id'>
      & { classifications: Array<(
        { __typename?: 'SmallBusinessReportClassification' }
        & Pick<SmallBusinessReportClassification, 'id' | 'goalPercent'>
        & { smallBusinessClassification: (
          { __typename?: 'SmallBusinessClassification' }
          & Pick<SmallBusinessClassification, 'id' | 'name' | 'abbreviation' | 'isFederal'>
        ) }
      )> }
    )> }
  )> }
);

export type UpdateProjectMutationVariables = Exact<{
  input: ProjectUpdateInput;
}>;


export type UpdateProjectMutation = (
  { __typename?: 'Mutation' }
  & { updateProject: (
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'number' | 'name' | 'notes' | 'address' | 'district' | 'city' | 'state' | 'zip' | 'isActive' | 'originalContractValue' | 'originalSubcontractValue' | 'smallBusinessRequirement' | 'primeContractNumber'>
    & { smallBusinessClient?: Maybe<(
      { __typename?: 'SmallBusinessClient' }
      & Pick<SmallBusinessClient, 'id'>
    )>, smallBusinessReportSettings?: Maybe<(
      { __typename?: 'SmallBusinessReportSettings' }
      & Pick<SmallBusinessReportSettings, 'id' | 'isActualCalculationsBasedOnTotalContractValue' | 'notes'>
      & { classifications: Array<(
        { __typename?: 'SmallBusinessReportClassification' }
        & Pick<SmallBusinessReportClassification, 'id' | 'goalPercent'>
      )> }
    )> }
  ) }
);

export type SetAdminStatusOnProjectMutationVariables = Exact<{
  projectId: Scalars['ID'];
  microsoftAdId: Scalars['String'];
  isAdmin: Scalars['Boolean'];
}>;


export type SetAdminStatusOnProjectMutation = (
  { __typename?: 'Mutation' }
  & { setAdminStatusOnProject: (
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'number' | 'name' | 'notes' | 'address' | 'district' | 'city' | 'state' | 'zip' | 'isActive' | 'originalContractValue' | 'originalSubcontractValue' | 'smallBusinessRequirement' | 'primeContractNumber'>
    & { smallBusinessClient?: Maybe<(
      { __typename?: 'SmallBusinessClient' }
      & Pick<SmallBusinessClient, 'id' | 'name' | 'abbreviation'>
    )>, smallBusinessReportSettings?: Maybe<(
      { __typename?: 'SmallBusinessReportSettings' }
      & Pick<SmallBusinessReportSettings, 'id' | 'isActualCalculationsBasedOnTotalContractValue' | 'notes'>
    )> }
  ) }
);

export type SmallBusinessAgencyListQueryVariables = Exact<{
  isActive: Array<Scalars['Boolean']> | Scalars['Boolean'];
  federal?: Maybe<Scalars['Boolean']>;
}>;


export type SmallBusinessAgencyListQuery = (
  { __typename?: 'Query' }
  & { smallBusinessAgencyList: Array<(
    { __typename?: 'SmallBusinessAgency' }
    & Pick<SmallBusinessAgency, 'id' | 'name' | 'abbreviation' | 'isFederal' | 'isActive' | 'url' | 'participationPercentage' | 'specialNotice'>
    & { smallBusinessAgencyClassifications: Array<(
      { __typename?: 'SmallBusinessAgencyClassification' }
      & Pick<SmallBusinessAgencyClassification, 'id'>
      & { smallBusinessClassification: (
        { __typename?: 'SmallBusinessClassification' }
        & Pick<SmallBusinessClassification, 'id' | 'name' | 'abbreviation' | 'sortOrder'>
      ) }
    )> }
  )> }
);

export type GetSmallBusinessAgenciesInUseQueryVariables = Exact<{
  smallBusinessClassificationId: Scalars['String'];
}>;


export type GetSmallBusinessAgenciesInUseQuery = (
  { __typename?: 'Query' }
  & { getSmallBusinessAgenciesInUse: Array<(
    { __typename?: 'SmallBusinessAgency' }
    & Pick<SmallBusinessAgency, 'id' | 'name' | 'abbreviation'>
  )> }
);

export type UpsertSmallBusinessAgencyMutationVariables = Exact<{
  input: SmallBusinessAgencyUpsertInput;
}>;


export type UpsertSmallBusinessAgencyMutation = (
  { __typename?: 'Mutation' }
  & { upsertSmallBusinessAgency: (
    { __typename?: 'SmallBusinessAgency' }
    & Pick<SmallBusinessAgency, 'id' | 'name' | 'abbreviation' | 'isFederal' | 'isActive' | 'url' | 'participationPercentage' | 'specialNotice'>
    & { smallBusinessAgencyClassifications: Array<(
      { __typename?: 'SmallBusinessAgencyClassification' }
      & Pick<SmallBusinessAgencyClassification, 'id'>
      & { smallBusinessAgency: (
        { __typename?: 'SmallBusinessAgency' }
        & Pick<SmallBusinessAgency, 'id'>
      ), smallBusinessClassification: (
        { __typename?: 'SmallBusinessClassification' }
        & Pick<SmallBusinessClassification, 'id'>
      ) }
    )> }
  ) }
);

export type DeleteSmallBusinessAgencyMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteSmallBusinessAgencyMutation = (
  { __typename?: 'Mutation' }
  & { deleteSmallBusinessAgency: (
    { __typename?: 'EntityIdentifier' }
    & Pick<EntityIdentifier, 'id'>
  ) }
);

export type GetSmallBusinessClassificationsQueryVariables = Exact<{
  federal?: Maybe<Scalars['Boolean']>;
}>;


export type GetSmallBusinessClassificationsQuery = (
  { __typename?: 'Query' }
  & { smallBusinessClassificationList: Array<(
    { __typename?: 'SmallBusinessClassification' }
    & Pick<SmallBusinessClassification, 'id' | 'name' | 'abbreviation' | 'sortOrder' | 'isFederal'>
  )> }
);

export type UpsertSmallBusinessClassificationMutationVariables = Exact<{
  input: SmallBusinessClassificationUpsertInput;
}>;


export type UpsertSmallBusinessClassificationMutation = (
  { __typename?: 'Mutation' }
  & { upsertSmallBusinessClassification: (
    { __typename?: 'SmallBusinessClassification' }
    & Pick<SmallBusinessClassification, 'id' | 'name' | 'abbreviation' | 'isFederal' | 'sortOrder'>
  ) }
);

export type DeleteSmallBusinessClassificationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteSmallBusinessClassificationMutation = (
  { __typename?: 'Mutation' }
  & { deleteSmallBusinessClassification: (
    { __typename?: 'EntityIdentifier' }
    & Pick<EntityIdentifier, 'id'>
  ) }
);

export type SmallBusinessClientListQueryVariables = Exact<{
  isActive: Array<Scalars['Boolean']> | Scalars['Boolean'];
  federal?: Maybe<Scalars['Boolean']>;
}>;


export type SmallBusinessClientListQuery = (
  { __typename?: 'Query' }
  & { smallBusinessClientList: Array<(
    { __typename?: 'SmallBusinessClient' }
    & Pick<SmallBusinessClient, 'id' | 'name' | 'abbreviation' | 'isFederal' | 'isActive'>
    & { smallBusinessClientAgencies: Array<(
      { __typename?: 'SmallBusinessClientAgency' }
      & Pick<SmallBusinessClientAgency, 'id'>
      & { smallBusinessAgency: (
        { __typename?: 'SmallBusinessAgency' }
        & Pick<SmallBusinessAgency, 'id' | 'name' | 'abbreviation' | 'isFederal' | 'url' | 'specialNotice' | 'participationPercentage'>
        & { smallBusinessAgencyClassifications: Array<(
          { __typename?: 'SmallBusinessAgencyClassification' }
          & Pick<SmallBusinessAgencyClassification, 'id'>
          & { smallBusinessClassification: (
            { __typename?: 'SmallBusinessClassification' }
            & Pick<SmallBusinessClassification, 'id' | 'name' | 'abbreviation' | 'isFederal'>
          ) }
        )> }
      ), smallBusinessClientClassifications: Array<(
        { __typename?: 'SmallBusinessClientClassification' }
        & Pick<SmallBusinessClientClassification, 'id'>
        & { smallBusinessClassification: (
          { __typename?: 'SmallBusinessClassification' }
          & Pick<SmallBusinessClassification, 'id' | 'name' | 'abbreviation' | 'isFederal'>
        ) }
      )> }
    )> }
  )> }
);

export type GetSmallBusinessClientsInUseQueryVariables = Exact<{
  smallBusinessAgencyId: Scalars['String'];
}>;


export type GetSmallBusinessClientsInUseQuery = (
  { __typename?: 'Query' }
  & { getSmallBusinessClientsInUse: Array<(
    { __typename?: 'SmallBusinessClient' }
    & Pick<SmallBusinessClient, 'id' | 'name' | 'abbreviation'>
  )> }
);

export type UpsertSmallBusinessClientMutationVariables = Exact<{
  input: SmallBusinessClientUpsertInput;
}>;


export type UpsertSmallBusinessClientMutation = (
  { __typename?: 'Mutation' }
  & { upsertSmallBusinessClient: (
    { __typename?: 'SmallBusinessClient' }
    & Pick<SmallBusinessClient, 'id' | 'name' | 'abbreviation' | 'isFederal' | 'isActive'>
    & { smallBusinessClientAgencies: Array<(
      { __typename?: 'SmallBusinessClientAgency' }
      & Pick<SmallBusinessClientAgency, 'id'>
      & { smallBusinessAgency: (
        { __typename?: 'SmallBusinessAgency' }
        & Pick<SmallBusinessAgency, 'id'>
      ), smallBusinessClientClassifications: Array<(
        { __typename?: 'SmallBusinessClientClassification' }
        & Pick<SmallBusinessClientClassification, 'id'>
        & { smallBusinessClassification: (
          { __typename?: 'SmallBusinessClassification' }
          & Pick<SmallBusinessClassification, 'id'>
        ) }
      )> }
    )> }
  ) }
);

export type DeleteSmallBusinessClientMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteSmallBusinessClientMutation = (
  { __typename?: 'Mutation' }
  & { deleteSmallBusinessClient: (
    { __typename?: 'EntityIdentifier' }
    & Pick<EntityIdentifier, 'id'>
  ) }
);

export type ProjectSmallBusinessReportQueryVariables = Exact<{
  smallBusinessReportSettingsId: Scalars['ID'];
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  form?: Maybe<SmallBusinessFederalReportFormOption>;
}>;


export type ProjectSmallBusinessReportQuery = (
  { __typename?: 'Query' }
  & { projectSmallBusinessReport: (
    { __typename?: 'ProjectSmallBusinessReport' }
    & Pick<ProjectSmallBusinessReport, 'checksum' | 'currentContractValue' | 'currentSubContractorContractValue'>
    & { classificationSummaries: Array<(
      { __typename?: 'SmallBusinessReportClassificationSummary' }
      & Pick<SmallBusinessReportClassificationSummary, 'abbreviation' | 'actualPercentTotal' | 'actualPercentOriginal' | 'contractIds' | 'lowerTierParticipationIds' | 'actualTotal' | 'lowerTierParticipationTotal' | 'lowerTierParticipationPercentOriginal' | 'lowerTierPercentTotal' | 'firstTierTotal' | 'firstTierPercentOriginal' | 'firstTierPercentTotal' | 'sortOrder' | 'smallBusinessReportClassificationId' | 'smallBusinessClassificationId' | 'goalPercent' | 'goalTotal' | 'name' | 'isFederal'>
    )>, contractSummaries: Array<(
      { __typename?: 'SmallBusinessReportContractSummary' }
      & Pick<SmallBusinessReportContractSummary, 'companyId' | 'companyName' | 'contractId' | 'phaseCode' | 'effectiveDate' | 'valueTotal' | 'isSelfWork' | 'participationPercentage'>
      & { coverages: Array<(
        { __typename?: 'SmallBusinessClassificationCoverage' }
        & Pick<SmallBusinessClassificationCoverage, 'id' | 'isFederal' | 'startDate' | 'endDate'>
        & { smallBusinessClassification: (
          { __typename?: 'SmallBusinessClassification' }
          & Pick<SmallBusinessClassification, 'id' | 'name' | 'abbreviation' | 'sortOrder' | 'isFederal' | 'created' | 'lastModified'>
        ), smallBusinessAgency?: Maybe<(
          { __typename?: 'SmallBusinessAgency' }
          & Pick<SmallBusinessAgency, 'id' | 'name' | 'abbreviation'>
        )> }
      )>, smallBusinessClassifications: Array<(
        { __typename?: 'SmallBusinessClassification' }
        & Pick<SmallBusinessClassification, 'id' | 'name' | 'abbreviation' | 'isFederal'>
      )>, changeOrderSummaries: Array<(
        { __typename?: 'SmallBusinessReportChangeOrderSummary' }
        & Pick<SmallBusinessReportChangeOrderSummary, 'date' | 'value'>
      )>, lowerTierParticipationClassificationSummaries: Array<(
        { __typename?: 'LowerTierParticipationClassificationSummary' }
        & Pick<LowerTierParticipationClassificationSummary, 'lowerTierParticipationId' | 'contractId' | 'value' | 'lowerTierCompanyName' | 'effectiveDate'>
        & { smallBusinessClassifications: Array<(
          { __typename?: 'SmallBusinessClassification' }
          & Pick<SmallBusinessClassification, 'id' | 'name' | 'abbreviation'>
        )>, lowerTierParticipationFileSummary?: Maybe<Array<(
          { __typename?: 'LowerTierParticipationFileSummary' }
          & Pick<LowerTierParticipationFileSummary, 'id' | 'participationPercentage'>
          & { file?: Maybe<(
            { __typename?: 'UploadedFile' }
            & Pick<UploadedFile, 'id' | 'contentType' | 'created' | 'isMalware' | 'isScanned' | 'lastModified' | 'name' | 'sizeInBytes' | 'url' | 'cachedUrl'>
          )>, uploadedBy: (
            { __typename?: 'AdUser' }
            & Pick<AdUser, 'id' | 'name'>
          ), smallBusinessAgency?: Maybe<(
            { __typename?: 'SmallBusinessAgency' }
            & Pick<SmallBusinessAgency, 'id' | 'name' | 'abbreviation'>
          )>, lowerTierParticipationClassifications: Array<(
            { __typename?: 'LowerTierParticipationClassification' }
            & Pick<LowerTierParticipationClassification, 'id'>
            & { smallBusinessClassification: (
              { __typename?: 'SmallBusinessClassification' }
              & Pick<SmallBusinessClassification, 'id' | 'name' | 'abbreviation'>
            ) }
          )> }
        )>> }
      )> }
    )> }
  ) }
);

export type SaveProjectSmallBusinessReportMutationVariables = Exact<{
  comments?: Maybe<Scalars['String']>;
  smallBusinessReportSettingsId: Scalars['ID'];
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  transactionKey: Scalars['String'];
  form?: Maybe<SmallBusinessFederalReportFormOption>;
  type: SmallBusinessSavedReportTypeOption;
}>;


export type SaveProjectSmallBusinessReportMutation = (
  { __typename?: 'Mutation' }
  & { saveProjectSmallBusinessReport: (
    { __typename?: 'ProjectSmallBusinessReport' }
    & Pick<ProjectSmallBusinessReport, 'checksum' | 'currentContractValue' | 'currentSubContractorContractValue'>
  ) }
);


export const GetAdContactsDocument = gql`
    query GetAdContacts($search: String!, $first: Int, $filter: AdContactFilter) {
  adContacts(search: $search, first: $first, filter: $filter) {
    microsoftAdId
    upn
    name
    firstName
    lastName
    email
    jobTitle
    officeLocation
    thumbnailUrl
  }
}
    `;

/**
 * __useGetAdContactsQuery__
 *
 * To run a query within a React component, call `useGetAdContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdContactsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetAdContactsQuery(baseOptions: Apollo.QueryHookOptions<GetAdContactsQuery, GetAdContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdContactsQuery, GetAdContactsQueryVariables>(GetAdContactsDocument, options);
      }
export function useGetAdContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdContactsQuery, GetAdContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdContactsQuery, GetAdContactsQueryVariables>(GetAdContactsDocument, options);
        }
export type GetAdContactsQueryHookResult = ReturnType<typeof useGetAdContactsQuery>;
export type GetAdContactsLazyQueryHookResult = ReturnType<typeof useGetAdContactsLazyQuery>;
export type GetAdContactsQueryResult = Apollo.QueryResult<GetAdContactsQuery, GetAdContactsQueryVariables>;
export const GetAdContactDocument = gql`
    query GetAdContact($microsoftAdId: String!) {
  adContact(microsoftAdId: $microsoftAdId) {
    microsoftAdId
    upn
    name
    firstName
    lastName
    email
    jobTitle
    officeLocation
    thumbnailUrl
  }
}
    `;

/**
 * __useGetAdContactQuery__
 *
 * To run a query within a React component, call `useGetAdContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdContactQuery({
 *   variables: {
 *      microsoftAdId: // value for 'microsoftAdId'
 *   },
 * });
 */
export function useGetAdContactQuery(baseOptions: Apollo.QueryHookOptions<GetAdContactQuery, GetAdContactQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdContactQuery, GetAdContactQueryVariables>(GetAdContactDocument, options);
      }
export function useGetAdContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdContactQuery, GetAdContactQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdContactQuery, GetAdContactQueryVariables>(GetAdContactDocument, options);
        }
export type GetAdContactQueryHookResult = ReturnType<typeof useGetAdContactQuery>;
export type GetAdContactLazyQueryHookResult = ReturnType<typeof useGetAdContactLazyQuery>;
export type GetAdContactQueryResult = Apollo.QueryResult<GetAdContactQuery, GetAdContactQueryVariables>;
export const CurrentAdUserDocument = gql`
    query CurrentAdUser {
  currentAdUser {
    projects {
      id
    }
  }
}
    `;

/**
 * __useCurrentAdUserQuery__
 *
 * To run a query within a React component, call `useCurrentAdUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentAdUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentAdUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentAdUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentAdUserQuery, CurrentAdUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentAdUserQuery, CurrentAdUserQueryVariables>(CurrentAdUserDocument, options);
      }
export function useCurrentAdUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentAdUserQuery, CurrentAdUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentAdUserQuery, CurrentAdUserQueryVariables>(CurrentAdUserDocument, options);
        }
export type CurrentAdUserQueryHookResult = ReturnType<typeof useCurrentAdUserQuery>;
export type CurrentAdUserLazyQueryHookResult = ReturnType<typeof useCurrentAdUserLazyQuery>;
export type CurrentAdUserQueryResult = Apollo.QueryResult<CurrentAdUserQuery, CurrentAdUserQueryVariables>;
export const GetPaginatedCompaniesDocument = gql`
    query GetPaginatedCompanies($skip: Int!, $first: Int!, $search: String, $filter: CompanyListFilterInput, $order: CompanyListOrderInput) {
  companyListResult(
    skip: $skip
    first: $first
    search: $search
    filter: $filter
    order: $order
  ) {
    count
    items {
      id
      name
      location
      federalSmallBusinessClassifications {
        id
        name
        abbreviation
        sortOrder
      }
      smallBusinessClassifications {
        id
        name
        abbreviation
        sortOrder
      }
    }
  }
}
    `;

/**
 * __useGetPaginatedCompaniesQuery__
 *
 * To run a query within a React component, call `useGetPaginatedCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedCompaniesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *      search: // value for 'search'
 *      filter: // value for 'filter'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetPaginatedCompaniesQuery(baseOptions: Apollo.QueryHookOptions<GetPaginatedCompaniesQuery, GetPaginatedCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaginatedCompaniesQuery, GetPaginatedCompaniesQueryVariables>(GetPaginatedCompaniesDocument, options);
      }
export function useGetPaginatedCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaginatedCompaniesQuery, GetPaginatedCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaginatedCompaniesQuery, GetPaginatedCompaniesQueryVariables>(GetPaginatedCompaniesDocument, options);
        }
export type GetPaginatedCompaniesQueryHookResult = ReturnType<typeof useGetPaginatedCompaniesQuery>;
export type GetPaginatedCompaniesLazyQueryHookResult = ReturnType<typeof useGetPaginatedCompaniesLazyQuery>;
export type GetPaginatedCompaniesQueryResult = Apollo.QueryResult<GetPaginatedCompaniesQuery, GetPaginatedCompaniesQueryVariables>;
export const GetSingleCompanyDocument = gql`
    query GetSingleCompany($id: ID!) {
  company(id: $id) {
    id
    name
    location
    notes
    federalSmallBusinessClassifications {
      id
      name
      abbreviation
      sortOrder
    }
    smallBusinessClassifications {
      id
      name
      abbreviation
      sortOrder
    }
    files {
      id
      title
      notes
      uploaded
      uploadedBy {
        id
        name
      }
      file {
        id
        contentType
        created
        isMalware
        isScanned
        lastModified
        name
        sizeInBytes
        url
        cachedUrl @client
      }
      smallBusinessClassificationCoverages {
        id
        isFederal
        smallBusinessClassification {
          id
          name
          abbreviation
          sortOrder
          isFederal
        }
        startDate
        endDate
        smallBusinessAgency {
          id
          name
          abbreviation
          url
          participationPercentage
          specialNotice
        }
      }
    }
  }
}
    `;

/**
 * __useGetSingleCompanyQuery__
 *
 * To run a query within a React component, call `useGetSingleCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSingleCompanyQuery(baseOptions: Apollo.QueryHookOptions<GetSingleCompanyQuery, GetSingleCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSingleCompanyQuery, GetSingleCompanyQueryVariables>(GetSingleCompanyDocument, options);
      }
export function useGetSingleCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSingleCompanyQuery, GetSingleCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSingleCompanyQuery, GetSingleCompanyQueryVariables>(GetSingleCompanyDocument, options);
        }
export type GetSingleCompanyQueryHookResult = ReturnType<typeof useGetSingleCompanyQuery>;
export type GetSingleCompanyLazyQueryHookResult = ReturnType<typeof useGetSingleCompanyLazyQuery>;
export type GetSingleCompanyQueryResult = Apollo.QueryResult<GetSingleCompanyQuery, GetSingleCompanyQueryVariables>;
export const GetCompaniesInUseDocument = gql`
    query GetCompaniesInUse($smallBusinessAgencyId: String, $smallBusinessClassificationIds: [String!]) {
  getCompaniesInUse(
    smallBusinessAgencyId: $smallBusinessAgencyId
    smallBusinessClassificationIds: $smallBusinessClassificationIds
  ) {
    id
    name
    files {
      id
      smallBusinessClassificationCoverages {
        id
        smallBusinessClassification {
          id
          name
          abbreviation
        }
      }
    }
  }
}
    `;

/**
 * __useGetCompaniesInUseQuery__
 *
 * To run a query within a React component, call `useGetCompaniesInUseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompaniesInUseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompaniesInUseQuery({
 *   variables: {
 *      smallBusinessAgencyId: // value for 'smallBusinessAgencyId'
 *      smallBusinessClassificationIds: // value for 'smallBusinessClassificationIds'
 *   },
 * });
 */
export function useGetCompaniesInUseQuery(baseOptions?: Apollo.QueryHookOptions<GetCompaniesInUseQuery, GetCompaniesInUseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompaniesInUseQuery, GetCompaniesInUseQueryVariables>(GetCompaniesInUseDocument, options);
      }
export function useGetCompaniesInUseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompaniesInUseQuery, GetCompaniesInUseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompaniesInUseQuery, GetCompaniesInUseQueryVariables>(GetCompaniesInUseDocument, options);
        }
export type GetCompaniesInUseQueryHookResult = ReturnType<typeof useGetCompaniesInUseQuery>;
export type GetCompaniesInUseLazyQueryHookResult = ReturnType<typeof useGetCompaniesInUseLazyQuery>;
export type GetCompaniesInUseQueryResult = Apollo.QueryResult<GetCompaniesInUseQuery, GetCompaniesInUseQueryVariables>;
export const UpdateCompanyDocument = gql`
    mutation UpdateCompany($input: CompanyUpdateInput!) {
  updateCompany(input: $input) {
    id
    name
    notes
    location
  }
}
    `;
export type UpdateCompanyMutationFn = Apollo.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, options);
      }
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const UploadCompanyFileDocument = gql`
    mutation UploadCompanyFile($input: CompanyFileCreateInput!) {
  uploadCompanyFile(input: $input) {
    id
    title
    notes
    uploaded
    uploadedBy {
      id
      name
    }
    file {
      id
      contentType
      created
      isMalware
      isScanned
      lastModified
      name
      sizeInBytes
      url
      cachedUrl @client
    }
  }
}
    `;
export type UploadCompanyFileMutationFn = Apollo.MutationFunction<UploadCompanyFileMutation, UploadCompanyFileMutationVariables>;

/**
 * __useUploadCompanyFileMutation__
 *
 * To run a mutation, you first call `useUploadCompanyFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadCompanyFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadCompanyFileMutation, { data, loading, error }] = useUploadCompanyFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadCompanyFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadCompanyFileMutation, UploadCompanyFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadCompanyFileMutation, UploadCompanyFileMutationVariables>(UploadCompanyFileDocument, options);
      }
export type UploadCompanyFileMutationHookResult = ReturnType<typeof useUploadCompanyFileMutation>;
export type UploadCompanyFileMutationResult = Apollo.MutationResult<UploadCompanyFileMutation>;
export type UploadCompanyFileMutationOptions = Apollo.BaseMutationOptions<UploadCompanyFileMutation, UploadCompanyFileMutationVariables>;
export const UpdateCompanyFileDocument = gql`
    mutation UpdateCompanyFile($input: CompanyFileUpdateInput!) {
  updateCompanyFile(input: $input) {
    id
    title
    notes
    uploaded
    uploadedBy {
      id
      name
    }
    file {
      id
      contentType
      created
      isMalware
      isScanned
      lastModified
      name
      sizeInBytes
      url
      cachedUrl @client
    }
  }
}
    `;
export type UpdateCompanyFileMutationFn = Apollo.MutationFunction<UpdateCompanyFileMutation, UpdateCompanyFileMutationVariables>;

/**
 * __useUpdateCompanyFileMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyFileMutation, { data, loading, error }] = useUpdateCompanyFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyFileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyFileMutation, UpdateCompanyFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyFileMutation, UpdateCompanyFileMutationVariables>(UpdateCompanyFileDocument, options);
      }
export type UpdateCompanyFileMutationHookResult = ReturnType<typeof useUpdateCompanyFileMutation>;
export type UpdateCompanyFileMutationResult = Apollo.MutationResult<UpdateCompanyFileMutation>;
export type UpdateCompanyFileMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyFileMutation, UpdateCompanyFileMutationVariables>;
export const DeleteCompanyFileDocument = gql`
    mutation DeleteCompanyFile($id: ID!) {
  deleteCompanyFile(id: $id) {
    id
  }
}
    `;
export type DeleteCompanyFileMutationFn = Apollo.MutationFunction<DeleteCompanyFileMutation, DeleteCompanyFileMutationVariables>;

/**
 * __useDeleteCompanyFileMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyFileMutation, { data, loading, error }] = useDeleteCompanyFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCompanyFileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCompanyFileMutation, DeleteCompanyFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCompanyFileMutation, DeleteCompanyFileMutationVariables>(DeleteCompanyFileDocument, options);
      }
export type DeleteCompanyFileMutationHookResult = ReturnType<typeof useDeleteCompanyFileMutation>;
export type DeleteCompanyFileMutationResult = Apollo.MutationResult<DeleteCompanyFileMutation>;
export type DeleteCompanyFileMutationOptions = Apollo.BaseMutationOptions<DeleteCompanyFileMutation, DeleteCompanyFileMutationVariables>;
export const UpsertContractDocument = gql`
    mutation UpsertContract($input: ContractUpsertInput!) {
  upsertContract(input: $input) {
    id
    effectiveDate
    isSelfWork
    participationPercentage
    company {
      id
      name
      smallBusinessClassifications {
        id
        name
        abbreviation
        isFederal
      }
    }
  }
}
    `;
export type UpsertContractMutationFn = Apollo.MutationFunction<UpsertContractMutation, UpsertContractMutationVariables>;

/**
 * __useUpsertContractMutation__
 *
 * To run a mutation, you first call `useUpsertContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertContractMutation, { data, loading, error }] = useUpsertContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertContractMutation(baseOptions?: Apollo.MutationHookOptions<UpsertContractMutation, UpsertContractMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertContractMutation, UpsertContractMutationVariables>(UpsertContractDocument, options);
      }
export type UpsertContractMutationHookResult = ReturnType<typeof useUpsertContractMutation>;
export type UpsertContractMutationResult = Apollo.MutationResult<UpsertContractMutation>;
export type UpsertContractMutationOptions = Apollo.BaseMutationOptions<UpsertContractMutation, UpsertContractMutationVariables>;
export const GetLowerTierParticipationsInUseDocument = gql`
    query GetLowerTierParticipationsInUse($smallBusinessAgencyId: String, $smallBusinessClassificationIds: [String!]) {
  getLowerTierParticipationsInUse(
    smallBusinessAgencyId: $smallBusinessAgencyId
    smallBusinessClassificationIds: $smallBusinessClassificationIds
  ) {
    id
    lowerTierCompanyName
    contract {
      id
      project {
        id
        name
        number
      }
    }
    lowerTierParticipationFileSummary {
      id
      smallBusinessAgencyId
      lowerTierParticipationClassifications {
        id
        smallBusinessClassification {
          id
          name
          abbreviation
        }
      }
    }
  }
}
    `;

/**
 * __useGetLowerTierParticipationsInUseQuery__
 *
 * To run a query within a React component, call `useGetLowerTierParticipationsInUseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLowerTierParticipationsInUseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLowerTierParticipationsInUseQuery({
 *   variables: {
 *      smallBusinessAgencyId: // value for 'smallBusinessAgencyId'
 *      smallBusinessClassificationIds: // value for 'smallBusinessClassificationIds'
 *   },
 * });
 */
export function useGetLowerTierParticipationsInUseQuery(baseOptions?: Apollo.QueryHookOptions<GetLowerTierParticipationsInUseQuery, GetLowerTierParticipationsInUseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLowerTierParticipationsInUseQuery, GetLowerTierParticipationsInUseQueryVariables>(GetLowerTierParticipationsInUseDocument, options);
      }
export function useGetLowerTierParticipationsInUseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLowerTierParticipationsInUseQuery, GetLowerTierParticipationsInUseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLowerTierParticipationsInUseQuery, GetLowerTierParticipationsInUseQueryVariables>(GetLowerTierParticipationsInUseDocument, options);
        }
export type GetLowerTierParticipationsInUseQueryHookResult = ReturnType<typeof useGetLowerTierParticipationsInUseQuery>;
export type GetLowerTierParticipationsInUseLazyQueryHookResult = ReturnType<typeof useGetLowerTierParticipationsInUseLazyQuery>;
export type GetLowerTierParticipationsInUseQueryResult = Apollo.QueryResult<GetLowerTierParticipationsInUseQuery, GetLowerTierParticipationsInUseQueryVariables>;
export const UpsertLowerTierParticipationDocument = gql`
    mutation UpsertLowerTierParticipation($input: LowerTierParticipationUpsertInput!) {
  upsertLowerTierParticipation(input: $input) {
    id
    value
    lowerTierCompanyName
    effectiveDate
    contract {
      id
    }
    lowerTierParticipationFileSummary {
      id
      uploaded
      uploadedBy {
        id
        name
      }
      file {
        id
        contentType
        created
        isMalware
        isScanned
        lastModified
        name
        sizeInBytes
        url
        cachedUrl @client
      }
      smallBusinessAgencyId
      lowerTierParticipationClassifications {
        id
        smallBusinessClassification {
          id
        }
      }
      participationPercentage
    }
  }
}
    `;
export type UpsertLowerTierParticipationMutationFn = Apollo.MutationFunction<UpsertLowerTierParticipationMutation, UpsertLowerTierParticipationMutationVariables>;

/**
 * __useUpsertLowerTierParticipationMutation__
 *
 * To run a mutation, you first call `useUpsertLowerTierParticipationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertLowerTierParticipationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertLowerTierParticipationMutation, { data, loading, error }] = useUpsertLowerTierParticipationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertLowerTierParticipationMutation(baseOptions?: Apollo.MutationHookOptions<UpsertLowerTierParticipationMutation, UpsertLowerTierParticipationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertLowerTierParticipationMutation, UpsertLowerTierParticipationMutationVariables>(UpsertLowerTierParticipationDocument, options);
      }
export type UpsertLowerTierParticipationMutationHookResult = ReturnType<typeof useUpsertLowerTierParticipationMutation>;
export type UpsertLowerTierParticipationMutationResult = Apollo.MutationResult<UpsertLowerTierParticipationMutation>;
export type UpsertLowerTierParticipationMutationOptions = Apollo.BaseMutationOptions<UpsertLowerTierParticipationMutation, UpsertLowerTierParticipationMutationVariables>;
export const DeleteLowerTierParticipationDocument = gql`
    mutation DeleteLowerTierParticipation($id: ID!) {
  deleteLowerTierParticipation(id: $id) {
    id
  }
}
    `;
export type DeleteLowerTierParticipationMutationFn = Apollo.MutationFunction<DeleteLowerTierParticipationMutation, DeleteLowerTierParticipationMutationVariables>;

/**
 * __useDeleteLowerTierParticipationMutation__
 *
 * To run a mutation, you first call `useDeleteLowerTierParticipationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLowerTierParticipationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLowerTierParticipationMutation, { data, loading, error }] = useDeleteLowerTierParticipationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLowerTierParticipationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLowerTierParticipationMutation, DeleteLowerTierParticipationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLowerTierParticipationMutation, DeleteLowerTierParticipationMutationVariables>(DeleteLowerTierParticipationDocument, options);
      }
export type DeleteLowerTierParticipationMutationHookResult = ReturnType<typeof useDeleteLowerTierParticipationMutation>;
export type DeleteLowerTierParticipationMutationResult = Apollo.MutationResult<DeleteLowerTierParticipationMutation>;
export type DeleteLowerTierParticipationMutationOptions = Apollo.BaseMutationOptions<DeleteLowerTierParticipationMutation, DeleteLowerTierParticipationMutationVariables>;
export const GetPaginatedProjectsDocument = gql`
    query GetPaginatedProjects($skip: Int!, $first: Int!, $order: ProjectListOrderInput, $filter: ProjectListFilterInput, $search: String) {
  projectListResult(
    skip: $skip
    first: $first
    order: $order
    filter: $filter
    search: $search
  ) {
    count
    items {
      id
      number
      name
      district
      city
      state
      smallBusinessRequirement
      isActive
      smallBusinessClientId
      smallBusinessClient {
        id
        name
        abbreviation
      }
    }
  }
}
    `;

/**
 * __useGetPaginatedProjectsQuery__
 *
 * To run a query within a React component, call `useGetPaginatedProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedProjectsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *      order: // value for 'order'
 *      filter: // value for 'filter'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetPaginatedProjectsQuery(baseOptions: Apollo.QueryHookOptions<GetPaginatedProjectsQuery, GetPaginatedProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaginatedProjectsQuery, GetPaginatedProjectsQueryVariables>(GetPaginatedProjectsDocument, options);
      }
export function useGetPaginatedProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaginatedProjectsQuery, GetPaginatedProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaginatedProjectsQuery, GetPaginatedProjectsQueryVariables>(GetPaginatedProjectsDocument, options);
        }
export type GetPaginatedProjectsQueryHookResult = ReturnType<typeof useGetPaginatedProjectsQuery>;
export type GetPaginatedProjectsLazyQueryHookResult = ReturnType<typeof useGetPaginatedProjectsLazyQuery>;
export type GetPaginatedProjectsQueryResult = Apollo.QueryResult<GetPaginatedProjectsQuery, GetPaginatedProjectsQueryVariables>;
export const GetSingleProjectDocument = gql`
    query GetSingleProject($id: ID!) {
  project(id: $id) {
    id
    number
    name
    notes
    address
    district
    city
    state
    zip
    isActive
    originalContractValue
    originalSubcontractValue
    smallBusinessRequirement
    primeContractNumber
    smallBusinessClient {
      id
      name
      abbreviation
      isFederal
      smallBusinessClientAgencies {
        id
        smallBusinessAgency {
          id
          name
          abbreviation
          isActive
          isFederal
          url
          specialNotice
          participationPercentage
          smallBusinessAgencyClassifications {
            id
            smallBusinessClassification {
              id
              name
              abbreviation
              isFederal
            }
          }
        }
        smallBusinessClientClassifications {
          id
          smallBusinessClassification {
            id
            name
            abbreviation
            isFederal
          }
        }
      }
    }
    smallBusinessReportSettings {
      id
      isActualCalculationsBasedOnTotalContractValue
      notes
    }
    admins {
      id
      microsoftAdId
      upn
      name
      firstName
      lastName
      email
      jobTitle
    }
  }
}
    `;

/**
 * __useGetSingleProjectQuery__
 *
 * To run a query within a React component, call `useGetSingleProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSingleProjectQuery(baseOptions: Apollo.QueryHookOptions<GetSingleProjectQuery, GetSingleProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSingleProjectQuery, GetSingleProjectQueryVariables>(GetSingleProjectDocument, options);
      }
export function useGetSingleProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSingleProjectQuery, GetSingleProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSingleProjectQuery, GetSingleProjectQueryVariables>(GetSingleProjectDocument, options);
        }
export type GetSingleProjectQueryHookResult = ReturnType<typeof useGetSingleProjectQuery>;
export type GetSingleProjectLazyQueryHookResult = ReturnType<typeof useGetSingleProjectLazyQuery>;
export type GetSingleProjectQueryResult = Apollo.QueryResult<GetSingleProjectQuery, GetSingleProjectQueryVariables>;
export const GetProjectsContractedAfterDocument = gql`
    query GetProjectsContractedAfter($companyId: String!, $date: String!) {
  getProjectsContractedAfter(companyId: $companyId, date: $date) {
    number
    name
  }
}
    `;

/**
 * __useGetProjectsContractedAfterQuery__
 *
 * To run a query within a React component, call `useGetProjectsContractedAfterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsContractedAfterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsContractedAfterQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetProjectsContractedAfterQuery(baseOptions: Apollo.QueryHookOptions<GetProjectsContractedAfterQuery, GetProjectsContractedAfterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectsContractedAfterQuery, GetProjectsContractedAfterQueryVariables>(GetProjectsContractedAfterDocument, options);
      }
export function useGetProjectsContractedAfterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectsContractedAfterQuery, GetProjectsContractedAfterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectsContractedAfterQuery, GetProjectsContractedAfterQueryVariables>(GetProjectsContractedAfterDocument, options);
        }
export type GetProjectsContractedAfterQueryHookResult = ReturnType<typeof useGetProjectsContractedAfterQuery>;
export type GetProjectsContractedAfterLazyQueryHookResult = ReturnType<typeof useGetProjectsContractedAfterLazyQuery>;
export type GetProjectsContractedAfterQueryResult = Apollo.QueryResult<GetProjectsContractedAfterQuery, GetProjectsContractedAfterQueryVariables>;
export const GetProjectsInUseDocument = gql`
    query GetProjectsInUse($smallBusinessClientId: String, $smallBusinessClassificationId: String) {
  getProjectsInUse(
    smallBusinessClientId: $smallBusinessClientId
    smallBusinessClassificationId: $smallBusinessClassificationId
  ) {
    id
    number
    name
    smallBusinessReportSettings {
      id
      classifications {
        id
        goalPercent
        smallBusinessClassification {
          id
          name
          abbreviation
          isFederal
        }
      }
    }
  }
}
    `;

/**
 * __useGetProjectsInUseQuery__
 *
 * To run a query within a React component, call `useGetProjectsInUseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsInUseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsInUseQuery({
 *   variables: {
 *      smallBusinessClientId: // value for 'smallBusinessClientId'
 *      smallBusinessClassificationId: // value for 'smallBusinessClassificationId'
 *   },
 * });
 */
export function useGetProjectsInUseQuery(baseOptions?: Apollo.QueryHookOptions<GetProjectsInUseQuery, GetProjectsInUseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectsInUseQuery, GetProjectsInUseQueryVariables>(GetProjectsInUseDocument, options);
      }
export function useGetProjectsInUseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectsInUseQuery, GetProjectsInUseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectsInUseQuery, GetProjectsInUseQueryVariables>(GetProjectsInUseDocument, options);
        }
export type GetProjectsInUseQueryHookResult = ReturnType<typeof useGetProjectsInUseQuery>;
export type GetProjectsInUseLazyQueryHookResult = ReturnType<typeof useGetProjectsInUseLazyQuery>;
export type GetProjectsInUseQueryResult = Apollo.QueryResult<GetProjectsInUseQuery, GetProjectsInUseQueryVariables>;
export const UpdateProjectDocument = gql`
    mutation UpdateProject($input: ProjectUpdateInput!) {
  updateProject(input: $input) {
    id
    number
    name
    notes
    address
    district
    city
    state
    zip
    isActive
    originalContractValue
    originalSubcontractValue
    smallBusinessRequirement
    primeContractNumber
    smallBusinessClient {
      id
    }
    smallBusinessReportSettings {
      id
      isActualCalculationsBasedOnTotalContractValue
      classifications {
        id
        goalPercent
      }
      notes
    }
  }
}
    `;
export type UpdateProjectMutationFn = Apollo.MutationFunction<UpdateProjectMutation, UpdateProjectMutationVariables>;

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectMutation, UpdateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(UpdateProjectDocument, options);
      }
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>;
export type UpdateProjectMutationResult = Apollo.MutationResult<UpdateProjectMutation>;
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<UpdateProjectMutation, UpdateProjectMutationVariables>;
export const SetAdminStatusOnProjectDocument = gql`
    mutation setAdminStatusOnProject($projectId: ID!, $microsoftAdId: String!, $isAdmin: Boolean!) {
  setAdminStatusOnProject(
    projectId: $projectId
    microsoftAdId: $microsoftAdId
    isAdmin: $isAdmin
  ) {
    id
    number
    name
    notes
    address
    district
    city
    state
    zip
    isActive
    originalContractValue
    originalSubcontractValue
    smallBusinessRequirement
    primeContractNumber
    smallBusinessClient {
      id
      name
      abbreviation
    }
    smallBusinessReportSettings {
      id
      isActualCalculationsBasedOnTotalContractValue
      notes
    }
  }
}
    `;
export type SetAdminStatusOnProjectMutationFn = Apollo.MutationFunction<SetAdminStatusOnProjectMutation, SetAdminStatusOnProjectMutationVariables>;

/**
 * __useSetAdminStatusOnProjectMutation__
 *
 * To run a mutation, you first call `useSetAdminStatusOnProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAdminStatusOnProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAdminStatusOnProjectMutation, { data, loading, error }] = useSetAdminStatusOnProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      microsoftAdId: // value for 'microsoftAdId'
 *      isAdmin: // value for 'isAdmin'
 *   },
 * });
 */
export function useSetAdminStatusOnProjectMutation(baseOptions?: Apollo.MutationHookOptions<SetAdminStatusOnProjectMutation, SetAdminStatusOnProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetAdminStatusOnProjectMutation, SetAdminStatusOnProjectMutationVariables>(SetAdminStatusOnProjectDocument, options);
      }
export type SetAdminStatusOnProjectMutationHookResult = ReturnType<typeof useSetAdminStatusOnProjectMutation>;
export type SetAdminStatusOnProjectMutationResult = Apollo.MutationResult<SetAdminStatusOnProjectMutation>;
export type SetAdminStatusOnProjectMutationOptions = Apollo.BaseMutationOptions<SetAdminStatusOnProjectMutation, SetAdminStatusOnProjectMutationVariables>;
export const SmallBusinessAgencyListDocument = gql`
    query SmallBusinessAgencyList($isActive: [Boolean!]!, $federal: Boolean) {
  smallBusinessAgencyList(isActive: $isActive, federal: $federal) {
    id
    name
    abbreviation
    isFederal
    isActive
    url
    participationPercentage
    specialNotice
    smallBusinessAgencyClassifications {
      id
      smallBusinessClassification {
        id
        name
        abbreviation
        sortOrder
      }
    }
  }
}
    `;

/**
 * __useSmallBusinessAgencyListQuery__
 *
 * To run a query within a React component, call `useSmallBusinessAgencyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSmallBusinessAgencyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSmallBusinessAgencyListQuery({
 *   variables: {
 *      isActive: // value for 'isActive'
 *      federal: // value for 'federal'
 *   },
 * });
 */
export function useSmallBusinessAgencyListQuery(baseOptions: Apollo.QueryHookOptions<SmallBusinessAgencyListQuery, SmallBusinessAgencyListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SmallBusinessAgencyListQuery, SmallBusinessAgencyListQueryVariables>(SmallBusinessAgencyListDocument, options);
      }
export function useSmallBusinessAgencyListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SmallBusinessAgencyListQuery, SmallBusinessAgencyListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SmallBusinessAgencyListQuery, SmallBusinessAgencyListQueryVariables>(SmallBusinessAgencyListDocument, options);
        }
export type SmallBusinessAgencyListQueryHookResult = ReturnType<typeof useSmallBusinessAgencyListQuery>;
export type SmallBusinessAgencyListLazyQueryHookResult = ReturnType<typeof useSmallBusinessAgencyListLazyQuery>;
export type SmallBusinessAgencyListQueryResult = Apollo.QueryResult<SmallBusinessAgencyListQuery, SmallBusinessAgencyListQueryVariables>;
export const GetSmallBusinessAgenciesInUseDocument = gql`
    query GetSmallBusinessAgenciesInUse($smallBusinessClassificationId: String!) {
  getSmallBusinessAgenciesInUse(
    smallBusinessClassificationId: $smallBusinessClassificationId
  ) {
    id
    name
    abbreviation
  }
}
    `;

/**
 * __useGetSmallBusinessAgenciesInUseQuery__
 *
 * To run a query within a React component, call `useGetSmallBusinessAgenciesInUseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSmallBusinessAgenciesInUseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSmallBusinessAgenciesInUseQuery({
 *   variables: {
 *      smallBusinessClassificationId: // value for 'smallBusinessClassificationId'
 *   },
 * });
 */
export function useGetSmallBusinessAgenciesInUseQuery(baseOptions: Apollo.QueryHookOptions<GetSmallBusinessAgenciesInUseQuery, GetSmallBusinessAgenciesInUseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSmallBusinessAgenciesInUseQuery, GetSmallBusinessAgenciesInUseQueryVariables>(GetSmallBusinessAgenciesInUseDocument, options);
      }
export function useGetSmallBusinessAgenciesInUseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSmallBusinessAgenciesInUseQuery, GetSmallBusinessAgenciesInUseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSmallBusinessAgenciesInUseQuery, GetSmallBusinessAgenciesInUseQueryVariables>(GetSmallBusinessAgenciesInUseDocument, options);
        }
export type GetSmallBusinessAgenciesInUseQueryHookResult = ReturnType<typeof useGetSmallBusinessAgenciesInUseQuery>;
export type GetSmallBusinessAgenciesInUseLazyQueryHookResult = ReturnType<typeof useGetSmallBusinessAgenciesInUseLazyQuery>;
export type GetSmallBusinessAgenciesInUseQueryResult = Apollo.QueryResult<GetSmallBusinessAgenciesInUseQuery, GetSmallBusinessAgenciesInUseQueryVariables>;
export const UpsertSmallBusinessAgencyDocument = gql`
    mutation UpsertSmallBusinessAgency($input: SmallBusinessAgencyUpsertInput!) {
  upsertSmallBusinessAgency(input: $input) {
    id
    name
    abbreviation
    isFederal
    isActive
    url
    participationPercentage
    specialNotice
    smallBusinessAgencyClassifications {
      id
      smallBusinessAgency {
        id
      }
      smallBusinessClassification {
        id
      }
    }
  }
}
    `;
export type UpsertSmallBusinessAgencyMutationFn = Apollo.MutationFunction<UpsertSmallBusinessAgencyMutation, UpsertSmallBusinessAgencyMutationVariables>;

/**
 * __useUpsertSmallBusinessAgencyMutation__
 *
 * To run a mutation, you first call `useUpsertSmallBusinessAgencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertSmallBusinessAgencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertSmallBusinessAgencyMutation, { data, loading, error }] = useUpsertSmallBusinessAgencyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertSmallBusinessAgencyMutation(baseOptions?: Apollo.MutationHookOptions<UpsertSmallBusinessAgencyMutation, UpsertSmallBusinessAgencyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertSmallBusinessAgencyMutation, UpsertSmallBusinessAgencyMutationVariables>(UpsertSmallBusinessAgencyDocument, options);
      }
export type UpsertSmallBusinessAgencyMutationHookResult = ReturnType<typeof useUpsertSmallBusinessAgencyMutation>;
export type UpsertSmallBusinessAgencyMutationResult = Apollo.MutationResult<UpsertSmallBusinessAgencyMutation>;
export type UpsertSmallBusinessAgencyMutationOptions = Apollo.BaseMutationOptions<UpsertSmallBusinessAgencyMutation, UpsertSmallBusinessAgencyMutationVariables>;
export const DeleteSmallBusinessAgencyDocument = gql`
    mutation DeleteSmallBusinessAgency($id: ID!) {
  deleteSmallBusinessAgency(id: $id) {
    id
  }
}
    `;
export type DeleteSmallBusinessAgencyMutationFn = Apollo.MutationFunction<DeleteSmallBusinessAgencyMutation, DeleteSmallBusinessAgencyMutationVariables>;

/**
 * __useDeleteSmallBusinessAgencyMutation__
 *
 * To run a mutation, you first call `useDeleteSmallBusinessAgencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSmallBusinessAgencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSmallBusinessAgencyMutation, { data, loading, error }] = useDeleteSmallBusinessAgencyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSmallBusinessAgencyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSmallBusinessAgencyMutation, DeleteSmallBusinessAgencyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSmallBusinessAgencyMutation, DeleteSmallBusinessAgencyMutationVariables>(DeleteSmallBusinessAgencyDocument, options);
      }
export type DeleteSmallBusinessAgencyMutationHookResult = ReturnType<typeof useDeleteSmallBusinessAgencyMutation>;
export type DeleteSmallBusinessAgencyMutationResult = Apollo.MutationResult<DeleteSmallBusinessAgencyMutation>;
export type DeleteSmallBusinessAgencyMutationOptions = Apollo.BaseMutationOptions<DeleteSmallBusinessAgencyMutation, DeleteSmallBusinessAgencyMutationVariables>;
export const GetSmallBusinessClassificationsDocument = gql`
    query GetSmallBusinessClassifications($federal: Boolean) {
  smallBusinessClassificationList(federal: $federal) {
    id
    name
    abbreviation
    sortOrder
    isFederal
  }
}
    `;

/**
 * __useGetSmallBusinessClassificationsQuery__
 *
 * To run a query within a React component, call `useGetSmallBusinessClassificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSmallBusinessClassificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSmallBusinessClassificationsQuery({
 *   variables: {
 *      federal: // value for 'federal'
 *   },
 * });
 */
export function useGetSmallBusinessClassificationsQuery(baseOptions?: Apollo.QueryHookOptions<GetSmallBusinessClassificationsQuery, GetSmallBusinessClassificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSmallBusinessClassificationsQuery, GetSmallBusinessClassificationsQueryVariables>(GetSmallBusinessClassificationsDocument, options);
      }
export function useGetSmallBusinessClassificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSmallBusinessClassificationsQuery, GetSmallBusinessClassificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSmallBusinessClassificationsQuery, GetSmallBusinessClassificationsQueryVariables>(GetSmallBusinessClassificationsDocument, options);
        }
export type GetSmallBusinessClassificationsQueryHookResult = ReturnType<typeof useGetSmallBusinessClassificationsQuery>;
export type GetSmallBusinessClassificationsLazyQueryHookResult = ReturnType<typeof useGetSmallBusinessClassificationsLazyQuery>;
export type GetSmallBusinessClassificationsQueryResult = Apollo.QueryResult<GetSmallBusinessClassificationsQuery, GetSmallBusinessClassificationsQueryVariables>;
export const UpsertSmallBusinessClassificationDocument = gql`
    mutation UpsertSmallBusinessClassification($input: SmallBusinessClassificationUpsertInput!) {
  upsertSmallBusinessClassification(input: $input) {
    id
    name
    abbreviation
    isFederal
    sortOrder
  }
}
    `;
export type UpsertSmallBusinessClassificationMutationFn = Apollo.MutationFunction<UpsertSmallBusinessClassificationMutation, UpsertSmallBusinessClassificationMutationVariables>;

/**
 * __useUpsertSmallBusinessClassificationMutation__
 *
 * To run a mutation, you first call `useUpsertSmallBusinessClassificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertSmallBusinessClassificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertSmallBusinessClassificationMutation, { data, loading, error }] = useUpsertSmallBusinessClassificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertSmallBusinessClassificationMutation(baseOptions?: Apollo.MutationHookOptions<UpsertSmallBusinessClassificationMutation, UpsertSmallBusinessClassificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertSmallBusinessClassificationMutation, UpsertSmallBusinessClassificationMutationVariables>(UpsertSmallBusinessClassificationDocument, options);
      }
export type UpsertSmallBusinessClassificationMutationHookResult = ReturnType<typeof useUpsertSmallBusinessClassificationMutation>;
export type UpsertSmallBusinessClassificationMutationResult = Apollo.MutationResult<UpsertSmallBusinessClassificationMutation>;
export type UpsertSmallBusinessClassificationMutationOptions = Apollo.BaseMutationOptions<UpsertSmallBusinessClassificationMutation, UpsertSmallBusinessClassificationMutationVariables>;
export const DeleteSmallBusinessClassificationDocument = gql`
    mutation DeleteSmallBusinessClassification($id: ID!) {
  deleteSmallBusinessClassification(id: $id) {
    id
  }
}
    `;
export type DeleteSmallBusinessClassificationMutationFn = Apollo.MutationFunction<DeleteSmallBusinessClassificationMutation, DeleteSmallBusinessClassificationMutationVariables>;

/**
 * __useDeleteSmallBusinessClassificationMutation__
 *
 * To run a mutation, you first call `useDeleteSmallBusinessClassificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSmallBusinessClassificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSmallBusinessClassificationMutation, { data, loading, error }] = useDeleteSmallBusinessClassificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSmallBusinessClassificationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSmallBusinessClassificationMutation, DeleteSmallBusinessClassificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSmallBusinessClassificationMutation, DeleteSmallBusinessClassificationMutationVariables>(DeleteSmallBusinessClassificationDocument, options);
      }
export type DeleteSmallBusinessClassificationMutationHookResult = ReturnType<typeof useDeleteSmallBusinessClassificationMutation>;
export type DeleteSmallBusinessClassificationMutationResult = Apollo.MutationResult<DeleteSmallBusinessClassificationMutation>;
export type DeleteSmallBusinessClassificationMutationOptions = Apollo.BaseMutationOptions<DeleteSmallBusinessClassificationMutation, DeleteSmallBusinessClassificationMutationVariables>;
export const SmallBusinessClientListDocument = gql`
    query SmallBusinessClientList($isActive: [Boolean!]!, $federal: Boolean) {
  smallBusinessClientList(isActive: $isActive, federal: $federal) {
    id
    name
    abbreviation
    isFederal
    isActive
    smallBusinessClientAgencies {
      id
      smallBusinessAgency {
        id
        name
        abbreviation
        isFederal
        url
        specialNotice
        participationPercentage
        smallBusinessAgencyClassifications {
          id
          smallBusinessClassification {
            id
            name
            abbreviation
            isFederal
          }
        }
      }
      smallBusinessClientClassifications {
        id
        smallBusinessClassification {
          id
          name
          abbreviation
          isFederal
        }
      }
    }
  }
}
    `;

/**
 * __useSmallBusinessClientListQuery__
 *
 * To run a query within a React component, call `useSmallBusinessClientListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSmallBusinessClientListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSmallBusinessClientListQuery({
 *   variables: {
 *      isActive: // value for 'isActive'
 *      federal: // value for 'federal'
 *   },
 * });
 */
export function useSmallBusinessClientListQuery(baseOptions: Apollo.QueryHookOptions<SmallBusinessClientListQuery, SmallBusinessClientListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SmallBusinessClientListQuery, SmallBusinessClientListQueryVariables>(SmallBusinessClientListDocument, options);
      }
export function useSmallBusinessClientListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SmallBusinessClientListQuery, SmallBusinessClientListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SmallBusinessClientListQuery, SmallBusinessClientListQueryVariables>(SmallBusinessClientListDocument, options);
        }
export type SmallBusinessClientListQueryHookResult = ReturnType<typeof useSmallBusinessClientListQuery>;
export type SmallBusinessClientListLazyQueryHookResult = ReturnType<typeof useSmallBusinessClientListLazyQuery>;
export type SmallBusinessClientListQueryResult = Apollo.QueryResult<SmallBusinessClientListQuery, SmallBusinessClientListQueryVariables>;
export const GetSmallBusinessClientsInUseDocument = gql`
    query GetSmallBusinessClientsInUse($smallBusinessAgencyId: String!) {
  getSmallBusinessClientsInUse(smallBusinessAgencyId: $smallBusinessAgencyId) {
    id
    name
    abbreviation
  }
}
    `;

/**
 * __useGetSmallBusinessClientsInUseQuery__
 *
 * To run a query within a React component, call `useGetSmallBusinessClientsInUseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSmallBusinessClientsInUseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSmallBusinessClientsInUseQuery({
 *   variables: {
 *      smallBusinessAgencyId: // value for 'smallBusinessAgencyId'
 *   },
 * });
 */
export function useGetSmallBusinessClientsInUseQuery(baseOptions: Apollo.QueryHookOptions<GetSmallBusinessClientsInUseQuery, GetSmallBusinessClientsInUseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSmallBusinessClientsInUseQuery, GetSmallBusinessClientsInUseQueryVariables>(GetSmallBusinessClientsInUseDocument, options);
      }
export function useGetSmallBusinessClientsInUseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSmallBusinessClientsInUseQuery, GetSmallBusinessClientsInUseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSmallBusinessClientsInUseQuery, GetSmallBusinessClientsInUseQueryVariables>(GetSmallBusinessClientsInUseDocument, options);
        }
export type GetSmallBusinessClientsInUseQueryHookResult = ReturnType<typeof useGetSmallBusinessClientsInUseQuery>;
export type GetSmallBusinessClientsInUseLazyQueryHookResult = ReturnType<typeof useGetSmallBusinessClientsInUseLazyQuery>;
export type GetSmallBusinessClientsInUseQueryResult = Apollo.QueryResult<GetSmallBusinessClientsInUseQuery, GetSmallBusinessClientsInUseQueryVariables>;
export const UpsertSmallBusinessClientDocument = gql`
    mutation UpsertSmallBusinessClient($input: SmallBusinessClientUpsertInput!) {
  upsertSmallBusinessClient(input: $input) {
    id
    name
    abbreviation
    isFederal
    isActive
    smallBusinessClientAgencies {
      id
      smallBusinessAgency {
        id
      }
      smallBusinessClientClassifications {
        id
        smallBusinessClassification {
          id
        }
      }
    }
  }
}
    `;
export type UpsertSmallBusinessClientMutationFn = Apollo.MutationFunction<UpsertSmallBusinessClientMutation, UpsertSmallBusinessClientMutationVariables>;

/**
 * __useUpsertSmallBusinessClientMutation__
 *
 * To run a mutation, you first call `useUpsertSmallBusinessClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertSmallBusinessClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertSmallBusinessClientMutation, { data, loading, error }] = useUpsertSmallBusinessClientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertSmallBusinessClientMutation(baseOptions?: Apollo.MutationHookOptions<UpsertSmallBusinessClientMutation, UpsertSmallBusinessClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertSmallBusinessClientMutation, UpsertSmallBusinessClientMutationVariables>(UpsertSmallBusinessClientDocument, options);
      }
export type UpsertSmallBusinessClientMutationHookResult = ReturnType<typeof useUpsertSmallBusinessClientMutation>;
export type UpsertSmallBusinessClientMutationResult = Apollo.MutationResult<UpsertSmallBusinessClientMutation>;
export type UpsertSmallBusinessClientMutationOptions = Apollo.BaseMutationOptions<UpsertSmallBusinessClientMutation, UpsertSmallBusinessClientMutationVariables>;
export const DeleteSmallBusinessClientDocument = gql`
    mutation DeleteSmallBusinessClient($id: ID!) {
  deleteSmallBusinessClient(id: $id) {
    id
  }
}
    `;
export type DeleteSmallBusinessClientMutationFn = Apollo.MutationFunction<DeleteSmallBusinessClientMutation, DeleteSmallBusinessClientMutationVariables>;

/**
 * __useDeleteSmallBusinessClientMutation__
 *
 * To run a mutation, you first call `useDeleteSmallBusinessClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSmallBusinessClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSmallBusinessClientMutation, { data, loading, error }] = useDeleteSmallBusinessClientMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSmallBusinessClientMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSmallBusinessClientMutation, DeleteSmallBusinessClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSmallBusinessClientMutation, DeleteSmallBusinessClientMutationVariables>(DeleteSmallBusinessClientDocument, options);
      }
export type DeleteSmallBusinessClientMutationHookResult = ReturnType<typeof useDeleteSmallBusinessClientMutation>;
export type DeleteSmallBusinessClientMutationResult = Apollo.MutationResult<DeleteSmallBusinessClientMutation>;
export type DeleteSmallBusinessClientMutationOptions = Apollo.BaseMutationOptions<DeleteSmallBusinessClientMutation, DeleteSmallBusinessClientMutationVariables>;
export const ProjectSmallBusinessReportDocument = gql`
    query projectSmallBusinessReport($smallBusinessReportSettingsId: ID!, $startDate: String, $endDate: String, $form: SmallBusinessFederalReportFormOption) {
  projectSmallBusinessReport(
    smallBusinessReportSettingsId: $smallBusinessReportSettingsId
    startDate: $startDate
    endDate: $endDate
    form: $form
  ) {
    checksum
    currentContractValue
    currentSubContractorContractValue
    classificationSummaries {
      abbreviation
      actualPercentTotal
      actualPercentOriginal
      contractIds
      lowerTierParticipationIds
      actualTotal
      lowerTierParticipationTotal
      lowerTierParticipationPercentOriginal
      lowerTierPercentTotal
      firstTierTotal
      firstTierPercentOriginal
      firstTierPercentTotal
      sortOrder
      smallBusinessReportClassificationId
      smallBusinessClassificationId
      goalPercent
      goalTotal
      name
      isFederal
    }
    contractSummaries {
      companyId
      companyName
      coverages {
        id
        isFederal
        smallBusinessClassification {
          id
          name
          abbreviation
          sortOrder
          isFederal
          created
          lastModified
        }
        startDate
        endDate
        smallBusinessAgency {
          id
          name
          abbreviation
        }
      }
      smallBusinessClassifications {
        id
        name
        abbreviation
        isFederal
      }
      contractId
      phaseCode
      effectiveDate
      changeOrderSummaries {
        date
        value
      }
      valueTotal
      isSelfWork
      participationPercentage
      lowerTierParticipationClassificationSummaries {
        lowerTierParticipationId
        contractId
        value
        lowerTierCompanyName
        smallBusinessClassifications {
          id
          name
          abbreviation
        }
        effectiveDate
        lowerTierParticipationFileSummary {
          id
          file {
            id
            contentType
            created
            isMalware
            isScanned
            lastModified
            name
            sizeInBytes
            url
            cachedUrl @client
          }
          uploadedBy {
            id
            name
          }
          smallBusinessAgency {
            id
            name
            abbreviation
          }
          lowerTierParticipationClassifications {
            id
            smallBusinessClassification {
              id
              name
              abbreviation
            }
          }
          participationPercentage
        }
      }
    }
  }
}
    `;

/**
 * __useProjectSmallBusinessReportQuery__
 *
 * To run a query within a React component, call `useProjectSmallBusinessReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectSmallBusinessReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectSmallBusinessReportQuery({
 *   variables: {
 *      smallBusinessReportSettingsId: // value for 'smallBusinessReportSettingsId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      form: // value for 'form'
 *   },
 * });
 */
export function useProjectSmallBusinessReportQuery(baseOptions: Apollo.QueryHookOptions<ProjectSmallBusinessReportQuery, ProjectSmallBusinessReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectSmallBusinessReportQuery, ProjectSmallBusinessReportQueryVariables>(ProjectSmallBusinessReportDocument, options);
      }
export function useProjectSmallBusinessReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectSmallBusinessReportQuery, ProjectSmallBusinessReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectSmallBusinessReportQuery, ProjectSmallBusinessReportQueryVariables>(ProjectSmallBusinessReportDocument, options);
        }
export type ProjectSmallBusinessReportQueryHookResult = ReturnType<typeof useProjectSmallBusinessReportQuery>;
export type ProjectSmallBusinessReportLazyQueryHookResult = ReturnType<typeof useProjectSmallBusinessReportLazyQuery>;
export type ProjectSmallBusinessReportQueryResult = Apollo.QueryResult<ProjectSmallBusinessReportQuery, ProjectSmallBusinessReportQueryVariables>;
export const SaveProjectSmallBusinessReportDocument = gql`
    mutation saveProjectSmallBusinessReport($comments: String, $smallBusinessReportSettingsId: ID!, $startDate: String, $endDate: String, $transactionKey: String!, $form: SmallBusinessFederalReportFormOption, $type: SmallBusinessSavedReportTypeOption!) {
  saveProjectSmallBusinessReport(
    comments: $comments
    smallBusinessReportSettingsId: $smallBusinessReportSettingsId
    startDate: $startDate
    endDate: $endDate
    transactionKey: $transactionKey
    form: $form
    type: $type
  ) {
    checksum
    currentContractValue
    currentSubContractorContractValue
  }
}
    `;
export type SaveProjectSmallBusinessReportMutationFn = Apollo.MutationFunction<SaveProjectSmallBusinessReportMutation, SaveProjectSmallBusinessReportMutationVariables>;

/**
 * __useSaveProjectSmallBusinessReportMutation__
 *
 * To run a mutation, you first call `useSaveProjectSmallBusinessReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveProjectSmallBusinessReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveProjectSmallBusinessReportMutation, { data, loading, error }] = useSaveProjectSmallBusinessReportMutation({
 *   variables: {
 *      comments: // value for 'comments'
 *      smallBusinessReportSettingsId: // value for 'smallBusinessReportSettingsId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      transactionKey: // value for 'transactionKey'
 *      form: // value for 'form'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useSaveProjectSmallBusinessReportMutation(baseOptions?: Apollo.MutationHookOptions<SaveProjectSmallBusinessReportMutation, SaveProjectSmallBusinessReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveProjectSmallBusinessReportMutation, SaveProjectSmallBusinessReportMutationVariables>(SaveProjectSmallBusinessReportDocument, options);
      }
export type SaveProjectSmallBusinessReportMutationHookResult = ReturnType<typeof useSaveProjectSmallBusinessReportMutation>;
export type SaveProjectSmallBusinessReportMutationResult = Apollo.MutationResult<SaveProjectSmallBusinessReportMutation>;
export type SaveProjectSmallBusinessReportMutationOptions = Apollo.BaseMutationOptions<SaveProjectSmallBusinessReportMutation, SaveProjectSmallBusinessReportMutationVariables>;