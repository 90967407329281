import AddLowerTierParticipationDialog from 'components/contract/AddLowerTierParticipationDialog/AddLowerTierParticipationDialog';
import DeleteLowerTierParticipationDialog from 'components/contract/DeleteLowerTierParticipationDialog';
import EditContractDialog from 'components/contract/EditContractDialog';
import EditLowerTierParticipationDialog from 'components/contract/EditLowerTierParticipationDialog/EditLowerTierParticipationDialog';
import ViewLowerTierParticipationDialog from 'components/contract/ViewLowerTierParticipationDialog';
import {
  classificationsExpirationStatus,
  getSmallBusinessClassificationStatus,
  largeBusinessClassificationCoverage,
} from 'components/shared/SmallBusinessClassificationCoverageHelper/SmallBusinessClassificationCoverageHelper';
import SmallBusinessClassificationDisplayName from 'components/shared/SmallBusinessClassificationDisplayName';
import StyledTable from 'components/shared/Table';
import { SmallBusinessClassificationName } from 'constants/classificationNames';
import { SmallBusinessRequirementOption } from 'constants/smallBusinessRequirementOptions';
import { DateTime } from 'luxon';
import { MUIDataTableColumn, MUIDataTableOptions, MUISortOptions } from 'mui-datatables';
import { FC, MouseEvent, useState } from 'react';
import NumberFormat from 'react-number-format';
import { SmallBusinessFederalReportFormOptionSelection } from 'recoil/smallBusinessFederalReportFormOption/atom';
import {
  ContractSummariesFromQuery,
  LowerTierParticipationClassificationSummariesFromQuery,
  LowerTierParticipationFileSummaryFromQuery,
  SmallBusinessClassificationCoverageFromQuery,
  SmallBusinessClassificationFromQuery,
  SmallBusinessClassificationSelection,
} from 'types';
import {
  GetSingleProjectQuery,
  GetSingleProjectQueryResult,
  LowerTierParticipationClassificationSummary,
  SmallBusinessClassification,
  SmallBusinessClassificationCoverage,
  SmallBusinessClient,
  SmallBusinessClientAgency,
  SmallBusinessReportClassificationSummary,
} from 'types/generated/graphql';
import { formatDate } from 'utils/general';

import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import ScheduleIcon from '@mui/icons-material/Schedule';
import {
  Grid,
  IconButton,
  Link,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';

const classificationColumn: SxProps<Theme> = {
  fontWeight: 'bold',
};

const classificationColumnNotAccepted: SxProps<Theme> = {
  fontWeight: 'normal',
  color: '#c1c1c1',
  textDecoration: 'line-through',
};

const classificationColumnInactive: SxProps<Theme> = {
  fontWeight: 'normal',
  color: '#c1c1c1',
};

const actionsContainer: SxProps<Theme> = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  [theme.breakpoints.down('md')]: { justifyContent: 'flex-start' },
});

const companyLink: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.secondary.contrastText,
});

const activeClassificationIcon: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.success.main,
  fontSize: '1rem',
});

const expiringSoonClassificationIcon: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.warning.main,
  fontSize: '1rem',
});

const classificationIconInactive: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.error.main,
  fontSize: '1rem',
});

const hiddenCell: SxProps<Theme> = {
  position: 'sticky',
  left: '0px',
  zIndex: 100,
  width: '56px',
};

const lowerTierParentCell: SxProps<Theme> = {
  padding: '0 !important',
};

const secondTierCell: SxProps<Theme> = {
  width: '165px',
};

const selfWorkHiddenCell: SxProps<Theme> = {
  width: '120px',
};

const lowerTierHiddenCell: SxProps<Theme> = {
  width: '120px',
};

const lowerTierCompanyNameCell: SxProps<Theme> = {
  width: '300px',
};

const lowerTierExecutionDateCell: SxProps<Theme> = {
  width: '200px',
};

const lowerTierIsLargeBusiness: SxProps<Theme> = (theme: Theme) => ({
  backgroundColor: theme.palette.error.light,
  fontSize: '1rem',
});

type SmallBusinessContractTableProps = {
  clientAcceptedClassifications: SmallBusinessClassificationFromQuery[];
  clientAcceptedAgencies: SmallBusinessClientAgency[];
  contractSummaryData: ContractSummariesFromQuery;
  project?: GetSingleProjectQuery['project'];
  editable?: boolean;
  smallBusinessReportSettings?: Exclude<
    GetSingleProjectQueryResult['data'],
    undefined
  >['project']['smallBusinessReportSettings'];
  smallBusinessReportClassificationSummaries?: SmallBusinessReportClassificationSummary[];
  allSmallBusinessClassifications: SmallBusinessClassificationSelection;
  selectedReportOption?: SmallBusinessFederalReportFormOptionSelection;
};

const SmallBusinessContractTable: FC<SmallBusinessContractTableProps> = ({
  clientAcceptedClassifications,
  clientAcceptedAgencies,
  contractSummaryData,
  project,
  editable,
  smallBusinessReportSettings,
  smallBusinessReportClassificationSummaries,
  allSmallBusinessClassifications,
  selectedReportOption,
}) => {
  const [contractToEdit, setContractToEdit] = useState<ContractSummariesFromQuery[number] | null>(null);
  const [isEditContractDialogOpen, setIsEditContractDialogOpen] = useState(false);

  const LOCAL_STORAGE_CONTRACT_SORT_KEY = 'contractSort';
  const savedContractTableSort = JSON.parse(localStorage.getItem(LOCAL_STORAGE_CONTRACT_SORT_KEY) as string);
  const [contractTableSort, setContractTableSort] = useState<MUISortOptions>(
    !savedContractTableSort ? { name: 'phaseCode', direction: 'asc' } : savedContractTableSort,
  );

  const lowerTierParticipationSummariesByContractId: {
    [contractId: string]: LowerTierParticipationClassificationSummariesFromQuery;
  } = contractSummaryData.reduce((accumulator, contract) => {
    const contractId = contract.contractId;
    accumulator[contractId] = contract.lowerTierParticipationClassificationSummaries ?? [];
    return accumulator;
  }, {} as { [contractId: string]: LowerTierParticipationClassificationSummariesFromQuery });

  const [lowerTierParticipationToAdd, setLowerTierParticipationToAdd] = useState<
    LowerTierParticipationClassificationSummariesFromQuery[number] | null
  >(null);
  const [lowerTierParticipation, setLowerTierParticipation] =
    useState<LowerTierParticipationClassificationSummariesFromQuery[number]>();
  const [lowerTierParticipationSummariesByContract, setLowerTierParticipationSummariesByContract] = useState<
    LowerTierParticipationClassificationSummariesFromQuery | undefined
  >([]);
  const [isAddLowerTierParticipationDialogOpen, setIsAddLowerTierParticipationDialogOpen] = useState(false);
  const [isDeleteLowerTierParticipationDialogOpen, setIsDeleteLowerTierParticipationDialogOpen] = useState(false);
  const [isViewLowerTierParticipationDialogOpen, setIsViewLowerTierParticipationDialogOpen] = useState(false);
  const [isEditLowerTierParticipationDialogOpen, setIsEditLowerTierParticipationDialogOpen] = useState(false);

  const smallBusinessClient = project?.smallBusinessClient as SmallBusinessClient;
  const projectSmallBusinessRequirement = project?.smallBusinessRequirement;
  const isNonFederalProject = projectSmallBusinessRequirement === SmallBusinessRequirementOption.NON_FEDERAL;
  const isHpSelfReporting = projectSmallBusinessRequirement === SmallBusinessRequirementOption.HP_SELF_REPORTING;

  const contractId = lowerTierParticipationToAdd
    ? lowerTierParticipationToAdd.contractId
    : lowerTierParticipation?.contractId;

  const contractSummaryDataByContractId = contractSummaryData.find((contract) => contract.contractId === contractId);

  const largeBusinessClassification = clientAcceptedClassifications?.find(
    (classification) => classification.name === SmallBusinessClassificationName.LB,
  ) as SmallBusinessClassification;

  const handleEditContract = (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>, dataIndex: number) => {
    event.stopPropagation();
    event.preventDefault();
    setContractToEdit(contractSummaryData[dataIndex]);
    setIsEditContractDialogOpen(true);
  };

  const handleAddLowerTierParticipation = (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    dataIndex: number,
  ) => {
    event.stopPropagation();
    event.preventDefault();
    setLowerTierParticipationToAdd(
      contractSummaryData[dataIndex] as unknown as LowerTierParticipationClassificationSummariesFromQuery[number],
    );
    setLowerTierParticipationSummariesByContract(
      lowerTierParticipationSummariesByContractId[contractSummaryData[dataIndex].contractId],
    );
    setIsAddLowerTierParticipationDialogOpen(true);
  };

  const handleDeleteLowerTierParticipation = (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    lowerTierParticipationSummary: LowerTierParticipationClassificationSummariesFromQuery[number],
  ) => {
    event.stopPropagation();
    event.preventDefault();
    setLowerTierParticipation(lowerTierParticipationSummary);
    setIsDeleteLowerTierParticipationDialogOpen(true);
  };

  const handleViewLowerParticipationDocument = (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    lowerTierParticipationSummary: LowerTierParticipationClassificationSummariesFromQuery[number],
  ) => {
    event.stopPropagation();
    event.preventDefault();
    setLowerTierParticipation(lowerTierParticipationSummary);
    setIsViewLowerTierParticipationDialogOpen(true);
  };

  const handleEditLowerParticipation = (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    lowerTierParticipationSummary: LowerTierParticipationClassificationSummariesFromQuery[number],
  ) => {
    event.stopPropagation();
    event.preventDefault();
    setLowerTierParticipation(lowerTierParticipationSummary);
    setIsEditLowerTierParticipationDialogOpen(true);
  };

  const highlightContractsAndLowerTierParticipationRow = (contractSummary: ContractSummariesFromQuery[number]) => {
    const lowerTierParticipationClassificationSummaries =
      contractSummary?.lowerTierParticipationClassificationSummaries.filter((summary) =>
        summary.smallBusinessClassifications.some(
          (smallBusinessClassification) => smallBusinessClassification.name === SmallBusinessClassificationName.LB,
        ),
      ) ?? [];

    const contractSummaryIncludesSmallBusiness = contractSummary?.smallBusinessClassifications.some(
      (classification) => classification.name !== SmallBusinessClassificationName.LB,
    );

    const contractSummaryValueTotal = contractSummary?.valueTotal;
    const contractSummaryThresholdPercentage = 0.8;
    const contractSummaryThreshold = contractSummaryValueTotal * contractSummaryThresholdPercentage;

    const haveLowerTierParticipationValuesReachedThreshold = lowerTierParticipationClassificationSummaries.filter(
      (summary) => {
        const isLowerTierValueWithinContractSummaryThreshold =
          Math.round(contractSummaryThreshold * 100) / 100 < summary.value;
        return isLowerTierValueWithinContractSummaryThreshold;
      },
    );

    if (contractSummaryIncludesSmallBusiness && haveLowerTierParticipationValuesReachedThreshold) {
      return haveLowerTierParticipationValuesReachedThreshold;
    }
  };

  const classificationStatusDisplay = (
    smallBusinessClassification: SmallBusinessClassification,
    acceptedClassificationToDisplayIds: string[],
    companyClassificationCoverage: SmallBusinessClassificationCoverage[],
  ) => {
    if (smallBusinessClassification.id === largeBusinessClassification.id) {
      return [getSmallBusinessClassificationStatus(null)]?.map((classificationStatus) => {
        if (classificationStatus !== undefined) {
          return <CheckIcon sx={activeClassificationIcon} />;
        }
      });
    }

    if (acceptedClassificationToDisplayIds.includes(smallBusinessClassification.id)) {
      return classificationsExpirationStatus(
        smallBusinessClassification.id,
        companyClassificationCoverage as SmallBusinessClassificationCoverage[],
      )?.map((classificationStatus) => {
        if (classificationStatus !== undefined) {
          if (classificationStatus.isExpired) {
            return <CancelIcon sx={classificationIconInactive} />;
          } else if (classificationStatus.isExpiringSoon) {
            return <ScheduleIcon sx={expiringSoonClassificationIcon} />;
          } else {
            return <CheckIcon sx={activeClassificationIcon} />;
          }
        }
      });
    }
  };

  let smallBusinessClassificationsCopy: SmallBusinessClassification[] = JSON.parse(
    JSON.stringify(clientAcceptedClassifications),
  );
  const smallBusinessClassificationsById = smallBusinessClassificationsCopy.reduce<{
    [id: string]: SmallBusinessClassificationSelection[number];
  }>((accumulator, classification) => {
    accumulator[classification.id] = classification;
    return accumulator;
  }, {});

  const contractSummaryListColumns: MUIDataTableColumn[] = [
    {
      name: 'companyId',
      label: 'CompanyId',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      name: 'contractId',
      label: 'Id',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      name: 'phaseCode',
      label: 'Contract Number',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'companyName',
      label: 'Company',
      options: {
        filter: false,
        searchable: true,
        sort: true,
        setCellProps: () => ({ width: '150px' }),
        customBodyRenderLite: (dataIndex) => {
          const contractSummary = contractSummaryData[dataIndex];
          const companyId = contractSummary?.companyId;
          return (
            companyId && (
              <Link href={`/companies/${companyId}`} target="_blank" sx={companyLink}>
                <Typography>{contractSummary?.companyName}</Typography>
              </Link>
            )
          );
        },
      },
    },
    {
      name: 'isSelfWork',
      label: 'HP Self Work',
      options: {
        filter: true,
        filterOptions: {
          fullWidth: true,
        },
        download: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const contractSummary = contractSummaryData[dataIndex];
          const isSelfWork = contractSummary?.isSelfWork;
          return (
            isSelfWork && (
              <Grid container alignItems="center">
                <Grid item>
                  <CheckIcon sx={activeClassificationIcon} />
                </Grid>
              </Grid>
            )
          );
        },
      },
    },
    {
      name: 'hasLowerTierParticipation',
      label: 'Lower Tier Participation',
      options: {
        filter: true,
        filterOptions: {
          fullWidth: true,
        },
        download: false,
        sort: true,
        display: (isNonFederalProject || isHpSelfReporting) ?? 'excluded',
        customBodyRenderLite: (dataIndex) => {
          const lowerTierParticipationClassificationSummaries =
            contractSummaryData[dataIndex].lowerTierParticipationClassificationSummaries;
          const hasLowerTierParticipation =
            lowerTierParticipationClassificationSummaries && lowerTierParticipationClassificationSummaries.length > 0;
          return (
            hasLowerTierParticipation && (
              <Grid container alignItems="center">
                <Grid item>
                  <CheckIcon sx={activeClassificationIcon} />
                </Grid>
              </Grid>
            )
          );
        },
      },
    },
    {
      name: 'hasLowerTierParticipation',
      label: '',
      options: {
        filter: false,
        display: 'excluded',
        customBodyRenderLite: (dataIndex) => {
          return highlightContractsAndLowerTierParticipationRow(contractSummaryData[dataIndex]);
        },
      },
    },
    {
      name: 'effectiveDate',
      label: 'Contract Date Execution',
      options: {
        filter: false,
        searchable: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const contractSummary = contractSummaryData[dataIndex];
          const date = contractSummary?.effectiveDate;
          const formattedDate = date !== null ? formatDate(date) : null;
          return <Typography>{formattedDate}</Typography>;
        },
      },
    },
    {
      name: 'smallBusinessClassifications',
      label: 'Classifications',
      options: {
        filter: true,
        filterOptions: {
          fullWidth: true,
          names: clientAcceptedClassifications.map((classification) => classification.id),
          renderValue: (id) => smallBusinessClassificationsById[id].abbreviation,
          logic: (classification, filters) => {
            const copyOfClassifications = classification as unknown as SmallBusinessClassification[];

            const smallBusinessClassificationIds =
              copyOfClassifications?.map((classification) => classification.id) ?? [];

            if (filters.length) {
              return !smallBusinessClassificationIds.some((classificationId) => filters.includes(classificationId));
            }
            /* istanbul ignore next */
            return false; // this line can never be tested as filters will always exist
          },
        },
        customFilterListOptions: {
          render: (id) => smallBusinessClassificationsById[id].abbreviation,
        },
        filterType: 'multiselect',
        searchable: true,
        sort: false,
        // eslint-disable-next-line react/display-name
        customBodyRenderLite: (dataIndex) => {
          const contractSummary = contractSummaryData[dataIndex];
          const allContractClassifications = contractSummary?.smallBusinessClassifications;
          const companyClassificationCoveragesCopy = [...contractSummary.coverages];

          const sortCompanyClassificationCoverages = companyClassificationCoveragesCopy.sort((a, b) => {
            // first sort alphabetically by classification name
            if (a.smallBusinessClassification.name < b.smallBusinessClassification.name) return -1;
            if (a.smallBusinessClassification.name > b.smallBusinessClassification.name) return 1;
            // if names are the same, sort by classification id
            if (a.smallBusinessClassification.id < b.smallBusinessClassification.id) return -1;
            if (a.smallBusinessClassification.id > b.smallBusinessClassification.id) return 1;
            // If ids are the same, compare end date
            const dateA = DateTime.fromISO(a.endDate);
            const dateB = DateTime.fromISO(b.endDate);
            return Number(dateB) - Number(dateA);
          });

          const companyClassificationCoverageDetails = sortCompanyClassificationCoverages.reduce(
            (acc: SmallBusinessClassificationCoverageFromQuery[], companyClassificationCoverage) => {
              const duplicateCompanyCoverageClassifications = acc.find(
                (classificationCoverage) =>
                  classificationCoverage.smallBusinessClassification.id ===
                  companyClassificationCoverage.smallBusinessClassification.id,
              );
              const expiredClassification = getSmallBusinessClassificationStatus(
                companyClassificationCoverage.endDate,
              ).isExpired;

              const expiringSoonClassification = getSmallBusinessClassificationStatus(
                companyClassificationCoverage.endDate,
              ).isExpiringSoon;

              if (!duplicateCompanyCoverageClassifications && (!expiredClassification || !expiringSoonClassification)) {
                acc.push(companyClassificationCoverage);
              }
              return acc;
            },
            [],
          );

          const largeBusinessClassificationCoverageDetails =
            largeBusinessClassificationCoverage(largeBusinessClassification);

          if (!companyClassificationCoverageDetails.length && contractSummary?.companyName !== '') {
            companyClassificationCoverageDetails?.push(largeBusinessClassificationCoverageDetails);
          }

          const classificationsToDisplay =
            companyClassificationCoverageDetails?.map((companyDetails) => {
              return companyDetails.smallBusinessClassification;
            }) ?? ([] as SmallBusinessClassification[]);

          const clientAcceptedAgenciesAndClassificationsInCompanyClassificationCoverages =
            clientAcceptedAgencies?.filter((clientAcceptedAgency: SmallBusinessClientAgency) =>
              companyClassificationCoverageDetails?.some(
                (classificationCoverage) =>
                  classificationCoverage?.smallBusinessAgency?.id === clientAcceptedAgency?.smallBusinessAgency?.id,
              ),
            );

          const classificationsToDisplayIds = clientAcceptedAgenciesAndClassificationsInCompanyClassificationCoverages
            .flatMap((agencyAndClassification: SmallBusinessClientAgency) =>
              agencyAndClassification.smallBusinessClientClassifications.flatMap(
                (clientClassification) => clientClassification.smallBusinessClassification.id,
              ),
            )
            .concat([largeBusinessClassification.id]);

          if (!classificationsToDisplay?.length) {
            classificationsToDisplay?.push({
              id: 'LB',
              abbreviation: 'LB',
              name: SmallBusinessClassificationName.LB,
              created: undefined,
              lastModified: undefined,
              sortOrder: 0,
            });
          }

          const isLargeBusinessCompany = allContractClassifications.some(
            (classification) => classification.id === largeBusinessClassification.id,
          );
          const isLargeBusinessDefault = contractSummary?.companyName === '' && !allContractClassifications?.length;

          const inactiveLargeBusinessClassification = (smallBusinessClassification: SmallBusinessClassification) => {
            return !classificationsToDisplayIds.includes(smallBusinessClassification.id) && isLargeBusinessDefault;
          };

          return (
            <Grid container direction="row" spacing={2}>
              {classificationsToDisplay?.map((classification: SmallBusinessClassification, index: number) => {
                const companyClassificationCoverage = companyClassificationCoverageDetails?.find(
                  (smallBusinessClassification) =>
                    smallBusinessClassification.smallBusinessClassification.id === classification.id,
                );
                const classificationIsAccepted =
                  classificationsToDisplayIds?.includes(classification.id) ||
                  isLargeBusinessCompany ||
                  isLargeBusinessDefault ||
                  classification.name === SmallBusinessClassificationName.LB;

                return (
                  <Grid item key={index}>
                    <Grid container alignItems="center" direction="row" spacing={1}>
                      <Grid item>
                        {classificationStatusDisplay(
                          classification,
                          classificationsToDisplayIds,
                          companyClassificationCoverageDetails as SmallBusinessClassificationCoverage[],
                        )}
                      </Grid>
                      <Grid item>
                        <SmallBusinessClassificationDisplayName
                          classification={classification}
                          classificationCoverage={companyClassificationCoverage}
                          reportingAgency={project?.smallBusinessClient}
                          displayFormat="abbreviation"
                          tooltipFormat={
                            getSmallBusinessClassificationStatus(companyClassificationCoverage?.endDate).isExpired &&
                            classificationIsAccepted &&
                            !inactiveLargeBusinessClassification(classification)
                              ? 'expired'
                              : getSmallBusinessClassificationStatus(companyClassificationCoverage?.endDate)
                                  .isExpiringSoon &&
                                classificationIsAccepted &&
                                !inactiveLargeBusinessClassification(classification)
                              ? 'expiringSoon'
                              : !classificationIsAccepted
                              ? 'acceptance'
                              : 'name'
                          }
                          typographyClassNames={
                            !classificationIsAccepted
                              ? classificationColumnNotAccepted
                              : inactiveLargeBusinessClassification(classification)
                              ? classificationColumnInactive
                              : classificationColumn
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          );
        },
      },
    },
    {
      name: 'participationPercentage',
      label: 'Participation Total',
      options: {
        filter: false,
        display: (isNonFederalProject || isHpSelfReporting) ?? 'excluded',
        download: false,
        customBodyRenderLite: (dataIndex) => {
          const valueTotal = contractSummaryData[dataIndex]?.valueTotal;
          const participationPercentage = contractSummaryData[dataIndex]?.participationPercentage as number;
          return (
            <NumberFormat
              value={valueTotal * (participationPercentage / 100)}
              displayType={'text'}
              prefix={'$'}
              decimalScale={2}
              fixedDecimalScale={true}
              allowLeadingZeros={false}
              isNumericString={true}
              thousandSeparator={true}
              margin="dense"
              variant="outlined"
              color="primary"
            />
          );
        },
      },
    },
    {
      name: 'participationPercentage',
      label: 'Participation Percent',
      options: {
        filter: false,
        display: (isNonFederalProject || isHpSelfReporting) ?? 'excluded',
        download: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <NumberFormat
              allowLeadingZeros={false}
              suffix="%"
              isNumericString={true}
              margin="dense"
              variant="outlined"
              color="primary"
              value={contractSummaryData[dataIndex]?.participationPercentage}
              displayType={'text'}
              fixedDecimalScale={true}
            />
          );
        },
      },
    },
    {
      name: 'valueTotal',
      label: 'Total',
      options: {
        filter: false,
        searchable: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          return (
            <NumberFormat
              value={contractSummaryData[dataIndex]?.valueTotal}
              displayType={'text'}
              prefix={'$'}
              decimalScale={2}
              fixedDecimalScale={true}
              allowLeadingZeros={false}
              isNumericString={true}
              thousandSeparator={true}
              margin="dense"
              variant="outlined"
              color="primary"
            />
          );
        },
      },
    },
    {
      name: 'edit',
      label: ' ',
      options: {
        filter: false,
        searchable: false,
        sort: false,
        display: editable,
        // eslint-disable-next-line react/display-name
        customBodyRenderLite: (dataIndex) => {
          return (
            <>
              <Grid container sx={actionsContainer}>
                <Grid item>
                  <Tooltip title="Edit Contract">
                    <span>
                      <IconButton
                        size="small"
                        onClick={(event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) =>
                          handleEditContract(event, dataIndex)
                        }
                      >
                        <EditIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Grid>
              </Grid>
            </>
          );
        },
      },
    },
    {
      name: 'add',
      label: ' ',
      options: {
        filter: false,
        searchable: false,
        sort: false,
        display: editable,
        // eslint-disable-next-line react/display-name
        customBodyRenderLite: (dataIndex) => {
          const shouldShowAddButton =
            contractSummaryData[dataIndex].companyName !== '' &&
            contractSummaryData[dataIndex].valueTotal !== 0 &&
            Math.sign(contractSummaryData[dataIndex].valueTotal) === 1 &&
            (isNonFederalProject || isHpSelfReporting);
          return (
            <>
              {shouldShowAddButton && (
                <Grid container sx={actionsContainer}>
                  <Grid item>
                    <Tooltip title="Add Lower Tier Participation">
                      <span>
                        <IconButton
                          size="small"
                          onClick={(event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) =>
                            handleAddLowerTierParticipation(event, dataIndex)
                          }
                        >
                          <AddIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Grid>
                </Grid>
              )}
            </>
          );
        },
      },
    },
  ];

  const handleSortChange = (name: string, direction: 'asc' | 'desc') => {
    setContractTableSort({ name: name, direction: direction });
    localStorage.setItem(LOCAL_STORAGE_CONTRACT_SORT_KEY, JSON.stringify({ name, direction }));
  };

  const handleLowerTierClassificationsDisplay = (fileSummary: LowerTierParticipationFileSummaryFromQuery) => {
    const lowerTierClassifications = fileSummary?.lowerTierParticipationClassifications;
    const lowerTierClassificationIds = lowerTierClassifications.map(
      (lowerTierClassification) => lowerTierClassification.smallBusinessClassification.id,
    );
    const classificationsToDisplay = clientAcceptedClassifications.filter((classification) =>
      lowerTierClassificationIds?.includes(classification.id),
    );
    if (!classificationsToDisplay.length) {
      classificationsToDisplay.push({
        id: 'LB',
        abbreviation: 'LB',
        name: SmallBusinessClassificationName.LB,
      });
    }
    return classificationsToDisplay;
  };

  const contractSummaryListOptions: MUIDataTableOptions = {
    rowsPerPageOptions: [10, 50],
    expandableRows: true,
    expandableRowsOnClick: true,
    expandableRowsHeader: false,
    sortOrder: { name: contractTableSort?.name, direction: contractTableSort?.direction },
    setRowProps(row, dataIndex) {
      const [, , , , , , lowerTierLargeBusiness, , , , ,] = row;
      return (isNonFederalProject || isHpSelfReporting) && lowerTierLargeBusiness(dataIndex)?.length
        ? { sx: lowerTierIsLargeBusiness }
        : {};
    },
    onColumnSortChange(changedColumns, direction) {
      handleSortChange(changedColumns, direction);
    },
    onTableInit(_, tableState) {
      const savedContractTableSort = contractTableSort;
      tableState.sortOrder = savedContractTableSort;
    },
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      const contractSummary = contractSummaryData[rowMeta.dataIndex];
      const changeOrderSummaries = contractSummaryData[rowMeta.dataIndex].changeOrderSummaries;
      const lowerTierParticipationClassificationSummaries =
        contractSummaryData[rowMeta.dataIndex].lowerTierParticipationClassificationSummaries;

      return (
        <>
          <TableRow>
            <TableCell colSpan={colSpan} sx={lowerTierParentCell}>
              <TableContainer>
                <Table>
                  {lowerTierParticipationClassificationSummaries.length > 0 && (
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>Company</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>Contract Date Execution</TableCell>
                        <TableCell>Classifications</TableCell>
                        <TableCell>Participation Total</TableCell>
                        <TableCell>Participation Percent</TableCell>
                        <TableCell>Total</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                  )}
                  <TableBody>
                    {lowerTierParticipationClassificationSummaries?.map((lowerTierParticipation, index) => {
                      return (
                        <TableRow
                          key={index}
                          sx={
                            highlightContractsAndLowerTierParticipationRow(contractSummary)?.includes(
                              lowerTierParticipation,
                            )
                              ? lowerTierIsLargeBusiness
                              : undefined
                          }
                        >
                          <TableCell sx={hiddenCell}></TableCell>
                          <TableCell align="left" sx={secondTierCell}>
                            <Typography>{'Lower Tier'}</Typography>
                          </TableCell>
                          <TableCell align="left" sx={lowerTierCompanyNameCell}>
                            <Typography>{lowerTierParticipation?.lowerTierCompanyName}</Typography>
                          </TableCell>
                          <TableCell sx={selfWorkHiddenCell}></TableCell>
                          <TableCell sx={lowerTierHiddenCell}></TableCell>
                          <TableCell sx={lowerTierExecutionDateCell} align="left">
                            <Typography>{formatDate(lowerTierParticipation?.effectiveDate)}</Typography>
                          </TableCell>
                          <TableCell>
                            <Grid container direction="row" spacing={2}>
                              {lowerTierParticipation?.lowerTierParticipationFileSummary?.flatMap((fileSummary) => {
                                return handleLowerTierClassificationsDisplay(fileSummary)?.map(
                                  (classificationToDisplay, index) => {
                                    const classificationToDisplayIndex = Object.keys(
                                      SmallBusinessClassificationName,
                                    ).indexOf(classificationToDisplay.abbreviation);

                                    return (
                                      <Grid item key={index}>
                                        <Grid container alignItems="center" direction="row" spacing={1}>
                                          <Grid item>
                                            <CheckIcon sx={activeClassificationIcon} />
                                          </Grid>
                                          <Grid item>
                                            <SmallBusinessClassificationDisplayName
                                              classification={{
                                                name: Object.values(SmallBusinessClassificationName)[
                                                  classificationToDisplayIndex
                                                ],
                                                abbreviation: classificationToDisplay.abbreviation,
                                              }}
                                              displayFormat="abbreviation"
                                              tooltipFormat="name"
                                              typographyClassNames={classificationColumn}
                                            />
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    );
                                  },
                                );
                              })}
                            </Grid>
                          </TableCell>
                          <TableCell>
                            {lowerTierParticipation?.lowerTierParticipationFileSummary?.flatMap((fileSummary) => {
                              const lowerTierParticipationPercentage = fileSummary.participationPercentage as number;

                              return handleLowerTierClassificationsDisplay(fileSummary)?.map(
                                (classificationToDisplay) => {
                                  const classificationToDisplayIndex = Object.keys(
                                    SmallBusinessClassificationName,
                                  ).indexOf(classificationToDisplay.abbreviation);

                                  return (
                                    <Grid container alignItems="center" direction="row" spacing={1}>
                                      <Grid item>
                                        <SmallBusinessClassificationDisplayName
                                          classification={{
                                            name: Object.values(SmallBusinessClassificationName)[
                                              classificationToDisplayIndex
                                            ],
                                            abbreviation: classificationToDisplay.abbreviation,
                                          }}
                                          displayFormat="abbreviation"
                                          tooltipFormat="name"
                                          typographyClassNames={classificationColumn}
                                        />
                                      </Grid>
                                      <Grid item>
                                        <NumberFormat
                                          value={
                                            lowerTierParticipation.value * (lowerTierParticipationPercentage / 100)
                                          }
                                          displayType={'text'}
                                          prefix={'$'}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          allowLeadingZeros={false}
                                          isNumericString={true}
                                          thousandSeparator={true}
                                          margin="dense"
                                          variant="outlined"
                                          color="primary"
                                        />
                                      </Grid>
                                    </Grid>
                                  );
                                },
                              );
                            })}
                          </TableCell>
                          <TableCell>
                            {lowerTierParticipation?.lowerTierParticipationFileSummary?.flatMap((fileSummary) => {
                              const lowerTierParticipationPercentage = fileSummary.participationPercentage as number;

                              return handleLowerTierClassificationsDisplay(fileSummary)?.map(
                                (classificationToDisplay) => {
                                  const classificationToDisplayIndex = Object.keys(
                                    SmallBusinessClassificationName,
                                  ).indexOf(classificationToDisplay.abbreviation);

                                  return (
                                    <Grid container alignItems="center" direction="row" spacing={1}>
                                      <Grid item>
                                        <SmallBusinessClassificationDisplayName
                                          classification={{
                                            name: Object.values(SmallBusinessClassificationName)[
                                              classificationToDisplayIndex
                                            ],
                                            abbreviation: classificationToDisplay.abbreviation,
                                          }}
                                          displayFormat="abbreviation"
                                          tooltipFormat="name"
                                          typographyClassNames={classificationColumn}
                                        />
                                      </Grid>
                                      <Grid item>
                                        <NumberFormat
                                          value={lowerTierParticipationPercentage}
                                          displayType={'text'}
                                          suffix="%"
                                          fixedDecimalScale={true}
                                          allowLeadingZeros={false}
                                          isNumericString={true}
                                          margin="dense"
                                          variant="outlined"
                                          color="primary"
                                        />
                                      </Grid>
                                    </Grid>
                                  );
                                },
                              );
                            })}
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={lowerTierParticipation?.value}
                              displayType={'text'}
                              prefix={'$'}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              allowLeadingZeros={false}
                              isNumericString={true}
                              thousandSeparator={true}
                              margin="dense"
                              variant="outlined"
                              color="primary"
                            />
                          </TableCell>
                          <TableCell align="right">
                            <Grid container sx={actionsContainer}>
                              <Grid item>
                                <Tooltip title="Lower Tier Participation File">
                                  <span>
                                    <IconButton
                                      size="small"
                                      onClick={(event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) =>
                                        handleViewLowerParticipationDocument(event, lowerTierParticipation)
                                      }
                                    >
                                      <PdfIcon />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </TableCell>
                          <TableCell align="right">
                            <Grid container sx={actionsContainer}>
                              <Grid item>
                                <Tooltip title="Edit Lower Tier Participation">
                                  <span>
                                    <IconButton
                                      size="small"
                                      onClick={(event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) =>
                                        handleEditLowerParticipation(event, lowerTierParticipation)
                                      }
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </TableCell>
                          <TableCell align="right">
                            <Grid container sx={actionsContainer}>
                              <Grid item>
                                <Tooltip title="Delete Lower Tier Participation">
                                  <span>
                                    <IconButton
                                      size="small"
                                      onClick={(event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) =>
                                        handleDeleteLowerTierParticipation(event, lowerTierParticipation)
                                      }
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={colSpan}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Date</TableCell>
                      <TableCell align="right">Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {changeOrderSummaries.map((changeOrderSummary, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell align="left">
                            {/* changeOrder dates are being pulled from Vista. */}
                            <Typography>{formatDate(changeOrderSummary.date)}</Typography>
                          </TableCell>
                          <TableCell align="right">
                            <NumberFormat
                              value={changeOrderSummary.value}
                              displayType={'text'}
                              prefix={'$'}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              allowLeadingZeros={false}
                              isNumericString={true}
                              thousandSeparator={true}
                              margin="dense"
                              variant="outlined"
                              color="primary"
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </TableCell>
          </TableRow>
        </>
      );
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      const formattedData = data.map((row: any) => {
        const rowData = [...row.data];
        const classificationArrayIndex = isNonFederalProject ? 5 : 4;

        if (Array.isArray(rowData[classificationArrayIndex])) {
          rowData[classificationArrayIndex] = rowData[classificationArrayIndex]
            .map((classification: SmallBusinessClassification) => classification.abbreviation)
            .join(', ');
        }
        return {
          ...row,
          data: rowData,
        };
      });
      return '\uFEFF' + buildHead(columns) + buildBody(formattedData);
    },
    downloadOptions: {
      filename: `Small_Business_${project?.number}_Contracts`,
      filterOptions: {
        useDisplayedColumnsOnly: true,
      },
    },
    sortFilterList: false,
  };

  return (
    <>
      <StyledTable
        title={'Contracts'}
        data={contractSummaryData}
        columns={contractSummaryListColumns}
        options={contractSummaryListOptions}
      />
      <EditContractDialog
        isOpen={isEditContractDialogOpen}
        setIsOpen={setIsEditContractDialogOpen}
        project={project}
        contractId={contractToEdit?.contractId}
        companyId={contractToEdit?.companyId}
        phaseCode={contractToEdit?.phaseCode}
        valueTotal={contractToEdit?.valueTotal}
        effectiveDate={contractToEdit?.effectiveDate}
        isSelfWork={contractToEdit?.isSelfWork as boolean}
        participationPercentage={contractToEdit?.participationPercentage as number}
        smallBusinessReportSettings={smallBusinessReportSettings}
        smallBusinessReportClassificationSummaries={smallBusinessReportClassificationSummaries}
        allSmallBusinessClassifications={allSmallBusinessClassifications}
        selectedReportOption={selectedReportOption}
      />
      <AddLowerTierParticipationDialog
        isOpen={isAddLowerTierParticipationDialogOpen}
        setIsOpen={setIsAddLowerTierParticipationDialogOpen}
        contractId={lowerTierParticipationToAdd?.contractId}
        allSmallBusinessClassifications={allSmallBusinessClassifications}
        smallBusinessReportSettings={smallBusinessReportSettings}
        smallBusinessReportClassificationSummaries={smallBusinessReportClassificationSummaries}
        smallBusinessClient={smallBusinessClient ?? undefined}
        contractSummaryData={contractSummaryDataByContractId}
        lowerTierParticipationSummariesByContract={
          lowerTierParticipationSummariesByContract as LowerTierParticipationClassificationSummary[]
        }
      />
      <DeleteLowerTierParticipationDialog
        isOpen={isDeleteLowerTierParticipationDialogOpen}
        setIsOpen={setIsDeleteLowerTierParticipationDialogOpen}
        lowerTierParticipation={lowerTierParticipation}
        smallBusinessReportSettings={smallBusinessReportSettings}
        smallBusinessReportClassificationSummaries={smallBusinessReportClassificationSummaries}
        contractId={contractSummaryDataByContractId?.contractId}
      />
      <ViewLowerTierParticipationDialog
        isOpen={isViewLowerTierParticipationDialogOpen}
        setIsOpen={setIsViewLowerTierParticipationDialogOpen}
        lowerTierParticipation={lowerTierParticipation}
      />
      <EditLowerTierParticipationDialog
        isOpen={isEditLowerTierParticipationDialogOpen}
        setIsOpen={setIsEditLowerTierParticipationDialogOpen}
        lowerTierParticipation={lowerTierParticipation}
        allSmallBusinessClassifications={allSmallBusinessClassifications}
        smallBusinessReportSettings={smallBusinessReportSettings}
        smallBusinessReportClassificationSummaries={smallBusinessReportClassificationSummaries}
        smallBusinessClient={smallBusinessClient ?? undefined}
        contractSummaryData={contractSummaryDataByContractId}
        lowerTierParticipationSummariesByContract={
          lowerTierParticipationSummariesByContract as LowerTierParticipationClassificationSummary[]
        }
      />
    </>
  );
};

export default SmallBusinessContractTable;
