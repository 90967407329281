import ActiveOrInactiveStatusContent from 'components/shared/ActiveOrInactiveStatusContent';
import StyledButtonPrimary from 'components/shared/ButtonPrimary';
import StyledButtonSecondary from 'components/shared/ButtonSecondary';
import StyledDialog from 'components/shared/Dialog';
import ErrorNotice from 'components/shared/ErrorNotice';
import StyledNotice, { NoticeTypeOption } from 'components/shared/Notice';
import { AGENCY_DIALOG_VIEWS } from 'constants/adminDialogViews';
import { SmallBusinessAdminRequirementOption } from 'constants/smallBusinessRequirementOptions';
import useToast from 'hooks/useToast';
import { FC, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  Company,
  GetSmallBusinessClassificationsQuery,
  LowerTierParticipation,
  SmallBusinessAgencyClassification,
  SmallBusinessClassification,
  useDeleteSmallBusinessAgencyMutation,
  useGetCompaniesInUseQuery,
  useGetLowerTierParticipationsInUseQuery,
  useGetSmallBusinessClientsInUseQuery,
  useUpsertSmallBusinessAgencyMutation,
} from 'types/generated/graphql';
import { generateTransactionKey } from 'utils/general';

import { useApolloClient } from '@apollo/client';
import {
  Autocomplete,
  Box,
  Chip,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  OutlinedInput,
  Radio,
  RadioGroup,
  Switch,
  SxProps,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';

type SmallBusinessAgencyDialogProps = {
  isOpen: boolean;
  setIsOpen: (x: boolean) => void;
  smallBusinessAgencyData: any;
  setSmallBusinessAgencyDialogView: any;
  smallBusinessAgencyDialogView: string;
  handleClose: (x: any) => void;
  smallBusinessClassificationList: GetSmallBusinessClassificationsQuery['smallBusinessClassificationList'];
};

const nameInputLabelStyle: SxProps<Theme> = (theme: Theme) => ({
  marginBottom: '-8px',
  marginTop: '5px',
  color: theme.palette.secondary.contrastText,
});

const specialNoticeInputLabelStyle: SxProps<Theme> = (theme: Theme) => ({
  marginBottom: '-8px',
  marginTop: '10px',
  color: theme.palette.secondary.contrastText,
});

const abbreviationInputLabelStyle: SxProps<Theme> = (theme: Theme) => ({
  marginBottom: '-15px',
  color: theme.palette.secondary.contrastText,
});

const statusLabelStyle: SxProps<Theme> = (theme: Theme) => ({
  marginBottom: '-15px',
  marginTop: '5px',
  color: theme.palette.secondary.contrastText,
});

const classificationLabelStyle: SxProps<Theme> = (theme: Theme) => ({
  marginBottom: '-15px',
  marginTop: '5px',
  color: theme.palette.secondary.contrastText,
});

const errorMessageStyle: SxProps<Theme> = {
  color: '#cd0000',
  marginLeft: '14px',
  marginTop: '4px',
  fontSize: '.75rem',
};

const inputContainer: SxProps<Theme> = {
  marginTop: '20px',
};

const classificationChipStyles: SxProps<Theme> = (theme: Theme) => ({
  paddingTop: theme.spacing(1),
});

const textFieldStyles: SxProps<Theme> = (theme: Theme) => ({
  paddingTop: theme.spacing(0),
});

const unableToDeleteMessageStyles: SxProps<Theme> = {
  marginBottom: '20px',
};

const radioLabelStyle: SxProps<Theme> = {
  fontSize: '0.875rem',
};

const requirementLabelStyle: SxProps<Theme> = (theme: Theme) => ({
  marginBottom: '-15px',
  color: theme.palette.secondary.contrastText,
});

const dataInUseLabelStyle: SxProps<Theme> = {
  fontWeight: 'bold',
};

const dataInUseContainerStyle: SxProps<Theme> = {
  maxHeight: '35vh',
  overflow: 'auto',
};

type EditSmallBusinessAgencyInput = {
  name: string;
  abbreviation?: string;
  isActive: boolean;
  agencyAndClassifications?: string[];
  url?: string;
  isFederal?: boolean;
  participationPercentage?: number;
  specialNotice?: string;
};

type PickedSmallBusinessClassification = Pick<
  SmallBusinessClassification,
  'id' | 'name' | 'abbreviation' | 'isFederal' | 'sortOrder'
>;

const SmallBusinessAgencyDialog: FC<SmallBusinessAgencyDialogProps> = ({
  isOpen,
  setIsOpen,
  smallBusinessAgencyData,
  setSmallBusinessAgencyDialogView,
  smallBusinessAgencyDialogView,
  smallBusinessClassificationList,
}) => {
  const { displayToast } = useToast();
  const apolloClient = useApolloClient();
  const [transactionKey, setTransactionKey] = useState(generateTransactionKey());
  const [isEditing, setIsEditing] = useState(false);
  const { EDIT_AGENCY, ADD_AGENCY, DELETE_AGENCY, CONFIRM_CANCEL } = AGENCY_DIALOG_VIEWS;

  const agencySupportedClassifications: string[] =
    smallBusinessAgencyData?.agencyAndClassifications?.map(
      (classification: SmallBusinessAgencyClassification) => classification.smallBusinessClassification.id,
    ) ?? [];

  const [classifications, setClassifications] = useState<string[]>(agencySupportedClassifications);

  useEffect(() => {
    if (smallBusinessAgencyDialogView === EDIT_AGENCY) {
      setIsEditing(true);
    }
  }, [EDIT_AGENCY, smallBusinessAgencyDialogView]);

  const smallBusinessClassificationsById = smallBusinessClassificationList.reduce(
    (accumulator: any, classification: { id: string | number }) => {
      accumulator[classification.id] = classification;
      return accumulator;
    },
    {},
  );

  const [createSmallBusinessAgency] = useUpsertSmallBusinessAgencyMutation();
  const [updateSmallBusinessAgency, { loading: isLoading }] = useUpsertSmallBusinessAgencyMutation();
  const [deleteSmallBusinessAgency] = useDeleteSmallBusinessAgencyMutation();

  const {
    loading: usedLowerTierParticipationsLoading,
    error: usedLowerTierParticipationsError,
    data: usedLowerTierParticipationsData,
  } = useGetLowerTierParticipationsInUseQuery({
    client: apolloClient,
    variables: {
      smallBusinessAgencyId: smallBusinessAgencyData?.id,
      smallBusinessClassificationIds: agencySupportedClassifications,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    skip: typeof smallBusinessAgencyData?.id !== 'string',
  });

  const lowerTierParticipationsInUse = usedLowerTierParticipationsData?.getLowerTierParticipationsInUse ?? [];

  const {
    loading: usedSmallBusinessClientsLoading,
    error: usedSmallBusinessClientsError,
    data: usedSmallBusinessClientsData,
  } = useGetSmallBusinessClientsInUseQuery({
    client: apolloClient,
    variables: {
      smallBusinessAgencyId: smallBusinessAgencyData?.id,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    skip: typeof smallBusinessAgencyData?.id !== 'string',
  });

  const smallBusinessClientsInUse = usedSmallBusinessClientsData?.getSmallBusinessClientsInUse ?? [];

  const {
    loading: usedCompaniesLoading,
    error: usedCompaniesError,
    data: usedCompaniesData,
  } = useGetCompaniesInUseQuery({
    client: apolloClient,
    variables: {
      smallBusinessAgencyId: smallBusinessAgencyData?.id,
      smallBusinessClassificationIds: agencySupportedClassifications,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    skip: typeof smallBusinessAgencyData?.id !== 'string',
  });

  const companiesInUse = usedCompaniesData?.getCompaniesInUse ?? [];

  const defaultValues = {
    name: smallBusinessAgencyData?.name ?? '',
    abbreviation: smallBusinessAgencyData?.abbreviation ?? '',
    agencyAndClassifications: agencySupportedClassifications,
    url: smallBusinessAgencyData?.url ?? '',
    isFederal: smallBusinessAgencyData?.isFederal === null ? 'any/all' : smallBusinessAgencyData?.isFederal,
    isActive: smallBusinessAgencyData?.isActive,
    participationPercentage: smallBusinessAgencyData?.participationPercentage,
    specialNotice: smallBusinessAgencyData?.specialNotice ?? '',
  };

  const classificationsUsedByLowerTierParticipation = lowerTierParticipationsInUse.flatMap((lowerTierParticipation) =>
    lowerTierParticipation.lowerTierParticipationFileSummary?.flatMap((summary) =>
      summary.lowerTierParticipationClassifications.flatMap(
        (classification) => classification.smallBusinessClassification,
      ),
    ),
  ) as PickedSmallBusinessClassification[];

  const classificationsUsedByCompanies = companiesInUse.flatMap((company) =>
    company.files.flatMap((file) =>
      file.smallBusinessClassificationCoverages.flatMap((coverage) => coverage.smallBusinessClassification),
    ),
  ) as PickedSmallBusinessClassification[];

  const lowerTierParticipationsByClassificationAbbr = classificationsUsedByLowerTierParticipation.reduce<{
    [abbreviation: string]: any;
  }>((accumulator, classification) => {
    accumulator[classification?.abbreviation] = lowerTierParticipationsInUse.flatMap((usedLowerTierParticipation) =>
      usedLowerTierParticipation.lowerTierParticipationFileSummary?.flatMap((fileSummary) =>
        fileSummary.lowerTierParticipationClassifications.flatMap((lowerTierClassification) =>
          lowerTierClassification.smallBusinessClassification.id === classification?.id
            ? usedLowerTierParticipation
            : [],
        ),
      ),
    );
    return accumulator;
  }, {});

  const companiesByClassificationAbbr = classificationsUsedByCompanies.reduce<{
    [abbreviation: string]: any;
  }>((accumulator, classification) => {
    accumulator[classification?.abbreviation] = companiesInUse.flatMap((usedCompany) =>
      usedCompany.files?.flatMap((file) =>
        file.smallBusinessClassificationCoverages?.flatMap((coverage) =>
          coverage.smallBusinessClassification.id === classification.id ? usedCompany : [],
        ),
      ),
    );
    return accumulator;
  }, {});

  const { handleSubmit, control, reset, watch, formState, setValue } = useForm<EditSmallBusinessAgencyInput>({
    defaultValues: defaultValues,
  });

  const { isDirty } = formState;

  const smallBusinessAgencyName = watch('name');
  const smallBusinessAgencyAbbreviation = watch('abbreviation');
  const smallBusinessClassifications = watch('agencyAndClassifications');
  const smallBusinessAgencyRequirement = watch('isFederal');
  const smallBusinessAgencyStatus = watch('isActive');

  const federalClassificationsList = smallBusinessClassificationList.filter(
    (classification) => classification.isFederal !== false,
  );
  const nonFederalClassificationsList = smallBusinessClassificationList.filter(
    (classification) => classification.isFederal !== true,
  );

  const getSmallBusinessAgencyRequirementByType = (requirement: string | boolean | undefined) => {
    if (typeof requirement === 'string') {
      if (requirement !== 'any/all') {
        return JSON.parse(requirement);
      }
      return null;
    } else {
      return requirement;
    }
  };

  const getClassificationList = () => {
    const smallBusinessAgencyRequirementByType =
      getSmallBusinessAgencyRequirementByType(smallBusinessAgencyRequirement);
    return smallBusinessAgencyRequirementByType === undefined
      ? []
      : smallBusinessAgencyRequirementByType === true
      ? federalClassificationsList
      : smallBusinessAgencyRequirementByType === false
      ? nonFederalClassificationsList
      : smallBusinessClassificationList;
  };
  let classificationList = getClassificationList();

  const handleRequirementChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const smallBusinessAgencyRequirementByType = getSmallBusinessAgencyRequirementByType(event.target.value);
    setValue('isFederal', smallBusinessAgencyRequirementByType);
    setValue('agencyAndClassifications', []);
    setClassifications([]);
    classificationList = getClassificationList();
  };

  const handleClose = () => {
    setIsOpen(false);
    setIsEditing(false);
    reset(defaultValues);
    setClassifications(agencySupportedClassifications);
    setTransactionKey(generateTransactionKey());
  };

  const handleDeleteClassifications = (classificationToDelete: string) => {
    const classificationsToKeep = smallBusinessClassifications?.filter(
      (classification) => classification !== classificationToDelete,
    ) as string[];
    setValue('agencyAndClassifications', classificationsToKeep);
    setClassifications(classificationsToKeep);
  };

  const haveClassificationsChanged = (initialClassifications: string[], updatedClassifications: string[]) => {
    return initialClassifications?.some((classification, index) => {
      return classification !== updatedClassifications[index];
    });
  };

  if (usedLowerTierParticipationsError || usedSmallBusinessClientsError || usedCompaniesError) {
    if (usedLowerTierParticipationsError) {
      console.error(usedLowerTierParticipationsError);
    } else if (usedCompaniesError) {
      console.error(usedCompaniesError);
    } else {
      console.error(usedSmallBusinessClientsError);
    }
    displayToast(
      'Error: Something went wrong while fetching data using the agency. If the problem persists after reloading the page, please contact support.',
      'error',
    );
  }
  if (usedLowerTierParticipationsError || usedSmallBusinessClientsError || usedCompaniesError) {
    return <ErrorNotice />;
  }

  const isFormValid = () => {
    return (
      smallBusinessAgencyName?.trim() !== '' &&
      smallBusinessAgencyAbbreviation?.trim() !== '' &&
      smallBusinessClassifications &&
      smallBusinessAgencyRequirement !== undefined
    );
  };

  const generateDialogTitle = () => {
    if (smallBusinessAgencyDialogView === EDIT_AGENCY) {
      return `Editing ${smallBusinessAgencyData?.name}`;
    }

    if (smallBusinessAgencyDialogView === ADD_AGENCY) {
      return 'Add New Agency';
    }

    if (smallBusinessAgencyDialogView === DELETE_AGENCY) {
      return `Delete ${smallBusinessAgencyData?.name}`;
    }

    if (smallBusinessAgencyDialogView === CONFIRM_CANCEL) {
      return 'Discard Agency';
    }
  };

  const generateDialogContent = () => {
    if (smallBusinessAgencyDialogView === CONFIRM_CANCEL) {
      return (
        <>
          <Grid container>
            <Grid item>
              <Typography className="margin-bottom">
                {'Are you sure you want to cancel? All information will be lost.'}
              </Typography>
            </Grid>
          </Grid>
        </>
      );
    }

    if (smallBusinessAgencyDialogView === DELETE_AGENCY) {
      return (
        <>
          <Grid container>
            {(!usedLowerTierParticipationsLoading || !usedSmallBusinessClientsLoading || !usedCompaniesLoading) &&
              !lowerTierParticipationsInUse?.length &&
              !smallBusinessClientsInUse?.length &&
              !companiesInUse?.length && (
                <Grid item>
                  <Typography className="margin-bottom">
                    {`Are you sure you want to delete the agency: ${smallBusinessAgencyData.name}? This action cannot be undone.`}
                  </Typography>
                </Grid>
              )}
            <Grid container justifyContent="space-between">
              {(usedLowerTierParticipationsLoading || usedSmallBusinessClientsLoading || usedCompaniesLoading) && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography>Loading...</Typography>
                </Grid>
              )}
              {(!usedLowerTierParticipationsLoading || !usedSmallBusinessClientsLoading || !usedCompaniesLoading) &&
                (!!lowerTierParticipationsInUse?.length ||
                  !!smallBusinessClientsInUse?.length ||
                  !!companiesInUse?.length) && (
                  <Box sx={dataInUseContainerStyle}>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12} sx={unableToDeleteMessageStyles}>
                        <Typography>Unable to delete the agency as it is being used by the following:</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        {!!lowerTierParticipationsInUse?.length && (
                          <List>
                            <Grid item>
                              <Typography sx={dataInUseLabelStyle}>Lower Tier Participations:</Typography>
                            </Grid>
                            {lowerTierParticipationsInUse?.map((lowerTierParticipation) => (
                              <ListItem key={lowerTierParticipation.id}>
                                {lowerTierParticipation?.lowerTierCompanyName +
                                  ` (Project: ${lowerTierParticipation.contract.project.number} - ${lowerTierParticipation.contract.project.name})`}
                              </ListItem>
                            ))}
                          </List>
                        )}
                        {!!smallBusinessClientsInUse?.length && (
                          <List>
                            <Grid item>
                              <Typography sx={dataInUseLabelStyle}>Small Business Clients:</Typography>
                            </Grid>
                            {smallBusinessClientsInUse?.map((smallBusinessClient) => (
                              <ListItem key={smallBusinessClient.id}>{smallBusinessClient?.name}</ListItem>
                            ))}
                          </List>
                        )}
                        {!!companiesInUse?.length && (
                          <List>
                            <Grid item>
                              <Typography sx={dataInUseLabelStyle}>Companies:</Typography>
                            </Grid>
                            {companiesInUse?.map((company) => (
                              <ListItem key={company.id}>{company?.name}</ListItem>
                            ))}
                          </List>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                )}
            </Grid>
          </Grid>
        </>
      );
    }

    if ((smallBusinessAgencyDialogView === EDIT_AGENCY && isEditing) || smallBusinessAgencyDialogView === ADD_AGENCY) {
      return (
        <Grid container>
          <Grid item xs={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={8}>
                <Grid item xs={8}>
                  <InputLabel sx={nameInputLabelStyle} shrink>
                    Agency Name
                  </InputLabel>
                  <Controller
                    render={({ field }) => (
                      <TextField {...field} variant="outlined" margin="dense" fullWidth size="small" />
                    )}
                    name="name"
                    control={control}
                    rules={{ required: true }}
                  />
                  <Grid sx={errorMessageStyle}>
                    {!smallBusinessAgencyName?.trim() ? 'Agency name is required' : null}
                  </Grid>
                </Grid>
                <Grid item>
                  <InputLabel sx={abbreviationInputLabelStyle} shrink>
                    Abbreviation
                  </InputLabel>
                  <Controller
                    render={({ field }) => <TextField {...field} variant="outlined" margin="dense" size="small" />}
                    name="abbreviation"
                    control={control}
                    rules={{ required: true }}
                  />
                  <Grid sx={errorMessageStyle}>
                    {!smallBusinessAgencyAbbreviation?.trim() ? 'Agency abbreviation is required' : null}
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={8}>
                <Grid item xs={4}>
                  <InputLabel sx={nameInputLabelStyle} shrink>
                    Participation Percentage
                  </InputLabel>
                  <Controller
                    render={({ field }) => (
                      <OutlinedInput
                        {...field}
                        id="participation-percentage"
                        size="small"
                        type="number"
                        endAdornment={<InputAdornment position="end">%</InputAdornment>}
                      />
                    )}
                    name="participationPercentage"
                    control={control}
                  />
                </Grid>
                {smallBusinessAgencyDialogView === EDIT_AGENCY && (
                  <Grid item>
                    <Grid container>
                      <InputLabel sx={statusLabelStyle} shrink>
                        Agency Status:
                      </InputLabel>
                      <ActiveOrInactiveStatusContent isActive={smallBusinessAgencyStatus} />
                    </Grid>
                    <Controller
                      render={({ field }) => (
                        <Switch
                          {...field}
                          defaultChecked
                          checked={smallBusinessAgencyStatus}
                          color="primary"
                          size="small"
                        />
                      )}
                      name="isActive"
                      control={control}
                    />
                  </Grid>
                )}
                {smallBusinessAgencyDialogView === ADD_AGENCY && (
                  <Grid item>
                    <InputLabel sx={requirementLabelStyle} shrink>
                      SB Requirement
                    </InputLabel>
                    <Controller
                      render={({ field }) => {
                        return (
                          <RadioGroup {...field}>
                            <Grid container direction="row" spacing={2}>
                              <Grid item>
                                <FormControlLabel
                                  value={'any/all'}
                                  control={<Radio size="small" color="primary" />}
                                  label={
                                    <Typography sx={radioLabelStyle}>
                                      {SmallBusinessAdminRequirementOption.ANY_ALL}
                                    </Typography>
                                  }
                                  onChange={(event: any) => {
                                    handleRequirementChange(event);
                                  }}
                                />
                              </Grid>
                              <Grid item>
                                <FormControlLabel
                                  value={true}
                                  control={<Radio size="small" color="primary" />}
                                  label={
                                    <Typography sx={radioLabelStyle}>
                                      {SmallBusinessAdminRequirementOption.FEDERAL}
                                    </Typography>
                                  }
                                  onChange={(event: any) => {
                                    handleRequirementChange(event);
                                  }}
                                />
                              </Grid>
                              <Grid item>
                                <FormControlLabel
                                  value={false}
                                  control={<Radio size="small" color="primary" />}
                                  label={
                                    <Typography sx={radioLabelStyle}>
                                      {SmallBusinessAdminRequirementOption.NON_FEDERAL}
                                    </Typography>
                                  }
                                  onChange={(event: any) => {
                                    handleRequirementChange(event);
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </RadioGroup>
                        );
                      }}
                      name="isFederal"
                      control={control}
                      rules={{ required: smallBusinessAgencyRequirement === undefined }}
                    />
                    <Grid sx={errorMessageStyle}>
                      {smallBusinessAgencyRequirement === undefined ? 'SB Requirement is required' : null}
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12}>
                <InputLabel sx={specialNoticeInputLabelStyle} shrink>
                  Special Notice
                </InputLabel>
                <Controller
                  render={({ field }) => (
                    <TextField {...field} variant="outlined" margin="dense" fullWidth size="small" multiline rows={2} />
                  )}
                  name="specialNotice"
                  control={control}
                />
              </Grid>
              <Grid item sx={inputContainer}>
                <InputLabel sx={classificationLabelStyle} shrink>
                  Classifications
                </InputLabel>
                <Controller
                  name={'agencyAndClassifications'}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Grid item xs={12} sx={classificationChipStyles}>
                      <Autocomplete
                        {...field}
                        onChange={(_: any, classificationIds: string[] | undefined) => {
                          setValue('agencyAndClassifications', classificationIds);
                          classificationIds && setClassifications(classificationIds);
                          field.onChange(classificationIds);
                        }}
                        id="combo-box-classifications"
                        multiple
                        options={classificationList.map((classification: { id: any }) => classification.id) ?? []}
                        filterSelectedOptions
                        getOptionLabel={(option: string) => {
                          const classification = smallBusinessClassificationsById[option];
                          return `${classification.abbreviation} (${classification.name})`;
                        }}
                        renderTags={(value: string[], getTagProps: (arg0: { index: any }) => any) => {
                          const classifications = value
                            .map((option: SmallBusinessClassification['id']) => {
                              return smallBusinessClassificationsById[option];
                            })
                            .sort((a, b) => a.name.localeCompare(b.name));

                          return classifications?.map((classification, index) => {
                            return (
                              <Tooltip arrow title={`${classification.name}`} key={classification.id}>
                                <div key={index}>
                                  <Chip
                                    variant="outlined"
                                    label={classification.abbreviation}
                                    {...getTagProps({ index })}
                                    disabled={
                                      lowerTierParticipationsByClassificationAbbr[classification?.abbreviation] ||
                                      companiesByClassificationAbbr[classification.abbreviation]
                                    }
                                    onDelete={() => handleDeleteClassifications(classification.id)}
                                  />
                                </div>
                              </Tooltip>
                            );
                          });
                        }}
                        renderInput={(params: any) => <TextField {...params} sx={textFieldStyles} />}
                        size="small"
                      />
                      <Grid sx={errorMessageStyle}>
                        {!smallBusinessClassifications?.length ? 'Agency classification(s) are required' : null}
                      </Grid>
                    </Grid>
                  )}
                />
              </Grid>
              <Grid item sx={inputContainer}>
                <InputLabel sx={nameInputLabelStyle} shrink>
                  Certification Documentation URL
                </InputLabel>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      size="small"
                      placeholder="https://www.example.com"
                    />
                  )}
                  name="url"
                  control={control}
                  rules={{ required: false }}
                />
              </Grid>
              <Grid item sx={inputContainer}>
                {!usedLowerTierParticipationsLoading &&
                  !usedCompaniesLoading &&
                  (lowerTierParticipationsInUse.length !== 0 || companiesInUse.length !== 0) && (
                    <Box sx={dataInUseContainerStyle}>
                      <StyledNotice
                        type={NoticeTypeOption.Notice}
                        message={
                          <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12} sx={unableToDeleteMessageStyles}>
                              <Typography>
                                Unable to edit the agency and/or classification(s) as it is being used by the following:
                              </Typography>
                            </Grid>
                            {lowerTierParticipationsInUse.length !== 0 && (
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <List>
                                  <Grid item>
                                    <Typography sx={dataInUseLabelStyle}>
                                      Lower Tier Participations using classifications:
                                    </Typography>
                                  </Grid>
                                  {Object.entries(lowerTierParticipationsByClassificationAbbr)?.map(
                                    (lowerTierParticipations) => {
                                      const classificationAbbrIndex = 0;
                                      const lowerTierParticipationSummaryIndex = 1;
                                      const classificationAbbreviationsInUse =
                                        lowerTierParticipations[classificationAbbrIndex];
                                      const filteredLowerTierParticipations = lowerTierParticipations[
                                        lowerTierParticipationSummaryIndex
                                      ].filter(
                                        (lowerTierParticipation: LowerTierParticipation, index: number) =>
                                          lowerTierParticipations[lowerTierParticipationSummaryIndex].indexOf(
                                            lowerTierParticipation,
                                          ) === index,
                                      );
                                      const lowerTierParticipationSummary = filteredLowerTierParticipations.map(
                                        (lowerTierParticipation: LowerTierParticipation) => {
                                          return (
                                            <ListItem key={lowerTierParticipation.id}>
                                              {lowerTierParticipation.lowerTierCompanyName +
                                                ` (Project: ${lowerTierParticipation.contract.project.number} - ${lowerTierParticipation.contract.project.name})`}
                                            </ListItem>
                                          );
                                        },
                                      );
                                      return (
                                        <ListItemText
                                          primary={classificationAbbreviationsInUse}
                                          secondary={lowerTierParticipationSummary}
                                        />
                                      );
                                    },
                                  )}
                                </List>
                              </Grid>
                            )}
                            {companiesInUse.length !== 0 && (
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <List>
                                  <Grid item>
                                    <Typography sx={dataInUseLabelStyle}>Companies using classifications:</Typography>
                                  </Grid>
                                  {Object.entries(companiesByClassificationAbbr)?.map((companies) => {
                                    const classificationAbbrIndex = 0;
                                    const companyIndex = 1;
                                    const classificationAbbreviationsInUse = companies[classificationAbbrIndex];
                                    const filteredCompanies = companies[companyIndex].filter(
                                      (company: Company, index: number) =>
                                        companies[companyIndex].indexOf(company) === index,
                                    );
                                    const companySummary = filteredCompanies.map((company: Company) => {
                                      return <ListItem key={company.id}>{company.name}</ListItem>;
                                    });
                                    return (
                                      <ListItemText
                                        primary={classificationAbbreviationsInUse}
                                        secondary={companySummary}
                                      />
                                    );
                                  })}
                                </List>
                              </Grid>
                            )}
                          </Grid>
                        }
                      />
                    </Box>
                  )}
              </Grid>
            </form>
          </Grid>
        </Grid>
      );
    }
  };

  const onSubmit: SubmitHandler<EditSmallBusinessAgencyInput> = (data: any) => {
    const validUrl = () => {
      if (data.url) {
        if (data.url.includes('http') || data.url.includes('https')) {
          return data.url;
        } else {
          return 'http://' + data.url;
        }
      }
      return data.url;
    };

    const isFederal =
      data.isFederal === 'true'
        ? true
        : data.isFederal === 'false'
        ? false
        : data.isFederal === 'any/all'
        ? null
        : undefined;

    const percentageAsNumber = parseFloat(data.participationPercentage);

    if (smallBusinessAgencyDialogView === ADD_AGENCY) {
      createSmallBusinessAgency({
        variables: {
          input: {
            transactionKey,
            name: data.name,
            abbreviation: data.abbreviation.toUpperCase(),
            smallBusinessClassificationIds: smallBusinessClassifications ?? [],
            url: validUrl() ?? null,
            isFederal,
            isActive: true,
            participationPercentage: percentageAsNumber,
            specialNotice: data.specialNotice ?? '',
          },
        },
      })
        .then(() => {
          handleClose();
          apolloClient.reFetchObservableQueries();
          displayToast('The agency was added successfully', 'success');
        })
        .catch((error: any) => {
          console.error('Add agency failed: ', error);
          displayToast(
            'Error: Something went wrong while trying to add the agency. Please try again. If the problem persists, please contact support.',
            'error',
          );
        });
    }

    if (smallBusinessAgencyDialogView === EDIT_AGENCY) {
      updateSmallBusinessAgency({
        variables: {
          input: {
            id: smallBusinessAgencyData?.id,
            name: data.name,
            abbreviation: data.abbreviation,
            smallBusinessClassificationIds: smallBusinessClassifications ?? [],
            url: validUrl() ?? '',
            isFederal,
            isActive: data.isActive,
            participationPercentage: percentageAsNumber,
            specialNotice: data.specialNotice ?? '',
          },
        },
      })
        .then(() => {
          handleClose();
          apolloClient.reFetchObservableQueries();
          displayToast('The agency was updated successfully', 'success');
        })
        .catch((error: any) => {
          console.error('Update agency failed: ', error);
          displayToast(
            'Error: Something went wrong while trying to update the agency. Please try again. If the problem persists, please contact support.',
            'error',
          );
        });
    }
  };

  const handleDeleteSmallBusinessAgency = () => {
    deleteSmallBusinessAgency({
      variables: { id: smallBusinessAgencyData.id },
    })
      .then(() => {
        handleClose();
        apolloClient.reFetchObservableQueries();
        displayToast('The agency was deleted successfully', 'success');
      })
      .catch((error: any) => {
        console.error('Delete agency failed: ', error);
        displayToast(
          'Error: Something went wrong while trying to delete the agency. Please try again. If the problem persists, please contact support.',
          'error',
        );
      });
  };

  const generateActions = () => {
    if (smallBusinessAgencyDialogView === DELETE_AGENCY) {
      return (
        <>
          <Grid item>
            <StyledButtonSecondary
              label={'Cancel'}
              disabled={isLoading}
              onClick={() => {
                setSmallBusinessAgencyDialogView(undefined);
                handleClose();
              }}
            />
          </Grid>
          <Grid item>
            <StyledButtonPrimary
              variant="contained"
              color="primary"
              onClick={() => handleDeleteSmallBusinessAgency()}
              label={'Delete'}
              disabled={
                isLoading ||
                lowerTierParticipationsInUse?.length !== 0 ||
                smallBusinessClientsInUse?.length !== 0 ||
                companiesInUse?.length !== 0
              }
            />
          </Grid>
        </>
      );
    }

    if (smallBusinessAgencyDialogView === EDIT_AGENCY) {
      return (
        <>
          <Grid container justifyContent={'space-between'}>
            <Grid item>
              <StyledButtonSecondary
                label={'Cancel'}
                disabled={isLoading}
                onClick={() => {
                  setSmallBusinessAgencyDialogView(undefined);
                  handleClose();
                }}
              />
            </Grid>
            <Grid item>
              <StyledButtonPrimary
                variant="contained"
                color="primary"
                onClick={handleSubmit(onSubmit)}
                label={'Save'}
                disabled={
                  !isFormValid() ||
                  isLoading ||
                  (!isDirty && !haveClassificationsChanged(agencySupportedClassifications, classifications))
                }
              />
            </Grid>
          </Grid>
        </>
      );
    }

    if (smallBusinessAgencyDialogView === ADD_AGENCY) {
      return (
        <>
          <Grid container justifyContent="space-between">
            <Grid item>
              <StyledButtonSecondary
                label={'Cancel'}
                disabled={isLoading}
                onClick={() => {
                  setSmallBusinessAgencyDialogView(undefined);
                  handleClose();
                }}
              />
            </Grid>
            <Grid item>
              <StyledButtonPrimary
                label={'Submit'}
                disabled={!isFormValid() || isLoading}
                onClick={handleSubmit(onSubmit)}
              />
            </Grid>
          </Grid>
        </>
      );
    }
  };

  return (
    <StyledDialog
      title={generateDialogTitle()}
      content={generateDialogContent()}
      actions={generateActions()}
      isOpen={isOpen}
      handleClose={handleClose}
      fullWidth={true}
      disableEscapeKeyDown={true}
    />
  );
};

export default SmallBusinessAgencyDialog;
