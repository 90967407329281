//import { gql } from '@apollo/client';

// https://www.apollographql.com/docs/react/local-state/client-side-schema/

// export const exampleTypeDefs = gql`
//   extend type Query {
//     isLoggedIn: Boolean!
//     cartItems: [Launch]!
//   }

//   extend type Launch {
//     isInCart: Boolean!
//   }

//   extend type Mutation {
//     addOrRemoveFromCart(id: ID!): [Launch]
//   }
// `;

const typeDefs = undefined;

export default typeDefs;
