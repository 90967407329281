import { FederalSmallBusinessClassificationName } from 'constants/classificationNames';
import PropTypes from 'prop-types';

import { SxProps, Theme, useTheme } from '@mui/material';

import SmallBusinessClassificationDisplayName from '../SmallBusinessClassificationDisplayName';
import { SmallBusinessClassificationNameProps } from '../SmallBusinessClassificationDisplayName/SmallBusinessClassificationDisplayName';

const classificationNameLevel1: SxProps<Theme> = (theme: Theme) => ({
  paddingLeft: theme.spacing(0),
  fontWeight: 'bold',
});

const classificationNameLevel2: SxProps<Theme> = (theme: Theme) => ({
  paddingLeft: theme.spacing(2),
  fontWeight: 'normal',
});

const classificationNameLevel3: SxProps<Theme> = (theme: Theme) => ({
  paddingLeft: theme.spacing(4),
  fontStyle: 'italic',
});

type FederalSmallBusinessClassificationNameProps = SmallBusinessClassificationNameProps & {
  indent?: boolean;
};

const getIndentationStyles = (classificationName: string, theme: Theme) => {
  switch (classificationName) {
    case FederalSmallBusinessClassificationName.LB:
    case FederalSmallBusinessClassificationName.SB:
    case FederalSmallBusinessClassificationName.IC:
      return classificationNameLevel1(theme);
    case FederalSmallBusinessClassificationName.SDVOSB:
      return classificationNameLevel3(theme);
    default:
      return classificationNameLevel2(theme);
  }
};

const getBoldedStyles = (classificationName: string, theme: Theme) => {
  switch (classificationName) {
    case FederalSmallBusinessClassificationName.LB:
    case FederalSmallBusinessClassificationName.DBT:
    case FederalSmallBusinessClassificationName.IC:
      return classificationNameLevel1(theme);
  }
};

export const FederalSmallBusinessClassificationDisplayName: React.FC<FederalSmallBusinessClassificationNameProps> = ({
  classification,
  displayFormat,
  displayFormatExtension,
  tooltipFormat,
  tooltipFormatExtension,
  indent = false,
  noWrap,
  typographyClassNames,
}) => {
  let typographyStyles = {};
  const theme = useTheme();
  if (indent) {
    typographyStyles = { ...getIndentationStyles(classification.name, theme) };
  } else {
    typographyStyles = { ...getBoldedStyles(classification.name, theme) };
  }
  if (typographyClassNames) {
    typographyStyles = { ...typographyClassNames };
  }
  return (
    <SmallBusinessClassificationDisplayName
      classification={classification}
      displayFormat={displayFormat}
      displayFormatExtension={displayFormatExtension}
      tooltipFormat={tooltipFormat}
      tooltipFormatExtension={tooltipFormatExtension}
      noWrap={noWrap}
      typographyClassNames={typographyStyles}
    />
  );
};

FederalSmallBusinessClassificationDisplayName.propTypes = {
  ...SmallBusinessClassificationDisplayName.propTypes,
  indent: PropTypes.bool,
};

export default FederalSmallBusinessClassificationDisplayName;
