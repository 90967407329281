import ActiveOrInactiveStatusContent from 'components/shared/ActiveOrInactiveStatusContent';
import StyledButtonPrimary from 'components/shared/ButtonPrimary';
import StyledButtonSecondary from 'components/shared/ButtonSecondary';
import StyledDialog from 'components/shared/Dialog';
import ErrorNotice from 'components/shared/ErrorNotice';
import { CLIENT_DIALOG_VIEWS } from 'constants/adminDialogViews';
import { SmallBusinessAdminRequirementOption } from 'constants/smallBusinessRequirementOptions';
import useToast from 'hooks/useToast';
import { FC, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  NestedSmallBusinessClientAgency,
  NestedSmallBusinessClientClassification,
  useDeleteSmallBusinessClientMutation,
  useGetProjectsInUseQuery,
  useUpsertSmallBusinessClientMutation,
} from 'types/generated/graphql';
import { generateTransactionKey } from 'utils/general';

import { useApolloClient } from '@apollo/client';
import {
  Box,
  FormControlLabel,
  Grid,
  InputLabel,
  List,
  ListItem,
  Radio,
  RadioGroup,
  Switch,
  SxProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material';

type SmallBusinessClientDialogProps = {
  isOpen: boolean;
  setIsOpen: (x: boolean) => void;
  smallBusinessClientData: any;
  setSmallBusinessClientDialogView: any;
  smallBusinessClientDialogView: string;
  handleClose: (x: any) => void;
};

const nameInputLabelStyle: SxProps<Theme> = (theme: Theme) => ({
  marginBottom: '-8px',
  marginTop: '5px',
  color: theme.palette.secondary.contrastText,
});

const abbreviationInputLabelStyle: SxProps<Theme> = (theme: Theme) => ({
  marginBottom: '-15px',
  marginTop: '15px',
  color: theme.palette.secondary.contrastText,
});

const errorMessageStyle: SxProps<Theme> = {
  color: '#cd0000',
  marginLeft: '14px',
  marginTop: '4px',
  fontSize: '.75rem',
};

const radioLabelStyle: SxProps<Theme> = {
  fontSize: '0.875rem',
};

const unableToDeleteMessageStyles: SxProps<Theme> = {
  marginBottom: '20px',
};

const dataInUseLabelStyle: SxProps<Theme> = {
  fontWeight: 'bold',
};

const dataInUseContainerStyle: SxProps<Theme> = {
  maxHeight: '35vh',
  overflow: 'auto',
};

type EditSmallBusinessClientInput = {
  name: string;
  abbreviation?: string;
  isActive: boolean;
  isFederal?: boolean;
};

const SmallBusinessClientDialog: FC<SmallBusinessClientDialogProps> = ({
  isOpen,
  setIsOpen,
  smallBusinessClientData,
  setSmallBusinessClientDialogView,
  smallBusinessClientDialogView,
}) => {
  const { displayToast } = useToast();
  const [transactionKey, setTransactionKey] = useState(generateTransactionKey());
  const [isEditing, setIsEditing] = useState(false);
  const apolloClient = useApolloClient();
  const { EDIT_CLIENT, ADD_CLIENT, DELETE_CLIENT, CONFIRM_CANCEL } = CLIENT_DIALOG_VIEWS;

  useEffect(() => {
    if (smallBusinessClientDialogView === EDIT_CLIENT) {
      setIsEditing(true);
    }
  }, [EDIT_CLIENT, smallBusinessClientDialogView]);

  const [createSmallBusinessClient] = useUpsertSmallBusinessClientMutation();
  const [updateSmallBusinessClient, { loading: isLoading }] = useUpsertSmallBusinessClientMutation();
  const [deleteSmallBusinessClient] = useDeleteSmallBusinessClientMutation();

  const {
    loading: usedProjectsLoading,
    error: usedProjectsError,
    data: usedProjectsData,
  } = useGetProjectsInUseQuery({
    client: apolloClient,
    variables: {
      smallBusinessClientId: smallBusinessClientData?.id,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    skip: typeof smallBusinessClientData?.id !== 'string',
  });

  const projectsInUse = usedProjectsData?.getProjectsInUse ?? [];

  const defaultValues = {
    name: smallBusinessClientData?.name ?? '',
    abbreviation: smallBusinessClientData?.abbreviation ?? '',
    agencyAndClassifications: smallBusinessClientData?.agencyAndClassifications ?? [],
    isActive: smallBusinessClientData?.isActive,
    isFederal: smallBusinessClientData?.isFederal === null ? 'any/all' : smallBusinessClientData?.isFederal,
  };

  const { handleSubmit, control, reset, watch, formState } = useForm<EditSmallBusinessClientInput>({
    defaultValues: defaultValues,
  });

  const { isDirty } = formState;

  const smallBusinessClientName = watch('name');
  const smallBusinessClientStatus = watch('isActive');
  const smallBusinessClientRequirement = watch('isFederal');

  const handleClose = () => {
    setIsOpen(false);
    setIsEditing(false);
    reset(defaultValues);
    setTransactionKey(generateTransactionKey());
  };

  if (usedProjectsError) {
    console.error(usedProjectsError);
    displayToast(
      'Error: Something went wrong while fetching projects used by the client. If the problem persists after reloading the page, please contact support.',
      'error',
    );
  }
  if (usedProjectsError) {
    return <ErrorNotice />;
  }

  const isFormValid = () => {
    return smallBusinessClientName?.trim() !== '' && smallBusinessClientRequirement !== undefined;
  };

  const generateDialogTitle = () => {
    if (smallBusinessClientDialogView === EDIT_CLIENT) {
      return `Editing ${smallBusinessClientData?.name}`;
    }

    if (smallBusinessClientDialogView === ADD_CLIENT) {
      return 'Add New Client';
    }

    if (smallBusinessClientDialogView === DELETE_CLIENT) {
      return `Delete ${smallBusinessClientData?.name}`;
    }

    if (smallBusinessClientDialogView === CONFIRM_CANCEL) {
      return 'Discard Client';
    }
  };

  const generateDialogContent = () => {
    if (smallBusinessClientDialogView === CONFIRM_CANCEL) {
      return (
        <>
          <Grid container>
            <Grid item>
              <Typography className="margin-bottom">
                {'Are you sure you want to cancel? All information will be lost.'}
              </Typography>
            </Grid>
          </Grid>
        </>
      );
    }

    if (smallBusinessClientDialogView === DELETE_CLIENT) {
      return (
        <>
          <Grid container>
            {!usedProjectsLoading && !projectsInUse?.length && (
              <Grid item>
                <Typography className="margin-bottom">
                  {`Are you sure you want to delete the client: ${smallBusinessClientData.name}? This action cannot be undone.`}
                </Typography>
              </Grid>
            )}
            <Grid container justifyContent="space-between">
              {usedProjectsLoading && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography>Loading...</Typography>
                </Grid>
              )}
              {!usedProjectsLoading && !!projectsInUse?.length && (
                <Box sx={dataInUseContainerStyle}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12} sx={unableToDeleteMessageStyles}>
                      <Typography>Unable to delete the client as it is being used by the following:</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <List>
                        <Grid item>
                          <Typography sx={dataInUseLabelStyle}>Projects:</Typography>
                        </Grid>
                        {projectsInUse?.map((project) => (
                          <ListItem key={project.id}>{project.number + ' - ' + project.name}</ListItem>
                        ))}
                      </List>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Grid>
          </Grid>
        </>
      );
    }

    if ((smallBusinessClientDialogView === EDIT_CLIENT && isEditing) || smallBusinessClientDialogView === ADD_CLIENT) {
      return (
        <Grid container>
          <Grid item xs={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={6}>
                <Grid item xs={8}>
                  <InputLabel sx={nameInputLabelStyle} shrink>
                    Name
                  </InputLabel>
                  <Controller
                    render={({ field }) => (
                      <TextField {...field} variant="outlined" fullWidth margin="dense" size="small" />
                    )}
                    name="name"
                    control={control}
                    rules={{ required: true }}
                  />
                  <Grid sx={errorMessageStyle}>{!smallBusinessClientName?.trim() ? 'Name is required' : null}</Grid>
                </Grid>
                {smallBusinessClientDialogView === EDIT_CLIENT && (
                  <Grid item>
                    <Grid container>
                      <InputLabel sx={nameInputLabelStyle} shrink>
                        Agency Status:
                      </InputLabel>
                      <ActiveOrInactiveStatusContent isActive={smallBusinessClientStatus} />
                    </Grid>
                    <Controller
                      render={({ field }) => (
                        <Switch
                          {...field}
                          defaultChecked
                          checked={smallBusinessClientStatus}
                          color="primary"
                          size="small"
                        />
                      )}
                      name="isActive"
                      control={control}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid item>
                <InputLabel sx={abbreviationInputLabelStyle} shrink>
                  Abbreviation
                </InputLabel>
                <Controller
                  render={({ field }) => <TextField {...field} variant="outlined" margin="dense" size="small" />}
                  name="abbreviation"
                  control={control}
                />
              </Grid>
              {smallBusinessClientDialogView === ADD_CLIENT && (
                <Grid item>
                  <Grid container>
                    <InputLabel sx={abbreviationInputLabelStyle} shrink>
                      SB Requirement
                    </InputLabel>
                  </Grid>
                  <Controller
                    render={({ field }) => {
                      return (
                        <RadioGroup {...field}>
                          <Grid container direction="row" spacing={2}>
                            <Grid item>
                              <FormControlLabel
                                value={'any/all'}
                                control={<Radio size="small" color="primary" />}
                                label={
                                  <Typography sx={radioLabelStyle}>
                                    {SmallBusinessAdminRequirementOption.ANY_ALL}
                                  </Typography>
                                }
                              />
                            </Grid>
                            <Grid item>
                              <FormControlLabel
                                value={true}
                                control={<Radio size="small" color="primary" />}
                                label={
                                  <Typography sx={radioLabelStyle}>
                                    {SmallBusinessAdminRequirementOption.FEDERAL}
                                  </Typography>
                                }
                              />
                            </Grid>
                            <Grid item>
                              <FormControlLabel
                                value={false}
                                control={<Radio size="small" color="primary" />}
                                label={
                                  <Typography sx={radioLabelStyle}>
                                    {SmallBusinessAdminRequirementOption.NON_FEDERAL}
                                  </Typography>
                                }
                              />
                            </Grid>
                          </Grid>
                        </RadioGroup>
                      );
                    }}
                    name="isFederal"
                    control={control}
                    rules={{ required: smallBusinessClientRequirement === undefined }}
                  />
                  <Grid sx={errorMessageStyle}>
                    {smallBusinessClientRequirement === undefined ? 'SB Requirement is required' : null}
                  </Grid>
                </Grid>
              )}
            </form>
          </Grid>
        </Grid>
      );
    }
  };

  const clientAgencyAndClassifications = smallBusinessClientData?.agencyAndClassifications?.map(
    (agencyAndClassification: any) => {
      return {
        smallBusinessAgencyId: agencyAndClassification.smallBusinessAgency.id ?? '',
        smallBusinessClientClassifications:
          (agencyAndClassification.smallBusinessClientClassifications.map((classification: any) => {
            return { smallBusinessClassificationId: classification.smallBusinessClassification.id };
          }) as NestedSmallBusinessClientClassification[]) ?? [],
      };
    },
  ) as NestedSmallBusinessClientAgency[];

  const onSubmit: SubmitHandler<EditSmallBusinessClientInput> = (data: any) => {
    const isFederal =
      data.isFederal === 'true'
        ? true
        : data.isFederal === 'false'
        ? false
        : data.isFederal === 'any/all'
        ? null
        : undefined;
    if (smallBusinessClientDialogView === ADD_CLIENT) {
      createSmallBusinessClient({
        variables: {
          input: {
            transactionKey,
            name: data.name,
            abbreviation: data.abbreviation.toUpperCase() ?? '',
            smallBusinessClientAgencies: [],
            isActive: true,
            isFederal,
          },
        },
      })
        .then(() => {
          handleClose();
          apolloClient.reFetchObservableQueries();
          displayToast('The client was added successfully', 'success');
        })
        .catch((error: any) => {
          console.error('Add client failed: ', error);
          displayToast(
            'Error: Something went wrong while trying to add the client. Please try again. If the problem persists, please contact support.',
            'error',
          );
        });
    }

    if (smallBusinessClientDialogView === EDIT_CLIENT) {
      updateSmallBusinessClient({
        variables: {
          input: {
            id: smallBusinessClientData.id,
            name: data.name,
            abbreviation: data.abbreviation.toUpperCase() ?? '',
            smallBusinessClientAgencies: clientAgencyAndClassifications ?? [],
            isFederal,
            isActive: data.isActive,
          },
        },
      })
        .then(() => {
          handleClose();
          apolloClient.reFetchObservableQueries();
          displayToast('The client was updated successfully', 'success');
        })
        .catch((error: any) => {
          console.error('Update client failed: ', error);
          displayToast(
            'Error: Something went wrong while trying to update the client. Please try again. If the problem persists, please contact support.',
            'error',
          );
        });
    }
  };

  const handleDeleteSmallBusinessClient = () => {
    deleteSmallBusinessClient({
      variables: { id: smallBusinessClientData.id },
    })
      .then(() => {
        handleClose();
        apolloClient.reFetchObservableQueries();
        displayToast('The client was deleted successfully', 'success');
      })
      .catch((error: any) => {
        console.error('Delete client failed: ', error);
        displayToast(
          'Error: Something went wrong while trying to delete the client. Please try again. If the problem persists, please contact support.',
          'error',
        );
      });
  };

  const generateActions = () => {
    if (smallBusinessClientDialogView === DELETE_CLIENT) {
      return (
        <>
          <Grid item>
            <StyledButtonSecondary
              label={'Cancel'}
              disabled={isLoading}
              onClick={() => {
                setSmallBusinessClientDialogView(undefined);
                handleClose();
              }}
            />
          </Grid>
          <Grid item>
            <StyledButtonPrimary
              variant="contained"
              color="primary"
              onClick={() => handleDeleteSmallBusinessClient()}
              label={'Delete'}
              disabled={isLoading || projectsInUse.length !== 0}
            />
          </Grid>
        </>
      );
    }

    if (smallBusinessClientDialogView === EDIT_CLIENT) {
      return (
        <>
          <Grid container justifyContent={'space-between'}>
            <Grid item>
              <StyledButtonSecondary
                label={'Cancel'}
                disabled={isLoading}
                onClick={() => {
                  setSmallBusinessClientDialogView(undefined);
                  handleClose();
                }}
              />
            </Grid>
            <Grid item>
              <StyledButtonPrimary
                variant="contained"
                color="primary"
                onClick={handleSubmit(onSubmit)}
                label={'Save'}
                disabled={!isFormValid() || isLoading || !isDirty}
              />
            </Grid>
          </Grid>
        </>
      );
    }

    if (smallBusinessClientDialogView === ADD_CLIENT) {
      return (
        <>
          <Grid container justifyContent="space-between">
            <Grid item>
              <StyledButtonSecondary
                label={'Cancel'}
                disabled={isLoading}
                onClick={() => {
                  setSmallBusinessClientDialogView(undefined);
                  handleClose();
                }}
              />
            </Grid>
            <Grid item>
              <StyledButtonPrimary
                label={'Submit'}
                disabled={!isFormValid() || isLoading}
                onClick={handleSubmit(onSubmit)}
              />
            </Grid>
          </Grid>
        </>
      );
    }
  };

  return (
    <StyledDialog
      title={generateDialogTitle()}
      content={generateDialogContent()}
      actions={generateActions()}
      isOpen={isOpen}
      handleClose={handleClose}
      fullWidth={true}
      disableEscapeKeyDown={true}
    />
  );
};

export default SmallBusinessClientDialog;
