import ActiveOrInactiveStatusContent from 'components/shared/ActiveOrInactiveStatusContent';
import BackToTopButton from 'components/shared/BackToTopButton';
import ErrorNotice from 'components/shared/ErrorNotice';
import LoadingSpinner from 'components/shared/LoadingSpinner/LoadingSpinner';
import SmallBusinessRequirementContent from 'components/shared/SmallBusinessRequirementContent';
import StyledTable from 'components/shared/Table/Table';
import { DISTRICT_NUMBERS } from 'constants/districts';
import {
  getSmallBusinessRequirementOptionFromValue,
  SmallBusinessRequirementOption,
} from 'constants/smallBusinessRequirementOptions';
import useDebounce from 'hooks/useDebounce';
import useRoles from 'hooks/useRoles';
import useTablePagination from 'hooks/useTablePagination';
import { MUIDataTableColumn, MUIDataTableOptions, MUIDataTableState } from 'mui-datatables';
import { FC, MouseEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useProjectListTableState } from 'recoil/projectListTableState';
import { ProjectListTableState } from 'recoil/projectListTableState/atom';
import { Project, useGetPaginatedProjectsQuery, useSmallBusinessClientListQuery } from 'types/generated/graphql';
import { getFilterByColumnName } from 'utils/muiDataTablesHelper';

import PermissionsIcon from '@mui/icons-material/VerifiedUser';
import { Grid, IconButton, SxProps, Theme, Tooltip, Typography } from '@mui/material';

const actionsContainer: SxProps<Theme> = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  [theme.breakpoints.down('md')]: { justifyContent: 'flex-start' },
});

const ProjectListPage: FC = () => {
  const [projectListTableState, { handleProjectListTableStateChange: handleTableStateChange }] =
    useProjectListTableState();
  const [initialState] = useState(projectListTableState);
  const history = useHistory();
  const { isEnterpriseAdmin } = useRoles();

  const { error, loading, data, fetchMore } = useGetPaginatedProjectsQuery({
    variables: {
      first: initialState.first,
      skip: initialState.skip,
      filter: initialState.filter,
      search: initialState.search,
      order: initialState.order,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });

  const { data: smallBusinessClientData } = useSmallBusinessClientListQuery({
    variables: {
      isActive: [true],
    },
  });

  const smallBusinessClientList =
    smallBusinessClientData?.smallBusinessClientList.reduce<{
      [id: string]: string;
    }>((accumulator, client) => {
      accumulator[client.id] = client.name + (client.isFederal ? ' (Federal)' : '');
      return accumulator;
    }, {}) ?? {};
  const getSmallBusinessClientOptionFromValue = (value?: string | null) =>
    value ? smallBusinessClientList[value] : '';

  const handleFilterChange = ({
    muiTableState,
    state,
  }: {
    muiTableState: MUIDataTableState;
    state: ProjectListTableState;
  }) => {
    const { filterList } = muiTableState;
    const smallBusinessRequirementFilter = getFilterByColumnName<Project>(muiTableState, 'smallBusinessRequirement');
    const isActiveFilter = getFilterByColumnName<Project>(muiTableState, 'isActive');
    const districtListFilter = getFilterByColumnName<Project>(muiTableState, 'district');
    const clientFilter = getFilterByColumnName<Project>(muiTableState, 'smallBusinessClientId');

    let selectedFilters = {};

    if (isActiveFilter.length > 0) {
      selectedFilters = {
        ...selectedFilters,
        isActive: isActiveFilter[0] === 'Active' ? true : false,
      };
    }

    if (smallBusinessRequirementFilter.length > 0) {
      selectedFilters = {
        ...selectedFilters,
        smallBusinessRequirement: smallBusinessRequirementFilter[0],
      };
    }

    if (districtListFilter.length > 0) {
      selectedFilters = {
        ...selectedFilters,
        districtList: {
          booleanOperator: 'OR',
          items: districtListFilter,
        },
      };
    }

    if (clientFilter.length > 0) {
      selectedFilters = {
        ...selectedFilters,
        smallBusinessClientId: clientFilter[0],
      };
    }

    handleTableStateChange({
      ...state,
      filter: selectedFilters,
      filterList,
      skip: 0,
      page: 0,
    });

    fetchMore({
      variables: {
        search: state.search,
        filter: selectedFilters,
        skip: 0,
        order: state.order,
      },
    });
  };

  const { debounced: debouncedHandleFilterChange } = useDebounce(handleFilterChange);

  const [projectListTableOptions] = useTablePagination({
    state: projectListTableState,
    handleTableStateChange: handleTableStateChange,
    fetchMore,
    handleFilterChange: debouncedHandleFilterChange,
  });

  const projects = data?.projectListResult.items ?? [];

  const handleManagePermissions = (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>, dataIndex: number) => {
    event.stopPropagation();
    event.preventDefault();
    const rowData = projects[dataIndex];
    if (!rowData) {
      throw new Error('Row data not found.');
    }
    return history.push(`/projects/${rowData.id}/permissions`);
  };

  const columns: MUIDataTableColumn[] = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: false,
      },
    },
    {
      name: 'number',
      label: 'Number',
      options: {
        filter: false,
      },
    },
    {
      name: 'district',
      label: 'Region',
      options: {
        filterType: 'multiselect',
        filterOptions: {
          names: DISTRICT_NUMBERS,
          fullWidth: true,
        },
        filterList: projectListTableState.filterList[3]?.length ? projectListTableState.filterList[3] : undefined,
        customFilterListOptions: { render: (value) => `Region: ${value}` },
      },
    },
    {
      name: 'city',
      label: 'Location',
      options: {
        filter: false,
      },
    },
    {
      name: 'smallBusinessRequirement',
      label: 'SB Requirement',
      options: {
        filterList: projectListTableState.filterList[5]?.length ? projectListTableState.filterList[5] : undefined,
        filterOptions: {
          names: Object.values(SmallBusinessRequirementOption),
          fullWidth: true,
          renderValue: getSmallBusinessRequirementOptionFromValue,
        },
        customFilterListOptions: {
          render: (value) => `SB Requirement: ${getSmallBusinessRequirementOptionFromValue(value)}`,
        },
        customBodyRenderLite: (dataIndex) => (
          <SmallBusinessRequirementContent value={projects[dataIndex]?.smallBusinessRequirement ?? ''} />
        ),
      },
    },
    {
      name: 'isActive',
      label: 'Status',
      options: {
        filterList: projectListTableState.filterList[6]?.length ? projectListTableState.filterList[6] : undefined,
        filterOptions: {
          names: ['Active', 'Inactive'],
          fullWidth: true,
        },
        customFilterListOptions: { render: (value) => `IsActive: ${value}` },
        customBodyRenderLite: (dataIndex) => <ActiveOrInactiveStatusContent isActive={projects[dataIndex]?.isActive} />,
      },
    },
    {
      name: 'smallBusinessClientId',
      label: 'Client',
      options: {
        filterList: projectListTableState.filterList[7]?.length ? projectListTableState.filterList[7] : undefined,
        filterOptions: {
          names: Object.keys(smallBusinessClientList),
          fullWidth: true,
          renderValue: getSmallBusinessClientOptionFromValue,
        },
        customFilterListOptions: { render: (value) => `Client: ${smallBusinessClientList[value]}` },
        customBodyRenderLite: (dataIndex) => {
          const getClientAbbreviations = (client: string | null | undefined) => {
            switch (client) {
              case projects[dataIndex]?.smallBusinessClient?.name:
                return projects[dataIndex]?.smallBusinessClient?.abbreviation;
              case 'NA':
                return 'NA';
              default:
                return 'Default';
            }
          };
          return <Typography>{getClientAbbreviations(projects[dataIndex]?.smallBusinessClient?.name)}</Typography>;
        },
      },
    },
    {
      name: 'permissions',
      label: 'Permissions',
      options: {
        filter: false,
        searchable: false,
        sort: false,
        display: isEnterpriseAdmin,
        // eslint-disable-next-line react/display-name
        customBodyRenderLite: (dataIndex) => {
          return (
            <>
              <Grid container sx={actionsContainer}>
                <Grid item>
                  <Tooltip title="Edit Permissions">
                    <span>
                      <IconButton
                        size="small"
                        onClick={(event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) =>
                          handleManagePermissions(event, dataIndex)
                        }
                      >
                        <PermissionsIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Grid>
              </Grid>
            </>
          );
        },
      },
    },
  ];

  const options: MUIDataTableOptions = {
    ...projectListTableOptions,
    count: data?.projectListResult.count,
    rowsPerPageOptions: [],
    download: false,
    onRowClick: (_, { dataIndex }) => {
      const rowData = projects[dataIndex];
      if (!rowData) {
        throw new Error('Row data not found.');
      }
      if (rowData.smallBusinessRequirement === SmallBusinessRequirementOption.FEDERAL) {
        return history.push(`/projects/${rowData.id}/reports/federal-small-business-report`);
      } else if (rowData.smallBusinessRequirement === SmallBusinessRequirementOption.NON_FEDERAL) {
        return history.push(`/projects/${rowData.id}/reports/small-business-report`);
      } else {
        return history.push(`/projects/${rowData.id}/reports`);
      }
    },
  };

  if (error) {
    console.error(error);
    return <ErrorNotice />;
  }

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <StyledTable title="Project List" data={projects} columns={columns} options={options} />
      <BackToTopButton />
    </>
  );
};

export default ProjectListPage;
