import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { confirmable, ReactConfirmProps } from 'react-confirm';

import { StyledEngineProvider, Theme, ThemeProvider, useTheme } from '@mui/material';

import StyledButtonPrimary from '../ButtonPrimary';
import StyledButtonSecondary from '../ButtonSecondary';
import Dialog from '../Dialog';

type CustomConfirmDialogProps = {
  title: any;
  header?: string;
  rejectLabel: any;
  confirmLabel: any;
  theme?: Partial<Theme> | ((outerTheme: Theme) => Theme);
};

type ConfirmDialogProps = ReactConfirmProps & CustomConfirmDialogProps;

export type ConfirmableDialogProps = Omit<ConfirmDialogProps, 'show' | 'cancel' | 'proceed' | 'dismiss'>;

const ConfirmDialog = ({
  show,
  title,
  confirmation,
  header,
  rejectLabel,
  cancel,
  confirmLabel,
  proceed,
  dismiss,
  theme,
}: ConfirmDialogProps) => {
  const defaultTheme = useTheme();
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme ?? defaultTheme}>
        <Dialog
          data-testid="confirm-dialog"
          isOpen={show}
          handleClose={dismiss}
          maxWidth="sm"
          title={title}
          contentGroupHeader={header}
          content={confirmation}
          actions={
            <Fragment>
              <StyledButtonSecondary onClick={() => cancel()} label={rejectLabel} />
              <StyledButtonPrimary onClick={() => proceed('y')} label={confirmLabel} />
            </Fragment>
          }
        />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

ConfirmDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.any,
  confirmation: PropTypes.string.isRequired,
  header: PropTypes.string,
  rejectLabel: PropTypes.any,
  cancel: PropTypes.func.isRequired,
  confirmLabel: PropTypes.any,
  proceed: PropTypes.func.isRequired,
  dismiss: PropTypes.func.isRequired,
  theme: PropTypes.any,
};

export default confirmable(ConfirmDialog);
