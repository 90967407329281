import debounce from 'lodash.debounce';
import { useCallback } from 'react';

const useDebounce = (callback: Function, wait: number = 300) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounced = useCallback(
    debounce((nextValue: unknown) => callback(nextValue), wait),
    [],
  );

  return { debounced };
};

export default useDebounce;
