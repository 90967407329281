import { APPLICATION_ADMIN, DEFAULT, ENTERPRISE_ADMIN, SUPPORT } from 'constants/appRoles';
import { useEffect, useState } from 'react';

import { useMsal } from '@azure/msal-react';

import { ENV, MERCURY_ENVIRONMENTS } from '../../constants';

type IdTokenClaims = {
  roles: string[];
};

const hasRole = (roles: string[], role: string) => {
  const downgradedRolesValue = window.sessionStorage.getItem('downgradedRoles') ?? '';
  const downgradedRoles = downgradedRolesValue.split(',');
  return roles?.includes(role) && !downgradedRoles.includes(role);
};

const useRoles = () => {
  const { instance } = useMsal();
  const [roles, setRoles] = useState<string[]>([]);

  useEffect(() => {
    const idTokenClaims = instance.getActiveAccount()?.idTokenClaims as IdTokenClaims;
    const roles = idTokenClaims?.roles ?? [];
    setRoles(roles);
  }, [instance]);

  const isEnterpriseAdmin = hasRole(roles, ENTERPRISE_ADMIN);
  const isDefaultRole = hasRole(roles, DEFAULT);
  const isSupportRole = hasRole(roles, SUPPORT);
  const isApplicationAdmin = hasRole(roles, APPLICATION_ADMIN);
  // TODO: this should be checking for support role, but I'm not sure
  // I have the permissions to even see the pages needed to create the role and/or
  // assign it to this app. See https://meodev.visualstudio.com/mercury/_workitems/edit/12004
  // const isSupportRoleForTesting = roles?.includes(SUPPORT) ?? false;
  const isSupportRoleForTesting = ENV && [MERCURY_ENVIRONMENTS.local, MERCURY_ENVIRONMENTS.dev].includes(ENV);

  return {
    isEnterpriseAdmin,
    isDefaultRole,
    isSupportRole,
    isSupportRoleForTesting,
    isApplicationAdmin,
    roles,
  };
};

export default useRoles;
