import { MERCURY_ENVIRONMENTS } from '../../constants';

export const features = {
  federalSmallBusinessReport: 'federalSmallBusinessReport',
  smallBusinessReport: 'smallBusinessReport',
};

type EnabledFeature = { [environment: string]: { [feature: string]: boolean } };

export const enabledFeatures: EnabledFeature = {
  [MERCURY_ENVIRONMENTS.local]: {
    [features.federalSmallBusinessReport]: true,
    [features.smallBusinessReport]: true,
  },
  [MERCURY_ENVIRONMENTS.dev]: {
    [features.federalSmallBusinessReport]: true,
    [features.smallBusinessReport]: true,
  },
  [MERCURY_ENVIRONMENTS.uat]: {
    [features.federalSmallBusinessReport]: true,
    [features.smallBusinessReport]: true,
  },
  [MERCURY_ENVIRONMENTS.prod]: {
    [features.federalSmallBusinessReport]: true,
    [features.smallBusinessReport]: true,
  },
};
