import hpLogo from 'assets/images/logos/hpLogoLight.png';
import TestByRoleDialog from 'components/TestByRoleDialog';
import { blackGray } from 'constants/themes/colors';
import useCurrentUser from 'hooks/useCurrentUser';
import useRoles from 'hooks/useRoles';
import { Fragment, useState } from 'react';

import BuildIcon from '@mui/icons-material/Build';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Grid,
  Hidden,
  IconButton,
  SxProps,
  Theme,
  Toolbar,
  Typography,
} from '@mui/material';

import SideNavigationMenu from './SideNavigationMenu';

const appBar: SxProps<Theme> = (theme: Theme) => ({
  position: 'sticky',
  boxShadow: 'none',
  backgroundColor: blackGray,
  [theme.breakpoints.down('md')]: { paddingTop: theme.spacing(0) },
});

const hpLogoImg: SxProps<Theme> = {
  width: 200,
  display: 'block',
  float: 'left',
  padding: 12,
};

const displayName: SxProps<Theme> = {
  display: 'block',
  float: 'left',
  marginLeft: 1,
  marginRight: 1,
};

const profilePicture: SxProps<Theme> = {
  width: 48,
  height: 48,
  display: 'block',
  float: 'left',
};

const helpButton: SxProps<Theme> = {
  float: 'left',
  color: 'white',
};

const appName: SxProps<Theme> = {
  fontWeight: 'bold',
  marginLeft: 4,
};

const testingIconRoot: SxProps<Theme> = (theme: Theme) => ({
  borderRadius: '50%',
  padding: theme.spacing(0.5),
  boxShadow: '2px -2px 36px 4px rgba(255, 255, 255, .75)',
  boxSizing: 'unset',
  color: 'white',
  width: 20,
  height: 20,
});

const badge: SxProps<Theme> = {
  fontWeight: 'bold',
};

const PrimaryAppbar = () => {
  const currentUser = useCurrentUser();
  const { isSupportRoleForTesting } = useRoles();
  const [isTestByRoleDialogOpen, setIsTestByRoleDialogOpen] = useState(false);
  const [downgradedRolesValue, handleDowngradedRolesChange] = useState(
    window.sessionStorage.getItem('downgradedRoles') ?? '',
  );
  let downgradedRoles = downgradedRolesValue.split(',').filter((r) => r);

  return (
    <Fragment>
      <AppBar sx={appBar}>
        <Toolbar>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container direction="row" alignItems="center">
                <Grid item>
                  <SideNavigationMenu />
                </Grid>
                <Hidden only={['xs']}>
                  <Grid item>
                    <Box
                      component="img"
                      data-testid="hpLogoImg"
                      src={hpLogo}
                      sx={hpLogoImg}
                      alt="Hensel Phelps Logo"
                    ></Box>
                  </Grid>
                </Hidden>
                <Grid item>
                  <Typography sx={appName}>IMPACT</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" alignItems="center">
                {currentUser?.profilePicture !== null && (
                  <Grid item sx={profilePicture}>
                    <Avatar src={currentUser?.profilePicture} alt="Hensel Phelps User Avatar" />
                  </Grid>
                )}
                {currentUser?.displayName !== null && (
                  <Grid item sx={displayName}>
                    <p>{currentUser?.displayName}</p>
                  </Grid>
                )}
                {isSupportRoleForTesting && (
                  <Grid item>
                    <Badge sx={badge} overlap="circular" color="error" badgeContent={downgradedRoles?.length}>
                      <IconButton size="small" onClick={() => setIsTestByRoleDialogOpen(true)}>
                        <BuildIcon sx={testingIconRoot} />
                      </IconButton>
                    </Badge>
                  </Grid>
                )}
                <Grid item>
                  <a href="https://henselphelps.service-now.com/" rel="noreferrer" target="_blank">
                    <IconButton data-testid="helpButton" sx={helpButton} size="large">
                      <HelpOutlineIcon />
                    </IconButton>
                  </a>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <TestByRoleDialog
        isTestByRoleDialogOpen={isTestByRoleDialogOpen}
        setIsTestByRoleDialogOpen={setIsTestByRoleDialogOpen}
        downgradedRolesValue={downgradedRolesValue}
        handleDowngradedRolesChange={handleDowngradedRolesChange}
      />
    </Fragment>
  );
};

export default PrimaryAppbar;
