import StyledButtonPrimary from 'components/shared/ButtonPrimary';
import StyledButtonSecondary from 'components/shared/ButtonSecondary/ButtonSecondary';
import StyledDialog from 'components/shared/Dialog';
import useToast from 'hooks/useToast';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  GetSmallBusinessClassificationsQuery,
  NestedSmallBusinessClientClassification,
  SmallBusinessAgencyListQuery,
  SmallBusinessClassification,
  SmallBusinessClient,
  useUpsertSmallBusinessClientMutation,
} from 'types/generated/graphql';

import { useApolloClient } from '@apollo/client';
import { Autocomplete, Chip, Grid, InputLabel, SxProps, TextField, Theme, Tooltip } from '@mui/material';

const containerStyles: SxProps<Theme> = (theme: Theme) => ({
  padding: theme.spacing(2),
});

const formStyles: SxProps<Theme> = {
  width: '100%',
};

const nameInputLabelStyle: SxProps<Theme> = (theme: Theme) => ({
  marginBottom: '-8px',
  marginTop: '5px',
  color: theme.palette.secondary.contrastText,
});

const inputContainer: SxProps<Theme> = {
  marginTop: '20px',
};

const classificationLabelStyle: SxProps<Theme> = (theme: Theme) => ({
  marginBottom: '-15px',
  marginTop: '5px',
  color: theme.palette.secondary.contrastText,
});

const classificationChipStyles: SxProps<Theme> = (theme: Theme) => ({
  paddingTop: theme.spacing(1),
});

const textFieldStyles: SxProps<Theme> = (theme: Theme) => ({
  paddingTop: theme.spacing(0),
});

type SmallBusinessClassificationsSelection = GetSmallBusinessClassificationsQuery['smallBusinessClassificationList'];
type SmallBusinessAgencyListSelection = SmallBusinessAgencyListQuery['smallBusinessAgencyList'];

type AddClientAgencyAndClassificationDialogProps = {
  isOpen: boolean;
  setIsOpen: (x: boolean) => void;
  smallBusinessClient: SmallBusinessClient;
  smallBusinessClassificationsList: SmallBusinessClassificationsSelection;
  smallBusinessAgenciesList: SmallBusinessAgencyListSelection;
};

const AddClientAgencyAndClassificationDialog: React.FC<
  AddClientAgencyAndClassificationDialogProps & Record<string, any>
> = ({ isOpen, setIsOpen, smallBusinessClient, smallBusinessClassificationsList, smallBusinessAgenciesList }) => {
  const { displayToast } = useToast();
  const apolloClient = useApolloClient();

  const [addClientAgencyAndClassifications, { loading: isLoading }] = useUpsertSmallBusinessClientMutation();
  interface ClientAgencyAndClassificationFormValues {
    classifications: SmallBusinessClassificationsSelection;
    agency?: string | null;
  }

  const { handleSubmit, control, setValue, reset, watch } = useForm();

  const selectedAgency = watch('agency');
  const selectedClassifications = watch('classifications');

  const federalAgencyList = smallBusinessAgenciesList.filter((agency) => agency.isFederal !== false);
  const nonFederalAgencyList = smallBusinessAgenciesList.filter((agency) => agency.isFederal !== true);

  const getAgencyList = () => {
    const smallBusinessClientRequirement = smallBusinessClient?.isFederal;

    return smallBusinessClientRequirement === undefined
      ? []
      : smallBusinessClientRequirement === true
      ? federalAgencyList
      : smallBusinessClientRequirement === false
      ? nonFederalAgencyList
      : smallBusinessAgenciesList;
  };
  const agencyList = getAgencyList();

  const smallBusinessClassificationListById =
    smallBusinessClassificationsList?.map((classification) => classification.id) ?? [];

  const findClassificationsBySelectedAgency = smallBusinessAgenciesList?.find(
    (agency) => agency.id === selectedAgency,
  )?.smallBusinessAgencyClassifications;

  const agencyAcceptedClassifications = findClassificationsBySelectedAgency
    ? [...findClassificationsBySelectedAgency].sort((a, b) =>
        a.smallBusinessClassification.name.localeCompare(b.smallBusinessClassification.name),
      )
    : [];

  const agencyAcceptedClassificationListById =
    agencyAcceptedClassifications?.map((classification) => classification.smallBusinessClassification.id) ?? [];

  const handleClose = () => {
    reset({
      smallBusinessClassificationsList: [],
      smallBusinessAgenciesList: '',
    });
    setIsOpen(false);
  };
  const smallBusinessClassificationsById = smallBusinessClassificationsList.reduce<{
    [id: string]: SmallBusinessClassificationsSelection[number];
  }>((accumulator, classification) => {
    accumulator[classification.id] = classification;
    return accumulator;
  }, {});

  const smallBusinessAgenciesById = smallBusinessAgenciesList.reduce<{
    [id: string]: SmallBusinessAgencyListSelection[number];
  }>((accumulator, agency) => {
    accumulator[agency.id] = agency;
    return accumulator;
  }, {});

  const isFormValid = () => {
    return selectedAgency;
  };

  const onSubmit: SubmitHandler<ClientAgencyAndClassificationFormValues> = (data) => {
    if (!smallBusinessClient.id) {
      displayToast(
        'Error: Something went wrong while trying to update the client information. Please contact support.',
        'error',
      );
      return;
    }

    const classificationIds = data.classifications.map((id) => ({
      smallBusinessClassificationId: id,
    })) as unknown as NestedSmallBusinessClientClassification[];

    const previousAgencyAndClassifications = smallBusinessClient.smallBusinessClientAgencies.map((agency) => {
      return {
        smallBusinessAgencyId: agency.smallBusinessAgency?.id,
        smallBusinessClientClassifications: agency.smallBusinessClientClassifications?.map((classification) => {
          return { smallBusinessClassificationId: classification.smallBusinessClassification.id };
        }),
        smallBusinessClientId: smallBusinessClient.id,
      };
    });

    return addClientAgencyAndClassifications({
      variables: {
        input: {
          id: smallBusinessClient.id,
          name: smallBusinessClient.name,
          abbreviation: smallBusinessClient.abbreviation ?? '',
          isFederal: smallBusinessClient.isFederal,
          isActive: true,
          smallBusinessClientAgencies: [
            ...previousAgencyAndClassifications,
            {
              smallBusinessAgencyId: data.agency ?? '',
              smallBusinessClientClassifications: classificationIds ?? [],
              smallBusinessClientId: smallBusinessClient.id,
            },
          ],
        },
      },
    })
      .then(() => {
        handleClose();
        apolloClient.reFetchObservableQueries();
        displayToast("The client's agency and classification details were added successfully", 'success');
      })
      .catch((error: any) => {
        console.error('Add client failed: ', error);
        displayToast(
          "Error: Something went wrong while trying to add the client's agency and classification details. Please try again. If the problem persists, please contact support.",
          'error',
        );
      });
  };

  return (
    <StyledDialog
      title={`${smallBusinessClient?.name} Agency and Classification Info`}
      isLoading={isLoading}
      content={
        <Grid container sx={containerStyles}>
          <Grid sx={formStyles}>
            <form>
              <Grid item>
                <InputLabel sx={nameInputLabelStyle} shrink>
                  Agencies
                </InputLabel>
                <Controller
                  name="agency"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      onChange={(_: any, agencyId: string | null) => {
                        setValue('agency', agencyId);
                        field.onChange(agencyId);
                        setValue('classifications', []);
                      }}
                      id="combo-box-agencies"
                      options={agencyList?.map((agency) => agency.id) ?? []}
                      getOptionLabel={(option: string) => {
                        const agency = smallBusinessAgenciesById[option];
                        return `${agency.name}`;
                      }}
                      renderInput={(params: any) => <TextField {...params} />}
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item sx={inputContainer}>
                <InputLabel sx={classificationLabelStyle} shrink>
                  Classifications
                </InputLabel>
                <Controller
                  name={'classifications'}
                  control={control}
                  render={({ field }) => (
                    <Grid item xs={12} sx={classificationChipStyles}>
                      <Autocomplete
                        {...field}
                        value={selectedClassifications ?? []}
                        isOptionEqualToValue={(option: string, value: string) => option === value}
                        onChange={(_: any, classificationIds: string[]) => {
                          setValue('classifications', classificationIds);
                          field.onChange(classificationIds);
                        }}
                        id="combo-box-classifications"
                        multiple
                        options={
                          agencyAcceptedClassificationListById.length > 0
                            ? agencyAcceptedClassificationListById
                            : smallBusinessClassificationListById ?? []
                        }
                        filterSelectedOptions
                        getOptionLabel={(option: string) => {
                          const classification = smallBusinessClassificationsById[option];
                          return `${classification.abbreviation ?? ''} (${classification.name})`.trim();
                        }}
                        renderTags={(value: string[], getTagProps: any) => {
                          return value?.map((option: SmallBusinessClassification['id'], index) => {
                            const classification = smallBusinessClassificationsById[option];
                            return (
                              <Tooltip arrow title={`${classification.name}`} key={classification?.id}>
                                <div key={index}>
                                  <Chip
                                    variant="outlined"
                                    label={classification.abbreviation ?? ''}
                                    {...getTagProps({ index })}
                                  />
                                </div>
                              </Tooltip>
                            );
                          });
                        }}
                        renderInput={(params: any) => <TextField {...params} sx={textFieldStyles} />}
                        size="small"
                        disabled={!selectedAgency}
                      />
                    </Grid>
                  )}
                />
              </Grid>
            </form>
          </Grid>
        </Grid>
      }
      actions={
        <Grid container justifyContent="center">
          <Grid item>
            <Grid container justifyContent="space-between">
              <Grid item>
                <StyledButtonSecondary disabled={isLoading} label={'cancel'} onClick={handleClose} />
              </Grid>
              <Grid item>
                <StyledButtonPrimary
                  label={'submit'}
                  type="submit"
                  disabled={isLoading || !isFormValid()}
                  onClick={handleSubmit(onSubmit)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      isOpen={isOpen}
      handleClose={handleClose}
    />
  );
};

export default AddClientAgencyAndClassificationDialog;
