import apple from 'assets/images/unsupportedBrowserImages/appleOSLogo.png';
import chrome from 'assets/images/unsupportedBrowserImages/chromeBrowserLogoColor.png';
import firefox from 'assets/images/unsupportedBrowserImages/firefoxBrowserLogoColor.png';
import meo from 'assets/images/unsupportedBrowserImages/hpMeoTechnologyEmblem.png';
import linux from 'assets/images/unsupportedBrowserImages/linuxOSLogo.png';
import edge from 'assets/images/unsupportedBrowserImages/microsoftEdgeBrowserLogoColor.png';
import safari from 'assets/images/unsupportedBrowserImages/safariBrowserLogoColor.png';
import mWindows from 'assets/images/unsupportedBrowserImages/windowsOSLogo.png';
import { SERVICENOW_URL } from 'constants/externalLinks';
import { FC } from 'react';

import { Box, Button, Grid, SxProps, Theme, Typography } from '@mui/material';

const root: SxProps<Theme> = {
  flex: 1,
  textAlign: 'center',
  backgroundColor: '#F2F2F2',
  minHeight: '100vh',
  height: '100%',
};

const meoImg: SxProps<Theme> = {
  maxWidth: 200,
  margin: '80px 0 32px 0',
};

const unsupportedBrowserText: SxProps<Theme> = {
  fontWeight: 'bold',
  fontSize: '2rem',
};

const browserItemContainer: SxProps<Theme> = (theme: Theme) => ({
  boxShadow: '2px 2px 10px gray',
  padding: theme.spacing(2),
  margin: theme.spacing(1),
  backgroundColor: '#FFFFFF',
  border: '8px solid #A6192E',
  borderRadius: 4,
});

const browserText: SxProps<Theme> = {
  textTransform: 'uppercase',
  fontSize: '0.75rem',
  fontWeight: 'bold',
  paddingBottom: 24,
};

const mainTextContainer: SxProps<Theme> = {
  padding: 24,
  color: '#5C5C5C',
};

const mainTextEmphasized: SxProps<Theme> = {
  fontWeight: 'bold',
};

const mainText: SxProps<Theme> = {
  fontWeight: 'normal',
};

const browserImg: SxProps<Theme> = (theme: Theme) => ({
  maxWidth: theme.spacing(8),
  marginBottom: '2rem',
});

const osText: SxProps<Theme> = {
  fontSize: '0.625rem',
};

const osImg: SxProps<Theme> = (theme: Theme) => ({
  height: theme.spacing(2),
  width: 'auto',
});

const helpBtn: SxProps<Theme> = {
  margin: '32px 0',
  backgroundColor: '#A6192E',
  color: '#FFFFFF',
  '&:hover': { backgroundColor: '#700006' },
};

const helpBtnText: SxProps<Theme> = {
  textTransform: 'capitalize',
  fontSize: '0.75rem',
  fontWeight: 'bold',
  '&:first-letter': { textTransform: 'uppercase' },
};

type OperatingSystemLogoProperties = { name: string; logoSrc: string };
const OperatingSystemLogo: FC<OperatingSystemLogoProperties> = ({ name, logoSrc }) => {
  return (
    <Grid item md={1} lg={4}>
      <Box component="img" src={logoSrc} alt={`${name} Logo`} sx={osImg}></Box>
    </Grid>
  );
};

const WindowsLogo: FC = () => {
  return <OperatingSystemLogo logoSrc={mWindows} name={'Windows'} />;
};
const AppleLogo: FC = () => {
  return <OperatingSystemLogo logoSrc={apple} name={'Apple'} />;
};
const LinuxLogo: FC = () => {
  return <OperatingSystemLogo logoSrc={linux} name={'Linux'} />;
};

type BrowserCardProperties = {
  name: string;
  downloadPageUrl: string;
  logoSrc: string;
  supportedOperatingSystemLogoComponents: FC[];
};
const BrowserCard: FC<BrowserCardProperties> = ({
  name,
  downloadPageUrl,
  logoSrc,
  supportedOperatingSystemLogoComponents,
}) => {
  return (
    <Grid item xs={5} lg={1} sx={browserItemContainer}>
      <Typography sx={browserText}>{name}</Typography>
      <a href={downloadPageUrl} target="_blank" rel="noopener noreferrer">
        <Box component="img" src={logoSrc} alt={`${name} Logo`} sx={browserImg}></Box>
      </a>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12}>
          <Typography sx={osText}>Operating System</Typography>
        </Grid>
        {supportedOperatingSystemLogoComponents.map((LogoComponent) => {
          return (
            <Grid item md={1} lg={4}>
              <LogoComponent />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export const UnsupportedBrowserDisclaimerPage = () => {
  return (
    <Grid sx={root}>
      <Grid container alignItems="center" alignContent="center" justifyContent="center">
        <Grid item>
          <Box component="img" src={meo} alt={'Hensel Phelps Meo Technology banner'} sx={meoImg}></Box>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={unsupportedBrowserText}>Unsupported Browser</Typography>
        </Grid>
        <Grid item xs={12} sx={mainTextContainer}>
          <Typography gutterBottom sx={mainTextEmphasized}>
            Oops! We're sorry but …
            <Box component="span" sx={mainText}>
              the browser you are using is not currently supported by this application. Please select a supported
              browser below and follow the links to download.
            </Box>
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <BrowserCard
          name="Google Chrome"
          downloadPageUrl="https://www.google.com/chrome/"
          logoSrc={chrome}
          supportedOperatingSystemLogoComponents={[WindowsLogo, LinuxLogo, AppleLogo]}
        />
        <BrowserCard
          name="Microsoft Edge"
          downloadPageUrl="https://www.microsoft.com/en-us/windows/microsoft-edge"
          logoSrc={edge}
          supportedOperatingSystemLogoComponents={[WindowsLogo, AppleLogo]}
        />
        <BrowserCard
          name="Apple Safari"
          downloadPageUrl="https://support.apple.com/downloads/safari"
          logoSrc={safari}
          supportedOperatingSystemLogoComponents={[AppleLogo]}
        />
        <BrowserCard
          name="Mozilla Firefox"
          downloadPageUrl="https://www.mozilla.org/en-US/firefox/new/"
          logoSrc={firefox}
          supportedOperatingSystemLogoComponents={[WindowsLogo, LinuxLogo, AppleLogo]}
        />
        <Grid container justifyContent="center">
          <Grid item>
            <Button size="small" variant="contained" sx={helpBtn} onClick={() => window.open(SERVICENOW_URL, '_blank')}>
              <Typography sx={helpBtnText}>Need Help?</Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UnsupportedBrowserDisclaimerPage;
