export enum ClientOption {
  USACE = 'United States Army Core of Engineers',
  GSA = 'General Services Administration',
  NAVFAC = 'Naval Facilities Engineering Systems Command',
  NASA = 'National Aeronautics and Space Administration',
  Smithsonian = 'Smithsonian Institution',
  SSA = 'Social Security Administration',
  DOE = 'Department of Energy',
  NIH = 'National Institutes of Health',
  FBOP = 'Federal Bureau of Prisons',
  FBI = 'Federal Bureau of Investigations',
  NIST = 'National Institute of Standards and Technology',
  DoD = 'Department of Defense',
  NA = 'Not Applicable',
  Default = '',
}

export const getClientAbbrFromValue = (value?: string | null) => {
  var clientIndex = Object.values(ClientOption).indexOf(value as ClientOption);
  return clientIndex ? Object.keys(ClientOption)[clientIndex] : '';
};

export const HP_CLIENT_NAME = 'Hensel Phelps';
