import { MaterialDesignContent, SnackbarProvider } from 'notistack';
import { FC } from 'react';

import { styled, SxProps, Theme } from '@mui/material';

const success: SxProps<Theme> = (theme: Theme) => ({
  padding: theme.spacing(1, 2),
  backgroundColor: theme.palette.success.main,
  color: theme.palette.primary.contrastText,
  fontSize: '0.875rem',
});

const error: SxProps<Theme> = (theme: Theme) => ({
  padding: theme.spacing(1, 2),
  backgroundColor: theme.palette.error.main,
  color: theme.palette.primary.contrastText,
  fontSize: '0.875rem',
});

const warning: SxProps<Theme> = (theme: Theme) => ({
  padding: theme.spacing(1, 2),
  backgroundColor: theme.palette.warning.main,
  color: theme.palette.primary.contrastText,
  fontSize: '0.875rem',
});

const info: SxProps<Theme> = (theme: Theme) => ({
  padding: theme.spacing(1, 2),
  backgroundColor: theme.palette.info.main,
  color: theme.palette.primary.contrastText,
  fontSize: '0.875rem',
});

export type ToastProviderProps = {
  /** standard children prop: accepts any valid React Node */
  children: React.ReactNode;
};

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => {
  return {
    '&.notistackMuiContentSuccess': success,
    '&.notistackMuiContentError': error,
    '&.notistackMuiContentWarning': warning,
    '&.notistackMuiContentInfo': info,
  };
});

const ToastProvider: FC<ToastProviderProps> = ({ children }) => {
  return (
    <SnackbarProvider
      maxSnack={2}
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent,
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

export default ToastProvider;
