import sherlock from 'assets/images/gifs/sherlockholmesparrot.gif';
import ButtonSecondary from 'components/shared/ButtonSecondary';
import Dialog from 'components/shared/Dialog';
import Notice from 'components/shared/Notice';
import useRoles from 'hooks/useRoles';
import { ChangeEvent, FC } from 'react';

import { FormControlLabel, FormGroup, Grid, Switch, SxProps, Theme, Typography } from '@mui/material';

const labelText: SxProps<Theme> = {
  fontSize: '0.875rem',
};

const container: SxProps<Theme> = (theme: Theme) => ({
  backgroundColor: theme.palette.background.default,
  marginTop: theme.spacing(2),
  padding: theme.spacing(1, 2),
  borderRadius: 4,
});

type TestByRoleDialogProps = {
  isTestByRoleDialogOpen: boolean;
  setIsTestByRoleDialogOpen: { (value: boolean): void };
  downgradedRolesValue: string;
  handleDowngradedRolesChange: { (value: string): void };
};

const TestByRoleDialog: FC<TestByRoleDialogProps> = ({
  isTestByRoleDialogOpen,
  setIsTestByRoleDialogOpen,
  downgradedRolesValue,
  handleDowngradedRolesChange,
}) => {
  const { roles } = useRoles();

  let downgradedRoles = downgradedRolesValue.split(',').filter((r) => r);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const isDowngraded = event.target.checked;
    const role = event.target.value;
    if (isDowngraded && !downgradedRoles.includes(role)) {
      downgradedRoles.push(role);
    }

    if (!isDowngraded && downgradedRoles.includes(role)) {
      downgradedRoles = downgradedRoles.filter((r) => r !== role);
    }
    const newDowngradedRolesValue = downgradedRoles.join(',');
    window.sessionStorage.setItem('downgradedRoles', newDowngradedRolesValue);
    handleDowngradedRolesChange(newDowngradedRolesValue);
  };

  const handleClose = () => {
    setIsTestByRoleDialogOpen(false);
  };

  return (
    <Dialog
      isOpen={isTestByRoleDialogOpen}
      handleClose={handleClose}
      disableEscapeKeyDown={false}
      maxWidth="sm"
      title={'Test by Role(s)'}
      content={
        <Grid container>
          <Grid item xs={12}>
            <img src={sherlock} alt={'Cult of the parrot sherlock version'} />
          </Grid>
          <Grid item xs={12}>
            <Notice message={`You are testing using downgraded role(s) in this tab: ${downgradedRolesValue}`} />
          </Grid>
          <Grid item xs={12}>
            <Typography>
              The following IMPACT user roles have been designated with permissioned access rights to the application.
              Toggle to selectively test by role(s).
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {roles.map((role, index) => {
              const isDowngraded = downgradedRoles.includes(role);
              return (
                <Grid container key={`disabled-role-${index}`} sx={container} justifyContent="space-between">
                  <Grid item>
                    <Typography>{role}</Typography>
                  </Grid>
                  <Grid item>
                    <FormGroup row>
                      <FormControlLabel
                        labelPlacement="start"
                        control={
                          <Switch
                            checked={isDowngraded}
                            onChange={handleChange}
                            value={role}
                            color="primary"
                            size="small"
                          />
                        }
                        label={
                          <Typography color="textPrimary" sx={labelText}>
                            {isDowngraded ? 'Downgraded' : 'Active'}
                          </Typography>
                        }
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      }
      actions={<ButtonSecondary label={'Close'} onClick={handleClose} />}
    />
  );
};

export default TestByRoleDialog;
