import PropTypes from 'prop-types';

import { Button, SxProps, Theme, Typography } from '@mui/material';

const buttonStyles: SxProps<Theme> = (theme: Theme) => ({
  width: 'auto',
  borderRadius: 16,
  minWidth: theme.spacing(10),
  backgroundColor: theme.palette.background.default,
});

const buttonText: SxProps<Theme> = {
  fontSize: '0.75rem',
  fontWeight: 'bold',
};

type StyledButtonMutedProps = {
  id?: string;
  startIcon?: React.ReactNode;
  label: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  className?: string;
};

const StyledButtonMuted: React.FC<StyledButtonMutedProps & Record<string, any>> = ({
  id,
  startIcon,
  label,
  onClick,
  className,
  ...rest
}) => {
  return (
    <Button variant="outlined" startIcon={startIcon} onClick={onClick} id={id} sx={buttonStyles} {...rest}>
      <Typography color="textSecondary" sx={buttonText}>
        {label}
      </Typography>
    </Button>
  );
};

StyledButtonMuted.propTypes = {
  startIcon: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default StyledButtonMuted;
