import cloneDeep from 'clone-deep';

// helper function to pre-format table data to match column structure in order to make sorting easier
export const getColumnFormattedAdminData = (adminData: any[]) => {
  const adminDataCopy = cloneDeep(adminData ?? []);
  if (adminDataCopy.length > 0) {
    return adminDataCopy.map((adminData: any) => {
      const name = adminData.name;
      const abbreviation = adminData.abbreviation;
      // Client classifications and Agency classifications have different properties
      const agencyAndClassifications =
        adminData.smallBusinessClientAgencies ?? adminData.smallBusinessAgencyClassifications;
      const url = adminData.url;
      const sortOrder = adminData.sortOrder;
      const isActive = adminData.isActive;
      const isFederal = adminData.isFederal;
      const participationPercentage = adminData.participationPercentage;
      const specialNotice = adminData.specialNotice;
      return {
        id: adminData.id,
        name,
        abbreviation,
        isActive,
        isFederal,
        sortOrder,
        agencyAndClassifications,
        participationPercentage,
        specialNotice,
        url,
      };
    });
  } else {
    return adminDataCopy;
  }
};

export const openAdminDialog = async (
  rowData: any,
  setAdminData: any,
  toggleAdminDialog: (x: boolean) => void,
  setAdminDialogView: (x: any) => void,
  actionType: string,
) => {
  if (rowData.length > 0) {
    const [
      id,
      name,
      abbreviation,
      isActive,
      isFederal,
      sortOrder,
      agencyAndClassifications,
      url,
      participationPercentage,
      specialNotice,
      ,
      ,
      ,
    ] = rowData;

    setAdminData({
      id,
      name,
      abbreviation,
      isActive,
      isFederal,
      sortOrder,
      agencyAndClassifications,
      url,
      participationPercentage,
      specialNotice,
    });
  } else {
    setAdminData(null);
  }
  setAdminDialogView(actionType);
  toggleAdminDialog(true);
};

export const closeDialog = (
  toggleAdminDialog: (x: boolean) => void,
  setAdminDialogView: (x: string | null | undefined) => void,
) => {
  toggleAdminDialog(false);
  setAdminDialogView(null);
};
