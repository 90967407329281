import { GetSingleCompanyQuery, useGetSingleCompanyQuery } from 'types/generated/graphql';

const useCompany: (
  companyId?: string,
  shouldSkip?: boolean,
) => { company: GetSingleCompanyQuery['company'] | undefined; loading: boolean; error: any } = (
  companyId,
  shouldSkip = false,
) => {
  const {
    loading,
    error,
    data: { company = undefined } = {},
  } = useGetSingleCompanyQuery({
    variables: {
      id: companyId as string,
    },
    skip: shouldSkip || !companyId,
  });

  return { company, loading, error };
};

export default useCompany;
