import cloneDeep from 'clone-deep';
import AdUserPermissionCard from 'components/AdUserPermissionCard';
import StyledButtonPrimary from 'components/shared/ButtonPrimary';
import { GET_SINGLE_PROJECT } from 'graphql/projects';
import useConfirmDialog from 'hooks/useConfirmDialog';
import useToast from 'hooks/useToast';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { GetSingleProjectQuery, useSetAdminStatusOnProjectMutation } from 'types/generated/graphql';

import { Grid, Hidden, IconButton, Tooltip, Typography } from '@mui/material';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';

let deleteAdminList: string[] = [];

const setDeleteAdminList = (arr: string[]) => {
  deleteAdminList = arr;
};

type AdminsSelection = GetSingleProjectQuery['project']['admins'];
type AdminSelection = AdminsSelection[number];

interface ProjectAdminListProps {
  admins: AdminsSelection;
  projectId: string;
}

export const ProjectAdminList = ({ admins, projectId }: ProjectAdminListProps) => {
  const confirmAction = useConfirmDialog();
  const { displayToast } = useToast();
  const isDeleting = (adminId: string) => {
    return deleteAdminList.includes(adminId);
  };

  const handleRemoveAdminClick = (admin: AdminSelection) => {
    confirmAction({
      title: 'Remove Project Admin',
      confirmation: `Are you sure you want to remove ${admin.firstName} ${admin.lastName} from the project?`,
      rejectLabel: 'Cancel',
      confirmLabel: 'Submit',
    }).then(
      (_) => {
        setDeleteAdminList([...deleteAdminList, admin.id]);
        handleRemoveAdmin(admin);
      },
      () => {
        // cancel was pressed
      },
    );
  };

  const [setAdminStatus] = useSetAdminStatusOnProjectMutation({
    refetchQueries: [
      {
        query: GET_SINGLE_PROJECT,
        variables: { id: projectId },
      },
    ],
  });

  const handleRemoveAdmin = (admin: AdminSelection) => {
    setAdminStatus({
      variables: {
        projectId: projectId,
        microsoftAdId: admin.microsoftAdId,
        isAdmin: false,
      },
    })
      .then((_) => {
        displayToast(`Success: ${admin.firstName} ${admin.lastName} has been removed from the project`, 'success');
      })
      .catch((error) => {
        console.error(error);
        displayToast(
          'Error: Something went wrong while trying to remove that admin. Please try again. If the problem persists, please contact support.',
          'error',
        );
      })
      .finally(() => {
        setDeleteAdminList(deleteAdminList.filter((deletedAdmin) => deletedAdmin !== admin.id));
      });
  };

  function getSortedAdmins(admins: AdminsSelection) {
    const sortedAdmins: AdminsSelection = cloneDeep(admins);
    return sortedAdmins.sort((a, b) => {
      var nameA = a.name.toLowerCase();
      var nameB = b.name.toLowerCase();
      return nameA.localeCompare(nameB);
    });
  }
  const sortedAdmins = getSortedAdmins(admins);

  return (
    <Grid container justifyContent="space-between" direction="row" spacing={3}>
      {sortedAdmins.map((admin) => (
        <Grid item xs={12} sm={12} md={12} lg={6} key={admin.id}>
          <AdUserPermissionCard
            user={admin}
            isLoading={isDeleting(admin.id)}
            actions={
              <Fragment>
                <Hidden smUp>
                  <Tooltip title="Remove">
                    <IconButton
                      data-testid="remove-admin-iconbutton"
                      disabled={isDeleting(admin.id)}
                      onClick={() => handleRemoveAdminClick(admin)}
                      size="large">
                      <RemoveIcon />
                    </IconButton>
                  </Tooltip>
                </Hidden>
                <Hidden smDown>
                  <StyledButtonPrimary
                    data-testid={`remove-admin-button-${admin.upn}`}
                    disabled={isDeleting(admin.id)}
                    label="Remove"
                    onClick={() => handleRemoveAdminClick(admin)}
                  />
                </Hidden>
              </Fragment>
            }
          />
        </Grid>
      ))}
      {sortedAdmins.length < 1 && (
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Typography color="primary">There are currently no admins for this project.</Typography>
        </Grid>
      )}
    </Grid>
  );
};

ProjectAdminList.propTypes = {
  admins: PropTypes.array.isRequired,
  projectId: PropTypes.string.isRequired,
};

export default ProjectAdminList;
