import StyledButtonPrimary from 'components/shared/ButtonPrimary';
import StyledButtonSecondary from 'components/shared/ButtonSecondary/ButtonSecondary';
import { GET_SINGLE_COMPANY, UPDATE_COMPANY } from 'graphql/companies';
import useRoles from 'hooks/useRoles';
import useToast from 'hooks/useToast';
import { FC, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { GetSingleCompanyQuery, useCurrentAdUserQuery } from 'types/generated/graphql';

import { useMutation } from '@apollo/client';
import EditIcon from '@mui/icons-material/Edit';
import { Card, Grid, IconButton, InputLabel, SxProps, TextField, Theme, Typography } from '@mui/material';

const generalInfoCard: SxProps<Theme> = (theme: Theme) => ({
  padding: theme.spacing(0, 2, 2, 2),
});

const cardTitle: SxProps<Theme> = {
  fontWeight: 'bold',
};

const notesCard: SxProps<Theme> = (theme: Theme) => ({
  backgroundColor: '#f2f2f2',
  boxShadow: 'none',
  padding: theme.spacing(2),
});

const notesSection: SxProps<Theme> = {
  whiteSpace: 'pre-wrap',
};

const titleContainer: SxProps<Theme> = (theme: Theme) => ({
  padding: theme.spacing(2, 0, 0, 0),
});

const notesLabel: SxProps<Theme> = (theme: Theme) => ({
  fontWeight: 'bold',
  marginTop: theme.spacing(2),
});

const actionsContainer: SxProps<Theme> = (theme: Theme) => ({
  marginTop: theme.spacing(2),
});

const editingLabel: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.primary.main,
});

type CompanyInfoCardProps = {
  company?: GetSingleCompanyQuery['company'];
};

type CompanyInfoCardInput = {
  notes?: string;
};

const CompanyInfoCard: FC<CompanyInfoCardProps> = ({ company }) => {
  const [isEditing, setIsEditing] = useState(false);
  const { displayToast } = useToast();
  const { isEnterpriseAdmin } = useRoles();
  const { data: currentAdUserData } = useCurrentAdUserQuery({
    fetchPolicy: 'no-cache',
  });
  const canEditCompany = isEnterpriseAdmin || !!currentAdUserData?.currentAdUser?.projects?.length;

  const [updateCompany, { loading: isLoading }] = useMutation(UPDATE_COMPANY, {
    refetchQueries: [
      {
        query: GET_SINGLE_COMPANY,
        variables: { id: company?.id },
      },
    ],
    awaitRefetchQueries: true,
  });

  const defaultValues = {
    notes: company?.notes ?? '',
  };

  const { handleSubmit, control, reset, formState } = useForm<CompanyInfoCardInput>({ defaultValues });

  const { isDirty } = formState;

  const handleEditButtonClick = () => {
    setIsEditing(!isEditing);
  };

  const handleClose = () => {
    reset(defaultValues);
    setIsEditing(false);
  };

  const onSubmit: SubmitHandler<CompanyInfoCardInput> = (data) => {
    return updateCompany({
      variables: {
        input: {
          id: company?.id,
          notes: data.notes ?? undefined,
        },
      },
    })
      .then(() => {
        displayToast('The company information section was updated successfully', 'success');
        setIsEditing(false);
      })
      .catch((error) => {
        console.error('Update company information failed: ', error);
        displayToast(
          'Error: Something went wrong while trying submit your changes. Please try again. If the problem persists, please contact support.',
          'error',
        );
      });
  };

  return (
    <Card sx={generalInfoCard}>
      <Grid container justifyContent="space-between">
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" sx={titleContainer}>
            <Grid item>
              <Typography sx={cardTitle}>General Information</Typography>
            </Grid>
            <Grid item>
              {canEditCompany && !isEditing && (
                <IconButton size="small" onClick={handleEditButtonClick}>
                  <EditIcon />
                </IconButton>
              )}
              {isEditing && <Typography sx={editingLabel}>Editing...</Typography>}
            </Grid>
          </Grid>
          <Typography>{company?.name}</Typography>
          <Typography>{company?.location}</Typography>
          {!isEditing && (
            <Grid item>
              <Typography sx={notesLabel}>Notes</Typography>
              <Card sx={notesCard}>
                <Typography sx={notesSection}>{company?.notes ? company?.notes : 'There are no notes.'}</Typography>
              </Card>
            </Grid>
          )}
        </Grid>
        {isEditing && (
          <Grid item xs={12} sx={actionsContainer}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                render={({ field }) => (
                  <>
                    <InputLabel>Notes</InputLabel>
                    <TextField {...field} variant="outlined" fullWidth multiline />
                  </>
                )}
                name="notes"
                control={control}
              />
              <Grid container justifyContent="flex-end" direction="row" alignItems="center">
                <Grid item>
                  <StyledButtonSecondary label={'Cancel'} disabled={isLoading} onClick={handleClose} />
                </Grid>
                <Grid item>
                  <StyledButtonPrimary label="Submit" disabled={!isDirty || isLoading} type={'submit'} />
                </Grid>
              </Grid>
            </form>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default CompanyInfoCard;
