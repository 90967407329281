/* eslint-disable @typescript-eslint/no-unused-vars */
import StyledButtonPrimary from 'components/shared/ButtonPrimary';
import StyledButtonSecondary from 'components/shared/ButtonSecondary';
import StyledDialog from 'components/shared/Dialog';
import ErrorNotice from 'components/shared/ErrorNotice';
import useToast from 'hooks/useToast';
import { FC } from 'react';
import {
  NestedSmallBusinessClientAgency,
  SmallBusinessClient,
  SmallBusinessClientAgency,
  useGetLowerTierParticipationsInUseQuery,
  useUpsertSmallBusinessClientMutation,
} from 'types/generated/graphql';

import { useApolloClient } from '@apollo/client';
import { Box, Grid, List, ListItem, SxProps, Theme, Typography } from '@mui/material';

const unableToDeleteMessageStyles: SxProps<Theme> = {
  marginBottom: '20px',
};

const dataInUseContainerStyle: SxProps<Theme> = {
  maxHeight: '35vh',
  overflow: 'auto',
};

type DeleteClientAgencyAndClassificationProps = {
  isOpen: boolean;
  setIsOpen: (x: boolean) => void;
  smallBusinessClient: SmallBusinessClient;
  smallBusinessAgencyAndClassificationDetails: SmallBusinessClientAgency;
};

const DeleteClientAgencyAndClassificationDialog: FC<DeleteClientAgencyAndClassificationProps> = ({
  isOpen,
  setIsOpen,
  smallBusinessClient,
  smallBusinessAgencyAndClassificationDetails,
}) => {
  const { displayToast } = useToast();
  const apolloClient = useApolloClient();

  const [deleteClientAgencyAndClassifications] = useUpsertSmallBusinessClientMutation();

  const smallBusinessAgencyId = smallBusinessAgencyAndClassificationDetails?.smallBusinessAgency?.id;

  const {
    loading: usedLowerTierParticipationsLoading,
    error: usedLowerTierParticipationsError,
    data: usedLowerTierParticipationsData,
  } = useGetLowerTierParticipationsInUseQuery({
    client: apolloClient,
    variables: {
      smallBusinessAgencyId,
      smallBusinessClassificationIds: [],
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    skip: typeof smallBusinessAgencyId !== 'string',
  });

  const lowerTierParticipationsInUse = usedLowerTierParticipationsData?.getLowerTierParticipationsInUse ?? [];

  const handleClose = () => {
    setIsOpen(false);
  };

  if (usedLowerTierParticipationsError) {
    console.error(usedLowerTierParticipationsError);
    displayToast(
      'Error: Something went wrong while fetching data using the agency. If the problem persists after reloading the page, please contact support.',
      'error',
    );
  }

  if (usedLowerTierParticipationsError) {
    return <ErrorNotice />;
  }

  const onSubmitSuccess = () => {
    const updatedSmallBusinessAgencyAndClassifications = smallBusinessClient?.smallBusinessClientAgencies
      .map((agency) => {
        return agency.id !== smallBusinessAgencyAndClassificationDetails?.id
          ? {
              smallBusinessAgencyId: agency?.smallBusinessAgency?.id,
              smallBusinessClientClassifications: agency?.smallBusinessClientClassifications?.map((classification) => {
                return { smallBusinessClassificationId: classification.smallBusinessClassification.id };
              }),
              smallBusinessClientId: smallBusinessClient?.id,
            }
          : [];
      })
      .filter(
        (agencyAndClassifications) => !Array.isArray(agencyAndClassifications),
      ) as NestedSmallBusinessClientAgency[];

    deleteClientAgencyAndClassifications({
      variables: {
        input: {
          id: smallBusinessClient?.id,
          name: smallBusinessClient?.name,
          abbreviation: smallBusinessClient?.abbreviation ?? '',
          smallBusinessClientAgencies: updatedSmallBusinessAgencyAndClassifications ?? [],
        },
      },
    })
      .then(() => {
        apolloClient.reFetchObservableQueries();
        handleClose();
        displayToast('The small business agency and classifications were deleted successfully', 'success');
      })
      .catch((error) => {
        console.error(error);
        displayToast(
          'Error: Something went wrong while trying to delete the small business agency and classifications. Please try again. If the problem persists, please contact support.',
          'error',
        );
      });
  };

  const generateDialogContent = () => {
    return (
      <Grid container>
        {!usedLowerTierParticipationsLoading && !lowerTierParticipationsInUse?.length && (
          <Grid item>
            <Typography className="margin-bottom">
              {`You are deleting the agency: ${smallBusinessAgencyAndClassificationDetails?.smallBusinessAgency?.name} for client: ${smallBusinessClient?.name}. This action cannot be undone.`}
            </Typography>
          </Grid>
        )}
        <Grid container justifyContent="space-between">
          {usedLowerTierParticipationsLoading && (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography>Loading...</Typography>
            </Grid>
          )}
          {!usedLowerTierParticipationsLoading && !!lowerTierParticipationsInUse?.length && (
            <Box sx={dataInUseContainerStyle}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} sx={unableToDeleteMessageStyles}>
                  <Typography>Unable to delete the agency as it is being used by the following:</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {!!lowerTierParticipationsInUse?.length && (
                    <List>
                      <Grid item>
                        <Typography>Lower Tier Participations:</Typography>
                      </Grid>
                      {lowerTierParticipationsInUse?.map((lowerTierParticipation) => (
                        <ListItem key={lowerTierParticipation.id}>
                          {lowerTierParticipation?.lowerTierCompanyName +
                            ` (Project: ${lowerTierParticipation.contract.project.number} - ${lowerTierParticipation.contract.project.name})`}
                        </ListItem>
                      ))}
                    </List>
                  )}
                </Grid>
              </Grid>
            </Box>
          )}
        </Grid>
      </Grid>
    );
  };

  return (
    <StyledDialog
      title={"Delete the Client's Agency and Classifications"}
      content={generateDialogContent()}
      actions={
        <Grid container>
          <Grid item xs={12}>
            <Grid container justifyContent={'space-between'}>
              <Grid item>
                <StyledButtonSecondary disabled={false} label={'cancel'} onClick={handleClose} />
              </Grid>
              <Grid item>
                <StyledButtonPrimary
                  label={'Delete'}
                  type="submit"
                  onClick={onSubmitSuccess}
                  disabled={lowerTierParticipationsInUse.length !== 0}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      isOpen={isOpen}
      handleClose={handleClose}
      fullWidth={true}
      disableEscapeKeyDown={true}
    />
  );
};

export default DeleteClientAgencyAndClassificationDialog;
