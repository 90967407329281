import { MOBILE_DEVICE_WIDTH } from 'constants/index';
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableOptions,
  MUIDataTableProps,
} from 'mui-datatables';
import { useMediaQuery } from 'react-responsive';

type StyledTableProps = {
  title: string;
  data: any[];
  columns: MUIDataTableColumn[];
  options?: MUIDataTableOptions;
  components?: MUIDataTableProps['components'];
};

const StyledTable: React.FC<StyledTableProps> = ({ title, data, columns, options, components, ...rest }) => {
  const isMobileDevice = useMediaQuery({ maxWidth: MOBILE_DEVICE_WIDTH });

  const getTableStackType = () => {
    if (data.length > 0) {
      return isMobileDevice ? 'vertical' : 'standard';
    } else {
      return 'simple';
    }
  };
  const defaultOptions: MUIDataTableOptions = {
    enableNestedDataAccess: '.',
    fixedHeader: true,
    filterType: 'dropdown',
    filter: true,
    print: false,
    selectToolbarPlacement: 'none',
    selectableRowsHeader: false,
    selectableRows: 'none',
    selectableRowsOnClick: false,
    caseSensitive: false,
    viewColumns: false,
    responsive: getTableStackType(),
    rowsPerPage: isMobileDevice ? 10 : 50,
    rowsPerPageOptions: [10, 20, 50, 100],
    downloadOptions: {
      filename: `${title.split(' ').join('_')}.csv`,
    },
    onDownload: (buildHead: any, buildBody: any, columns: any, data: any) => {
      // https://github.com/gregnb/mui-datatables/pull/722#issuecomment-526346440
      return '\uFEFF' + buildHead(columns) + buildBody(data);
    },
  };
  return (
    <MUIDataTable
      title={title}
      data={data}
      columns={columns}
      options={{ ...defaultOptions, ...options }}
      components={components}
      {...rest}
    />
  );
};

export default StyledTable;
