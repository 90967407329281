import { atom, useRecoilState } from 'recoil';
import { defaultListTableState, listTableHasActiveFilters } from 'recoil/shared';
import { StandardListTableState } from 'types';
import { CompanyListFilterInput } from 'types/generated/graphql';

export type CompanyListTableState = StandardListTableState<CompanyListFilterInput>;

const companyListTableStateAtom = atom({
  key: 'companyListTableState',
  default: defaultListTableState,
});

export const useCompanyListTableState = () => {
  const [companyListTableState, setCompanyListTableState] = useRecoilState(companyListTableStateAtom);

  function handleCompanyListTableStateChange(newState: CompanyListTableState) {
    newState.hasActiveFilters = listTableHasActiveFilters<CompanyListFilterInput>(newState);

    setCompanyListTableState({
      ...companyListTableState,
      ...newState,
    });
  }
  return [companyListTableState, { handleCompanyListTableStateChange }] as const;
};

export default companyListTableStateAtom;
