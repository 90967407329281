import AddCompanyDocumentDialog from 'components/company/AddCompanyDocumentDialog';
import CompanyInfoCard from 'components/company/CompanyInfoCard';
import FederalSmallBusinessClassificationsCard from 'components/company/FederalSmallBusinessClassificationsCard';
import NonFederalSmallBusinessClassificationsCard from 'components/company/NonFederalSmallBusinessClassificationsCard';
import ViewCompanyDocumentDialog from 'components/company/ViewCompanyDocumentDialog';
import StyledButtonMuted from 'components/shared/ButtonMuted/ButtonMuted';
import ErrorNotice from 'components/shared/ErrorNotice';
import FederalSmallBusinessClassificationDisplayName from 'components/shared/FederalSmallBusinessClassificationDisplayName';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import SmallBusinessClassificationDisplayName from 'components/shared/SmallBusinessClassificationDisplayName';
import StyledTable from 'components/shared/Table';
import useCompany from 'hooks/useCompany';
import useRoles from 'hooks/useRoles';
import { DateTime } from 'luxon';
import { MUIDataTableColumn, MUIDataTableOptions, MUISortOptions } from 'mui-datatables';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  GetSingleCompanyQuery,
  GetSmallBusinessClassificationsQuery,
  useCurrentAdUserQuery,
  useGetSmallBusinessClassificationsQuery,
} from 'types/generated/graphql';
import { formatDate } from 'utils/general';

import AddIcon from '@mui/icons-material/Add';
import { Grid, SxProps, Theme, Typography } from '@mui/material';

const section: SxProps<Theme> = (theme: Theme) => ({
  marginBottom: theme.spacing(2),
});

const pageTitle: SxProps<Theme> = {
  fontSize: '1.5rem',
};

const pageSubTitle: SxProps<Theme> = {
  fontSize: '1.25rem',
};

type useParamsProps = {
  companyId?: string;
};

type SmallBusinessClassificationSelection =
  GetSmallBusinessClassificationsQuery['smallBusinessClassificationList'][number];

const CompanyPage: FC = () => {
  const { companyId } = useParams<useParamsProps>();
  const [addDocumentDialogIsOpen, setAddDocumentDialogIsOpen] = useState(false);
  const [viewDocumentDialogIsOpen, setViewDocumentDialogIsOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<GetSingleCompanyQuery['company']['files'][number] | undefined>(
    undefined,
  );
  const [classificationType, setClassificationType] = useState('');

  const LOCAL_STORAGE_SUPPORTING_DOCS_SORT_KEY = 'supportingDocumentsSort';
  const savedSupportingDocumentsTableSort = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_SUPPORTING_DOCS_SORT_KEY) as string,
  );
  const [supportingDocumentsTableSort, setSupportingDocumentsTableSort] = useState<MUISortOptions>(
    !savedSupportingDocumentsTableSort
      ? {
          name: 'title',
          direction: 'asc',
        }
      : savedSupportingDocumentsTableSort,
  );

  const { isEnterpriseAdmin } = useRoles();
  const { data: currentAdUserData } = useCurrentAdUserQuery({
    fetchPolicy: 'no-cache',
  });
  const canManageCompanyDocuments = isEnterpriseAdmin || !!currentAdUserData?.currentAdUser?.projects?.length;

  const { company, loading: companyLoading, error: companyError } = useCompany(companyId);

  const companyFiles = company?.files ?? [];

  const { error: smallBusinessClassificationError, data: smallBusinessClassificationData } =
    useGetSmallBusinessClassificationsQuery();

  const federalSmallBusinessClassifications =
    smallBusinessClassificationData?.smallBusinessClassificationList?.filter(
      (classification) => classification.isFederal !== false && classification.abbreviation !== 'DBT',
    ) ?? [];

  const federalSmallBusinessClassificationsById = federalSmallBusinessClassifications.reduce<{
    [id: string]: SmallBusinessClassificationSelection;
  }>((accumulator, classification) => {
    accumulator[classification.id] = classification;
    return accumulator;
  }, {});

  const smallBusinessClassifications =
    smallBusinessClassificationData?.smallBusinessClassificationList?.filter(
      (classification) => classification.isFederal !== true,
    ) ?? [];

  const smallBusinessClassificationsById = smallBusinessClassifications.reduce<{
    [id: string]: SmallBusinessClassificationSelection;
  }>((accumulator, classification) => {
    accumulator[classification.id] = classification;
    return accumulator;
  }, {});

  const dateSortCompare =
    (order: 'asc' | 'desc') =>
    ({ data: a }: { data: any }, { data: b }: { data: any }) => {
      const orderModifier = order === 'asc' ? 1 : -1;
      if (typeof a !== 'string') {
        if (typeof b !== 'string') {
          return 0;
        }
        return -orderModifier;
      } else if (typeof b !== 'string') {
        return orderModifier;
      }
      return (DateTime.fromISO(a).toMillis() - DateTime.fromISO(b).toMillis()) * orderModifier;
    };

  const columns: MUIDataTableColumn[] = [
    {
      name: 'id',
      label: 'Id',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      name: 'title',
      label: 'Title',
      options: {
        filter: false,
        searchable: true,
        sort: true,
      },
    },
    {
      name: 'federalSmallBusinessClassificationIds',
      label: 'Federal Certifications',
      options: {
        filter: true,
        filterType: 'multiselect',
        filterOptions: {
          names: federalSmallBusinessClassifications.map((classification) => classification.id),
          renderValue: (id) => federalSmallBusinessClassificationsById[id].abbreviation,
          fullWidth: true,
        },
        customFilterListOptions: {
          render: (id) => `Federal Classification: ${federalSmallBusinessClassificationsById[id].abbreviation}`,
        },
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          const classifications = companyFiles[dataIndex]?.smallBusinessClassificationCoverages
            ?.filter((classification) => classification.isFederal !== false)
            ?.map((coverage) => coverage.smallBusinessClassification)
            .sort((a, b) => a.sortOrder - b.sortOrder);
          if (!classifications?.length) {
            classifications.push({
              id: 'none',
              abbreviation: 'none',
              name: 'no classifications',
              sortOrder: -1,
            });
          }
          return (
            <Grid container direction="row" spacing={1}>
              {classifications.map((classification, index, array) => (
                <Grid item>
                  <FederalSmallBusinessClassificationDisplayName
                    classification={classification}
                    displayFormat="abbreviation"
                    displayFormatExtension={(formattedName) =>
                      `${formattedName}${index === array?.length - 1 ? '' : ','}`
                    }
                    tooltipFormat="name"
                  />
                </Grid>
              ))}
            </Grid>
          );
        },
      },
    },
    {
      name: 'smallBusinessClassificationIds',
      label: 'Non-Federal Certifications',
      options: {
        filter: true,
        filterType: 'multiselect',
        filterOptions: {
          names: smallBusinessClassifications.map((classification) => classification.id),
          renderValue: (id) => smallBusinessClassificationsById[id].abbreviation,
          fullWidth: true,
        },
        customFilterListOptions: {
          render: (id) => `Non-Federal Classification: ${smallBusinessClassificationsById[id].abbreviation}`,
        },
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          const classifications = companyFiles[dataIndex]?.smallBusinessClassificationCoverages
            ?.filter((classification) => classification.isFederal !== true)
            ?.map((coverage) => coverage.smallBusinessClassification)
            .sort((a, b) => a.name.localeCompare(b.name));

          if (!classifications?.length) {
            classifications.push({
              id: 'none',
              abbreviation: 'none',
              name: 'no classifications',
              sortOrder: -1,
            });
          }
          return (
            <Grid container direction="row" spacing={1}>
              {classifications.map((classification, index, array) => (
                <Grid item>
                  <SmallBusinessClassificationDisplayName
                    classification={classification}
                    displayFormat="abbreviation"
                    displayFormatExtension={(formattedName) =>
                      `${formattedName}${index === array?.length - 1 ? '' : ','}`
                    }
                    tooltipFormat="name"
                  />
                </Grid>
              ))}
            </Grid>
          );
        },
      },
    },
    {
      name: 'startDate',
      label: 'Start Date',
      options: {
        filter: false,
        searchable: true,
        sort: true,
        sortCompare: dateSortCompare,
        customBodyRenderLite: (dataIndex) => {
          const date = companyFiles[dataIndex]?.smallBusinessClassificationCoverages?.[0]?.startDate;
          return <Typography>{date ? formatDate(date) : 'N/A'}</Typography>;
        },
      },
    },
    {
      name: 'endDate',
      label: 'End Date',
      options: {
        filter: false,
        searchable: true,
        sort: true,
        sortCompare: dateSortCompare,
        customBodyRenderLite: (dataIndex) => {
          const date = companyFiles[dataIndex]?.smallBusinessClassificationCoverages?.[0]?.endDate;
          return <Typography>{date ? formatDate(date) : 'N/A'}</Typography>;
        },
      },
    },
  ];

  const getAddNewDocumentButton = (label: string, id?: string) =>
    canManageCompanyDocuments && (
      <StyledButtonMuted
        id={id}
        startIcon={<AddIcon color="action" />}
        label={label}
        onClick={(event) => {
          setClassificationType(event.currentTarget.id);
          setAddDocumentDialogIsOpen(true);
        }}
      />
    );

  const handleSortChange = (name: string, direction: 'asc' | 'desc') => {
    setSupportingDocumentsTableSort({ name: name, direction: direction });
    localStorage.setItem(LOCAL_STORAGE_SUPPORTING_DOCS_SORT_KEY, JSON.stringify({ name, direction }));
  };

  const options: MUIDataTableOptions = {
    customToolbar: () => getAddNewDocumentButton('Add New Document', 'addNewDocument'),
    sortOrder: { name: supportingDocumentsTableSort?.name, direction: supportingDocumentsTableSort?.direction },
    onColumnSortChange(changedColumns, direction) {
      handleSortChange(changedColumns, direction);
    },
    onTableInit(_, tableState) {
      const savedSupportingDocumentsTableSort = supportingDocumentsTableSort;
      tableState.sortOrder = savedSupportingDocumentsTableSort;
    },
    onRowClick: (_, { dataIndex }) => {
      setViewDocumentDialogIsOpen(true);
      const id = companyFiles[dataIndex]?.id;
      const file = companyFiles.find((companyFile) => companyFile.id === id);
      if (file) {
        setSelectedFile(file);
      }
    },
    sortFilterList: false,
  };

  if (companyLoading) {
    return <LoadingSpinner />;
  }

  if (companyError) {
    console.error(companyError);
    return <ErrorNotice />;
  }

  if (smallBusinessClassificationError) {
    console.error(smallBusinessClassificationError);
    return <ErrorNotice />;
  }

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={12} sx={section}>
          <Typography sx={pageTitle}>Company</Typography>
          <Typography sx={pageSubTitle}>{company?.name}</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={11} lg={11} sx={section}>
          <CompanyInfoCard company={company} />
        </Grid>
        <Grid item xs={12} sm={12} md={11} lg={11} sx={section}>
          {company && (
            <FederalSmallBusinessClassificationsCard
              company={company}
              classificationsToDisplay={federalSmallBusinessClassifications}
              addNewDocumentButton={getAddNewDocumentButton(
                'Add Classification',
                'federalSmallBusinessClassifications',
              )}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={11} lg={11} sx={section}>
          {company && (
            <NonFederalSmallBusinessClassificationsCard
              company={company}
              addNewDocumentButton={getAddNewDocumentButton(
                'Add Classification',
                'nonFederalSmallBusinessClassifications',
              )}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={11} lg={11} sx={section}>
          <StyledTable
            title="Supporting Documents"
            data={companyFiles.map((file) => {
              const firstCoverage = file.smallBusinessClassificationCoverages[0];
              return {
                ...file,
                federalSmallBusinessClassificationIds: file
                  ? file.smallBusinessClassificationCoverages
                      ?.filter((coverage) => coverage.isFederal !== false)
                      .map((coverage) => coverage.smallBusinessClassification.id)
                  : [],
                smallBusinessClassificationIds: file
                  ? file.smallBusinessClassificationCoverages
                      ?.filter((coverage) => coverage.isFederal !== true)
                      .map((coverage) => coverage.smallBusinessClassification.id)
                  : [],
                startDate: firstCoverage?.startDate,
                endDate: firstCoverage?.endDate,
              };
            })}
            columns={columns}
            options={options}
          />
        </Grid>
      </Grid>
      {canManageCompanyDocuments && (
        <AddCompanyDocumentDialog
          isOpen={addDocumentDialogIsOpen}
          setIsOpen={setAddDocumentDialogIsOpen}
          classificationType={classificationType}
          smallBusinessClassifications={
            classificationType === 'federalSmallBusinessClassifications'
              ? federalSmallBusinessClassifications
              : smallBusinessClassifications
          }
        />
      )}
      {companyId && (
        <ViewCompanyDocumentDialog
          isOpen={viewDocumentDialogIsOpen}
          setIsOpen={setViewDocumentDialogIsOpen}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          companyId={companyId}
        />
      )}
    </>
  );
};

export default CompanyPage;
