import { SmallBusinessClassificationName } from 'constants/classificationNames';
import { SmallBusinessClassificationCoverageFromQuery } from 'types';
import { GetSingleProjectQuery } from 'types/generated/graphql';
import { formatDate } from 'utils/general';

import { Theme, Tooltip, Typography } from '@mui/material';
import {
  CSSSelectorObjectOrCssVariables,
  Theme as MuiTheme,
  SxProps,
  SystemCssProperties,
  SystemStyleObject,
  useTheme,
} from '@mui/system';

import { getSmallBusinessClassificationStatus } from '../SmallBusinessClassificationCoverageHelper/SmallBusinessClassificationCoverageHelper';

type Classification = { name: string; abbreviation: string };
type SmallBusinessClassificationNameFormatFunction = (
  classification: Classification,
  classificationCoverage?: SmallBusinessClassificationCoverageFromQuery,
  reportingAgency?: GetSingleProjectQuery['project']['smallBusinessClient'],
) => string | undefined;
const smallBusinessClassificationNameDisplayFormatOptions = [
  'abbreviation',
  'name',
  'full',
  'expired',
  'expiringSoon',
  'acceptance',
] as const;
type SmallBusinessClassificationNameFormatFunctionMap = {
  [Property in typeof smallBusinessClassificationNameDisplayFormatOptions[number]]: SmallBusinessClassificationNameFormatFunction;
};
const smallBusinessClassificationNameFormatFunctions: SmallBusinessClassificationNameFormatFunctionMap = {
  abbreviation: (classification: Classification) => classification.abbreviation,
  name: (classification: Classification) => classification.name,
  full: (classification: Classification) => `${classification.abbreviation} (${classification.name})`,
  expired: (
    classification: Classification,
    classificationCoverage: SmallBusinessClassificationCoverageFromQuery | undefined,
  ) => `${classification.name} expired on ${formatDate(classificationCoverage?.endDate)}`,
  expiringSoon: (
    classification: Classification,
    classificationCoverage: SmallBusinessClassificationCoverageFromQuery | undefined,
  ) =>
    `${classification.name} expiring in ${
      getSmallBusinessClassificationStatus(classificationCoverage?.endDate).numberOfDaysUntilExpiration
    } days`,
  acceptance: (
    classification: Classification,
    classificationCoverage: SmallBusinessClassificationCoverageFromQuery | undefined,
    reportingAgency: GetSingleProjectQuery['project']['smallBusinessClient'],
  ) =>
    `${reportingAgency?.name} does not accept ${classification.name} from ${classificationCoverage?.smallBusinessAgency?.name}`,
};
type SmallBusinessClassificationNameFormatOption = keyof typeof smallBusinessClassificationNameFormatFunctions;

type CustomFormatExtension = { (formattedName: string): string | React.ReactElement };

export type SmallBusinessClassificationNameProps = {
  classification: Classification;
  classificationCoverage?: SmallBusinessClassificationCoverageFromQuery;
  reportingAgency?: GetSingleProjectQuery['project']['smallBusinessClient'];
  displayFormat: SmallBusinessClassificationNameFormatOption;
  displayFormatExtension?: CustomFormatExtension;
  tooltipFormat?: SmallBusinessClassificationNameFormatOption;
  tooltipFormatExtension?: CustomFormatExtension;
  noWrap?: boolean;
  typographyClassNames?: SystemStyleObject<Theme> | SystemCssProperties<Theme> | CSSSelectorObjectOrCssVariables<Theme>;
  indent?: boolean;
};

const noFormatExtension: CustomFormatExtension = (formattedName) => formattedName;

const classificationNameLevel1: SxProps<MuiTheme> = (theme: MuiTheme) => ({
  paddingLeft: theme.spacing(0),
  fontWeight: 'bold',
});

const classificationNameLevel2: SxProps<MuiTheme> = (theme: MuiTheme) => ({
  paddingLeft: theme.spacing(2),
  fontWeight: 'normal',
});

const classificationNameLevel3: SxProps<MuiTheme> = (theme: MuiTheme) => ({
  paddingLeft: theme.spacing(4),
  fontStyle: 'italic',
});

const getIndentationStyles = (classificationName: string, theme: MuiTheme) => {
  switch (classificationName) {
    case SmallBusinessClassificationName.LB:
    case SmallBusinessClassificationName.DBT:
      return classificationNameLevel1(theme);
    case SmallBusinessClassificationName.SDVOSB:
      return classificationNameLevel3(theme);
    default:
      return classificationNameLevel2(theme);
  }
};

export const SmallBusinessClassificationDisplayName: React.FC<SmallBusinessClassificationNameProps> = ({
  classification,
  classificationCoverage,
  reportingAgency,
  displayFormat,
  displayFormatExtension = noFormatExtension,
  tooltipFormat,
  tooltipFormatExtension = noFormatExtension,
  noWrap = false,
  typographyClassNames,
  indent = false,
}) => {
  let typographyStyles = {};
  const theme = useTheme();
  if (indent) {
    typographyStyles = { ...getIndentationStyles(classification.name, theme) };
  }
  if (typographyClassNames) {
    typographyStyles = { ...typographyClassNames };
  }
  const typography = (
    <Typography sx={{ ...typographyStyles }} width={noWrap ? 'auto' : 'fit-content'}>
      {displayFormatExtension(
        smallBusinessClassificationNameFormatFunctions[displayFormat](
          classification,
          classificationCoverage,
          reportingAgency,
        ) as string,
      )}
    </Typography>
  );

  if (!!tooltipFormat) {
    return (
      <Tooltip
        arrow
        title={tooltipFormatExtension(
          smallBusinessClassificationNameFormatFunctions[tooltipFormat](
            classification,
            classificationCoverage,
            reportingAgency,
          ) as string,
        )}
      >
        {typography}
      </Tooltip>
    );
  } else {
    return typography;
  }
};

export default SmallBusinessClassificationDisplayName;
