import AddFile from 'components/shared/AddFile';
import StyledNotice, { NoticeTypeOption } from 'components/shared/Notice';
import { SmallBusinessClassificationName } from 'constants/classificationNames';
import { LOWER_TIER_FILE_SUMMARY_DICTIONARY } from 'constants/lowerTierFileSummary';
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import {
  LowerTierFileSummary,
  LowerTierFileSummaryDictionary,
  PickedSmallBusinessClassification,
  SmallBusinessClassificationSelection,
  SmallBusinessClassificationsFromQuery,
} from 'types';
import { SmallBusinessAgency, SmallBusinessClassification, SmallBusinessClient } from 'types/generated/graphql';

import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import {
  Autocomplete,
  Chip,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  SxProps,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';

const containerStyles: SxProps<Theme> = (theme: Theme) => ({
  padding: theme.spacing(2),
});

const formStyles: SxProps<Theme> = {
  width: '100%',
};

const infoLabel: SxProps<Theme> = {
  lineHeight: '1.5',
  fontSize: '0.75rem',
  fontWeight: 700,
  color: 'rgba(0, 0, 0, 0.6)',
  marginTop: '10px',
};

const errorMessageStyle: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.error.main,
  marginLeft: '14px',
  marginTop: '4px',
  fontSize: '.75rem',
});

const warningMessageStyle: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.warning.dark,
  marginLeft: '14px',
  fontSize: '.90rem',
});

const itemStyles: SxProps<Theme> = (theme: Theme) => ({
  paddingTop: theme.spacing(1),
});

type LowerTierFileSummaryDialogProps = {
  control: Control<any>;
  lowerTierFileSummary: LowerTierFileSummary;
  handleLowerTierFileSummaryChange: (a: any, b: number, c: keyof LowerTierFileSummary) => void;
  lowerTierSummaryIndex: number;
  smallBusinessAgencyList?: SmallBusinessAgency[] | undefined;
  allSmallBusinessClassifications: SmallBusinessClassificationSelection;
  handleRemoveLowerTierFileSummary: (x: number) => void;
  handleLowerTierFileSummaryInputFields: (x: LowerTierFileSummary[], y: File[]) => void;
  isLowerTierDialogOpen: boolean;
  isAddingLowerTierParticipation: boolean;
  contractSmallBusinessClassifications?: SmallBusinessClassificationsFromQuery;
  selectedValue: number;
  smallBusinessClient?: SmallBusinessClient | undefined;
};

type LowerTierFileSummaryInputFieldProps = {
  control: Control<any>;
  lowerTierFileSummaryDictionary: LowerTierFileSummaryDictionary;
  lowerTierFileSummary: LowerTierFileSummary;
  handleLowerTierFileSummaryChange: (a: any, b: number, c: keyof LowerTierFileSummary) => void;
  lowerTierSummaryIndex: number;
  smallBusinessAgencyList: SmallBusinessAgency[] | undefined;
  allSmallBusinessClassifications: SmallBusinessClassificationSelection;
  selectedAgency: string | undefined | null;
  setSelectedAgency: (x: string | undefined | null) => void;
  selectedClassifications: PickedSmallBusinessClassification[] | null | undefined;
  setSelectedClassifications: (x: PickedSmallBusinessClassification[] | undefined | null) => void;
  participationPercentage: number;
  setParticipationPercentage: (x: number) => void;
  addedFiles: File[];
  setAddedFiles: (x: File[]) => void;
  isAddingLowerTierParticipation: boolean;
  handleLowerTierFileSummaryInputFields: (x: LowerTierFileSummary[], y: File[]) => void;
  contractSmallBusinessClassifications?: SmallBusinessClassificationsFromQuery;
  selectedValue: number;
  smallBusinessClient?: SmallBusinessClient | undefined;
};

const LowerTierFileSummaryInputFields: FC<LowerTierFileSummaryInputFieldProps> = ({
  control,
  lowerTierFileSummaryDictionary,
  lowerTierFileSummary,
  handleLowerTierFileSummaryChange,
  lowerTierSummaryIndex,
  smallBusinessAgencyList,
  allSmallBusinessClassifications,
  selectedAgency,
  setSelectedAgency,
  selectedClassifications,
  setSelectedClassifications,
  participationPercentage,
  setParticipationPercentage,
  addedFiles,
  setAddedFiles,
  isAddingLowerTierParticipation,
  handleLowerTierFileSummaryInputFields,
  contractSmallBusinessClassifications,
  selectedValue,
  smallBusinessClient,
}) => {
  const [agencyPercentageHasChanged, setAgencyPercentageHasChanged] = useState(false);
  const selectedValueWithoutCommas = parseFloat(selectedValue?.toString().replace(/,/g, ''));
  const participationTotal = isNaN(selectedValueWithoutCommas * (participationPercentage / 100))
    ? 0
    : selectedValueWithoutCommas * (participationPercentage / 100);

  const existingClassifications = useMemo(() => {
    return (
      (lowerTierFileSummary.Classifications?.map((classificationId) => {
        return allSmallBusinessClassifications?.find((classification) => classification?.id === classificationId);
      }) as PickedSmallBusinessClassification[]) ?? []
    );
  }, [lowerTierFileSummary.Classifications, allSmallBusinessClassifications]);

  const projectClientClassifications =
    smallBusinessClient?.smallBusinessClientAgencies
      .find((agency) => agency.smallBusinessAgency.id === selectedAgency)
      ?.smallBusinessClientClassifications.map(
        (smallBusinessClientClassification) => smallBusinessClientClassification.smallBusinessClassification,
      )
      .sort((a, b) => a.name.localeCompare(b.name)) ?? [];

  const handleFindAgency = (agencyId: string) => {
    return smallBusinessAgencyList?.find((agency) => agency?.id === agencyId);
  };

  const existingAgency = handleFindAgency(lowerTierFileSummary.Agency as string);
  const existingAgencyId = existingAgency?.id;
  const existingSpecialParticipationPercentForAgency = existingAgency?.participationPercentage as number;

  // Set initial values for agency, classification, participationPercentage, and handle file field change
  useEffect(() => {
    if (lowerTierFileSummaryDictionary.key === 'Agency' && !isAddingLowerTierParticipation) {
      handleLowerTierFileSummaryChange(existingAgencyId, lowerTierSummaryIndex, lowerTierFileSummaryDictionary.key);
      setSelectedAgency(existingAgencyId);
    } else if (lowerTierFileSummaryDictionary.key === 'Classifications' && !isAddingLowerTierParticipation) {
      setSelectedClassifications(existingClassifications);
    } else if (lowerTierFileSummaryDictionary.key === 'Documentation') {
      const documentation = isAddingLowerTierParticipation ? addedFiles : lowerTierFileSummary.Documentation;
      handleLowerTierFileSummaryChange(documentation, lowerTierSummaryIndex, lowerTierFileSummaryDictionary.key);
    } else if (lowerTierFileSummaryDictionary.key === 'Percentage') {
      if (isAddingLowerTierParticipation) {
        handleLowerTierFileSummaryChange(
          agencyPercentageHasChanged ? participationPercentage : existingSpecialParticipationPercentForAgency,
          lowerTierSummaryIndex,
          lowerTierFileSummaryDictionary.key,
        );
        setParticipationPercentage(
          agencyPercentageHasChanged ? participationPercentage : existingSpecialParticipationPercentForAgency,
        );
      } else {
        handleLowerTierFileSummaryChange(
          lowerTierFileSummary.Percentage,
          lowerTierSummaryIndex,
          lowerTierFileSummaryDictionary.key,
        );
        setParticipationPercentage(lowerTierFileSummary.Percentage);
      }
    } else {
      return;
    }
  }, [
    addedFiles,
    existingAgencyId,
    existingClassifications,
    handleLowerTierFileSummaryChange,
    isAddingLowerTierParticipation,
    lowerTierFileSummaryDictionary.key,
    lowerTierFileSummary.Documentation,
    lowerTierSummaryIndex,
    setSelectedAgency,
    setSelectedClassifications,
    lowerTierFileSummary.Percentage,
    participationPercentage,
    setParticipationPercentage,
    existingSpecialParticipationPercentForAgency,
    agencyPercentageHasChanged,
  ]);

  // Pass input values changes to EditLowerTierDialog when editing
  useEffect(() => {
    return handleLowerTierFileSummaryInputFields(
      [
        {
          id: lowerTierFileSummary.id,
          Agency: lowerTierFileSummary.Agency,
          Classifications: lowerTierFileSummary.Classifications,
          Documentation: lowerTierFileSummary.Documentation,
          Percentage: lowerTierFileSummary.Percentage,
        },
      ],
      addedFiles ?? null,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAgency, selectedClassifications, addedFiles, participationPercentage]);

  const agencyHasSpecialParticipationPercentage =
    existingSpecialParticipationPercentForAgency > 0 && existingSpecialParticipationPercentForAgency < 100;
  const selectedSmallBusinessAgency = handleFindAgency(selectedAgency as string);

  const smallBusinessClassificationIdByName = allSmallBusinessClassifications?.reduce<{
    [name: string]: string;
  }>((accumulator, classification) => {
    accumulator[classification?.name] = classification?.id;
    return accumulator;
  }, {});

  const largeBusinessClassificationId =
    smallBusinessClassificationIdByName && smallBusinessClassificationIdByName[SmallBusinessClassificationName.LB];

  const largeBusinessClassification = allSmallBusinessClassifications?.find(
    (classification) => classification.id === largeBusinessClassificationId,
  ) as PickedSmallBusinessClassification;

  const largeBusinessClassificationContract = contractSmallBusinessClassifications?.some(
    (classification) => classification.id === largeBusinessClassificationId,
  );

  const smallBusinessAgenciesById = smallBusinessAgencyList?.reduce<{
    [id: string]: SmallBusinessAgency;
  }>((accumulator, agency) => {
    accumulator[agency?.id] = agency;
    return accumulator;
  }, {});

  const handleSelectClassification = (_: any, classifications: PickedSmallBusinessClassification[] | null) => {
    handleLowerTierFileSummaryChange(classifications, lowerTierSummaryIndex, lowerTierFileSummaryDictionary.key);
    setSelectedClassifications(classifications);
  };

  const handleParticipationPercentageChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const percentAsNumber = parseFloat(event.target.value);
    const percentage = isNaN(percentAsNumber) ? 0 : percentAsNumber;
    handleLowerTierFileSummaryChange(percentage, lowerTierSummaryIndex, lowerTierFileSummaryDictionary.key);
    setParticipationPercentage(percentage);
    setAgencyPercentageHasChanged(true);
  };

  const percentageHasError = () => {
    return participationPercentage && !(participationPercentage >= 0 && participationPercentage <= 100);
  };

  const handleDisabledClassifications = useCallback(
    (option) => {
      const selectedClassificationId = selectedClassifications?.map((selectedClassification) => {
        return selectedClassification?.id;
      });

      if (selectedClassifications?.length) {
        if (selectedClassificationId?.includes(largeBusinessClassificationId as string)) {
          return option?.id !== largeBusinessClassificationId;
        } else {
          return option?.id === largeBusinessClassificationId;
        }
      }
      return option;
    },
    [largeBusinessClassificationId, selectedClassifications],
  );

  const handleDeleteClassifications = (classificationToDelete: string) => {
    const classificationsToKeep = selectedClassifications?.filter(
      (classification) => classification.id !== classificationToDelete,
    );
    handleLowerTierFileSummaryChange(classificationsToKeep, lowerTierSummaryIndex, lowerTierFileSummaryDictionary.key);
    setSelectedClassifications(classificationsToKeep);
  };

  const handleIsAllowed = (values: NumberFormatValues) => {
    const { formattedValue, floatValue } = values;
    if (floatValue === undefined) {
      setParticipationPercentage(0);
    }
    return (floatValue !== undefined && floatValue >= 0) || formattedValue === '';
  };

  const lowerTierFileSummaryLabel =
    (lowerTierFileSummaryDictionary.label.includes('Compliance') && !selectedAgency) ||
    (lowerTierFileSummaryDictionary.label.includes('LB') &&
      (largeBusinessClassificationContract ||
        !selectedClassifications?.some((classification) => classification.id === largeBusinessClassificationId)))
      ? ''
      : lowerTierFileSummaryDictionary.label;

  return (
    <Grid item>
      <Controller
        name={lowerTierFileSummaryDictionary.name}
        control={control}
        render={() => (
          <>
            <Typography sx={infoLabel}>{lowerTierFileSummaryLabel}</Typography>
            {lowerTierFileSummaryDictionary.key === 'Agency' ? (
              <>
                <Autocomplete
                  value={selectedAgency ?? ''}
                  onChange={(_: any, agencyId: string | null) => {
                    handleLowerTierFileSummaryChange(
                      agencyId,
                      lowerTierSummaryIndex,
                      lowerTierFileSummaryDictionary.key,
                    );
                    setSelectedAgency(agencyId);
                    handleLowerTierFileSummaryChange([], lowerTierSummaryIndex, 'Classifications');
                    setSelectedClassifications([]);
                  }}
                  id={`combo-box-agencies-${lowerTierFileSummary.id ?? lowerTierFileSummary.transactionKey}`}
                  options={smallBusinessAgencyList?.map((agency) => agency?.id) ?? []}
                  getOptionLabel={(option: string) => {
                    const agency = smallBusinessAgenciesById && smallBusinessAgenciesById[option];
                    return `${agency?.name ?? ''}`;
                  }}
                  renderInput={(params: any) => <TextField {...params} />}
                  size="small"
                  filterSelectedOptions
                />
              </>
            ) : lowerTierFileSummaryDictionary.key === 'Classifications' ? (
              <>
                <Autocomplete
                  value={selectedClassifications ?? []}
                  id={`combo-box-classifications-${lowerTierFileSummary.id ?? lowerTierFileSummary.transactionKey}`}
                  multiple
                  options={
                    projectClientClassifications.length > 0
                      ? projectClientClassifications
                      : [largeBusinessClassification] ?? []
                  }
                  isOptionEqualToValue={(
                    option: PickedSmallBusinessClassification,
                    value: PickedSmallBusinessClassification,
                  ) => {
                    return option.id === value.id;
                  }}
                  getOptionLabel={(option: { abbreviation: string; name: string }) =>
                    `${option?.abbreviation} (${option?.name})`
                  }
                  renderInput={(params: any) => <TextField {...params} />}
                  onChange={(_: any, classifications: PickedSmallBusinessClassification[]) => {
                    handleSelectClassification(_, classifications);
                  }}
                  size="small"
                  getOptionDisabled={handleDisabledClassifications}
                  filterSelectedOptions
                  renderTags={(value: any[], getTagProps: any) => {
                    const classifications = value
                      .map((classification: SmallBusinessClassification) => {
                        return classification;
                      })
                      .sort((a, b) => a.name.localeCompare(b.name));

                    return classifications.map((classification, index) => {
                      return (
                        <Tooltip arrow title={`${classification?.name}`}>
                          <div key={index}>
                            <Chip
                              variant="outlined"
                              label={classification?.abbreviation}
                              {...getTagProps({ index })}
                              onDelete={() => handleDeleteClassifications(classification.id)}
                            />
                          </div>
                        </Tooltip>
                      );
                    });
                  }}
                />
                <Grid sx={errorMessageStyle}>
                  {(selectedClassifications && selectedClassifications.length === 0) ||
                  existingClassifications.length === 0
                    ? 'Classification(s) are required'
                    : null}
                </Grid>
              </>
            ) : lowerTierFileSummaryDictionary.key === 'Percentage' ? (
              <Grid container display={'flex'}>
                <Grid item xs={3}>
                  <NumberFormat
                    decimalScale={2}
                    isAllowed={(values: NumberFormatValues) => handleIsAllowed(values)}
                    allowLeadingZeros={false}
                    isNumericString={true}
                    margin="dense"
                    variant="outlined"
                    fullWidth={true}
                    color="primary"
                    customInput={TextField}
                    error={percentageHasError() || agencyHasSpecialParticipationPercentage}
                    helperText={
                      percentageHasError() ? (
                        <Typography sx={errorMessageStyle}>Value must be a number in the range of 0 to 100</Typography>
                      ) : (
                        ''
                      )
                    }
                    value={participationPercentage}
                    defaultValue={existingSpecialParticipationPercentForAgency}
                    onChange={(event) => handleParticipationPercentageChange(event)}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  {agencyHasSpecialParticipationPercentage ? (
                    <Typography
                      sx={warningMessageStyle}
                    >{`Warning: This agency has set a default participation percentage of ${existingSpecialParticipationPercentForAgency}%. Please use caution when changing this field.`}</Typography>
                  ) : (
                    ''
                  )}
                </Grid>
                <Grid item xs={12} sx={itemStyles}>
                  {selectedAgency && selectedSmallBusinessAgency?.specialNotice && (
                    <>
                      <Grid item xs={12} sx={itemStyles}>
                        <Divider variant="middle" light={true} />
                      </Grid>
                      <StyledNotice
                        type={
                          agencyHasSpecialParticipationPercentage ? NoticeTypeOption.Warning : NoticeTypeOption.Notice
                        }
                        message={<Typography>{selectedSmallBusinessAgency?.specialNotice}</Typography>}
                      />
                    </>
                  )}
                </Grid>
              </Grid>
            ) : lowerTierFileSummaryDictionary.key === 'Participation Total' &&
              selectedValueWithoutCommas &&
              !percentageHasError() ? (
              <Grid item>
                <NumberFormat
                  value={participationTotal}
                  displayType={'text'}
                  prefix={'$'}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  allowLeadingZeros={false}
                  isNumericString={true}
                  thousandSeparator={true}
                  margin="dense"
                  variant="outlined"
                  color="primary"
                />
              </Grid>
            ) : (
              lowerTierFileSummaryDictionary.key === 'Documentation' &&
              selectedAgency && (
                <>
                  <Grid item>
                    <AddFile
                      id={`add-file-${lowerTierFileSummary.id ?? lowerTierFileSummary.transactionKey}`}
                      setAddedFiles={setAddedFiles}
                      addedFiles={addedFiles}
                      existingFiles={lowerTierFileSummary.Documentation ?? []}
                      maxNumFiles={1}
                      isEditable={lowerTierFileSummary.Documentation.length === 0}
                    />
                    <Grid sx={errorMessageStyle}>
                      {(isAddingLowerTierParticipation && addedFiles.length === 0) ||
                      (!isAddingLowerTierParticipation &&
                        lowerTierFileSummary.Documentation.length === 0 &&
                        addedFiles.length === 0)
                        ? 'Compliance Documentation is required'
                        : null}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sx={itemStyles}>
                    <Divider variant="middle" light={true} />
                  </Grid>
                  <StyledNotice
                    type={NoticeTypeOption.Notice}
                    message={
                      <Grid item>
                        <Typography>
                          Please ensure compliance documentation is effective as of the contract effective date.
                        </Typography>
                        {selectedSmallBusinessAgency?.url && (
                          <Typography sx={itemStyles}>
                            For agency compliance documentation, please go to:{' '}
                            {
                              <a
                                href={selectedSmallBusinessAgency?.url}
                                target="_blank"
                                rel="noreferrer"
                                title={selectedSmallBusinessAgency?.url}
                              >
                                Agency Documentation ({selectedSmallBusinessAgency.name})
                              </a>
                            }
                          </Typography>
                        )}
                      </Grid>
                    }
                  />
                </>
              )
            )}
          </>
        )}
      />
    </Grid>
  );
};

const LowerTierFileSummaryDialog: React.FC<LowerTierFileSummaryDialogProps> = ({
  control,
  lowerTierFileSummary,
  handleLowerTierFileSummaryChange,
  lowerTierSummaryIndex,
  smallBusinessAgencyList,
  handleRemoveLowerTierFileSummary,
  handleLowerTierFileSummaryInputFields,
  isLowerTierDialogOpen,
  isAddingLowerTierParticipation,
  allSmallBusinessClassifications,
  contractSmallBusinessClassifications,
  selectedValue,
  smallBusinessClient,
}) => {
  const [addedFiles, setAddedFiles] = useState<File[]>([]);
  const [selectedAgency, setSelectedAgency] = useState<string | null | undefined>('');
  const [selectedClassifications, setSelectedClassifications] = useState<
    PickedSmallBusinessClassification[] | null | undefined
  >([]);
  const [participationPercentage, setParticipationPercentage] = useState<number>(100);

  // Set addedFile to empty array if cancelling changes to Lower Tier Participation
  useEffect(() => {
    return !isLowerTierDialogOpen ? setAddedFiles([]) : undefined;
  }, [isLowerTierDialogOpen]);

  return (
    <Grid container sx={containerStyles} justifyContent={'space-between'}>
      <Grid sx={formStyles}>
        <Grid item xs={12} sx={itemStyles}>
          <Divider variant="middle" light={true} />
        </Grid>
        <StyledNotice
          type={NoticeTypeOption.Notice}
          message={
            <Typography>
              If an agency or classification isn’t found, please contact your Supplier Diversity representative to have
              the information added.
            </Typography>
          }
        />
        {LOWER_TIER_FILE_SUMMARY_DICTIONARY.map(
          (lowerTierFileSummaryDictionary: LowerTierFileSummaryDictionary, index: number) => {
            return (
              <>
                <LowerTierFileSummaryInputFields
                  key={index}
                  lowerTierSummaryIndex={lowerTierSummaryIndex}
                  control={control}
                  lowerTierFileSummaryDictionary={lowerTierFileSummaryDictionary}
                  lowerTierFileSummary={lowerTierFileSummary}
                  handleLowerTierFileSummaryChange={handleLowerTierFileSummaryChange}
                  smallBusinessAgencyList={smallBusinessAgencyList}
                  allSmallBusinessClassifications={allSmallBusinessClassifications}
                  selectedAgency={selectedAgency}
                  setSelectedAgency={setSelectedAgency}
                  selectedClassifications={selectedClassifications}
                  setSelectedClassifications={setSelectedClassifications}
                  participationPercentage={participationPercentage}
                  setParticipationPercentage={setParticipationPercentage}
                  addedFiles={addedFiles}
                  setAddedFiles={setAddedFiles}
                  isAddingLowerTierParticipation={isAddingLowerTierParticipation}
                  handleLowerTierFileSummaryInputFields={handleLowerTierFileSummaryInputFields}
                  contractSmallBusinessClassifications={contractSmallBusinessClassifications}
                  selectedValue={selectedValue}
                  smallBusinessClient={smallBusinessClient}
                />
              </>
            );
          },
        )}
        {((isAddingLowerTierParticipation && lowerTierSummaryIndex > 0) || !isAddingLowerTierParticipation) && (
          <Grid item>
            <Tooltip title={'Remove Lower Tier File Summary'}>
              <IconButton
                onClick={() => {
                  handleRemoveLowerTierFileSummary(lowerTierSummaryIndex);
                }}
              >
                <DeleteForeverOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default LowerTierFileSummaryDialog;
