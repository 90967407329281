import { FC } from 'react';

import { Grid, SxProps, Theme } from '@mui/material';

type Props = {
  /** standard children prop: accepts any valid React Node */
  children: React.ReactNode;
};

const root: SxProps<Theme> = (theme: Theme) => ({
  backgroundColor: theme.palette.background.default,
  minHeight: 'calc(100vh - 64px)',
  height: 'auto',
  paddingTop: 7,
  [theme.breakpoints.down('sm')]: {
    minHeight: 'calc(100vh)',
    paddingTop: 0,
  },
});

const padding: SxProps<Theme> = (theme: Theme) => ({
  padding: theme.spacing(1, 4.5, 3, 4.5),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3, 2),
  },
});

export const RootContainer: FC<Props> = ({ children }) => {
  return (
    <Grid sx={root}>
      <Grid sx={padding}>{children}</Grid>
    </Grid>
  );
};

export default RootContainer;
