import PropTypes from 'prop-types';
import { useState } from 'react';
import { GroupBase, OnChangeValue } from 'react-select';
import {
  GetPaginatedCompaniesQuery,
  SortOrderOption,
  useGetPaginatedCompaniesQuery,
  useGetSingleCompanyQuery,
} from 'types/generated/graphql';

import { StyledAutoComplete, StyledAutoCompleteOptions } from '../../shared/StyledAutoComplete';

type CompanySelection = GetPaginatedCompaniesQuery['companyListResult']['items'][number];

interface CompanySelectOptions<
  IsMulti extends boolean = false,
  Group extends GroupBase<CompanySelection> = GroupBase<CompanySelection>,
> extends StyledAutoCompleteOptions<CompanySelection, IsMulti, Group> {
  selectedCompanyId?: string;
}

function CompanySelect<
  IsMulti extends boolean = false,
  Group extends GroupBase<CompanySelection> = GroupBase<CompanySelection>,
>({
  selectedCompanyId,
  onChange,
  placeholder = 'Select a company',
  isLoading,
  ...rest
}: CompanySelectOptions<IsMulti, Group>): JSX.Element {
  const [inputValue, handleInputChange] = useState('');
  const [selectedCompanyIdState, setSelectedCompanyIdState] = useState(selectedCompanyId);

  const COMPANY_SEARCH_PAGE_SIZE = 100;

  const { data: paginatedCompanyData, loading: paginatedCompaniesIsLoading } = useGetPaginatedCompaniesQuery({
    skip: !inputValue,
    variables: {
      search: inputValue,
      first: COMPANY_SEARCH_PAGE_SIZE,
      skip: 0,
      order: { field: 'name', direction: SortOrderOption.Asc },
    },
    fetchPolicy: 'no-cache',
  });

  const { data: selectedCompanyData, loading: selectedCompanyIsLoading } = useGetSingleCompanyQuery({
    skip: !selectedCompanyIdState,
    variables: {
      id: selectedCompanyIdState as string,
    },
    fetchPolicy: 'no-cache',
  });
  const selectedCompany = selectedCompanyData?.company;

  function isCompanySelection(value: unknown): value is CompanySelection {
    return (value as CompanySelection)?.__typename === 'Company';
  }

  const companyOptions = paginatedCompanyData?.companyListResult?.items ?? [];
  const isFullPageOfCompanyResults = companyOptions.length >= COMPANY_SEARCH_PAGE_SIZE;
  return (
    <StyledAutoComplete<CompanySelection, IsMulti, Group>
      formatOptionLabel={(company) => company.name}
      placeholder={placeholder}
      selectedValue={selectedCompany}
      isClearable={true}
      onChange={(value, actionMeta) => {
        if (!isCompanySelection(value) && value !== null) {
          throw new Error('Unexpected type for company selection');
        }
        setSelectedCompanyIdState(value?.id);
        onChange?.(value as OnChangeValue<CompanySelection, IsMulti>, actionMeta);
      }}
      onInputChange={(value) => handleInputChange(value)}
      options={isFullPageOfCompanyResults ? [] : companyOptions}
      isLoading={isLoading || paginatedCompaniesIsLoading || selectedCompanyIsLoading}
      noOptionsMessage={({ inputValue }) =>
        inputValue
          ? isFullPageOfCompanyResults
            ? 'Search is too broad. Please enter a more specific search term.'
            : 'Please enter a search term.'
          : 'No Options'
      }
      {...rest}
    />
  );
}

CompanySelect.propTypes = {
  selectedCompanyId: PropTypes.string,
};

export default CompanySelect;
