import StyledButtonPrimary from 'components/shared/ButtonPrimary';
import StyledButtonSecondary from 'components/shared/ButtonSecondary/ButtonSecondary';
import StyledDialog from 'components/shared/Dialog';
import ErrorNotice from 'components/shared/ErrorNotice';
import StyledNotice, { NoticeTypeOption } from 'components/shared/Notice';
import useToast from 'hooks/useToast';
import { FC, useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  GetSmallBusinessClassificationsQuery,
  LowerTierParticipation,
  NestedSmallBusinessClientClassification,
  SmallBusinessAgencyListQuery,
  SmallBusinessClassification,
  SmallBusinessClient,
  SmallBusinessClientAgency,
  useGetLowerTierParticipationsInUseQuery,
  useUpsertSmallBusinessClientMutation,
} from 'types/generated/graphql';

import { useApolloClient } from '@apollo/client';
import {
  Autocomplete,
  Box,
  Chip,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  SxProps,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';

const containerStyles: SxProps<Theme> = (theme: Theme) => ({
  padding: theme.spacing(2),
});

const formStyles: SxProps<Theme> = {
  width: '100%',
};

const nameInputLabelStyle: SxProps<Theme> = (theme: Theme) => ({
  marginBottom: '-8px',
  marginTop: '5px',
  color: theme.palette.secondary.contrastText,
});

const inputContainer: SxProps<Theme> = {
  marginTop: '20px',
};

const classificationLabelStyle: SxProps<Theme> = (theme: Theme) => ({
  marginBottom: '-15px',
  marginTop: '5px',
  color: theme.palette.secondary.contrastText,
});

const classificationChipStyles: SxProps<Theme> = (theme: Theme) => ({
  paddingTop: theme.spacing(1),
});

const textFieldStyles: SxProps<Theme> = (theme: Theme) => ({
  paddingTop: theme.spacing(0),
});

const unableToDeleteMessageStyles: SxProps<Theme> = {
  marginBottom: '20px',
};

const dataInUseLabelStyle: SxProps<Theme> = {
  fontWeight: 'bold',
  marginTop: '20px',
};

const dataInUseContainerStyle: SxProps<Theme> = {
  maxHeight: '35vh',
  overflow: 'auto',
};

type SmallBusinessClassificationsSelection = GetSmallBusinessClassificationsQuery['smallBusinessClassificationList'];
type SmallBusinessAgencyListSelection = SmallBusinessAgencyListQuery['smallBusinessAgencyList'];

type PickedSmallBusinessClassification = Pick<
  SmallBusinessClassification,
  'id' | 'name' | 'abbreviation' | 'isFederal' | 'sortOrder'
>;

type EditClientAgencyAndClassificationDialogProps = {
  isOpen: boolean;
  setIsOpen: (x: boolean) => void;
  smallBusinessClient: SmallBusinessClient;
  smallBusinessClassificationsList: SmallBusinessClassificationsSelection;
  smallBusinessAgenciesList: SmallBusinessAgencyListSelection;
  smallBusinessAgencyAndClassificationDetails: SmallBusinessClientAgency;
};

type EditClientAgencyAndClassificationInput = {
  agency?: string | null;
  classifications?: string[] | undefined;
};

const EditClientAgencyAndClassificationDialog: FC<EditClientAgencyAndClassificationDialogProps> = ({
  isOpen,
  setIsOpen,
  smallBusinessClient,
  smallBusinessClassificationsList,
  smallBusinessAgenciesList,
  smallBusinessAgencyAndClassificationDetails,
}) => {
  const { displayToast } = useToast();
  const apolloClient = useApolloClient();
  const [agency, setAgency] = useState<string | undefined>('');
  const [classifications, setClassifications] = useState<string[]>();

  const [updateClientAgencyAndClassifications, { loading: isLoading }] = useUpsertSmallBusinessClientMutation();

  const {
    loading: usedLowerTierParticipationsLoading,
    error: usedLowerTierParticipationsError,
    data: usedLowerTierParticipationsData,
  } = useGetLowerTierParticipationsInUseQuery({
    client: apolloClient,
    variables: {
      smallBusinessAgencyId: agency,
      smallBusinessClassificationIds: classifications,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    skip: typeof agency !== 'string',
  });

  const allLowerTierParticipationsInUse = usedLowerTierParticipationsData?.getLowerTierParticipationsInUse ?? [];

  const lowerTierParticipationsUsedByAgency = allLowerTierParticipationsInUse?.filter((lowerTierParticipations) =>
    lowerTierParticipations.lowerTierParticipationFileSummary?.filter(
      (lowerTierFileSummary) => lowerTierFileSummary.smallBusinessAgencyId === agency,
    ),
  );

  const lowerTierParticipationsUsedByClassifications = allLowerTierParticipationsInUse?.filter(
    (lowerTierParticipations) =>
      lowerTierParticipations.lowerTierParticipationFileSummary?.filter((lowerTierFileSummary) =>
        lowerTierFileSummary.lowerTierParticipationClassifications?.filter((classification) =>
          classifications?.includes(classification.smallBusinessClassification.id),
        ),
      ),
  );

  const classificationsBeingUsed = lowerTierParticipationsUsedByClassifications?.flatMap((lowerTierParticipation) =>
    lowerTierParticipation.lowerTierParticipationFileSummary?.flatMap((summary) =>
      summary.lowerTierParticipationClassifications?.flatMap(
        (classification) => classification.smallBusinessClassification,
      ),
    ),
  ) as PickedSmallBusinessClassification[];

  const lowerTierParticipationsByClassificationAbbr = classificationsBeingUsed?.reduce<{
    [abbreviation: string]: any;
  }>((accumulator, classification) => {
    accumulator[classification?.abbreviation] = lowerTierParticipationsUsedByClassifications?.flatMap(
      (usedLowerTierParticipation) =>
        usedLowerTierParticipation.lowerTierParticipationFileSummary?.flatMap((fileSummary) =>
          fileSummary.lowerTierParticipationClassifications?.flatMap((lowerTierClassification) =>
            lowerTierClassification.smallBusinessClassification.id === classification?.id
              ? usedLowerTierParticipation
              : [],
          ),
        ),
    );
    return accumulator;
  }, {});

  const previouslySelectedAgencyNameById = smallBusinessAgencyAndClassificationDetails?.smallBusinessAgency?.id ?? '';

  const previouslySelectedClassificationsById = useMemo(() => {
    return (
      smallBusinessAgencyAndClassificationDetails?.smallBusinessClientClassifications.map(
        (classification) => classification?.smallBusinessClassification?.id,
      ) ?? []
    );
  }, [smallBusinessAgencyAndClassificationDetails?.smallBusinessClientClassifications]);

  const defaultValues = {
    agency: agency ?? '',
    classifications: classifications ?? [],
  };

  const { handleSubmit, control, setValue, reset } = useForm({
    defaultValues,
  });

  useEffect(() => {
    setAgency(previouslySelectedAgencyNameById);
    setClassifications(previouslySelectedClassificationsById);
  }, [previouslySelectedAgencyNameById, previouslySelectedClassificationsById]);

  const findClassificationsBySelectedAgency = smallBusinessAgenciesList?.find(
    (smallBusinessAgency) => smallBusinessAgency.id === agency,
  )?.smallBusinessAgencyClassifications;

  const agencyAcceptedClassifications = findClassificationsBySelectedAgency
    ? [...findClassificationsBySelectedAgency].sort((a, b) =>
        a.smallBusinessClassification.name.localeCompare(b.smallBusinessClassification.name),
      )
    : [];

  const agencyAcceptedClassificationListById =
    agencyAcceptedClassifications?.map((classification) => classification.smallBusinessClassification.id) ?? [];

  const allClassificationsById = smallBusinessClassificationsList.map((classification) => classification.id) ?? [];

  const handleClose = () => {
    reset(defaultValues);
    setAgency(previouslySelectedAgencyNameById);
    setClassifications(previouslySelectedClassificationsById);
    setIsOpen(false);
  };
  const smallBusinessClassificationsById = smallBusinessClassificationsList.reduce<{
    [id: string]: SmallBusinessClassificationsSelection[number];
  }>((accumulator, classification) => {
    accumulator[classification.id] = classification;
    return accumulator;
  }, {});

  const smallBusinessAgenciesById = smallBusinessAgenciesList.reduce<{
    [id: string]: SmallBusinessAgencyListSelection[number];
  }>((accumulator, agency) => {
    accumulator[agency.id] = agency;
    return accumulator;
  }, {});

  const isFormValid = () => {
    return agency;
  };

  if (usedLowerTierParticipationsError) {
    console.error(usedLowerTierParticipationsError);
    displayToast(
      'Error: Something went wrong while trying to update the client information. If the problem persists after reloading the page, please contact support.',
      'error',
    );
  }

  if (usedLowerTierParticipationsError) {
    return <ErrorNotice />;
  }

  const onSubmit: SubmitHandler<EditClientAgencyAndClassificationInput> = () => {
    if (!smallBusinessClient.id) {
      displayToast(
        'Error: Something went wrong while trying to update the client information. Please contact support.',
        'error',
      );
      return;
    }

    const classificationIds = classifications?.map((id) => ({
      smallBusinessClassificationId: id,
    })) as NestedSmallBusinessClientClassification[];

    const updatedSmallBusinessAgencyAndClassifications = smallBusinessClient?.smallBusinessClientAgencies?.map(
      (smallBusinessClientAgency) => {
        if (smallBusinessClientAgency.id === smallBusinessAgencyAndClassificationDetails.id) {
          return {
            smallBusinessAgencyId: agency ?? '',
            smallBusinessClientClassifications: classificationIds ?? [],
            smallBusinessClientId: smallBusinessClient.id,
          };
        } else {
          return {
            smallBusinessAgencyId: smallBusinessClientAgency.smallBusinessAgency?.id,
            smallBusinessClientClassifications:
              smallBusinessClientAgency.smallBusinessClientClassifications?.map((classification) => {
                return { smallBusinessClassificationId: classification.smallBusinessClassification.id };
              }) ?? [],
            smallBusinessClientId: smallBusinessClient.id,
          };
        }
      },
    );

    return updateClientAgencyAndClassifications({
      variables: {
        input: {
          id: smallBusinessClient.id,
          name: smallBusinessClient.name,
          abbreviation: smallBusinessClient.abbreviation ?? '',
          isActive: smallBusinessClient.isActive,
          smallBusinessClientAgencies: updatedSmallBusinessAgencyAndClassifications ?? [],
          isFederal: smallBusinessClient.isFederal,
        },
      },
    })
      .then(() => {
        handleClose();
        apolloClient.reFetchObservableQueries();
        displayToast("The client's agency and classification details were updated successfully", 'success');
      })
      .catch((error: any) => {
        console.error('Add client failed: ', error);
        displayToast(
          "Error: Something went wrong while trying to update the client's agency and classification details. Please try again. If the problem persists, please contact support.",
          'error',
        );
      });
  };

  const handleDeleteClassifications = (classificationToDelete: string) => {
    const classificationsToKeep = classifications?.filter(
      (classification) => classification !== classificationToDelete,
    );
    setClassifications(classificationsToKeep);
  };

  return (
    <StyledDialog
      title={`${smallBusinessClient?.name} Agency and Classification Info`}
      isLoading={isLoading}
      content={
        <Grid container sx={containerStyles}>
          <Grid sx={formStyles}>
            <form>
              <Grid item>
                <InputLabel sx={nameInputLabelStyle} shrink>
                  Agency Name
                </InputLabel>
                <Controller
                  name="agency"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      value={agency}
                      onChange={(_: any, agencyId: string | undefined) => {
                        setValue('agency', agencyId ?? '');
                        setAgency(agencyId);
                        field.onChange(agencyId);
                        setValue('classifications', []);
                        setClassifications([]);
                      }}
                      disableClearable={true}
                      disabled={!lowerTierParticipationsUsedByAgency.length ? false : true}
                      id="combo-box-agencies"
                      options={smallBusinessAgenciesList?.map((agency) => agency.id) ?? []}
                      getOptionLabel={(option: string) => {
                        const agency = smallBusinessAgenciesById[option];
                        return `${agency?.name}`;
                      }}
                      renderInput={(params: any) => <TextField {...params} />}
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item sx={inputContainer}>
                <InputLabel sx={classificationLabelStyle} shrink>
                  Classifications
                </InputLabel>
                <Controller
                  name={'classifications'}
                  control={control}
                  render={({ field }) => (
                    <Grid item xs={12} sx={classificationChipStyles}>
                      <Autocomplete
                        {...field}
                        value={classifications}
                        isOptionEqualToValue={(option: string, value: string) => option === value}
                        onChange={(_: any, classificationIds: string[]) => {
                          setValue('classifications', classificationIds);
                          setClassifications(classificationIds);
                          field.onChange(classificationIds);
                        }}
                        id="combo-box-classifications"
                        multiple
                        options={
                          agencyAcceptedClassificationListById.length > 0
                            ? agencyAcceptedClassificationListById
                            : allClassificationsById ?? []
                        }
                        filterSelectedOptions
                        getOptionLabel={(option: string) => {
                          const classification = smallBusinessClassificationsById[option];
                          return `${classification?.abbreviation} (${classification?.name})`;
                        }}
                        renderTags={(value: string[], getTagProps: any) => {
                          const classifications = value
                            .map((option: SmallBusinessClassification['id']) => {
                              return smallBusinessClassificationsById[option];
                            })
                            .sort((a, b) => a.name.localeCompare(b.name));

                          return classifications.map((classification, index) => {
                            return (
                              <Tooltip arrow title={`${classification.name}`} key={classification?.id}>
                                <div key={index}>
                                  <Chip
                                    variant="outlined"
                                    label={classification?.abbreviation}
                                    {...getTagProps({ index })}
                                    disabled={lowerTierParticipationsByClassificationAbbr[classification?.abbreviation]}
                                    onDelete={() => handleDeleteClassifications(classification.id)}
                                  />
                                </div>
                              </Tooltip>
                            );
                          });
                        }}
                        renderInput={(params: any) => <TextField {...params} sx={textFieldStyles} />}
                        size="small"
                        disabled={!agency}
                      />
                    </Grid>
                  )}
                />
              </Grid>
              <Grid item sx={inputContainer}>
                {!usedLowerTierParticipationsLoading && allLowerTierParticipationsInUse.length !== 0 && (
                  <Box sx={dataInUseContainerStyle}>
                    <StyledNotice
                      type={NoticeTypeOption.Notice}
                      message={
                        <Grid container>
                          <Grid item xs={12} sm={12} md={12} lg={12} sx={unableToDeleteMessageStyles}>
                            <Typography>
                              Unable to edit the agency and/or classification(s) as it is being used by the following:
                            </Typography>
                          </Grid>
                          {lowerTierParticipationsUsedByAgency.length !== 0 && (
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <List>
                                <Grid item>
                                  <Typography sx={dataInUseLabelStyle}>
                                    Lower Tier Participations using agency:
                                  </Typography>
                                </Grid>
                                {lowerTierParticipationsUsedByAgency?.map((lowerTierParticipation) => (
                                  <ListItem key={lowerTierParticipation.id}>
                                    {lowerTierParticipation?.lowerTierCompanyName +
                                      ` (Project: ${lowerTierParticipation.contract.project.number} - ${lowerTierParticipation.contract.project.name})`}
                                  </ListItem>
                                ))}
                              </List>
                            </Grid>
                          )}
                          {lowerTierParticipationsUsedByClassifications.length !== 0 && (
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <List>
                                <Grid item>
                                  <Typography sx={dataInUseLabelStyle}>
                                    Lower Tier Participations using classifications:
                                  </Typography>
                                </Grid>
                                {Object.entries(lowerTierParticipationsByClassificationAbbr)?.map(
                                  (lowerTierParticipations) => {
                                    const classificationAbbrIndex = 0;
                                    const lowerTierParticipationSummaryIndex = 1;
                                    const classificationAbbreviationsInUse =
                                      lowerTierParticipations[classificationAbbrIndex];
                                    const filteredLowerTierParticipations = lowerTierParticipations[
                                      lowerTierParticipationSummaryIndex
                                    ]?.filter(
                                      (lowerTierParticipation: LowerTierParticipation, index: number) =>
                                        lowerTierParticipations[lowerTierParticipationSummaryIndex].indexOf(
                                          lowerTierParticipation,
                                        ) === index,
                                    );
                                    const lowerTierParticipationSummary = filteredLowerTierParticipations?.map(
                                      (lowerTierParticipation: LowerTierParticipation) => {
                                        return (
                                          <ListItem key={lowerTierParticipation.id}>
                                            {lowerTierParticipation.lowerTierCompanyName +
                                              ` (Project: ${lowerTierParticipation.contract.project.number} - ${lowerTierParticipation.contract.project.name})`}
                                          </ListItem>
                                        );
                                      },
                                    );
                                    return (
                                      <ListItemText
                                        primary={classificationAbbreviationsInUse}
                                        secondary={lowerTierParticipationSummary}
                                      />
                                    );
                                  },
                                )}
                              </List>
                            </Grid>
                          )}
                        </Grid>
                      }
                    />
                  </Box>
                )}
              </Grid>
            </form>
          </Grid>
        </Grid>
      }
      actions={
        <Grid container justifyContent="center">
          <Grid item>
            <Grid container justifyContent="space-between">
              <Grid item>
                <StyledButtonSecondary disabled={isLoading} label={'cancel'} onClick={handleClose} />
              </Grid>
              <Grid item>
                <StyledButtonPrimary
                  label={'submit'}
                  type="submit"
                  disabled={isLoading || !isFormValid()}
                  onClick={handleSubmit(onSubmit)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      isOpen={isOpen}
      handleClose={handleClose}
    />
  );
};

export default EditClientAgencyAndClassificationDialog;
