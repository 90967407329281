import {
  StyledAutoComplete,
  StyledAutoCompleteOptions,
} from 'components/shared/StyledAutoComplete';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { GroupBase, OnChangeValue } from 'react-select';
import {
  GetAdContactsQuery,
  useGetAdContactQuery,
  useGetAdContactsQuery,
} from 'types/generated/graphql';

export type AdContactSelection = GetAdContactsQuery['adContacts'][number];

interface AdContactSelectOptions<
  IsMulti extends boolean = false,
  Group extends GroupBase<AdContactSelection> = GroupBase<AdContactSelection>,
> extends StyledAutoCompleteOptions<AdContactSelection, IsMulti, Group> {
  selectedMicrosoftAdId?: string;
}

function AdContactSelect<
  IsMulti extends boolean = false,
  Group extends GroupBase<AdContactSelection> = GroupBase<AdContactSelection>,
>({
  selectedMicrosoftAdId,
  onChange,
  placeholder = 'Select a user',
  isLoading,
  ...rest
}: AdContactSelectOptions<IsMulti, Group>): JSX.Element {
  const [inputValue, handleInputChange] = useState('');
  const [selectedMicrosoftAdIdState, setSelectedMicrosoftAdIdState] = useState(selectedMicrosoftAdId);

  const AD_CONTACT_SEARCH_PAGE_SIZE = 100;

  const { data: paginatedAdContactData, loading: paginatedAdContactsIsLoading } = useGetAdContactsQuery({
    skip: !inputValue,
    variables: {
      search: inputValue,
      first: AD_CONTACT_SEARCH_PAGE_SIZE,
    },
    fetchPolicy: 'no-cache',
  });

  const { data: selectedAdContactData, loading: selectedAdContactIsLoading } = useGetAdContactQuery({
    skip: !selectedMicrosoftAdIdState,
    variables: {
      microsoftAdId: selectedMicrosoftAdIdState as string,
    },
    fetchPolicy: 'no-cache',
  });
  const selectedAdContact = selectedAdContactData?.adContact;

  function isAdContactSelection(value: unknown): value is AdContactSelection {
    return (value as AdContactSelection)?.__typename === 'AdContact';
  }

  const adContactOptions = paginatedAdContactData?.adContacts ?? [];
  const isFullPageOfResults = adContactOptions.length >= AD_CONTACT_SEARCH_PAGE_SIZE;
  return (
    <StyledAutoComplete<AdContactSelection, IsMulti, Group>
      formatOptionLabel={(adContact) => `${adContact.name}${adContact.jobTitle ? ` (${adContact.jobTitle})` : ''}`}
      placeholder={placeholder}
      selectedValue={selectedAdContact}
      onChange={(value, actionMeta) => {
        if (!isAdContactSelection(value) && value !== null) {
          throw new Error('Unexpected type for AdContact selection');
        }
        setSelectedMicrosoftAdIdState(value?.microsoftAdId);
        if (value !== null) {
          onChange?.(value as OnChangeValue<AdContactSelection, IsMulti>, actionMeta);
        }
      }}
      onInputChange={(value) => handleInputChange(value)}
      options={isFullPageOfResults ? [] : adContactOptions}
      isLoading={isLoading || paginatedAdContactsIsLoading || selectedAdContactIsLoading}
      noOptionsMessage={({ inputValue }) =>
        inputValue
          ? isFullPageOfResults
            ? 'Search is too broad. Please enter a more specific search term.'
            : 'Please enter a search term.'
          : 'No Options'
      }
      {...rest}
    />
  );
}

AdContactSelect.propTypes = {
  selectedMicrosoftAdId: PropTypes.string,
};

export default AdContactSelect;
