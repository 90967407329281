export enum SmallBusinessRequirementOption {
  FEDERAL = 'Federal',
  NON_FEDERAL = 'Non-Federal',
  HP_SELF_REPORTING = 'Hensel Phelps Self Reporting',
  NONE = '',
}

export enum SmallBusinessAdminRequirementOption {
  FEDERAL = 'Federal',
  NON_FEDERAL = 'Non-Federal',
  ANY_ALL = 'Any / All',
}

export const getSmallBusinessRequirementOptionFromValue = (value?: string | null) =>
  value ? value : SmallBusinessRequirementOption.NONE;
