import PrimaryAppBar from 'components/PrimaryAppBar';
import SecondaryAppBar from 'components/SecondaryAppBar';
import RootContainer from 'components/shared/RootContainer';
import useSetCurrentUser from 'hooks/useSetCurrentUser';
import { Redirect, Route, Switch } from 'react-router-dom';
import { enabledRoutes } from 'routes';

import { Hidden } from '@mui/material';

const App = () => {
  useSetCurrentUser();

  return (
    <main>
      <PrimaryAppBar />
      <Hidden only={['xs']}>
        <SecondaryAppBar />
      </Hidden>
      <RootContainer>
        <Switch>
          {enabledRoutes.map((route) => (
            <Route {...route} key={route.path} />
          ))}
          <Redirect from="*" to="/projects" />
        </Switch>
      </RootContainer>
    </main>
  );
};

export default App;
