import { DateTime } from 'luxon';
import { SmallBusinessClassification, SmallBusinessClassificationCoverage } from 'types/generated/graphql';

export const largeBusinessClassificationCoverage = (largeBusinessClassification: SmallBusinessClassification) => {
  return {
    endDate: null,
    id: '',
    isFederal: false,
    smallBusinessClassification: {
      abbreviation: largeBusinessClassification.abbreviation,
      id: largeBusinessClassification.id,
      name: largeBusinessClassification.name,
      sortOrder: largeBusinessClassification.sortOrder ?? 0,
      created: largeBusinessClassification.created ?? undefined,
      lastModified: largeBusinessClassification.lastModified ?? undefined,
    },
    startDate: null,
    created: undefined,
    lastModified: undefined,
    supportingDocument: {
      __typename: undefined,
      company: {
        __typename: undefined,
        contracts: [],
        created: undefined,
        federalSmallBusinessClassifications: undefined,
        files: [],
        id: '',
        lastModified: undefined,
        lastSink: undefined,
        location: undefined,
        name: undefined,
        notes: undefined,
        smallBusinessClassifications: undefined,
      },
      created: undefined,
      file: {
        __typename: undefined,
        cachedUrl: undefined,
        contentType: '',
        created: undefined,
        id: '',
        isMalware: undefined,
        isScanned: undefined,
        lastModified: undefined,
        name: '',
        sizeInBytes: undefined,
        url: undefined,
      },
      id: '',
      lastModified: undefined,
      notes: undefined,
      smallBusinessClassificationCoverages: [],
      title: '',
      uploaded: undefined,
      uploadedBy: {
        __typename: undefined,
        contactInformation: {
          __typename: undefined,
          emailAddress: {
            __typename: undefined,
            email: undefined,
            type: undefined,
          },
          faxPhone: {
            __typename: undefined,
            display: undefined,
            extension: undefined,
            number: undefined,
            type: undefined,
          },
          phoneNumbers: [],
          primaryPhone: {
            __typename: undefined,
            display: undefined,
            extension: undefined,
            number: undefined,
            type: undefined,
          },
          secondaryPhone: {
            __typename: undefined,
            display: undefined,
            extension: undefined,
            number: undefined,
            type: undefined,
          },
          website: undefined,
        },
        created: undefined,
        dob: undefined,
        email: '',
        firstName: '',
        hpEmployeeNumber: undefined,
        id: '',
        jobTitle: undefined,
        lastModified: undefined,
        lastName: '',
        microsoftAdId: '',
        name: '',
        projects: [],
        upn: '',
      },
    },
  };
};

export const getSmallBusinessClassificationStatus = (endDate: string | null) => {
  if (endDate === null) {
    return {
      isExpired: false,
      isExpiringSoon: false,
      numberOfDaysUntilExpiration: null,
    };
  }

  const todaysDate = DateTime.now().toISODate() ?? '';
  const formattedExpirationDate = DateTime.fromISO(endDate).toISODate() ?? '';
  const isExpired = formattedExpirationDate <= todaysDate;
  const numberOfDaysUntilExpiration = Math.ceil(
    DateTime.fromISO(formattedExpirationDate).diff(DateTime.now(), 'days').toObject().days ?? 0,
  );
  const isExpiringSoon = numberOfDaysUntilExpiration < 30 && numberOfDaysUntilExpiration > 0;

  return {
    isExpired,
    isExpiringSoon,
    numberOfDaysUntilExpiration,
  };
};

export const classificationsExpirationStatus = (
  classificationId: string,
  companyClassificationCoverage: SmallBusinessClassificationCoverage[],
) => {
  return companyClassificationCoverage?.map((companyDetails) => {
    if (companyDetails.smallBusinessClassification.id === classificationId) {
      return getSmallBusinessClassificationStatus(companyDetails.endDate);
    }
  });
};
