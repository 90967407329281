import { atom, useRecoilState } from 'recoil';
import { SmallBusinessFederalReportFormOption } from 'types/generated/graphql';

export type SmallBusinessFederalReportFormOptionSelection = SmallBusinessFederalReportFormOption | '';
const defaultFormOption: SmallBusinessFederalReportFormOptionSelection = '';

const smallBusinessFederalReportFormOptionAtom = atom<SmallBusinessFederalReportFormOptionSelection>({
  key: 'smallBusinessFederalReportFormOption',
  default: defaultFormOption,
});

export const useSmallBusinessFederalReportFormOptionState = () => {
  const [smallBusinessFederalReportFormOption, setSmallBusinessFederalReportFormOption] = useRecoilState(
    smallBusinessFederalReportFormOptionAtom,
  );

  function handleSmallBusinessFederalReportFormOptionChange(
    newFormOption: SmallBusinessFederalReportFormOptionSelection,
  ) {
    setSmallBusinessFederalReportFormOption(newFormOption);
  }

  return [smallBusinessFederalReportFormOption, { handleSmallBusinessFederalReportFormOptionChange }] as const;
};

export default smallBusinessFederalReportFormOptionAtom;
