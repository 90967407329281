import { SmallBusinessFederalReportFormOption } from 'types/generated/graphql';

export const federalSmallBusinessReportOptions = [
  {
    name: 'Small Business 294 March 31',
    value: SmallBusinessFederalReportFormOption.SmallBusiness294March,
  },
  {
    name: 'Small Business 294 September 30',
    value: SmallBusinessFederalReportFormOption.SmallBusiness294September,
  },
  {
    name: 'Small Business 295 September 30',
    value: SmallBusinessFederalReportFormOption.SmallBusiness295September,
  },
];

export const federalSmallBusinessReportOptionShortDisplayNames: { [optionValue: string]: string } = {
  [SmallBusinessFederalReportFormOption.SmallBusiness294March]: 'FederalSmallBusiness_294Mar',
  [SmallBusinessFederalReportFormOption.SmallBusiness294September]: 'FederalSmallBusiness_294Sep',
  [SmallBusinessFederalReportFormOption.SmallBusiness295September]: 'FederalSmallBusiness_295Sep',
};
