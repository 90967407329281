import { DateTime } from 'luxon';
import { UploadedFile } from 'types/generated/graphql';

/**
 * A value is considered nullish if it is null, undefined, empty string, or a non-empty object/array containing only nullish values
 */
export const isNullish: { (value: any): boolean } = (value) => {
  if (value === undefined || value === null || value === '') {
    return true;
  }

  if (Array.isArray(value)) {
    return value.length > 0 && value.every((item) => isNullish(item));
  }

  if (typeof value === 'object') {
    const nestedValues = Object.values(value);
    return nestedValues.length > 0 && nestedValues.every((nested) => isNullish(nested));
  }

  return false;
};

export const generateTransactionKey = () => {
  return Date.now() + (Math.random() * 100000).toFixed();
};

export function formatDate(isoDateString: string, shouldShowTime = false) {
  return formatDateTime(DateTime.fromISO(isoDateString), shouldShowTime);
}

export function formatDateTime(dateTime: DateTime, shouldShowTime = false) {
  if (shouldShowTime) {
    return dateTime.toFormat('MM/dd/yyyy h:mm a').toLocaleString();
  } else {
    return dateTime.toFormat('MM/dd/yyyy').toLocaleString();
  }
}

const getCachedBlob = async (url?: string) => {
  if (url) {
    return fetch(url)
      .then(function (response) {
        if (response.status === 200) {
          return response.blob();
        }
        return null;
      })
      .catch(() => {
        return null;
      });
  }
  return null;
};

export const getCachedUrl = async (url?: string) => {
  return getCachedBlob(url).then((blob) => {
    return blob ? URL.createObjectURL(blob) : null;
  });
};

export const getCachedFile = async (url: string, name: string, fileType: string) => {
  return getCachedBlob(url).then((blob) => {
    return blob
      ? new File([blob], name, {
          type: fileType,
        })
      : null;
  });
};

export const isImageFile = (file?: UploadedFile) => {
  return file?.contentType?.includes('image');
};

export const isPdfFile = (file?: UploadedFile) => {
  return file?.contentType === 'application/pdf';
};

export const convertUTCDateToLocalDate = (date: any) => {
  return DateTime.fromISO(date).toLocal();
};
