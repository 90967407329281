import InfoIcon from '@mui/icons-material/Info';
import DangerousIcon from '@mui/icons-material/PriorityHigh';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { Grid, SxProps, Theme, Typography } from '@mui/material';

const notice: SxProps<Theme> = (theme: Theme) => ({
  borderLeft: '6px solid' + theme.palette.secondary.dark,
  backgroundColor: theme.palette.secondary.main,
  padding: theme.spacing(2),
  marginBottom: theme.spacing(1),
});

const noticeTitle: SxProps<Theme> = (theme: Theme) => ({
  fontSize: '0.875rem',
  fontWeight: 'bold',
  color: theme.palette.secondary.contrastText,
});

const noticeContent: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.secondary.contrastText,
});

const warningNotice: SxProps<Theme> = (theme: Theme) => ({
  borderLeft: '6px solid' + theme.palette.warning.dark,
  backgroundColor: theme.palette.warning.main,
});

const warningNoticeTitle: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.primary.contrastText,
});

const warningNoticeContent: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.primary.contrastText,
  fontWeight: 'bold',
});

const warningIcon: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.primary.contrastText,
});

const urgentNotice: SxProps<Theme> = (theme: Theme) => ({
  borderLeft: '6px solid' + theme.palette.error.dark,
  backgroundColor: theme.palette.error.main,
});

const urgentNoticeTitle: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.primary.contrastText,
});

const urgentNoticeContent: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.primary.contrastText,
  fontWeight: 'bold',
});

const urgentIcon: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.primary.contrastText,
});

// How to use each level of user notice:

// 1. Notice - to convey information
// 2. Warning - to convey possible impacting information
// 3. Urgent/Error - to convey immediately impactful information

const NOTICE_TITLE = 'Please Note';
const WARNING_TITLE = 'Warning';
const URGENT_TITLE = 'PLEASE ADDRESS THE FOLLOWING';

export enum NoticeTypeOption {
  Notice = 'notice',
  Warning = 'warning',
  Urgent = 'urgent',
}

type StyledNoticeProps = {
  icon?: React.ReactNode;
  message: React.ReactNode;
  title?: String;
  type?: NoticeTypeOption;
};

const StyledNotice: React.FC<StyledNoticeProps> = ({ icon, title, message, type }) => {
  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Grid
          container
          sx={
            type === NoticeTypeOption.Notice
              ? notice
              : type === NoticeTypeOption.Warning
              ? warningNotice
              : type === NoticeTypeOption.Urgent
              ? urgentNotice
              : notice
          }
        >
          <Grid item xs={12}>
            <Grid container direction="row" spacing={1} alignItems="center">
              <Grid item>
                {icon ?? icon}
                {!icon && (type === NoticeTypeOption.Notice || !type) && (
                  <InfoIcon sx={!type || type === NoticeTypeOption.Notice ? icon : undefined} />
                )}
                {!icon && type === NoticeTypeOption.Warning && (
                  <ReportProblemOutlinedIcon sx={!icon && type === NoticeTypeOption.Warning ? warningIcon : icon} />
                )}
                {!icon && type === NoticeTypeOption.Urgent && (
                  <DangerousIcon sx={!icon && type === NoticeTypeOption.Urgent ? urgentIcon : icon} />
                )}
              </Grid>
              <Grid item>
                <Typography
                  sx={
                    type === NoticeTypeOption.Notice
                      ? noticeTitle
                      : type === NoticeTypeOption.Warning
                      ? warningNoticeTitle
                      : type === NoticeTypeOption.Urgent
                      ? urgentNoticeTitle
                      : noticeTitle
                  }
                >
                  {title ?? title}
                  {!title && (type === NoticeTypeOption.Notice || !type) && NOTICE_TITLE}
                  {!title && type === NoticeTypeOption.Warning && WARNING_TITLE}
                  {!title && type === NoticeTypeOption.Urgent && URGENT_TITLE}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography
              component="div"
              sx={
                !type || type === NoticeTypeOption.Notice
                  ? noticeContent
                  : !type || type === NoticeTypeOption.Warning
                  ? warningNoticeContent
                  : !type || type === NoticeTypeOption.Urgent
                  ? urgentNoticeContent
                  : noticeContent
              }
            >
              {message}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StyledNotice;
