import ConfirmDialog from 'components/shared/ConfirmDialog';
import { ConfirmableDialogProps } from 'components/shared/ConfirmDialog/ConfirmDialog';
import { createConfirmation } from 'react-confirm';

import { useTheme } from '@mui/material';

const confirmDialog = createConfirmation(ConfirmDialog);

const useConfirmDialog = () => {
  const defaultTheme = useTheme();
  return (props: ConfirmableDialogProps) => confirmDialog({ theme: defaultTheme, ...props });
};

export default useConfirmDialog;
