import { FC } from 'react';

import { AppBar, SxProps, Theme, Toolbar } from '@mui/material';

import BreadcrumbNavigation from './BreadcrumbNavigation';

const appBarContainerAndSolidColor: SxProps<Theme> = (theme: Theme) => ({
  boxShadow: 'none',
  marginTop: theme.spacing(8),
  backgroundColor: theme.palette.background.default,
  zIndex: 1000,
  marginBottom: 200,
});

const SecondaryAppBar: FC = () => {
  return (
    <AppBar sx={appBarContainerAndSolidColor}>
      <Toolbar variant="dense">
        <BreadcrumbNavigation />
      </Toolbar>
    </AppBar>
  );
};

export default SecondaryAppBar;
