import { getSmallBusinessRequirementOptionFromValue } from 'constants/smallBusinessRequirementOptions';
import PropTypes from 'prop-types';

import { SxProps, Theme, Typography } from '@mui/material';

const label: SxProps<Theme> = {
  fontSize: '0.875rem',
};

type SmallBusinessReportingRequirementContentProps = {
  value: string | null;
};

const SmallBusinessReportingRequirementContent: React.FC<SmallBusinessReportingRequirementContentProps> = ({
  value,
}) => {
  return <Typography sx={label}>{getSmallBusinessRequirementOptionFromValue(value)}</Typography>;
};

SmallBusinessReportingRequirementContent.propTypes = {
  value: PropTypes.any,
};

export default SmallBusinessReportingRequirementContent;
