import AdContactSelect from 'components/AdContactSelect';
import { AdContactSelection } from 'components/AdContactSelect/AdContactSelect';
import ProjectAdminList from 'components/ProjectAdminList';
import StyledButtonPrimary from 'components/shared/ButtonPrimary';
import ErrorNotice from 'components/shared/ErrorNotice';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import { GET_SINGLE_PROJECT } from 'graphql/projects';
import useProject from 'hooks/useProject';
import useRoles from 'hooks/useRoles';
import useToast from 'hooks/useToast';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSetAdminStatusOnProjectMutation } from 'types/generated/graphql';

import { Card, Divider, Grid, SxProps, Theme, Typography } from '@mui/material';

const outerContentContainer: SxProps<Theme> = (theme: Theme) => ({
  padding: theme.spacing(3),
});

const contentContainer: SxProps<Theme> = (theme: Theme) => ({
  padding: theme.spacing(3),
  [theme.breakpoints.down('md')]: { padding: 0 },
});

const contentTitle: SxProps<Theme> = {
  fontSize: '1.25rem',
};

const divider: SxProps<Theme> = (theme: Theme) => ({
  [theme.breakpoints.down('md')]: { margin: theme.spacing(2, 0) },
});

const placeholderText: SxProps<Theme> = (theme: Theme) => ({
  [theme.breakpoints.down('sm')]: { fontSize: '0.75rem' },
});

type useParamsProps = { projectId?: string };

const ProjectPermissionsPage: FC = () => {
  const { projectId } = useParams<useParamsProps>();
  const { project, error, loading } = useProject(projectId);
  const [selectedAdContact, setSelectedAdContact] = useState<AdContactSelection | null>(null);
  const { displayToast } = useToast();
  const [addAdmin, { loading: isAdding }] = useSetAdminStatusOnProjectMutation({
    refetchQueries: [
      {
        query: GET_SINGLE_PROJECT,
        variables: { id: projectId },
      },
    ],
  });
  const { isEnterpriseAdmin } = useRoles();

  const handleSubmit = () => {
    const showError = (error: any) => {
      console.error(error);
      displayToast(
        'Error: Something went wrong while trying to add the admin. Please try again. If the problem persists, please contact support.',
        'error',
      );
    };
    if (!projectId || !selectedAdContact) {
      showError('There must be a project and user selected in order to set admin status.');
      return;
    }
    addAdmin({
      variables: {
        projectId,
        microsoftAdId: selectedAdContact?.microsoftAdId,
        isAdmin: true,
      },
    })
      .then((_) => {
        displayToast(
          `Success: ${selectedAdContact?.firstName} ${selectedAdContact?.lastName} has been added as an admin for this project.`,
          'success',
        );
        setSelectedAdContact(null);
      })
      .catch(showError);
  };

  if (!isEnterpriseAdmin) {
    return <ErrorNotice message="Only enterprise admins can access this page." />;
  }

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error || !project) {
    return <ErrorNotice />;
  }
  return (
    <Card sx={outerContentContainer}>
      <Grid container sx={contentContainer}>
        <Grid item xs={12}>
          <Grid container direction="column" justifyContent="flex-start" spacing={2}>
            <Grid item>
              <Typography color="textPrimary" sx={contentTitle}>
                Add New Admin
              </Typography>
            </Grid>
            <Grid item>
              <AdContactSelect<false>
                onChange={setSelectedAdContact}
                placeholder={<Typography sx={placeholderText}>Search by Last Name, First Name…</Typography>}
              />
            </Grid>
            <Grid item>
              <StyledButtonPrimary
                data-testid="add-admin-button"
                label="Add"
                onClick={handleSubmit}
                disabled={!selectedAdContact || isAdding}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider sx={divider}></Divider>
      <Grid container sx={contentContainer}>
        <Grid item xs={12}>
          <Typography color="textPrimary" sx={contentTitle}>
            Manage Current Admins
          </Typography>
        </Grid>
        <ProjectAdminList admins={project.admins} projectId={project.id} />
      </Grid>
    </Card>
  );
};

export default ProjectPermissionsPage;
