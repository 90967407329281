export const DISTRICT_NUMBERS = [
  '100',
  '200',
  '250',
  '300',
  '350',
  '400',
  '500',
  '590',
  '600',
  '650',
  '700',
  '800',
  '850',
  '900',
];
