import StyledButtonPrimary from 'components/shared/ButtonPrimary';
import StyledUserNoticeTemplate from 'components/shared/UserNoticeTemplate';
import { SERVICENOW_URL } from 'constants/externalLinks';
import PropTypes from 'prop-types';

import { Box, Grid, SxProps, Theme, Typography, useTheme } from '@mui/material';

const heading: SxProps<Theme> = {
  fontSize: '2rem',
};

const img: SxProps<Theme> = (theme: Theme) => ({
  width: 200,
  padding: theme.spacing(3),
});

type ErrorNoticeProps = {
  message?: string;
};

export const ErrorNotice: React.FC<ErrorNoticeProps> = ({ message }) => {
  const theme = useTheme();

  return (
    <StyledUserNoticeTemplate
      image={<Box component="img" sx={img} src={theme.errorNotice} alt="" role="presentation"></Box>}
      content={
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography color="textPrimary" sx={heading}>
              Oops, something went wrong!
            </Typography>
          </Grid>
          {message && (
            <Grid item>
              <Typography color="textPrimary">{message}</Typography>
            </Grid>
          )}
          {!message && (
            <Grid item>
              <Typography color="textPrimary">Please try reloading the page.</Typography>
              <Typography color="textPrimary">If the problem persists, please contact support.</Typography>
            </Grid>
          )}
          <Grid item>
            <Grid container justifyContent="space-between">
              <Grid item>
                <StyledButtonPrimary
                  data-testid="reload-page"
                  label={'Reload'}
                  onClick={() => {
                    localStorage.clear();
                    window.location.reload();
                  }}
                />
              </Grid>
              <Grid item>
                <StyledButtonPrimary
                  data-testid="servicenow-external-link"
                  label={'Get Help'}
                  onClick={() => window.open(SERVICENOW_URL, '_blank', 'noopener')}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
};

ErrorNotice.propTypes = {
  message: PropTypes.string,
};

export default ErrorNotice;
