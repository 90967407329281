import { Button, SxProps, Theme, Typography } from '@mui/material';

const buttonStyle: SxProps<Theme> = (theme: Theme) => ({
  borderRadius: theme.spacing(2),
  minWidth: theme.spacing(10),
  '&:hover': { backgroundColor: 'none !important' },
});

const buttonText: SxProps<Theme> = {
  fontSize: '0.75rem',
  fontWeight: 'bold',
};

type StyledButtonSecondaryProps = {
  label: string;
  color?: 'inherit' | 'primary' | 'secondary';
  variant?: 'text' | 'outlined' | 'contained';
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  className?: string;
  icon?: React.ReactNode;
};

const StyledButtonSecondary: React.FC<StyledButtonSecondaryProps & Record<string, any>> = ({
  label,
  onClick,
  className,
  ...rest
}) => {
  return (
    <Button data-testid="button-secondary" onClick={onClick} sx={buttonStyle} {...rest}>
      <Typography sx={buttonText}>{label}</Typography>
    </Button>
  );
};

export default StyledButtonSecondary;
