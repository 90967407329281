import PropTypes from 'prop-types';

import CheckIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';
import { Grid, Hidden, SxProps, Theme, Tooltip, Typography } from '@mui/material';

const active: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.success.main,
});

const icon: SxProps<Theme> = {
  fontSize: '1rem',
  paddingTop: 4,
};

const label: SxProps<Theme> = {
  fontSize: '0.875rem',
};

const grid: SxProps<Theme> = {
  width: 'fit-content',
};

type ActiveOrInactiveStatusContentProps = {
  isActive: boolean;
};

const ActiveOrInactiveStatusContent: React.FC<ActiveOrInactiveStatusContentProps> = ({ isActive }) => {
  return (
    <Tooltip arrow title={isActive ? 'Active' : 'Inactive'}>
      <Grid container alignItems="center" direction="row" spacing={1} sx={grid}>
        <Grid item sx={isActive ? active : undefined}>
          {isActive && <CheckIcon sx={{ fontSize: icon }} data-testid="active-icon" />}
          {!isActive && <ClearIcon sx={{ fontSize: icon }} data-testid="inactive-icon" />}
        </Grid>
        <Hidden only={['xs', 'sm', 'md']}>
          <Grid item>
            <Typography sx={label}>{isActive ? 'Active' : 'Inactive'}</Typography>
          </Grid>
        </Hidden>
      </Grid>
    </Tooltip>
  );
};

ActiveOrInactiveStatusContent.propTypes = {
  isActive: PropTypes.any,
};

export default ActiveOrInactiveStatusContent;
