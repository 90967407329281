import SmallBusinessClassificationDisplayName from 'components/shared/SmallBusinessClassificationDisplayName';
import { DateTime } from 'luxon';
import { FC, Fragment, ReactElement, useState } from 'react';
import { GetSingleCompanyQuery } from 'types/generated/graphql';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ViewTimelineIcon from '@mui/icons-material/Timelapse';
import ViewCurrentIcon from '@mui/icons-material/Visibility';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import { Box, Card, Grid, IconButton, SxProps, Theme, Tooltip, Typography } from '@mui/material';

import ClassificationCertificationStatusChangeDisplayOption, {
  ClassificationCertificationStatusChangeOption,
} from '../ClassificationCertificationStatusChangeDisplayOption';

const classificationsCard: SxProps<Theme> = (theme: Theme) => ({
  padding: theme.spacing(0, 2, 2, 0),
});

const classificationsCardTitle: SxProps<Theme> = (theme: Theme) => ({
  padding: theme.spacing(2, 0, 2, 2),
  fontWeight: 'bold',
});

const classificationRow: SxProps<Theme> = (theme: Theme) => ({
  borderTop: '1px solid #c5c5c5',
  padding: theme.spacing(0.5, 2),
});

const smBusinessClassificationsCard: SxProps<Theme> = (theme: Theme) => ({
  marginTop: theme.spacing(2),
});

const smBusinessClassificationsLabel: SxProps<Theme> = (theme: Theme) => ({
  marginLeft: theme.spacing(2),
  fontSize: '0.875rem',
  fontWeight: 'bold',
});

const statusLabel: SxProps<Theme> = {
  fontSize: '0.875rem',
};

const timelineCard: SxProps<Theme> = (theme: Theme) => ({
  backgroundColor: theme.palette.background.default,
  width: 'fit-content',
});

const timelineClassificationBoxNowNotCertified: SxProps<Theme> = (theme: Theme) => ({
  borderColor: theme.palette.error.main,
  backgroundColor: '#DEC1C1',
});

const timelineClassificationBoxStillCertified: SxProps<Theme> = (theme: Theme) => ({
  borderColor: theme.palette.secondary.dark,
});

const timelineClassificationBoxNowCertified: SxProps<Theme> = (theme: Theme) => ({
  borderColor: theme.palette.success.main,
  backgroundColor: '#C1DE64',
});

const timelineTypography: SxProps<Theme> = (theme: Theme) => ({
  fontWeight: 'bold',
  margin: theme.spacing(0.5, 0.5, 0),
});

const timelineDate: SxProps<Theme> = (theme: Theme) => ({
  fontWeight: 'bold',
  margin: theme.spacing(0.5, 0.5, 0),
  marginTop: theme.spacing(1.75),
});

const noDataTypography: SxProps<Theme> = (theme: Theme) => ({
  marginLeft: theme.spacing(2),
});

type SmallBusinessClassificationCoveragesSelection =
  GetSingleCompanyQuery['company']['files'][number]['smallBusinessClassificationCoverages'];

type SmallBusinessClassificationSelection =
  SmallBusinessClassificationCoveragesSelection[number]['smallBusinessClassification'];

type BusinessClassificationsCardProps = {
  company: GetSingleCompanyQuery['company'];
  addNewDocumentButton: ReactElement | boolean;
};

enum NonFederalSmallBusinessClassificationCardView {
  Current,
  Timeline,
}

const NonFederalSmallBusinessClassificationsCard: FC<BusinessClassificationsCardProps> = ({
  company,
  addNewDocumentButton,
}) => {
  const [cardView, setCardView] = useState(NonFederalSmallBusinessClassificationCardView.Current);

  const handleViewCurrent = () => {
    setCardView(NonFederalSmallBusinessClassificationCardView.Current);
  };

  const handleViewTimeline = () => {
    setCardView(NonFederalSmallBusinessClassificationCardView.Timeline);
  };

  interface ClassificationCoverage {
    classification: SmallBusinessClassificationSelection;
    startIsoDate: string;
    endIsoDate: string;
  }

  interface ClassificationCertificationAssertion {
    classification: SmallBusinessClassificationSelection;
    isCertified: boolean;
    isChanged: boolean;
  }

  type ClassificationTimeline = { [isoDate: string]: ClassificationCertificationAssertion[] };

  const compareIsoDates = (a: string, b: string) => DateTime.fromISO(a).toMillis() - DateTime.fromISO(b).toMillis();

  const addToTimeline = (timeline: ClassificationTimeline, coverage: ClassificationCoverage) => {
    const startIsoDate = coverage.startIsoDate;
    if (!(startIsoDate in timeline)) {
      timeline[startIsoDate] = [];
    }
    timeline[startIsoDate].push({ classification: coverage.classification, isCertified: true, isChanged: true });

    const endIsoDate = coverage.endIsoDate;
    if (!(endIsoDate in timeline)) {
      timeline[endIsoDate] = [];
    }
    timeline[endIsoDate].push({ classification: coverage.classification, isCertified: false, isChanged: true });

    return timeline;
  };

  const fillTimelineSpans = (timeline: ClassificationTimeline, coverage: ClassificationCoverage) => {
    // For any existing timeline entries which fall within this coverage, ensure that they assert continuing certification
    Object.keys(timeline)
      .filter(
        (isoDate) =>
          compareIsoDates(isoDate, coverage.startIsoDate) > 0 && compareIsoDates(isoDate, coverage.endIsoDate) < 0,
      )
      .forEach((isoDate) => {
        const assertion = timeline[isoDate].find(
          (assertion) => assertion.classification.id === coverage.classification.id,
        );
        if (assertion) {
          assertion.isCertified = true;
          assertion.isChanged = false;
        } else {
          timeline[isoDate].push({
            classification: coverage.classification,
            isCertified: true,
            isChanged: false,
          });
        }
      });
    return timeline;
  };

  const getClassificationTimeline = (classificationCoverages: SmallBusinessClassificationCoveragesSelection) => {
    // Coverages should already be sorted, but explicitly make sure start dates are ascending in case external behavior changes.
    classificationCoverages.sort((a, b) => compareIsoDates(a.startDate, b.startDate));
    const unionedClassificationCoveragesByClassificationId = classificationCoverages.reduce<{
      [id: string]: ClassificationCoverage[];
    }>((accumulator, coverage) => {
      const classificationId = coverage.smallBusinessClassification.id;
      if (!(classificationId in accumulator)) {
        accumulator[classificationId] = [];
      }
      const unionedCoverages = accumulator[classificationId];
      const latestCoverage = unionedCoverages[unionedCoverages?.length - 1];
      if (latestCoverage && compareIsoDates(latestCoverage.endIsoDate, coverage.startDate) >= 0) {
        // current coverage overlaps or touches latest coverage.
        if (compareIsoDates(latestCoverage.endIsoDate, coverage.endDate) < 0) {
          // current coverage extends latest coverage.
          latestCoverage.endIsoDate = coverage.endDate;
        }
      } else {
        unionedCoverages.push({
          classification: coverage.smallBusinessClassification,
          startIsoDate: coverage.startDate,
          endIsoDate: coverage.endDate,
        });
      }

      return accumulator;
    }, {});
    const allUnionedCoverages = Object.values(unionedClassificationCoveragesByClassificationId).flat();
    const timeline = allUnionedCoverages.reduce<ClassificationTimeline>(addToTimeline, {});
    return allUnionedCoverages.reduce<ClassificationTimeline>(fillTimelineSpans, timeline);
  };

  const coverages =
    company?.files
      ?.flatMap((file) => file.smallBusinessClassificationCoverages)
      ?.filter((coverage) => coverage.isFederal !== true) ?? [];

  const allCoveredClassifications = Array.from(
    new Set(coverages.map((coverage) => coverage.smallBusinessClassification)),
  ).sort((a, b) => a.name.localeCompare(b.name));
  const classificationTimeline = getClassificationTimeline(coverages);
  const sortedTimelineIsoDates = Object.keys(classificationTimeline).sort((a, b) => compareIsoDates(a, b));

  return (
    <Card sx={classificationsCard}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography sx={classificationsCardTitle}>Other Business Classifications (Non-Federal)</Typography>
        </Grid>
        <Grid item>
          <Grid container justifyContent="space-between" alignItems="flex-end">
            <Grid item>
              <Typography>
                {cardView === NonFederalSmallBusinessClassificationCardView.Current
                  ? 'Viewing current classification status'
                  : 'Viewing classification timeline'}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                size="small"
                onClick={handleViewCurrent}
                disabled={cardView === NonFederalSmallBusinessClassificationCardView.Current}
              >
                <ViewCurrentIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                size="small"
                onClick={handleViewTimeline}
                disabled={cardView === NonFederalSmallBusinessClassificationCardView.Timeline}
              >
                <ViewTimelineIcon />
              </IconButton>
            </Grid>
            {!!addNewDocumentButton && <Grid item>{addNewDocumentButton}</Grid>}
          </Grid>
        </Grid>
      </Grid>
      {cardView === NonFederalSmallBusinessClassificationCardView.Current && (
        <Grid container direction="row" justifyContent="space-between" sx={smBusinessClassificationsCard}>
          <Grid item xs={9} sm={9} md={9} lg={9}>
            <Typography sx={smBusinessClassificationsLabel}>Classifications</Typography>
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3}>
            <Typography sx={statusLabel}>Status (today)</Typography>
          </Grid>
        </Grid>
      )}
      {cardView === NonFederalSmallBusinessClassificationCardView.Current &&
        allCoveredClassifications.map((classification) => (
          <Fragment key={classification.id}>
            <Grid
              container
              key={classification.id}
              justifyContent="space-between"
              alignItems="center"
              sx={classificationRow}
            >
              <Grid item xs={9} sm={9} md={9} lg={9}>
                <SmallBusinessClassificationDisplayName classification={classification} displayFormat={'full'} />
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3}>
                <Grid container key={`${classification.id}.status`} alignItems="flex-start">
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <ClassificationCertificationStatusChangeDisplayOption
                      changeOption={
                        company.smallBusinessClassifications?.some(
                          (certifiedClassification) => certifiedClassification.id === classification.id,
                        )
                          ? ClassificationCertificationStatusChangeOption.Certified
                          : ClassificationCertificationStatusChangeOption.NotCertified
                      }
                      classificationDisplayName={classification.abbreviation}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Fragment>
        ))}
      {cardView === NonFederalSmallBusinessClassificationCardView.Timeline && (
        <Grid container justifyContent="flex-start">
          {!coverages?.length && (
            <Typography sx={noDataTypography}>
              There are no Business Classifications in this company's history.
            </Typography>
          )}
          {!!coverages?.length && (
            <Timeline position="alternate">
              {sortedTimelineIsoDates.map((isoDate, index, timelineDates) => (
                <TimelineItem>
                  <TimelineOppositeContent>
                    <Typography sx={timelineDate}>{isoDate}</Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot>
                      <ViewTimelineIcon />
                    </TimelineDot>
                    {index < timelineDates?.length - 1 && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent>
                    <Grid
                      container
                      justifyContent={index % 2 ? 'flex-end' : 'flex-start'}
                      alignItems={index % 2 ? 'flex-end' : 'flex-start'}
                    >
                      <Grid item>
                        <Card sx={timelineCard}>
                          <Grid
                            container
                            justifyContent={index % 2 ? 'flex-end' : 'flex-start'}
                            alignItems={index % 2 ? 'flex-end' : 'flex-start'}
                          >
                            {classificationTimeline[isoDate]
                              .sort((a, b) => a.classification.sortOrder - b.classification.sortOrder)
                              .map((assertion) => (
                                <Grid item>
                                  <Box
                                    sx={
                                      assertion.isCertified
                                        ? assertion.isChanged
                                          ? timelineClassificationBoxNowCertified
                                          : timelineClassificationBoxStillCertified
                                        : timelineClassificationBoxNowNotCertified
                                    }
                                  >
                                    <Tooltip
                                      arrow
                                      title={`${assertion.isChanged ? 'Become' : 'Remain'} ${
                                        assertion.isCertified
                                          ? ClassificationCertificationStatusChangeOption.Certified
                                          : ClassificationCertificationStatusChangeOption.NotCertified
                                      } ${assertion.classification.abbreviation} (${assertion.classification.name})`}
                                    >
                                      <Grid container>
                                        {!assertion.isCertified && assertion.isChanged ? (
                                          <CancelIcon />
                                        ) : assertion.isCertified && assertion.isChanged ? (
                                          <CheckCircleIcon />
                                        ) : null}
                                        <Typography sx={timelineTypography}>
                                          {assertion.classification.abbreviation}
                                        </Typography>
                                      </Grid>
                                    </Tooltip>
                                  </Box>
                                </Grid>
                              ))}
                          </Grid>
                        </Card>
                      </Grid>
                    </Grid>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          )}
        </Grid>
      )}
    </Card>
  );
};

export default NonFederalSmallBusinessClassificationsCard;
