import { enabledFeatures, features } from 'constants/features';
import AdminSettingsPage from 'pages/AdminSettingsPage/AdminSettingsPage';
import CompanyListPage from 'pages/CompanyListPage';
import CompanyPage from 'pages/CompanyPage';
import ProjectListPage from 'pages/ProjectListPage';
import ProjectPermissionsPage from 'pages/ProjectPermissionsPage';
import SmallBusinessReportPage from 'pages/SmallBusinessReportPage';
import { isNullish } from 'utils/general';

import { ENV } from '../constants';

// TODO: Implement lazy loading! https://reactjs.org/docs/code-splitting.html

type AppRoute = {
  exact: boolean;
  component: React.ComponentType;
  path: string;
  feature?: string;
};

export const allRoutes: AppRoute[] = [
  {
    exact: true,
    component: ProjectListPage,
    path: '/projects',
    feature: features.federalSmallBusinessReport,
  },
  {
    exact: true,
    component: ProjectPermissionsPage,
    path: '/projects/:projectId/permissions',
  },
  {
    exact: true,
    component: CompanyListPage,
    path: '/companies',
  },
  {
    exact: true,
    component: SmallBusinessReportPage,
    path: '/projects/:projectId/reports/federal-small-business-report',
    feature: features.smallBusinessReport,
  },
  {
    exact: true,
    component: SmallBusinessReportPage,
    path: '/projects/:projectId/reports/small-business-report',
    feature: features.smallBusinessReport,
  },
  {
    exact: true,
    component: SmallBusinessReportPage,
    path: '/projects/:projectId/reports',
  },
  {
    exact: true,
    component: CompanyPage,
    path: '/companies/:companyId',
  },
  {
    exact: true,
    component: AdminSettingsPage,
    path: '/adminSettings',
  },
];

export const enabledRoutes = allRoutes.filter((route) =>
  isNullish(route.feature) ? true : ENV && route.feature ? enabledFeatures[ENV][route.feature] : false,
);
