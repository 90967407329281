import { useGetSingleProjectQuery } from 'types/generated/graphql';

const useProject = (projectId?: string, shouldSkip: boolean = false) => {
  const {
    data: { project = undefined } = {},
    loading,
    error,
  } = useGetSingleProjectQuery({
    variables: {
      id: projectId as string,
    },
    skip: shouldSkip || !projectId,
  });

  return { project, loading, error };
};

export default useProject;
