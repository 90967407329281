import TabPanel from 'components/shared/TabPanel';
import AgencyAdminTable from 'components/SmallBusinessAgencyAdminTable';
import ClassificationAdminTable from 'components/SmallBusinessClassificationAdminTable/SmallBusinessClassificationAdminTable';
import ClientAdminTable from 'components/SmallBusinessClientAdminTable/SmallBusinessClientAdminTable';
import { FC, useState } from 'react';

import { Box, Tab, Tabs } from '@mui/material';

const AdminSettingsPage: FC = () => {
  const LOCAL_STORAGE_TAB_KEY = 'adminTab';
  const savedSelectedTab = JSON.parse(localStorage.getItem(LOCAL_STORAGE_TAB_KEY) as string);
  const [selectedTab, setSelectedTab] = useState(!savedSelectedTab ? 0 : savedSelectedTab);

  const handleTabChange = (_: any, newTabSelected: number) => {
    setSelectedTab(newTabSelected);
    localStorage.setItem(LOCAL_STORAGE_TAB_KEY, newTabSelected.toString());
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={selectedTab} onChange={handleTabChange}>
          <Tab label="Clients" />
          <Tab label="Agencies" />
          <Tab label="Classifications" />
        </Tabs>
      </Box>
      <TabPanel value={selectedTab} index={0}>
        <ClientAdminTable />
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <AgencyAdminTable />
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        <ClassificationAdminTable />
      </TabPanel>
    </>
  );
};

export default AdminSettingsPage;
