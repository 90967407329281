import { FC, useState } from 'react';
import { animateScroll as scroll } from 'react-scroll';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Grid, IconButton, SxProps, Theme } from '@mui/material';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

const container: SxProps<Theme> = (theme: Theme) => ({
  position: 'fixed',
  bottom: theme.spacing(1.5),
});

const button: SxProps<Theme> = (theme: Theme) => ({
  backgroundColor: theme.palette.primary.main,
  padding: 0,
});

const icon: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.primary.contrastText,
  fontSize: 40,
});

const BackToTopButton: FC = () => {
  const [shouldShow, setShouldShow] = useState(false);

  useScrollPosition(
    ({ currPos }) => {
      const isVisible = currPos.y < -500;

      if (isVisible !== shouldShow) {
        setShouldShow(isVisible);
      }
    },
    [shouldShow, setShouldShow],
    undefined,
    false,
    300,
  );

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  return <>
    {shouldShow && (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        sx={container}
      >
        <Grid item>
          <IconButton color="primary" onClick={scrollToTop} sx={button} size="large">
            <KeyboardArrowUpIcon sx={icon} />
          </IconButton>
        </Grid>
      </Grid>
    )}
  </>;
};

export default BackToTopButton;
