import PropTypes from 'prop-types';
import { Fragment, ReactElement } from 'react';
import { GetSingleProjectQuery } from 'types/generated/graphql';

import { Box, CircularProgress, Grid, SxProps, Theme, Typography } from '@mui/material';

const adminInfoContainer: SxProps<Theme> = (theme: Theme) => ({
  borderRadius: 4,
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
});

const nameText: SxProps<Theme> = (theme: Theme) => ({
  fontWeight: 'bold',
  maxWidth: 370,
  [theme.breakpoints.down('lg')]: { maxWidth: 280 },
  [theme.breakpoints.down('md')]: { maxWidth: 250 },
  [theme.breakpoints.down('sm')]: { maxWidth: 200 },
});

const titleText: SxProps<Theme> = {
  fontSize: '0.875rem',
};

const linkText: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.secondary.contrastText,
  fontSize: '0.875rem',
});

// This happens to be the only selection in current use.
// If we start reusing this, we may point this to some more generic generated type.
type AdUserSelection = GetSingleProjectQuery['project']['admins'][number];

interface AdUserPermissionCardProps {
  user: AdUserSelection;
  actions: ReactElement;
  isLoading: boolean;
}

export const AdUserPermissionCard = ({ user, actions, isLoading }: AdUserPermissionCardProps) => {
  const getMailTo = (email: string) => {
    return `mailto: ${email}`;
  };

  return (
    <Fragment>
      <Grid container direction="row" justify-content="space-between" alignItems="center" sx={adminInfoContainer}>
        <Grid item xs={10}>
          <Grid container direction="row" spacing={1} alignItems="center">
            <Grid item>
              <Typography sx={nameText} color="textPrimary">
                {user.name}
              </Typography>
              <Typography sx={titleText} color="textPrimary">
                {user.jobTitle}
              </Typography>
              <Typography component="span" noWrap={true}>
                <Box component="a" sx={linkText}>
                  <a href={getMailTo(user.email)}>{user.email}</a>
                </Box>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Grid container>
            {isLoading && (
              <Grid item>
                <CircularProgress color="primary" />
              </Grid>
            )}
            {!isLoading && <Grid item>{actions}</Grid>}
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

AdUserPermissionCard.propTypes = {
  user: PropTypes.object,
  actions: PropTypes.node,
  isLoading: PropTypes.bool,
};

export default AdUserPermissionCard;
