import { LowerTierFileSummaryDictionary } from 'types';

export const LOWER_TIER_FILE_SUMMARY_DICTIONARY: LowerTierFileSummaryDictionary[] = [
  {
    name: 'lowerTierFileSummary.agency',
    label: 'Agency',
    key: 'Agency',
    xs: 6,
  },
  {
    name: 'lowerTierFileSummary.classifications',
    label: 'Other Business Classifications (Non-Federal)',
    key: 'Classifications',
    xs: 6,
  },
  {
    name: 'lowerTierFileSummary.participationPercentage',
    label: 'Participation Percentage',
    key: 'Percentage',
    xs: 4,
  },
  {
    name: 'lowerTierFileSummary.participationPercentage',
    label: 'Participation Total',
    key: 'Participation Total',
    xs: 4,
  },
  {
    name: 'lowerTierFileSummary.documentation',
    label: 'Compliance Documentation (PDFs and Image types only)',
    key: 'Documentation',
    xs: 4,
  },
];
