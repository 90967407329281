import { StandardListTableState } from 'types';
import { SortOrderOption } from 'types/generated/graphql';

const DEFAULT_ROWS_PER_LIST_TABLE_PAGE = 100;
export const defaultListTableState: StandardListTableState = {
  first: DEFAULT_ROWS_PER_LIST_TABLE_PAGE,
  order: {
    field: 'name',
    direction: SortOrderOption.Asc,
  },
  filter: {},
  filterList: [],
  search: '',
  skip: 0,
  page: 0,
  hasActiveFilters: false,
};

export const listTableHasActiveFilters: <T>(tableState: StandardListTableState<T>) => boolean = (tableState) => {
  let hasActiveFilters = false;

  const filterState = tableState.filter;

  if (typeof filterState === 'object') {
    for (const value of Object.values(filterState)) {
      if (value) {
        hasActiveFilters = true;
        break;
      }
    }
  }

  return hasActiveFilters;
};
