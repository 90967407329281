import {
  AuthenticationResult,
  Configuration,
  EventMessage,
  EventType,
  PublicClientApplication,
} from '@azure/msal-browser';

const MSAL_SCOPES = ['User.Read'];

export const loginRequest = {
  scopes: MSAL_SCOPES,
};

const CLIENT_ID = '3eda0711-2ba1-42fe-9a8c-4595d13c0abb'; // meodev-mercury AD App Registration
const AD_TENANT_ID = 'aeeffdfb-cb01-4af6-a78f-fcaba7a6fad8';

const configuration: Configuration = {
  auth: {
    clientId: CLIENT_ID,
    authority: `https://login.microsoftonline.com/${AD_TENANT_ID}/`,
    redirectUri: '/',
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
};

export const getMsalInstance = () => {
  const msalInstance = new PublicClientApplication(configuration);

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }
  return msalInstance;
};

const acquireToken = async (msalInstance: PublicClientApplication) => {
  const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    /*
     * User is not signed in. Throw error or wait for user to login.
     * Do not attempt to log a user in outside of the context of MsalProvider
     */
  }
  const request = {
    ...loginRequest,
    account: activeAccount ?? accounts[0],
  };

  return await msalInstance.acquireTokenSilent(request);
};

export const acquireAccessToken = async (msalInstance: PublicClientApplication) => {
  const authResult = await acquireToken(msalInstance);
  return authResult.accessToken;
};

export const acquireIdToken = async (msalInstance: PublicClientApplication) => {
  const authResult = await acquireToken(msalInstance);
  return authResult.idToken;
};
