import { createUploadLink } from 'apollo-upload-client';
import { createClient } from 'graphql-ws';
import typeDefs from 'graphql/typeDefs';
import { getCachedUrl } from 'utils/general';

import { ApolloClient, from, InMemoryCache, makeVar, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { PublicClientApplication } from '@azure/msal-browser';

import { ENV, MERCURY_ENVIRONMENTS } from '../constants';
import { acquireIdToken } from './msalConfig';

const url = new URL(process.env.REACT_APP_API_URL as string);
const isLocal = ENV === MERCURY_ENVIRONMENTS.local;

const webSocketUrl = `${isLocal ? 'ws' : 'wss'}://${url.hostname}:${isLocal ? url.port : ''}/ws`;

const httpLink = createUploadLink({
  uri: url.origin,
  headers: { 'Apollo-Require-Preflight': 'true' },
});

const downgradedRolesLink = setContext(async (_, { headers }) => {
  const downgradedRoles = window.sessionStorage.getItem('downgradedRoles');
  if (!downgradedRoles) {
    return headers;
  }
  return {
    headers: {
      ...headers,
      'X-Downgraded-Roles': downgradedRoles,
    },
  };
});

export const getApolloClient = (msalInstance: PublicClientApplication, onWsError: { (error: any): void }) => {
  const authLink = setContext(async (_, { headers }) => {
    const token = await acquireIdToken(msalInstance);
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: webSocketUrl,
      connectionParams: async () => {
        const token = await acquireIdToken(msalInstance).catch((error) => onWsError(error));
        return {
          authorization: `Bearer ${token}`,
        };
      },
    }),
  );

  const retryLink = new RetryLink();

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    authLink.concat(downgradedRolesLink).concat(retryLink).concat(httpLink),
  );

  return new ApolloClient({
    link: from([splitLink]),
    cache: new InMemoryCache({
      typePolicies: {
        UploadedFile: {
          fields: {
            cachedUrl: {
              read(_, { storage, readField }) {
                if (!storage.cachedUrl) {
                  storage.cachedUrl = makeVar(null);
                  const url: string | undefined = readField('url');
                  getCachedUrl(url).then((blobUrl) => storage.cachedUrl(blobUrl));
                }
                return storage.cachedUrl();
              },
            },
          },
        },
        Query: {
          fields: {
            companyListResult: {
              keyArgs: false,
            },
            projectListResult: {
              keyArgs: false,
            },
          },
        },
      },
    }),
    typeDefs,
    assumeImmutableResults: true,
  });
};
