export const MOBILE_DEVICE_WIDTH = 960;

export const MERCURY_ENVIRONMENTS = {
  standalone: 'standalone',
  test: 'test',
  local: 'local',
  dev: 'dev',
  uat: 'uat',
  prod: 'prod',
};

export const ENV = process.env.REACT_APP_ENV;
