import { atom, useRecoilState } from 'recoil';
import { defaultListTableState, listTableHasActiveFilters } from 'recoil/shared';
import { StandardListTableState } from 'types';
import { ProjectListFilterInput } from 'types/generated/graphql';

export type ProjectListTableState = StandardListTableState<ProjectListFilterInput>;

const projectListTableStateAtom = atom<ProjectListTableState>({
  key: 'projectListTableState',
  default: defaultListTableState,
});

export const useProjectListTableState = () => {
  const [projectListTableState, setProjectListTableState] = useRecoilState(projectListTableStateAtom);

  function handleProjectListTableStateChange(newState: ProjectListTableState) {
    newState.hasActiveFilters = listTableHasActiveFilters<ProjectListFilterInput>(newState);

    setProjectListTableState({
      ...projectListTableState,
      ...newState,
    });
  }
  return [projectListTableState, { handleProjectListTableStateChange }] as const;
};

export default projectListTableStateAtom;
