import './index.scss';

import App from 'components/App';
import AzureAdProtector from 'components/AzureAdProtector';
import CacheBuster from 'components/CacheBuster';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import { getMsalInstance } from 'config/msalConfig';
import { detect } from 'detect-browser';
import UnsupportedBrowserDisclaimerPage from 'pages/UnsupportedBrowserDisclaimerPage';
import MercuryApolloProvider from 'providers/MercuryApolloProvider';
import MercuryThemeProvider from 'providers/MercuryThemeProvider';
import ToastProvider from 'providers/ToastProvider';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { MsalProvider } from '@azure/msal-react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import * as Sentry from '@sentry/react';
import { registerLicense } from '@syncfusion/ej2-base';

import { ENV } from './constants';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE_KEY ?? '');

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://32f9c921c82e44ecbe117a1481b0fff1@o322387.ingest.sentry.io/5772801',
    environment: ENV,
  });
}

const msalInstance = getMsalInstance();
const target = document.getElementById('root');
const browser = detect();

if (browser && browser.name === 'ie') {
  ReactDOM.render(
    <React.Suspense fallback={<LoadingSpinner isFullScreen />}>
      <UnsupportedBrowserDisclaimerPage />
    </React.Suspense>,
    target,
  );
} else {
  ReactDOM.render(
    <React.StrictMode>
      <RecoilRoot>
        <MercuryThemeProvider>
          <BrowserRouter>
            <MsalProvider instance={msalInstance}>
              <MercuryApolloProvider msalInstance={msalInstance}>
                <ToastProvider>
                  <AzureAdProtector>
                    <CacheBuster>
                      <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <App />
                      </LocalizationProvider>
                    </CacheBuster>
                  </AzureAdProtector>
                </ToastProvider>
              </MercuryApolloProvider>
            </MsalProvider>
          </BrowserRouter>
        </MercuryThemeProvider>
      </RecoilRoot>
    </React.StrictMode>,
    document.getElementById('root'),
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
