import ErrorIcon from '@mui/icons-material/Error';
import ImageIcon from '@mui/icons-material/Image';
import { Box, SxProps, Theme } from '@mui/material';

const img: SxProps<Theme> = {
  display: 'block',
  maxWidth: '90%',
};

const fullSizeScale: SxProps<Theme> = {
  width: '50%',
  height: '50%',
  alignSelf: 'center',
};

const MercuryFileDisplayImage = ({ file, isFullSizePreview = false }: { file: any; isFullSizePreview?: boolean }) => {
  if (file) {
    if (file.cachedUrl && !file.isMalWare) {
      return (
        <Box component="img" data-testid="image-cachedUrl" src={file.cachedUrl} sx={img} alt={`${file.name}`}></Box>
      );
    }

    if (file.isMalware) {
      return <ErrorIcon data-testid="malware-icon" sx={isFullSizePreview ? fullSizeScale : undefined} />;
    }
  }

  return (
    <>
      <ImageIcon data-testid="image-icon" sx={fullSizeScale} />
    </>
  );
};

export default MercuryFileDisplayImage;
