import useActiveRoute from 'hooks/useActiveRoute';
import useCompany from 'hooks/useCompany';
import useProject from 'hooks/useProject';
import PropTypes from 'prop-types';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  Breadcrumbs,
  Grid,
  Link as MuiLink,
  SxProps,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';

const root: SxProps<Theme> = {
  justifyContentContent: 'center',
  padding: '0 12px',
};

const breadcrumbs: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.secondary.contrastText,
});

const breadcrumbLinkText: SxProps<Theme> = {
  fontSize: '0.875rem',
  maxWidth: 200,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const BreadCrumbTitle = ({ title, tooltipTitle }: any) => {
  return (
    <Tooltip arrow title={tooltipTitle ?? ''}>
      <Typography color="textPrimary" sx={breadcrumbLinkText}>
        {title}
      </Typography>
    </Tooltip>
  );
};

type BreadcrumbProps = {
  path: string;
  title: string;
  tooltipTitle?: string;
  isReadOnly?: boolean;
};

const Breadcrumb: FC<BreadcrumbProps> = ({ path, title, tooltipTitle, isReadOnly = false }) => {
  if (isReadOnly) {
    return <BreadCrumbTitle title={title} tooltipTitle={tooltipTitle} />;
  } else {
    return (
      <MuiLink component={RouterLink} to={path} underline="none">
        <BreadCrumbTitle title={title} tooltipTitle={tooltipTitle} />
      </MuiLink>
    );
  }
};

const BreadcrumbNavigation = () => {
  const { isActiveRoute, params } = useActiveRoute();
  const { projectId, companyId } = params;

  const { project } = useProject(projectId);
  const { company } = useCompany(companyId);

  return (
    <Grid sx={root}>
      <Breadcrumbs separator="/" sx={breadcrumbs}>
        {/* Project  */}
        {isActiveRoute('/projects', false) && <Breadcrumb path={'/projects'} title={'Projects'} isReadOnly={false} />}
        {isActiveRoute('/projects/:projectId', false) && (
          // TODO: Update isReadOnly once there is an actual project page
          <Breadcrumb path={`/projects/${project?.id}`} title={project?.name ?? 'Project'} isReadOnly={true} />
        )}
        {isActiveRoute('/projects/:projectId/permissions', true) && (
          <Breadcrumb path={`/projects/${project?.id}/permissions`} title={'Permissions'} isReadOnly={true} />
        )}
        {isActiveRoute('/projects/:projectId/reports', false) && (
          <Breadcrumb path={`/projects/${project?.id}/reports`} title={'Reports'} isReadOnly={true} />
        )}
        {isActiveRoute('/projects/:projectId/reports/small-business-report', false) && (
          <Breadcrumb
            path={`/projects/${project?.id}/reports/small-business-report`}
            isReadOnly={!project}
            title={'Small Business Report'}
          />
        )}

        {/* Company  */}
        {isActiveRoute('/companies', false) && (
          <Breadcrumb path={'/companies'} title={'Companies'} isReadOnly={false} />
        )}
        {isActiveRoute('/companies/:companyId', false) && (
          <Breadcrumb path={`/companies/${company?.id}`} isReadOnly={!company} title={company?.name ?? 'Company'} />
        )}
        {isActiveRoute('/adminSettings', false) && (
          <Breadcrumb path={'/adminSettings'} isReadOnly={false} title={'Admin Settings'} />
        )}
      </Breadcrumbs>
    </Grid>
  );
};

BreadcrumbNavigation.propTypes = {
  location: PropTypes.any,
};

export default BreadcrumbNavigation;
