import { matchPath, useLocation } from 'react-router-dom';

const useActiveRoute = () => {
  const location = useLocation();
  const isActiveRoute = (routeName: string, exact = true) => {
    return !!matchPath(location.pathname, {
      path: routeName,
      exact: exact,
    });
  };

  const projectMatch = matchPath(location.pathname, {
    path: '/projects/:projectId',
    exact: false,
  });

  const companyMatch = matchPath(location.pathname, {
    path: '/companies/:companyId',
    exact: false,
  });

  const params = {
    companyId: undefined,
    projectId: undefined,
    ...projectMatch?.params,
    ...companyMatch?.params,
  };

  return { isActiveRoute, params };
};

export default useActiveRoute;
