export const AGENCY_DIALOG_VIEWS = {
  EDIT_AGENCY: 'editAgency',
  ADD_AGENCY: 'addAgency',
  DELETE_AGENCY: 'deleteAgency',
  CONFIRM_CANCEL: 'confirmCancel',
};

export const CLASSIFICATION_VIEWS = {
  EDIT_CLASSIFICATION: 'editClassification',
  ADD_CLASSIFICATION: 'addClassification',
  DELETE_CLASSIFICATION: 'deleteClassification',
  CONFIRM_CANCEL: 'confirmCancel',
};

export const CLIENT_DIALOG_VIEWS = {
  EDIT_CLIENT: 'editClient',
  ADD_CLIENT: 'addClient',
  DELETE_CLIENT: 'deleteClient',
  CONFIRM_CANCEL: 'confirmCancel',
};
