import { gql } from '@apollo/client';

export const UPLOAD_COMPANY_FILE = gql`
  mutation UploadCompanyFile($input: CompanyFileCreateInput!) {
    uploadCompanyFile(input: $input) {
      id
      title
      notes
      uploaded
      uploadedBy {
        id
        name
      }
      file {
        id
        contentType
        created
        isMalware
        isScanned
        lastModified
        name
        sizeInBytes
        url
        cachedUrl @client
      }
    }
  }
`;

export const UPDATE_COMPANY_FILE = gql`
  mutation UpdateCompanyFile($input: CompanyFileUpdateInput!) {
    updateCompanyFile(input: $input) {
      id
      title
      notes
      uploaded
      uploadedBy {
        id
        name
      }
      file {
        id
        contentType
        created
        isMalware
        isScanned
        lastModified
        name
        sizeInBytes
        url
        cachedUrl @client
      }
    }
  }
`;

export const DELETE_COMPANY_FILE = gql`
  mutation DeleteCompanyFile($id: ID!) {
    deleteCompanyFile(id: $id) {
      id
    }
  }
`;
