import {
  createTheme as createMuiTheme,
  unstable_createMuiStrictModeTheme,
} from '@mui/material/styles';

import {
  appleGreen,
  blackGray,
  brightWhite,
  hpRedDarkened,
  hpRedLightened,
  overlayLight,
  trueBlack,
} from './colors';
import { HENSEL_PHELPS_TYPOGRAPHY, sharedOverrides } from './shared';

// THEME COLORS: LIGHT
const hpRed = '#A6192E';
const featherLightGray = '#f2f2f2';
const mediumLightGray = '#d9d9d9';
const lightAppleGreen = '#749116';
const peach = '#DF6C2C';
const darkPeach = '#B25623';
const blueSea = '#1975A6';
const raspberry = '#cd0000';
const darkRaspberry = '#A40000';

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    checkMark: string;
    transparent: string;
    overlay: string;
  }
  interface PaletteOptions {
    checkMark?: string;
    transparent?: string;
    overlay: string;
  }
}
declare module '@mui/material/styles/createTheme' {
  interface Theme {
    transparent: string;
    primaryAppbarBackgroundColor: string;
    secondaryAppbarBackgroundColor: string;
    errorNotice: string;
  }
  interface DeprecatedThemeOptions {
    transparent?: string;
    primaryAppbarBackgroundColor?: string;
    secondaryAppbarBackgroundColor?: string;
    errorNotice?: string;
  }
}

const createTheme = process.env.NODE_ENV === 'production' ? createMuiTheme : unstable_createMuiStrictModeTheme;

export const HP_LIGHT_THEME = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: hpRed,
      light: hpRedLightened,
      dark: hpRedDarkened,
      contrastText: brightWhite,
    },
    secondary: {
      main: featherLightGray,
      light: brightWhite,
      dark: mediumLightGray,
      contrastText: trueBlack,
    },
    error: {
      main: raspberry,
      dark: darkRaspberry,
    },
    warning: {
      main: peach,
      dark: darkPeach,
    },
    info: {
      main: blueSea,
    },
    success: {
      main: lightAppleGreen,
    },
    background: {
      default: featherLightGray,
      paper: brightWhite,
    },
    overlay: overlayLight,
    checkMark: appleGreen,
  },

  // App wide
  typography: HENSEL_PHELPS_TYPOGRAPHY,

  // Overrides
  components: Object.assign({
    sharedOverrides,
    MuiChip: {
      styleOverrides: {
        deleteIcon: {
          color: trueBlack,
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-active': {
            color: hpRed + '!important',
          },
          '&.Mui-completed': {
            color: lightAppleGreen + '!important',
          },
        },
      },
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        icon: {
          '&:hover': {
            color: trueBlack,
          },
        },
        iconActive: {
          color: hpRed,
        },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          color: trueBlack,
        },
      },
    },
    MUIDataTable: {
      styleOverrides: {
        responsiveBase: {
          overflow: 'hidden',
        },
      },
    },
    MUITableCell: {
      styleOverrides: {
        root: {
          color: trueBlack,
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        icon: {
          color: trueBlack,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '0.875rem',
          backgroundColor: blackGray,
          fontWeight: 'bold',
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        container: {
          position: 'unset !important',
          //marginTop: '10px !important',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation: {
          marginBottom: '10px !important',
        },
      },
    },
  }),
});
