import { gql } from '@apollo/client';

export const GET_PAGINATED_COMPANIES = gql`
  query GetPaginatedCompanies(
    $skip: Int!
    $first: Int!
    $search: String
    $filter: CompanyListFilterInput
    $order: CompanyListOrderInput
  ) {
    companyListResult(skip: $skip, first: $first, search: $search, filter: $filter, order: $order) {
      count
      items {
        id
        name
        location
        federalSmallBusinessClassifications {
          id
          name
          abbreviation
          sortOrder
        }
        smallBusinessClassifications {
          id
          name
          abbreviation
          sortOrder
        }
      }
    }
  }
`;

export const GET_SINGLE_COMPANY = gql`
  query GetSingleCompany($id: ID!) {
    company(id: $id) {
      id
      name
      location
      notes
      federalSmallBusinessClassifications {
        id
        name
        abbreviation
        sortOrder
      }
      smallBusinessClassifications {
        id
        name
        abbreviation
        sortOrder
      }
      files {
        id
        title
        notes
        uploaded
        uploadedBy {
          id
          name
        }
        file {
          id
          contentType
          created
          isMalware
          isScanned
          lastModified
          name
          sizeInBytes
          url
          cachedUrl @client
        }
        smallBusinessClassificationCoverages {
          id
          isFederal
          smallBusinessClassification {
            id
            name
            abbreviation
            sortOrder
            isFederal
          }
          startDate
          endDate
          smallBusinessAgency {
            id
            name
            abbreviation
            url
            participationPercentage
            specialNotice
          }
        }
      }
    }
  }
`;

export const GET_COMPANIES_IN_USE = gql`
  query GetCompaniesInUse($smallBusinessAgencyId: String, $smallBusinessClassificationIds: [String!]) {
    getCompaniesInUse(
      smallBusinessAgencyId: $smallBusinessAgencyId
      smallBusinessClassificationIds: $smallBusinessClassificationIds
    ) {
      id
      name
      files {
        id
        smallBusinessClassificationCoverages {
          id
          smallBusinessClassification {
            id
            name
            abbreviation
          }
        }
      }
    }
  }
`;

export const UPDATE_COMPANY = gql`
  mutation UpdateCompany($input: CompanyUpdateInput!) {
    updateCompany(input: $input) {
      id
      name
      notes
      location
    }
  }
`;
