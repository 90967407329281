import StyledButtonPrimary from 'components/shared/ButtonPrimary';
import StyledButtonSecondary from 'components/shared/ButtonSecondary';
import StyledDialog from 'components/shared/Dialog';
import ErrorNotice from 'components/shared/ErrorNotice';
import { CLASSIFICATION_VIEWS } from 'constants/adminDialogViews';
import { SmallBusinessAdminRequirementOption } from 'constants/smallBusinessRequirementOptions';
import useToast from 'hooks/useToast';
import { FC, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  useDeleteSmallBusinessClassificationMutation,
  useGetCompaniesInUseQuery,
  useGetLowerTierParticipationsInUseQuery,
  useGetProjectsInUseQuery,
  useGetSmallBusinessAgenciesInUseQuery,
  useUpsertSmallBusinessClassificationMutation,
} from 'types/generated/graphql';
import { generateTransactionKey } from 'utils/general';

import { useApolloClient } from '@apollo/client';
import {
  Box,
  FormControlLabel,
  Grid,
  InputLabel,
  List,
  ListItem,
  Radio,
  RadioGroup,
  SxProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material';

type SmallBusinessClassificationDialogProps = {
  isOpen: boolean;
  setIsOpen: (x: boolean) => void;
  smallBusinessClassificationData: any;
  setSmallBusinessClassificationDialogView: any;
  smallBusinessClassificationDialogView: string;
  handleClose: (x: any) => void;
};

const nameInputLabelStyle: SxProps<Theme> = (theme: Theme) => ({
  marginBottom: '-8px',
  marginTop: '5px',
  color: theme.palette.secondary.contrastText,
});

const abbreviationInputLabelStyle: SxProps<Theme> = (theme: Theme) => ({
  marginBottom: '-15px',
  marginTop: '5px',
  color: theme.palette.secondary.contrastText,
});

const errorMessageStyle: SxProps<Theme> = {
  color: '#cd0000',
  marginLeft: '14px',
  marginTop: '4px',
  fontSize: '.75rem',
};

const inputContainer: SxProps<Theme> = {
  marginTop: '20px',
};

const unableToDeleteMessageStyles: SxProps<Theme> = {
  marginBottom: '20px',
};

const radioLabelStyle: SxProps<Theme> = {
  fontSize: '0.875rem',
};

const requirementLabelStyle: SxProps<Theme> = (theme: Theme) => ({
  marginBottom: '-15px',
  marginTop: '15px',
  color: theme.palette.secondary.contrastText,
});

const dataInUseLabelStyle: SxProps<Theme> = {
  fontWeight: 'bold',
};

const dataInUseContainerStyle: SxProps<Theme> = {
  maxHeight: '35vh',
  overflow: 'auto',
};

type EditSmallBusinessClassificationInput = {
  name: string;
  abbreviation?: string;
  isFederal?: boolean;
};

const SmallBusinessClassificationDialog: FC<SmallBusinessClassificationDialogProps> = ({
  isOpen,
  setIsOpen,
  smallBusinessClassificationData,
  setSmallBusinessClassificationDialogView,
  smallBusinessClassificationDialogView,
}) => {
  const { displayToast } = useToast();
  const apolloClient = useApolloClient();
  const [transactionKey, setTransactionKey] = useState(generateTransactionKey());
  const [isEditing, setIsEditing] = useState(false);

  const { EDIT_CLASSIFICATION, ADD_CLASSIFICATION, DELETE_CLASSIFICATION, CONFIRM_CANCEL } = CLASSIFICATION_VIEWS;

  useEffect(() => {
    if (smallBusinessClassificationDialogView === EDIT_CLASSIFICATION) {
      setIsEditing(true);
    }
  }, [EDIT_CLASSIFICATION, smallBusinessClassificationDialogView]);

  const [createSmallBusinessClassification] = useUpsertSmallBusinessClassificationMutation();
  const [updateSmallBusinessClassification, { loading: isLoading }] = useUpsertSmallBusinessClassificationMutation();
  const [deleteSmallBusinessClassification] = useDeleteSmallBusinessClassificationMutation();

  const {
    loading: usedLowerTierParticipationsLoading,
    error: usedLowerTierParticipationsError,
    data: usedLowerTierParticipationsData,
  } = useGetLowerTierParticipationsInUseQuery({
    client: apolloClient,
    variables: {
      smallBusinessClassificationIds: [smallBusinessClassificationData?.id],
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    skip:
      typeof smallBusinessClassificationData?.id !== 'string' ||
      smallBusinessClassificationDialogView !== DELETE_CLASSIFICATION,
  });

  const lowerTierParticipationsInUse = usedLowerTierParticipationsData?.getLowerTierParticipationsInUse ?? [];

  const {
    loading: usedProjectsLoading,
    error: usedProjectsError,
    data: usedProjectsData,
  } = useGetProjectsInUseQuery({
    client: apolloClient,
    variables: {
      smallBusinessClassificationId: smallBusinessClassificationData?.id,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    skip:
      typeof smallBusinessClassificationData?.id !== 'string' ||
      smallBusinessClassificationDialogView !== DELETE_CLASSIFICATION,
  });

  const projectsInUse = usedProjectsData?.getProjectsInUse ?? [];

  const projectsInUseWithGoalPercentage = projectsInUse?.filter(
    (project) =>
      project.smallBusinessReportSettings?.classifications.find(
        (classification) => classification.smallBusinessClassification.id === smallBusinessClassificationData.id,
      )?.goalPercent,
  );

  const {
    loading: usedCompaniesLoading,
    error: usedCompaniesError,
    data: usedCompaniesData,
  } = useGetCompaniesInUseQuery({
    client: apolloClient,
    variables: {
      smallBusinessClassificationIds: [smallBusinessClassificationData?.id],
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    skip:
      typeof smallBusinessClassificationData?.id !== 'string' ||
      smallBusinessClassificationDialogView !== DELETE_CLASSIFICATION,
  });

  const companiesInUse = usedCompaniesData?.getCompaniesInUse ?? [];

  const {
    loading: usedAgenciesLoading,
    error: usedAgenciesError,
    data: usedAgenciesData,
  } = useGetSmallBusinessAgenciesInUseQuery({
    client: apolloClient,
    variables: {
      smallBusinessClassificationId: smallBusinessClassificationData?.id,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    skip:
      typeof smallBusinessClassificationData?.id !== 'string' ||
      smallBusinessClassificationDialogView !== DELETE_CLASSIFICATION,
  });

  const agenciesInUse = usedAgenciesData?.getSmallBusinessAgenciesInUse ?? [];

  const defaultValues = {
    name: smallBusinessClassificationData?.name ?? '',
    abbreviation: smallBusinessClassificationData?.abbreviation ?? '',
    sortOrder: smallBusinessClassificationData?.sortOrder ?? smallBusinessClassificationData.id,
    isFederal:
      smallBusinessClassificationData?.isFederal === null ? 'any/all' : smallBusinessClassificationData?.isFederal,
  };

  const { handleSubmit, control, reset, watch, formState } = useForm<EditSmallBusinessClassificationInput>({
    defaultValues: defaultValues,
  });

  const { isDirty } = formState;

  const smallBusinessClassificationName = watch('name');
  const smallBusinessClassificationAbbreviation = watch('abbreviation');
  const smallBusinessClassificationRequirement = watch('isFederal');

  const handleClose = () => {
    setIsOpen(false);
    setIsEditing(false);
    reset(defaultValues);
    setTransactionKey(generateTransactionKey());
  };

  if (usedLowerTierParticipationsError || usedCompaniesError || usedAgenciesError || usedProjectsError) {
    if (usedLowerTierParticipationsError) {
      console.error(usedLowerTierParticipationsError);
    } else if (usedCompaniesError) {
      console.error(usedCompaniesError);
    } else if (usedProjectsError) {
      console.error(usedProjectsError);
    } else {
      console.error(usedAgenciesError);
    }
    displayToast(
      'Error: Something went wrong while fetching data using the classification. If the problem persists after reloading the page, please contact support.',
      'error',
    );
  }

  if (usedLowerTierParticipationsError || usedCompaniesError || usedAgenciesError || usedProjectsError) {
    return <ErrorNotice />;
  }

  const isFormValid = () => {
    return (
      smallBusinessClassificationName?.trim() !== '' &&
      smallBusinessClassificationAbbreviation?.trim() !== '' &&
      smallBusinessClassificationRequirement !== undefined
    );
  };

  const generateDialogTitle = () => {
    if (smallBusinessClassificationDialogView === EDIT_CLASSIFICATION) {
      return `Editing ${smallBusinessClassificationData?.name}`;
    }

    if (smallBusinessClassificationDialogView === ADD_CLASSIFICATION) {
      return 'Add New Classification';
    }

    if (smallBusinessClassificationDialogView === DELETE_CLASSIFICATION) {
      return `Delete ${smallBusinessClassificationData?.name}`;
    }

    if (smallBusinessClassificationDialogView === CONFIRM_CANCEL) {
      return 'Discard Classification';
    }
  };

  const generateDialogContent = () => {
    if (smallBusinessClassificationDialogView === CONFIRM_CANCEL) {
      return (
        <>
          <Grid container>
            <Grid item>
              <Typography className="margin-bottom">
                {'Are you sure you want to cancel? All information will be lost.'}
              </Typography>
            </Grid>
          </Grid>
        </>
      );
    }

    if (smallBusinessClassificationDialogView === DELETE_CLASSIFICATION) {
      return (
        <>
          <Grid container>
            {(!usedLowerTierParticipationsLoading ||
              !usedCompaniesLoading ||
              !usedAgenciesLoading ||
              !usedProjectsLoading) &&
              !lowerTierParticipationsInUse?.length &&
              !companiesInUse?.length &&
              !agenciesInUse?.length &&
              ((smallBusinessClassificationData?.isFederal === false && !projectsInUse?.length) ||
                (smallBusinessClassificationData?.isFederal !== false && !projectsInUseWithGoalPercentage?.length)) && (
                <Grid item>
                  <Typography className="margin-bottom">
                    {`Are you sure you want to delete the classification: ${smallBusinessClassificationData.name}? This action cannot be undone.`}
                  </Typography>
                </Grid>
              )}
            <Grid container justifyContent="space-between">
              {(usedLowerTierParticipationsLoading ||
                usedCompaniesLoading ||
                usedAgenciesLoading ||
                usedProjectsLoading) && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography>Loading...</Typography>
                </Grid>
              )}
              {(!usedLowerTierParticipationsLoading ||
                !usedCompaniesLoading ||
                !usedAgenciesLoading ||
                !usedProjectsLoading) &&
                (!!lowerTierParticipationsInUse?.length ||
                  !!companiesInUse?.length ||
                  !!agenciesInUse?.length ||
                  (smallBusinessClassificationData?.isFederal === false && !!projectsInUse?.length) ||
                  (smallBusinessClassificationData?.isFederal !== false &&
                    !!projectsInUseWithGoalPercentage?.length)) && (
                  <Box sx={dataInUseContainerStyle}>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12} sx={unableToDeleteMessageStyles}>
                        <Typography>
                          Unable to delete the classification as it is being used by the following:
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        {!!lowerTierParticipationsInUse?.length && (
                          <List>
                            <Grid item>
                              <Typography sx={dataInUseLabelStyle}>Lower Tier Participations:</Typography>
                            </Grid>
                            {lowerTierParticipationsInUse?.map((lowerTierParticipation) => (
                              <ListItem key={lowerTierParticipation.id}>
                                {lowerTierParticipation?.lowerTierCompanyName +
                                  ` (Project: ${lowerTierParticipation.contract.project.number} - ${lowerTierParticipation.contract.project.name})`}
                              </ListItem>
                            ))}
                          </List>
                        )}
                        {!!companiesInUse?.length && (
                          <List>
                            <Grid item>
                              <Typography sx={dataInUseLabelStyle}>Companies:</Typography>
                            </Grid>
                            {companiesInUse?.map((classification) => (
                              <ListItem key={classification.id}>{classification.name}</ListItem>
                            ))}
                          </List>
                        )}
                        {!!agenciesInUse?.length && (
                          <List>
                            <Grid item>
                              <Typography sx={dataInUseLabelStyle}>Agencies:</Typography>
                            </Grid>
                            {agenciesInUse?.map((agency) => (
                              <ListItem key={agency.id}>{agency.name}</ListItem>
                            ))}
                          </List>
                        )}
                        {((smallBusinessClassificationData?.isFederal === false && !!projectsInUse?.length) ||
                          (smallBusinessClassificationData?.isFederal !== false &&
                            !!projectsInUseWithGoalPercentage?.length)) && (
                          <List>
                            <Grid item>
                              <Typography sx={dataInUseLabelStyle}>Projects:</Typography>
                            </Grid>
                            {projectsInUse?.map((project) => (
                              <ListItem key={project.id}>
                                {project.number} - {project.name}
                              </ListItem>
                            ))}
                          </List>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                )}
            </Grid>
          </Grid>
        </>
      );
    }

    if (
      (smallBusinessClassificationDialogView === EDIT_CLASSIFICATION && isEditing) ||
      smallBusinessClassificationDialogView === ADD_CLASSIFICATION
    ) {
      return (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid item>
            <InputLabel sx={nameInputLabelStyle} shrink>
              Classification Name
            </InputLabel>
            <Controller
              render={({ field }) => <TextField {...field} variant="outlined" margin="dense" fullWidth size="small" />}
              name="name"
              control={control}
              rules={{ required: true }}
            />
            <Grid sx={errorMessageStyle}>
              {!smallBusinessClassificationName?.trim() ? 'Classification name is required' : null}
            </Grid>
          </Grid>
          <Grid item sx={inputContainer}>
            <InputLabel sx={abbreviationInputLabelStyle} shrink>
              Abbreviation
            </InputLabel>
            <Controller
              render={({ field }) => <TextField {...field} variant="outlined" margin="dense" size="small" />}
              name="abbreviation"
              control={control}
              rules={{ required: true }}
            />
            <Grid sx={errorMessageStyle}>
              {!smallBusinessClassificationAbbreviation?.trim() ? 'Classification abbreviation is required' : null}
            </Grid>
          </Grid>
          {smallBusinessClassificationDialogView === ADD_CLASSIFICATION && (
            <Grid item>
              <Grid container>
                <InputLabel sx={requirementLabelStyle} shrink>
                  SB Requirement
                </InputLabel>
              </Grid>
              <Controller
                render={({ field }) => {
                  return (
                    <RadioGroup {...field}>
                      <Grid container direction="row" spacing={2}>
                        <Grid item>
                          <FormControlLabel
                            value={'any/all'}
                            control={<Radio size="small" color="primary" />}
                            label={
                              <Typography sx={radioLabelStyle}>
                                {SmallBusinessAdminRequirementOption.ANY_ALL}
                              </Typography>
                            }
                          />
                        </Grid>
                        <Grid item>
                          <FormControlLabel
                            value={true}
                            control={<Radio size="small" color="primary" />}
                            label={
                              <Typography sx={radioLabelStyle}>
                                {SmallBusinessAdminRequirementOption.FEDERAL}
                              </Typography>
                            }
                          />
                        </Grid>
                        <Grid item>
                          <FormControlLabel
                            value={false}
                            control={<Radio size="small" color="primary" />}
                            label={
                              <Typography sx={radioLabelStyle}>
                                {SmallBusinessAdminRequirementOption.NON_FEDERAL}
                              </Typography>
                            }
                          />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  );
                }}
                name="isFederal"
                control={control}
                rules={{ required: smallBusinessClassificationRequirement === undefined }}
              />
              <Grid sx={errorMessageStyle}>
                {smallBusinessClassificationRequirement === undefined ? 'SB Requirement is required' : null}
              </Grid>
            </Grid>
          )}
        </form>
      );
    }
  };

  const onSubmit: SubmitHandler<EditSmallBusinessClassificationInput> = (data: any) => {
    const isFederal =
      data.isFederal === 'true'
        ? true
        : data.isFederal === 'false'
        ? false
        : data.isFederal === 'any/all'
        ? null
        : undefined;

    /* Federal classifications have a specific sortOrder. Since sortOrder is a required field, 
  non-federal classifications are assigned a default sortOrder of 100, which has no special significance.*/
    const sortOrder = () => {
      if (data.isFederal === 'true') {
        return data.sortOrder;
      }
      return 100;
    };

    if (smallBusinessClassificationDialogView === ADD_CLASSIFICATION) {
      createSmallBusinessClassification({
        variables: {
          input: {
            transactionKey,
            name: data.name,
            abbreviation: data.abbreviation.toUpperCase(),
            sortOrder: sortOrder(),
            isFederal,
          },
        },
      })
        .then(() => {
          handleClose();
          apolloClient.reFetchObservableQueries();
          displayToast('The classification was added successfully', 'success');
        })
        .catch((error: any) => {
          console.error('Add classification failed: ', error);
          displayToast(
            'Error: Something went wrong while trying to add the classification. Please try again. If the problem persists, please contact support.',
            'error',
          );
        });
    }

    if (smallBusinessClassificationDialogView === EDIT_CLASSIFICATION) {
      updateSmallBusinessClassification({
        variables: {
          input: {
            id: smallBusinessClassificationData.id,
            name: data.name,
            abbreviation: data.abbreviation.toUpperCase(),
            sortOrder: smallBusinessClassificationData.sortOrder,
            isFederal,
          },
        },
      })
        .then(() => {
          handleClose();
          apolloClient.reFetchObservableQueries();
          displayToast('The classification was updated successfully', 'success');
        })
        .catch((error: any) => {
          console.error('Update classification failed: ', error);
          displayToast(
            'Error: Something went wrong while trying to update the classification. Please try again. If the problem persists, please contact support.',
            'error',
          );
        });
    }
  };

  const handleDeleteSmallBusinessClassification = () => {
    deleteSmallBusinessClassification({
      variables: { id: smallBusinessClassificationData.id },
    })
      .then(() => {
        handleClose();
        apolloClient.reFetchObservableQueries();
        displayToast('The classification was deleted successfully', 'success');
      })
      .catch((error: any) => {
        console.error('Delete classification failed: ', error);
        displayToast(
          'Error: Something went wrong while trying to delete the classification. Please try again. If the problem persists, please contact support.',
          'error',
        );
      });
  };

  const generateActions = () => {
    if (smallBusinessClassificationDialogView === DELETE_CLASSIFICATION) {
      return (
        <>
          <Grid item>
            <StyledButtonSecondary
              label={'Cancel'}
              disabled={isLoading}
              onClick={() => {
                setSmallBusinessClassificationDialogView(undefined);
                handleClose();
              }}
            />
          </Grid>
          <Grid item>
            <StyledButtonPrimary
              variant="contained"
              color="primary"
              onClick={() => handleDeleteSmallBusinessClassification()}
              label={'Delete'}
              disabled={
                isLoading ||
                lowerTierParticipationsInUse.length !== 0 ||
                companiesInUse.length !== 0 ||
                agenciesInUse.length !== 0 ||
                (smallBusinessClassificationData?.isFederal === false && projectsInUse?.length !== 0) ||
                (smallBusinessClassificationData?.isFederal !== false && projectsInUseWithGoalPercentage?.length !== 0)
              }
            />
          </Grid>
        </>
      );
    }

    if (smallBusinessClassificationDialogView === EDIT_CLASSIFICATION) {
      return (
        <>
          <Grid container justifyContent={'space-between'}>
            <Grid item>
              <StyledButtonSecondary
                label={'Cancel'}
                disabled={isLoading}
                onClick={() => {
                  setSmallBusinessClassificationDialogView(undefined);
                  handleClose();
                }}
              />
            </Grid>
            <Grid item>
              <StyledButtonPrimary
                variant="contained"
                color="primary"
                onClick={handleSubmit(onSubmit)}
                label={'Save'}
                disabled={!isFormValid() || isLoading || !isDirty}
              />
            </Grid>
          </Grid>
        </>
      );
    }

    if (smallBusinessClassificationDialogView === ADD_CLASSIFICATION) {
      return (
        <>
          <Grid container justifyContent="space-between">
            <Grid item>
              <StyledButtonSecondary
                label={'Cancel'}
                disabled={isLoading}
                onClick={() => {
                  setSmallBusinessClassificationDialogView(undefined);
                  handleClose();
                }}
              />
            </Grid>
            <Grid item>
              <StyledButtonPrimary
                label={'Submit'}
                disabled={!isFormValid() || isLoading}
                onClick={handleSubmit(onSubmit)}
              />
            </Grid>
          </Grid>
        </>
      );
    }
  };

  return (
    <StyledDialog
      title={generateDialogTitle()}
      content={generateDialogContent()}
      actions={generateActions()}
      isOpen={isOpen}
      handleClose={handleClose}
      fullWidth={true}
      disableEscapeKeyDown={true}
    />
  );
};

export default SmallBusinessClassificationDialog;
