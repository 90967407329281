import useDebounce from 'hooks/useDebounce';
import { MUIDataTableOptions, MUIDataTableState, MUISortOptions } from 'mui-datatables';
import { StandardListTableOrderState, StandardListTableState } from 'types';
import { SortOrderOption } from 'types/generated/graphql';

type UseTablePaginationProps = {
  state: StandardListTableState<any>;
  handleTableStateChange: (state: StandardListTableState<any>) => void;
  fetchMore: any;
  handleFilterChange: ({
    muiTableState,
    state,
  }: {
    muiTableState: MUIDataTableState;
    state: StandardListTableState<any>;
  }) => void;
};

const useTablePagination = ({
  state,
  handleTableStateChange,
  fetchMore,
  handleFilterChange,
}: UseTablePaginationProps) => {
  const getSortOrderState = (sortOptions: MUISortOptions): StandardListTableOrderState => ({
    field: sortOptions.name,
    direction: sortOptions.direction === 'asc' ? SortOrderOption.Asc : SortOrderOption.Desc,
  });
  type getMUISortOptionsArgs = {
    orderState?: StandardListTableOrderState;
    defaultOptions: MUISortOptions;
  };
  const getMUISortOptions = ({ orderState, defaultOptions }: getMUISortOptionsArgs): MUISortOptions => ({
    name: orderState?.field ?? defaultOptions.name,
    direction: orderState?.direction === SortOrderOption.Asc ? 'asc' : 'desc' ?? defaultOptions.direction,
  });
  const handlePageChange = (muiTableState: MUIDataTableState) => {
    const { rowsPerPage, page } = muiTableState;

    let skip = page < state.page ? state.skip - rowsPerPage : state.skip + rowsPerPage;

    handleTableStateChange({
      ...state,
      page,
      skip,
    });

    fetchMore({
      variables: {
        search: state.search,
        skip,
        filter: state.filter,
        order: state.order,
      },
    });
  };

  const handleSortChange = (muiTableState: MUIDataTableState) => {
    const sortOrder = getSortOrderState(muiTableState.sortOrder);

    handleTableStateChange({
      ...state,
      order: sortOrder,
      page: 0,
      skip: 0,
    });

    fetchMore({
      variables: {
        search: state.search,
        skip: 0,
        filter: state.filter,
        order: sortOrder,
      },
    });
  };

  const handleSearchChange = ({
    muiTableState,
    state,
  }: {
    muiTableState: MUIDataTableState;
    state: StandardListTableState<unknown>;
  }) => {
    const { searchText } = muiTableState;

    handleTableStateChange({
      ...state,
      skip: 0,
      page: 0,
      search: searchText ?? undefined,
    });

    fetchMore({
      variables: {
        search: searchText,
        skip: 0,
        filter: state.filter,
        order: state.order,
      },
    });
  };

  const { debounced: debouncedHandleSearchChange } = useDebounce(handleSearchChange);

  const options: MUIDataTableOptions = {
    serverSide: true,
    rowsPerPage: state.first,
    searchText: state.search,
    page: state.page,
    sortOrder: getMUISortOptions({ orderState: state.order, defaultOptions: { name: 'name', direction: 'asc' } }),
    onTableChange: (action, muiTableState) => {
      switch (action) {
        case 'changePage':
          handlePageChange(muiTableState);
          break;
        case 'sort':
          handleSortChange(muiTableState);
          break;
        case 'filterChange':
        case 'resetFilters':
          handleFilterChange({ muiTableState, state });
          break;
        case 'search':
        case 'onSearchClose':
          debouncedHandleSearchChange({ muiTableState, state });
          break;
        default:
      }
    },
  };
  return [options];
};

export default useTablePagination;
